import React from 'react'
import Export from '../../../../components/Export'
import { getTrad } from '../../../../lang'
import { exportheaders } from './consts'

function RenderHader({ data = [] }) {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-6">
          <h2 className="card-title">{getTrad('navbar.list_orphelin')}</h2>
        </div>
        <div className="col-6 text-right">
          <div className="btn-group" role="group" aria-label="Basic example">
            <Export
              headers={exportheaders}
              title={getTrad('commun.export')}
              fileName={'liste_enftans_orphelins'}
              csvData={data}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenderHader
