import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import InputText from '../../../../components/Form/InputText'
import NumInput from '../../../../components/Form/NumInput'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import { successMsg, fatalError } from '../../../../helpers/sweetAlerts'

function ShowAdherant(props) {
  if (props.loadingAdherant)
    return (
      <div className="alert alert-info text-center">
        <Waiting />
      </div>
    )
  if (props.adherant === null) {
    return (
      <div className="alert alert-danger text-center">{getTrad('errors.giveValidMatricule')}</div>
    )
  }
  return (
    <div className="alert alert-success">
      <div>
        <span className="font-weight-bold">{getTrad('fileds.matricule')} :</span>
        <span className="ml-1">{props.adherant.matricule}</span>
      </div>
      <div>
        <span className="font-weight-bold">{getTrad('fileds.first_name')} :</span>
        <span className="ml-1">{props.adherant.first_name}</span>
      </div>
      <div>
        <span className="font-weight-bold">{getTrad('fileds.last_name')} :</span>
        <span className="ml-1">{props.adherant.last_name}</span>
      </div>
    </div>
  )
}

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      matricule: '',
      total: '',
      nb_echeance: '',
      sous_compte: '',
      adherant: null,
      loadingAdherant: false,
      sendingData: false,
    }
  }

  updateAdherant = () => {
    this.setState({ loadingAdherant: true }, () => {
      axios
        .get(getUrl('adherants.info', [this.state.matricule]))
        .then(res => this.setState({ loadingAdherant: false, adherant: res.data.success }))
        .catch(() => this.setState({ loadingAdherant: false, adherant: null }))
    })
  }

  handleData = event => this.setState({ [event.target.name]: event.target.value })

  handleShow = () => {
    if (this.state.sendingData) return
    this.setState({ show: !this.state.show }, this.refreshProps)
  }

  send = () => {
    if (!this.verifChange) return

    this.setState({ sendingData: true }, () => {
      const data = {
        matricule: this.state.matricule,
        sous_compte: this.state.sous_compte,
        nb_echeance: this.state.nb_echeance,
        total: this.state.total,
      }
      axios
        .patch(getUrl('parent.contract.update', [this.props.id]), data)
        .then(res => {
          this.setState({ sendingData: false }, () => {
            successMsg(getTrad('success.update.contact'))
            this.props.refresh()
            this.handleShow()
          })
        })
        .catch(err => {
          this.setState({ sendingData: false }, () => {
            fatalError()
            this.props.refresh()
            this.handleShow()
          })
        })
    })
  }

  refreshProps = () => {
    this.setState({
      matricule:
        this.props.adherant && this.props.adherant.matricule ? this.props.adherant.matricule : '',
      sous_compte: this.props.sous_compte ? this.props.sous_compte : '',
      total: this.props.totalValue ? this.props.totalValue : '',
      nb_echeance: this.props.nb_echeance ? this.props.nb_echeance : '',
    })
  }

  verifChange = () => {
    if (this.state.loadingAdherant) return false
    if (this.state.adherant === null) return false
    const propsSousCompte = this.props.sous_compte ? this.props.sous_compte : ''
    const propsTotal = this.props.totalValue ? this.props.totalValue : ''
    const propsNbEcheance = this.props.nb_echeance ? this.props.nb_echeance : ''
    if (this.state.matricule !== this.props.adherant.matricule) return true
    if (this.state.sous_compte !== propsSousCompte) return true
    if (this.state.total !== propsTotal) return true
    if (this.state.nb_echeance !== propsNbEcheance) return true
    return false
  }

  componentDidMount() {
    this.refreshProps()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.refreshProps()
    }
    if (prevState.matricule !== this.state.matricule) {
      this.updateAdherant()
    }
  }

  render() {
    const fullname = this.props.adherant
      ? this.props.adherant.first_name + ' ' + this.props.adherant.last_name
      : ''
    return (
      <>
        <button onClick={this.handleShow} className="btn btn-sm btn-secondary">
          <i className="fas fa-edit" />
        </button>
        <Modal show={this.state.show} onHide={() => null}>
          <Modal.Header>
            <Modal.Title>{getTrad('parent.edit.title', [fullname, this.props.id])}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputText
              label={getTrad('fileds.matricule')}
              value={this.state.matricule}
              handler={this.handleData}
              name="matricule"
            />
            <ShowAdherant
              loadingAdherant={this.state.loadingAdherant}
              adherant={this.state.adherant}
            />

            <NumInput
              value={this.state.total}
              handler={this.handleData}
              label={getTrad('fileds.total')}
              name="total"
            />
            <NumInput
              value={this.state.nb_echeance}
              handler={this.handleData}
              label={getTrad('fileds.nb_echeance')}
              name="nb_echeance"
            />

            <InputText
              label={getTrad('fileds.sous_compte')}
              value={this.state.sous_compte}
              handler={this.handleData}
              name="sous_compte"
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={!this.verifChange() || this.state.sendingData}
              onClick={this.send}
              className="btn btn-success"
            >
              {this.state.sendingData ? (
                <Waiting />
              ) : (
                <>
                  <i className="fas fa-check" /> {getTrad('commun.edit')}
                </>
              )}
            </button>
            <button
              disabled={this.state.sendingData}
              onClick={this.handleShow}
              className="btn btn-danger"
            >
              <i className="fas fa-times" /> {getTrad('commun.close')}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Edit
