import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { getTrad } from '../../../../lang'
import axios from 'axios'
import { successMsg, fatalError } from '../../../../helpers/sweetAlerts'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import Waiting from '../../../../components/Waiting'
const today = new Date()

const initialValues = {
  montant: 50,
  type: 'E',
  date: today.toISOString().slice(0, 10),
}

const typeVersements = [
  { value: 'E', text: getTrad('credit.type_remboursement.E') },
  { value: 'C', text: getTrad('credit.type_remboursement.C') },
  { value: 'B', text: getTrad('credit.type_remboursement.B') },
  { value: 'P', text: getTrad('credit.type_remboursement.P') },
  { value: 'V', text: getTrad('credit.type_remboursement.V') },
]

const formValidation = values => {
  const testDate = values.date ? new Date(values.date) : null
  const errors = {}
  if (!values.montant) {
    errors.montant = getTrad('validation.required')
  }
  if (!testDate) {
    errors.date = getTrad('validation.required')
  }
  if (testDate > today) {
    errors.date = getTrad('errors.date.beforeToday')
  }
  return errors
}

function ErrorDiv({ children }) {
  return (
    <div>
      <small className="text-danger">{children}</small>
    </div>
  )
}

function Pay({ id }) {
  const [show, setShow] = useState(false)
  const toogle = () => setShow(!show)

  const onSubmit = (values, { resetForm }) => {
    return axios
      .post('/veuve', { ...values, id_adherant: id })
      .then(() => {
        successMsg(getTrad('success.add'))
        resetForm()
      })
      .catch(fatalError)
  }

  return (
    <>
      <button onClick={toogle} className="btn btn-success">
        <i className="fas fa-cash-register"></i>
      </button>
      <Modal show={show} onHide={toogle}>
        <Modal.Header closeButton>
          <Modal.Title>{getTrad('adherant.addremboursement')}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={formValidation}
          onReset={() => {
            toogle()
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Body>
                <label htmlFor="date">{getTrad('fileds.date')}</label>
                <ErrorMessage component={ErrorDiv} name="date" />
                <Field
                  className="form-control form-control-sm"
                  name="date"
                  type="date"
                  placeholder={getTrad('fileds.date')}
                />

                <label htmlFor="montant">{getTrad('fileds.montant')}</label>
                <ErrorMessage component={ErrorDiv} name="montant" />
                <Field
                  className="form-control form-control-sm"
                  name="montant"
                  type="number"
                  placeholder={getTrad('fileds.montant')}
                />

                <label htmlFor="type">{getTrad('fileds.type')}</label>
                <ErrorMessage component={ErrorDiv} name="type" />
                <Field
                  as="select"
                  className="form-control form-control-sm"
                  name="type"
                  placeholder={getTrad('fileds.type')}
                >
                  {typeVersements.map(({ value, text }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" disabled={isSubmitting} className="btn btn-success">
                  {isSubmitting ? (
                    <Waiting />
                  ) : (
                    <>
                      <i className="fas fa-check mr-2"></i> {getTrad('commun.add')}
                    </>
                  )}
                </button>
                <button type="reset" disabled={isSubmitting} className="btn btn-danger">
                  <i className="fas fa-times mr-2"></i> {getTrad('commun.close')}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default Pay
