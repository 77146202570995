import axios from 'axios'
import React from 'react'
import { useMutation } from 'react-query'
import { getTrad } from '../../../../lang'
import { successMsg, errorMsg, areYouSurePromise } from '../../../../helpers/sweetAlerts'
import Waiting from '../../../../components/Waiting'

function DeleteAdmin({ admin, refresh }) {
  const { isLoading, mutate } = useMutation(
    () => axios.delete('/gestion-admins/deactivate/' + admin),
    {
      onSuccess: () => {
        successMsg(getTrad('success.deletedAdin'))
        refresh()
      },
      onError: err => {
        if (err.response.status === 401) {
          errorMsg(getTrad('errors.mustHaveSuperUsers'))
        } else {
          errorMsg(getTrad('errors.cummonError'))
        }
      },
      onMutate: areYouSurePromise,
    }
  )
  if (isLoading) return <Waiting />
  return (
    <button type="button" className="btn btn-danger btn-sm" onClick={mutate}>
      <i className="fas fa-trash"></i>
    </button>
  )
}

export default DeleteAdmin
