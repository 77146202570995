import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import InputText from '../../../components/Form/InputText'
import InputDate from '../../../components/Form/InputDate'
import { getTrad } from '../../../lang'
import { getUrl } from '../../../Api'
import axios from 'axios'
import { convertDate } from '../../../helpers'
import { fatalError, successMsg } from '../../../helpers/sweetAlerts'

function AdherantInfo(props) {
  if (props.id)
    return (
      <div className="alert alert-success">
        <table>
          <tbody>
            <tr>
              <td className="info-adherant-label">{getTrad('fileds.first_name')}</td>
              <td>{props.first_name}</td>
            </tr>
            <tr>
              <td className="info-adherant-label">{getTrad('fileds.last_name')}</td>
              <td>{props.last_name}</td>
            </tr>
            <tr>
              <td className="info-adherant-label">{getTrad('fileds.matricule_cnrps')}</td>
              <td>{props.matricule_cnrps}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  return (
    <div className="alert alert-danger">
      <h3>{getTrad('errors.matriculeNotFound')}</h3>
    </div>
  )
}

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      sending: false,
      id_credit: null,
      adherant: null,
      matricule_adh: '',
      montant_demande: '',
      date_versement: '',
      frais: '',
      montant: '',
      nb_echeance: '',
      date_demande: '',
      sous_compte: '',
    }
  }

  refreshProps = () => {
    this.setState(
      {
        id_credit: this.props.id ? this.props.id : '',
        matricule_adh: this.props.matricule_adh ? this.props.matricule_adh : '',
        montant_demande: this.props.montant_demande ? this.props.montant_demande : '',
        montant: this.props.montant_val ? this.props.montant_val : '',
        nb_echeance: this.props.nb_echeance ? this.props.nb_echeance : '',
        date_demande: this.props.date_demande ? this.props.date_demande : '',
        frais: this.props.frais_dossier ? this.props.frais_dossier : '',
        sous_compte: this.props.sous_compte ? this.props.sous_compte : '',
        date_versement: this.props.date_versement ? this.props.date_versement : '',
      },
      this.updateAdherant
    )
  }

  canSubmit = () => {
    const props = {
      id_credit: this.props.id ? this.props.id : '',
      matricule_adh: this.props.matricule_adh ? this.props.matricule_adh : '',
      montant_demande: this.props.montant_demande ? this.props.montant_demande : '',
      montant: this.props.montant_val ? this.props.montant_val : '',
      nb_echeance: this.props.nb_echeance ? this.props.nb_echeance : '',
      frais_dossier: this.props.frais_dossier ? this.props.frais_dossier : '',
      date_demande: this.props.date_demande ? this.props.date_demande : '',
      sous_compte: this.props.sous_compte ? this.props.sous_compte : '',
      date_versement: this.props.date_versement ? this.props.date_versement : '',
    }

    if (this.state.sending) return false
    if (this.state.adherant === null) return false
    if (this.state.matricule_adh.toString() !== props.matricule_adh.toString()) return false
    if (this.state.montant_demande.toString() !== props.montant_demande.toString()) return false
    if (this.state.frais.toString() !== props.frais_dossier.toString()) return false
    if (this.state.montant.toString() !== props.montant.toString()) return false
    if (this.state.nb_echeance.toString() !== props.nb_echeance.toString()) return false
    if (this.state.date_demande.toString() !== props.date_demande.toString()) return false
    if (this.state.sous_compte.toString() !== props.sous_compte.toString()) return false
    if (this.state.date_versement.toString() !== props.date_versement.toString()) return false
    return true
  }

  updateAdherant = () => {
    const mat = this.state.matricule_adh
    if (mat) {
      const url = getUrl('adherants.info', [mat])
      axios
        .get(url)
        .then(res => {
          this.setState({ adherant: res.data.success })
        })
        .catch(err => this.setState({ adherant: null }))
    }
  }

  componentDidMount() {
    this.refreshProps()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.seed !== this.props.seed) {
      this.refreshProps()
    }
  }

  handleInput = event => {
    const callback = event.target.name === 'matricule_adh' ? this.updateAdherant : () => null
    this.setState({ [event.target.name]: event.target.value }, callback)
  }

  send = event => {
    event.preventDefault()
    this.setState({ sending: true }, () => {
      const data = {
        matricule_adh: this.state.matricule_adh,
        montant_demande: this.state.montant_demande,
        frais: this.state.frais,
        montant: this.state.montant,
        nb_echeance: this.state.nb_echeance,
        date_demande: convertDate(this.state.date_demande),
        date_versement: convertDate(this.state.date_versement),
        sous_compte: this.state.sous_compte,
      }
      axios
        .patch(getUrl('credits.update', [this.props.id]), data)
        .then(res => {
          successMsg(getTrad('success.update.credit'))
          this.setState({ sending: false }, this.props.refresh)
        })
        .catch(err => {
          this.setState({ sending: false }, this.props.refresh)
          fatalError()
        })
    })
  }

  reset = () => {
    this.setState({ show: false }, this.refreshProps)
  }

  handleShow = () => this.setState({ show: !this.state.show })

  render() {
    return (
      <>
        <button onClick={this.handleShow} className="btn btn-sm btn-secondary">
          <i className="fas fa-edit" />
        </button>
        <Modal onHide={() => null} show={this.state.show}>
          <form onSubmit={this.send} onReset={this.reset}>
            <Modal.Body>
              {/* : this.props.matricule_adh, : this.props.montant_demande, : this.props.montant,
            : this.props.nb_echeance, : this.props.date_demande, : this.props.sous_compte, :
            this.props.montant_echance_val, */}
              <InputText
                label={getTrad('fileds.matricule_adh')}
                name="matricule_adh"
                handler={this.handleInput}
                value={this.state.matricule_adh.toString()}
              />
              <AdherantInfo {...this.state.adherant} />
              <InputText
                label={getTrad('fileds.montant_demande')}
                name="montant_demande"
                handler={this.handleInput}
                value={this.state.montant_demande.toString()}
              />
              <InputText
                label={getTrad('fileds.montant')}
                name="montant"
                handler={this.handleInput}
                value={this.state.montant.toString()}
              />
              <InputText
                label={getTrad('fileds.frais')}
                name="frais"
                handler={this.handleInput}
                value={this.state.frais.toString()}
              />
              <InputText
                label={getTrad('fileds.nb_echeance')}
                name="nb_echeance"
                handler={this.handleInput}
                value={this.state.nb_echeance.toString()}
              />
              <InputDate
                label={getTrad('fileds.date_demande')}
                name="date_demande"
                handler={this.handleInput}
                value={this.state.date_demande.toString()}
              />
              <InputText
                label={getTrad('fileds.sous_compte')}
                name="sous_compte"
                handler={this.handleInput}
                value={this.state.sous_compte.toString()}
              />
              <InputDate
                label={getTrad('fileds.date_versement')}
                name="date_versement"
                handler={this.handleInput}
                value={this.state.date_versement.toString()}
              />
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" disabled={this.canSubmit()} className="btn btn-success">
                <i className="fas mr-2 fa-check"></i>
                {getTrad('commun.edit')}
              </button>
              <button type="reset" disabled={this.state.sending} className="btn btn-danger">
                <i className="fas mr-2 fa-times"></i>
                {getTrad('commun.cancel')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}
export default Edit
