import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../lang'
import { Tabs, Tab } from 'react-bootstrap'
import TableRow from './TableRow'
const thisYear = new Date().getFullYear()

function Table(props) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>{getTrad('fileds.date')}</th>
          <th>{getTrad('fileds.montant')}</th>
          <th>{getTrad('fileds.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((e, k) => (
          <TableRow key={k} {...e} />
        ))}
      </tbody>
    </table>
  )
}

function ShowCessions(props) {
  const [show, setShow] = useState(false)
  const [key, setKey] = useState(thisYear)

  let years = []
  let data = {}

  for (var i = 0; i < props.cession_parents.length; i++) {
    const cession = props.cession_parents[i]
    for (var j = 0; j < cession.cesions.length; j++) {
      const row = cession.cesions[j]
      const year = new Date(row.date).getFullYear()
      if (!years.includes(year)) {
        years.push(year)
        data[year] = []
      }
      data[year].push(row)
    }
  }

  // let years = [
  //   ...new Set(
  //     ...props.cession_parents.map(e => [
  //       ...new Set(e.cesions.map(ele => new Date(ele.date).getFullYear())),
  //     ])
  //   ),
  // ].sort()
  // let data = {}
  //
  // for (var i = 0; i < years.length; i++) {
  //   const year = years[i]
  //   data[year] = props.cession_parents
  //     .map(e => {
  //       return e.cesions
  //     })
  //     .filter(e => new Date(e.date).getFullYear() === year)
  // }

  const handleShow = () => setShow(!show)
  return (
    <>
      <button onClick={handleShow} className="btn btn-sm btn-primary">
        <i className="fas fa-eye" />
      </button>
      <Modal show={show} onHide={handleShow}>
        <Modal.Header>
          <Modal.Title>
            {props.last_name} {props.first_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
            {years.map((e, k) => (
              <Tab key={k} eventKey={e} title={e}>
                <Table deleteItem={props.deleteItem} data={data[e]} />
              </Tab>
            ))}
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShowCessions
