import React from 'react'
import { getTrad } from '../../../../lang'
import NumInput from '../../../../components/Form/NumInput'
import InputText from '../../../../components/Form/InputText'

function FilterFinis(props) {
  return (
    <div className="row">
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule}
          label={getTrad('fileds.matricule')}
          name="matricule"
        />
      </div>
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule_cnrps}
          label={getTrad('fileds.matricule_cnrps')}
          name="matricule_cnrps"
        />
      </div>

      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.sous_compte}
          label={getTrad('fileds.sous_compte')}
          name="sous_compte"
        />
      </div>

      <div className="col">
        <InputText
          handler={props.handler}
          value={props.first_name}
          label={getTrad('fileds.first_name')}
          name="first_name"
        />
      </div>
      <div className="col">
        <InputText
          handler={props.handler}
          value={props.last_name}
          label={getTrad('fileds.last_name')}
          name="last_name"
        />
      </div>
    </div>
  )
}

export default FilterFinis
