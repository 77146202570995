// import React, { Component, } from 'react'
// import Swal from 'sweetalert2'
import axios from 'axios'

import { useState, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'

// import DateInput from '../../../components/Form/DateInput'
// import NumberInput from '../../../components/Form/NumberInput'
// import TextInput from '../../../components/Form/TextInput'
// import AutoComplete from '../../../components/AutoComplete2'

// import { convertDate } from '../../../helpers'
import { successMsg, errorMsg, areYouSure } from '../../../helpers/sweetAlerts'
import { getTrad, useTrad } from '../../../lang'
import { useMutation } from 'react-query'
import { LangContext } from '../../../context/LangContext'
import Waiting from '../../../components/Waiting'
const today = new Date()
function Header() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{useTrad('pages.newCredit')}</h2>
        </div>
      </div>
    </div>
  )
}

function Status({ matricule, messageError }) {
  const { mutate, data: { canGet } = { canGet: {} } } = useMutation(
    'get-adherant-status-' + matricule,
    () => axios.get(`bulletins/${matricule}/plafond`).then(res => res.data.success)
  )

  useEffect(() => {
    if (matricule) mutate()
  }, [matricule, mutate])

  if (messageError) {
    return <div className={'alert my-3 alert-danger'}>{messageError}</div>
  }

  return (
    matricule && (
      <div className={`alert my-3 alert-${canGet.status ? 'success' : 'danger'}`}>
        {(canGet.message || '').split('|').map((mess, index) => (
          <div key={index}>{mess}</div>
        ))}
      </div>
    )
  )
}

function AdherantInfo({ matricule = '' }) {
  const {
    mutate,
    isError,
    isLoading,
    data: { first_name, last_name, status } = {},
  } = useMutation(() => axios.get(`/adherants/${matricule}`).then(res => res.data.success))
  useEffect(() => {
    if (matricule) mutate()
  }, [matricule, mutate])

  const lang = useContext(LangContext)

  return (
    matricule &&
    !isError && (
      <div id="infoAdherant" className="mb-3">
        {isLoading ? (
          <Waiting />
        ) : (
          <table>
            <tbody>
              <tr>
                <td className="info-adherant-label">{getTrad('fileds.first_name')}</td>
                <td>{first_name}</td>
              </tr>
              <tr>
                <td className="info-adherant-label">{getTrad('fileds.last_name')}</td>
                <td>{last_name}</td>
              </tr>
              <tr>
                <td className="info-adherant-label">{getTrad('fileds.status')}</td>
                <td>{lang.extra.status[status]}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  )
}

const initialValues = {
  mat_adherant: '',
  montant_demande: 0,
  date_demande: today.toISOString().slice(0, 10),
  motif: '',
}

const formValidation = values => {
  const testDate = values.date_demande ? new Date(values.date_demande) : null
  const errors = {}
  if (!values.mat_adherant) {
    errors.mat_adherant = getTrad('validation.required')
  }
  if (!values.mat_adherant) {
    errors.mat_adherant = getTrad('validation.required')
  }
  if (!values.montant_demande) {
    errors.montant_demande = getTrad('validation.required')
  }
  if (!testDate) {
    errors.date_demande = getTrad('validation.required')
  }
  if (testDate > today) {
    errors.date_demande = getTrad('errors.date.beforeToday')
  }
  return errors
}

function ErrorDiv({ children }) {
  return (
    <div>
      <small className="text-danger">{children}</small>
    </div>
  )
}

export default function Add() {
  const [redirect, setRedirect] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const onSubmit = (values, { resetForm }) => {
    return new Promise((resolve, reject) => {
      areYouSure.fire().then(async result => {
        if (result.value) {
          axios
            .post('/credit', values)
            .then(() => {
              successMsg(getTrad('success.addDemandeCredit'))
              setRedirect(true)
            })
            .catch(err => {
              if (err.response) {
                if (err.response.status === 401) {
                  setMessageError(getTrad('errors.hasOtherCredit'))
                } else {
                  setMessageError(getTrad('errors.cummonError'))
                }
              } else {
                setMessageError(getTrad('errors.cummonError'))
              }
              resolve()
            })
        } else {
          resolve()
        }
      })
    }).catch(() => null)
  }

  if (redirect) return <Redirect to={'/credit/demande'} />

  return (
    <div className="col-md-8 mx-auto">
      <div className="card ">
        <Header />
        <div className="card-body">
          <Formik initialValues={initialValues} onSubmit={onSubmit} validate={formValidation}>
            {({ isSubmitting, values: { mat_adherant }, ...others }) => (
              <Form>
                <Status matricule={mat_adherant} messageError={messageError} />
                <AdherantInfo matricule={mat_adherant} />
                <div className="form-group">
                  <label htmlFor="mat_adherant">{getTrad('fileds.matricule_adh')}</label>
                  <ErrorMessage component={ErrorDiv} name="mat_adherant" />
                  <Field
                    className="form-control form-control-sm"
                    name="mat_adherant"
                    type="text"
                    placeholder={getTrad('fileds.matricule_adh')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="montant_demande">{getTrad('fileds.montant')}</label>
                  <ErrorMessage component={ErrorDiv} name="montant_demande" />
                  <Field
                    className="form-control form-control-sm"
                    name="montant_demande"
                    type="number"
                    placeholder={getTrad('fileds.montant')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="date_demande">{getTrad('fileds.date_debut')}</label>
                  <ErrorMessage component={ErrorDiv} name="date_demande" />
                  <Field
                    className="form-control form-control-sm"
                    name="date_demande"
                    type="date"
                    placeholder={getTrad('fileds.date_debut')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="motif">{getTrad('fileds.motif')}</label>
                  <ErrorMessage component={ErrorDiv} name="motif" />
                  <Field
                    className="form-control form-control-sm"
                    name="motif"
                    type="text"
                    placeholder={getTrad('fileds.motif')}
                  />
                </div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn-sm w-100 btn btn-success"
                >
                  {isSubmitting ? <Waiting /> : getTrad('credit.demande')}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
