import React, { Component } from 'react'
import { getTrad } from '../../../../lang'
import axios from 'axios'
import Waiting from '../../../../components/Waiting'
import { getUrl } from '../../../../Api'
import StatCard from './StatCard'

const thisYear = new Date().getFullYear()

class Statistiques extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('aide_sociale.stats'))
        .then(res =>
          this.setState({
            loading: false,
            data: res.data.success.sort((a, b) => b.annee - a.annee),
          })
        )
        .catch(() => this.setState({ loading: false }))
    })
  }

  render() {
    if (this.state.loading) return <Waiting />
    return (
      <>
        <div className="card bg-light rounded p-3 my-3">
          <h1 className="text-dark p-0 m-0 text-center">
            {getTrad('pages.statistiques.aides_sociales')}
          </h1>
        </div>
        {this.state.data.map((e, k) => (
          <StatCard show={thisYear.toString() === e.annee.toString()} key={k} {...e} />
        ))}
      </>
    )
  }
}

export default Statistiques
