import axios from 'axios'
import React, { Component, createRef } from 'react'
import { Redirect } from 'react-router-dom'
import { areYouSure, successToast, fatalError } from '../../../../helpers/sweetAlerts'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import { showDate, showMoney, inString } from '../../../../helpers'
import ShowTable from '../../../../components/ShowTable'
import CardHeader from '../../../../components/CardHeader'
import Export from '../../../../components/Export'
import Details from './Edit'
import Filter from './Filter'
export default class Liste extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      redirect: false,
      name: '',
      adherants: [],
      matricule: '',
      nb_beneficiaire: '',
      search_matricule: '',
      search_matricule_cnrps: '',
      search_first: '',
      search_last: '',
    }
  }

  demandeRef = createRef()

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    const {
      params: { id },
    } = this.props.match || { params: {} }
    const {
      params: { id: oldId },
    } = prevProps.match || { params: {} }

    if (id !== oldId) this.refresh()
  }

  clearData = e => ({
    id: e.id,
    matricule: e.matricule,
    matricule_cnrps: e.matricule_cnrps,
    first_name: e.first_name,
    last_name: e.last_name,
    montant: showMoney(e.montant),
    nb_beneficiaire: e.nb_beneficiaire,
    depuis: showDate(e.depuis),
    details: (
      <Details
        id={e.id}
        matricule={e.matricule}
        first_name={e.first_name}
        birth_day={e.birth_day}
        last_name={e.last_name}
      />
    ),
  })

  refresh = () => {
    const {
      params: { id },
    } = this.props.match || { params: {} }

    if (id) {
      this.setState({ redirect: false, loading: true })
      axios
        .get(getUrl('aide_sociale.show', [id]))
        .then(res => {
          this.setState({
            ...res.data.success,
            adherants: res.data.success.adherants
              .filter(adherant => adherant.nb_beneficiaire !== 0)
              .map(this.clearData),
            loading: false,
          })
        })
        .catch(err => {
          this.setState({ redirect: true, loading: false })
        })
    } else {
      this.setState({ redirect: true, loading: false })
    }
  }

  generate = () => {
    areYouSure.fire().then(response => {
      if (response.isConfirmed) {
        axios
          .get(getUrl('aide_sociale.generate', [this.state.id]))
          .then(res => {
            successToast.fire(getTrad('aide_sociale.success.generate'))
            this.refresh()
          })
          .catch(err => {
            fatalError()
            this.refresh()
          })
      }
    })
  }

  handleSearch = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    if (!inString(this.state.search_matricule, e.matricule)) return false
    if (!inString(this.state.search_matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.search_first, e.first_name)) return false
    if (!inString(this.state.search_last, e.last_name)) return false
    return true
  }

  render() {
    if (this.state.redirect) return <Redirect to="/aide-sociale" />

    let keys = [
      'matricule',
      'matricule_cnrps',
      'first_name',
      'last_name',
      'nb_beneficiaire',
      'montant',
      'depuis',
      'details',
    ]

    let header = {}

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`aidesociale.fileds.${k}`)
    }

    let showProps = {
      data: this.state.adherants.filter(this.filter),
      keys: keys,
      searching: this.state.loading,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }

    return (
      <>
        <div className="card">
          <CardHeader
            title={this.state.name}
            actions={
              <div className="btn-group">
                <button onClick={this.generate} className="btn btn-dark">
                  <i className="fas fa-cogs" /> {getTrad('aidesociale.generate')}
                </button>
                <Export
                  title={getTrad('commun.export')}
                  fileName={`aide_sociale_${this.state.name}`}
                  csvData={this.state.adherants}
                />
              </div>
            }
          />
          <Filter
            search_matricule={this.state.search_matricule}
            search_matricule_cnrps={this.state.search_matricule_cnrps}
            search_first={this.state.search_first}
            search_last={this.state.search_last}
            handler={this.handleSearch}
          />
          <div className="card-body p-0">
            <ShowTable {...showProps} />
          </div>
        </div>
      </>
    )
  }
}
