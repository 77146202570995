import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { getTrad } from '../../../../lang'
import Waiting from '../../../../components/Waiting'
import TextInput from '../../../../components/Form/TxtInput'
import NumInput from '../../../../components/Form/NumInput'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import { fatalError, areYouSure, successToast } from '../../../../helpers/sweetAlerts'

export default class Rembours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remboursemement: '',
      obs: '',
      sending: false,
      show: false,
      errors: {
        remboursemement: [],
        obs: [],
      },
    }
    this.hide = this.hide.bind(this)
    this.sendFlow = this.sendFlow.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  show = () => {
    this.setState({
      show: true,
      obs: this.props.observation,
      remboursemement: this.props.remboursemement,
    })
  }

  hide() {
    this.setState({ show: false, remboursemement: '', obs: '' })
  }

  hideAndReload() {
    successToast.fire({ title: getTrad('success.remboursSuccess') })
    this.setState({ show: false, sending: false, remboursemement: '', obs: '' }, this.props.reload)
  }

  sendFlow() {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.send)
    })
  }

  send() {
    let data = {
      remboursemement: this.state.remboursemement,
      observations: this.state.obs,
    }

    axios
      .post(getUrl('bs.rembours', [this.props.matricule]), data)
      .then(res => this.hideAndReload())
      .catch(error => {
        if (error.response) {
          this.setState({ sending: false })
          if (error.response.status === 404) {
            fatalError()
            this.hide()
            this.props.reload()
          } else if (error.response.status === 422) {
            let err = {
              remboursemement: [],
              obs: [],
            }

            if (error.response.data.errors.remboursemement) {
              err.remboursemement = error.response.data.errors.remboursemement.map(e =>
                getTrad(e, [getTrad('bs.total_remboursement')])
              )
            }
            if (error.response.data.errors.observations) {
              err.obs = error.response.data.errors.observations.map(e => getTrad(e))
            }
            this.setState({ errors: err, sending: false })
          }
        }
      })
  }

  handleInput(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  SendBtn() {
    let inner = getTrad('commun.refound')
    let btnProps = {
      className: 'w-100 btn btn-success',
      disabled: false,
      onClick: this.sendFlow,
    }
    if (this.state.sending) {
      inner = <Waiting />
      btnProps.disabled = true
    }
    return <button {...btnProps}>{inner}</button>
  }

  render() {
    return (
      <>
        <Button variant="success" className="m-0 w-100" size="sm" onClick={this.show}>
          <i className="fas fa-reply"></i>
        </Button>
        <Modal show={this.state.show} onHide={this.hide} dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {getTrad('bs.rembours')} {this.props.matricule}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NumInput
              value={this.state.remboursemement}
              label={getTrad('bs.total_remboursement')}
              handler={this.handleInput}
              error={this.state.errors.remboursemement}
              name="remboursemement"
            />
            <TextInput
              value={this.state.obs}
              label={getTrad('bs.observations')}
              handler={this.handleInput}
              errors={this.state.errors.obs}
              name="obs"
            />
          </Modal.Body>
          <Modal.Footer>{this.SendBtn()}</Modal.Footer>
        </Modal>
      </>
    )
  }
}
