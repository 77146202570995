import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import List from './List'
import Show from './Show'
import AddBS from './AddBs'
import AddBsSelf from './AddBsSelf'
import EditBS from './Edit'
import Search from './search'
import Bordereau from './Bordereau'
import DetailsBord from './DetailsBord'
import Statistiques from '../../../components/Stats'
import Demandes from './Demandes'
import StatAdherant from './StatAdherant'
import ShowBS from './ShowBS'
import Virements from './Virements'
import { userStore } from '../../../store/userStore'

export default function BultinSoins(props) {
  let { path } = useRouteMatch()

  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  if (isNotAdmin(role)) {
    return (
      <Switch>
        <Route exact path={path}>
          <Show />
        </Route>
        <Route path={`${path}/add`}>
          <AddBsSelf />
        </Route>
        <Route>
          <Redirect to={path} />
        </Route>
      </Switch>
    )
  }
  return (
    <Switch>
      <Route exact path={path}>
        <List />
      </Route>
      <Route path={`${path}/add`}>
        <AddBS action="A" />
      </Route>
      <Route path={`${path}/demande`}>
        <Demandes action="A" />
      </Route>
      <Route path={`${path}/virements`}>
        <Virements action="A" />
      </Route>
      <Route path={`${path}/recherche`}>
        <Search />
      </Route>
      <Route exact path={`${path}/bordereaux`}>
        <Bordereau {...props} />
      </Route>
      <Route exact path={`${path}/bordereaux/:id`} component={DetailsBord}></Route>
      <Route exact path={`${path}/statistiques-adherant`}>
        <StatAdherant />
      </Route>
      <Route exact path={`${path}/statistiques`}>
        <Statistiques
          barKeys={[]}
          tableKeys={[
            { key: 'number_bs', label: 'bs.nb_bss' },
            { key: 'numbers', label: 'bs.nb_bords' },
            { key: 'sommes', label: 'bs.montant_bss', isMoney: true },
            { key: 'remboursemement', label: 'bs.montant_remboursement', isMoney: true },
          ]}
          path={path}
          page="pages.statistiques.bs"
          statsUrl="bs.stats"
        />
      </Route>
      <Route path={`${path}/:matricule/edit`} component={EditBS}></Route>
      <Route path={`${path}/:matricule/show`} component={ShowBS}></Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
