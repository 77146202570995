import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import { Redirect } from 'react-router-dom'
import Demande from './Demande'
import Liste from './Liste'

export default class AideSociales extends Component {
  constructor(props) {
    super(props)
    this.state = { listeDemande: [], error: false, loading: true }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('aidesociale.myData'))
        .then(res => this.setState({ listeDemande: res.data.success, loading: false }))
        .catch(err => this.setState({ error: true, loading: false }))
    })
  }

  render() {
    if (this.state.loading) return <Waiting />
    if (this.state.error) return <Redirect to="/" />
    return (
      <>
        <Demande {...this.props} {...this.state} refresh={this.refresh} />
        <Liste {...this.props} {...this.state} />
      </>
    )
  }
}
