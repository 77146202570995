import React, { Component, createRef } from 'react'
import axios from 'axios'
import { Api, getUrl } from '../../../Api'
import Swal from 'sweetalert2'
import Waiting from '../../../components/Waiting'
import { validDate, convertDate, showDate } from '../../../helpers'
import { getTrad } from '../../../lang'
import BSInfo from './components/infoBS'
import BSFields from './components/bsFilds'
import BSHeader from './components/headerBS'
import { fatalError, areYouSure } from '../../../helpers/sweetAlerts'
import { Redirect } from 'react-router-dom'

function Plafond(props) {
  if (props.benifPlafondLoading) return <></>
  if (props.canGet === true)
    return (
      <div className="alert text-center rounded-0 alert-success" role="alert">
        {getTrad('bs.depacepas', [`${props.current} `, props.plafond])}
      </div>
    )
  if (props.canGet === false)
    return (
      <div className="alert text-center rounded-0 alert-danger" role="alert">
        {getTrad('bs.depace', [`${props.current} `, props.plafond])}
      </div>
    )
  return <></>
}

export default class EditBs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      redirect: false,
      loadingPresta: false,
      prestatires: [],
      oldMatricule: null,
      action: 'U',
      bsFields: [],
      bsData: {},
      benifPlafondLoading: false,
      benifPlafond: {
        plafond: '',
        current: '',
        canGet: null,
      },
      bsHeader: {
        id_bs: '',
        mat_bultin: '',
        date_soin: '',
        mat_adherant: '',
        id_prestatire: '',
        description: '',
        complement: '',
      },
      errors: { mat_bultin: null, date_soin: null },
    }
    this.send = this.send.bind(this)
    this.complementCalc = createRef()
    this.handleInput = this.handleInput.bind(this)
  }

  getData() {
    axios
      .get(Api.bs.crud + '/' + this.state.oldMatricule)
      .then(res => {
        let tmp = res.data.success
        if (!['NT', 'V'].includes(tmp.status)) {
          this.setState({ loading: false, redirect: true })
        } else {
          let vals = JSON.parse(JSON.stringify(this.state.bsData))
          res.data.success.values.map(e => (vals[e.bs_champs_id] = e.bs_champs_value))
          this.setState(
            {
              bsHeader: {
                id_bs: tmp.id,
                mat_bultin: tmp.mat_bultin,
                date_soin: showDate(tmp.date_soin),
                mat_adherant: tmp.adherant.matricule,
                id_prestatire: tmp.id_prestatire,
                description: tmp.description,
                complement: tmp.complement,
              },
              bsData: vals,
              loading: false,
              redirect: false,
              loadingPresta: false,
            },
            () => this.updatePrestataire(tmp.id_prestatire)
          )
        }
      })
      .catch(fatalError)
  }

  getBs = () => {
    const { matricule } = this.props.match.params || {}

    this.setState({ oldMatricule: matricule, loading: true }, this.getData)
  }

  handleCalcComp = value => {
    let bsHeader = this.state.bsHeader
    bsHeader.complement = value
    this.setState({ bsHeader })
  }

  getConf = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(Api.baseURL + Api.bs.confs)
        .then(res => {
          let newState = { bsFields: res.data.success, bsData: {} }
          res.data.success.map(e => e.champs.map(ele => (newState.bsData[ele.id] = '')))
          this.setState(newState, this.getBs())
        })
        .catch(() => Swal.fire({ icon: 'error', title: getTrad('errors.contactAdmin') }))
    })
  }

  componentDidMount() {
    this.getConf()
  }

  send() {
    areYouSure.fire().then(result => {
      if (result.value) {
        let url = Api.baseURL + Api.bs.crud + '/' + this.state.oldMatricule

        let data = { values: this.state.bsData, ...this.state.bsHeader }
        if (validDate(data.date_soin)) {
          data.date_soin = convertDate(data.date_soin)
        }

        axios
          .patch(url, data)
          .then(res => {
            Swal.fire({ title: getTrad('success.updateBs'), icon: 'success' }).then(result => {
              if (result.value) this.setState({ loading: true }, this.getData)
            })
          })
          .catch(err => {
            if (err.response) {
              if (err.response.data) {
                this.setState({ errors: err.response.data.errors })
                fatalError()
              } else {
                fatalError()
              }
            } else {
              fatalError()
            }
          })
      }
    })
  }

  handleInput(e) {
    if (e.target.name === 'mat_adherant') {
      let tmp = this.state.bsHeader
      tmp[e.target.name] = e.target.value
      this.setState({ bsHeader: tmp }, this.updatePrestataire)
    } else if (Object.keys(this.state.bsHeader).includes(e.target.name)) {
      let tmp = this.state.bsHeader
      tmp[e.target.name] = e.target.value
      this.setState({ bsHeader: tmp })
    } else {
      let tmp = this.state.bsData
      let field = e.target.name.substring(6)
      tmp[field] = e.target.value
      this.setState({ bsData: tmp })
    }
  }

  updatePrestataire(id = '') {
    let presta = JSON.parse(JSON.stringify(this.state.bsHeader))
    presta.id_prestatire = ''
    if (id) {
      presta.id_prestatire = id
    }
    this.setState({ benifPlafondLoading: true, loadingPresta: true, bsHeader: presta }, () => {
      let mat = this.state.bsHeader.mat_adherant
      let url = Api.baseURL + Api.adherantFamily + mat
      axios
        .get(getUrl('bs.plafond', [this.state.bsHeader.mat_adherant]))
        .then(res => this.setState({ benifPlafond: res.data.success }))
        .catch(err => {})

      axios
        .get(url)
        .then(res => {
          this.setState({
            adherant: mat,
            loadingPresta: false,
            prestatires: res.data.success,
            errors: { ...this.state.errors, mat_adherant: null },
          })
        })
        .catch(err => {
          this.setState({
            adherant: null,
            prestatires: [],
            errors: {
              ...this.state.errors,
              mat_adherant: ['notfound'],
            },
            loadingPresta: false,
          })
        })
    })
  }

  handleCalculator = (id, value) => {
    let tmp = this.state.bsData
    tmp[id] = value
    this.setState({ bsData: tmp })
  }

  render() {
    if (this.state.loading) {
      return <Waiting />
    }
    if (this.state.redirect) {
      return <Redirect to="/bultin-soins" />
    }
    return (
      <>
        <div className="card mb-3">
          <BSHeader send={this.send} action="U" />
          <Plafond loading={this.state.benifPlafondLoading} {...this.state.benifPlafond} />
          <div className="card-body">
            <BSInfo
              handleCalculator={this.handleCalcComp}
              mat_bultin={{
                value: this.state.bsHeader.mat_bultin,
                error: this.state.errors.mat_bultin,
              }}
              date_soin={{
                value: this.state.bsHeader.date_soin,
                error: this.state.errors.date_soin,
              }}
              mat_adherant={{
                value: this.state.bsHeader.mat_adherant,
                error: this.state.errors.mat_adherant,
              }}
              complement={{
                value: this.state.bsHeader.complement,
                error: this.state.errors.complement,
              }}
              id_prestatire={{
                prestatires: this.state.prestatires,
                loading: this.state.loadingPresta,
                value: this.state.bsHeader.id_prestatire,
                error: this.state.errors.id_prestatire,
              }}
              description={{
                value: this.state.bsHeader.description,
                error: this.state.errors.description,
              }}
              handler={this.handleInput}
              ref={this.complementCalc}
            />
          </div>
        </div>
        <div className="card-columns">
          {this.state.bsFields.map((e, k) => (
            <div key={k} className=" card">
              <div className="card-header">{e.champs[0].label}</div>
              <div className="card-body">
                <BSFields
                  elements={e}
                  handleCalculator={this.handleCalculator}
                  handler={this.handleInput}
                  values={this.state.bsData}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
}
