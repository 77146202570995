import { Redirect } from 'react-router-dom'
import { userStore } from '../store/userStore'

const authorizedRoles = ['ROLE_SUPER_USER']

function SuperUserMiddleWare({ children }) {
  //
  // ROLE_ADMINISTRATOR
  // ROLE_MAGISTRAT
  // ROLE_ADMINISTRATOR_LOCAL

  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  if (!authorizedRoles.includes(role)) return <Redirect to="/" />
  return children
}

export default SuperUserMiddleWare
