import React, { Component } from 'react'
import Contracts from './Contracts'
import Demande from './Demande'
import Prelevements from './Prelevements'

import axios from 'axios'
import { getUrl } from '../../../../Api'

class Magistrat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      listContracts: [],
      loadingContracts: true,
      listCessions: [],
      loadingCessions: true,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.refreshCessions()
    this.refreshContracts()
  }

  refreshContracts = () => {
    this.setState({ loadingContracts: true }, () => {
      axios
        .get(getUrl('parent.contract.my'))
        .then(res => this.setState({ listContracts: res.data.success, loadingContracts: false }))
        .catch(() => this.setState({ listContracts: [], loadingContracts: false }))
    })
  }

  refreshCessions = () => {
    this.setState({ loadingCessions: true }, () => {
      axios
        .get(getUrl('parent.saisie.my'))
        .then(res => this.setState({ listCessions: res.data.success, loadingCessions: false }))
        .catch(() => this.setState({ listCessions: [], loadingCessions: false }))
    })
  }

  render() {
    return (
      <>
        <Demande />
        <Contracts list={this.state.listContracts} loading={this.state.loadingContracts} />
        <Prelevements list={this.state.listCessions} loading={this.state.loadingCessions} />
      </>
    )
  }
}

export default Magistrat
