import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import NumInput from '../../../../components/Form/NumInput'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { showMounth, convertDate } from '../../../../helpers'
import { areYouSure, fatalError, successToast } from '../../../../helpers/sweetAlerts'
import axios from 'axios'
import InputMounth from '../../../../components/Form/InputMounth'
import InputSelect from '../../../../components/Form/InputSelect'

function EditRemboursement(props) {
  const [show, setShow] = useState(false)
  const [montant, setMontant] = useState(
    props.montant_rembours ? props.montant_rembours.toString() : ''
  )

  const [date, setDate] = useState(props.date ? showMounth(props.date) : '')
  const [type, setType] = useState(props.type ? showMounth(props.type) : '')

  const handleMontant = event => setMontant(event.target.value)
  const handleDate = event => setDate(event.target.value)
  const handleType = event => setType(event.target.value)
  const handleShow = () => setShow(!show)

  const send = () => {
    areYouSure.fire().then(response => {
      if (response.value)
        axios
          .patch(getUrl('credits.rembours', [props.id]), {
            value: montant,
            type: type,
            date: convertDate('01-' + date),
          })
          .then(res => {
            successToast.fire(getTrad('success.update.remboursement'))
            props.refresh()
            handleShow()
          })
          .catch(err => {
            fatalError()
            props.refresh()
            handleShow()
          })
    })
  }

  return (
    <>
      <button onClick={handleShow} className="btn btn-secondary btn-sm">
        <i className="fas fa-edit" />
      </button>

      <Modal show={show} centered onHide={() => false}>
        <Modal.Header>
          <Modal.Title>
            {getTrad('credit.update.rembours', [props.id, showMounth(props.date)])}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <NumInput
            value={montant}
            handler={handleMontant}
            name={`montant-${props.id}`}
            label={getTrad('credit.montant_rembours')}
          />
          <InputMounth
            handler={handleDate}
            label={getTrad('fileds.date')}
            name="date"
            value={date}
          />
          <InputSelect
            name="type"
            value={type}
            label={getTrad('credit.type_remboursement_name')}
            handler={handleType}
            data={[
              { value: 'E', text: getTrad('credit.type_remboursement.E') },
              { value: 'C', text: getTrad('credit.type_remboursement.C') },
              { value: 'B', text: getTrad('credit.type_remboursement.B') },
              { value: 'P', text: getTrad('credit.type_remboursement.P') },
              { value: 'V', text: getTrad('credit.type_remboursement.V') },
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="col">
            <button
              onClick={send}
              disabled={
                type === props.type.toString() &&
                montant === props.montant_rembours.toString() &&
                date === showMounth(props.date)
              }
              className="btn btn-success w-100 btn-sm"
            >
              <i className="fas fa-check" />
            </button>
          </div>
          <div className="col">
            <button onClick={handleShow} className="btn btn-danger w-100 btn-sm">
              <i className="fas fa-times" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditRemboursement
