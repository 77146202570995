import axios from 'axios'
import React from 'react'
import { useQuery } from 'react-query'
import CardHeader from '../../../../components/CardHeader'
import ShowTable from '../../../../components/ShowTable'
import Waiting from '../../../../components/Waiting'
import { showDate } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import Pay from './Pay'
import Show from './Show'

const keys = [
  'matricule',
  'matricule_cnrps',
  'first_name',
  'last_name',
  'profession',
  'birth_day',
  'actions',
]

let header = {
  matricule: getTrad('adherant.matricule'),
  matricule_cnrps: getTrad('adherant.matricule_cnrps'),
  first_name: getTrad('adherant.first_name'),
  last_name: getTrad('adherant.last_name'),
  birth_day: getTrad('adherant.birth_day'),
  profession: getTrad('adherant.profession'),
  actions: getTrad('fileds.actions'),
}

function Veuve() {
  const {
    isLoading,
    isFetching,
    data: veuves = [],
  } = useQuery('get-veuve-list', () =>
    axios.get('/veuve').then(res =>
      res.data.success.map(({ birth_day, ...veuve }) => ({
        ...veuve,
        birth_day: showDate(birth_day),
        actions: (
          <div className="btn-group btn-group-sm">
            <Pay id={veuve.id} />
            <Show id={veuve.id} first_name={veuve.first_name} last_name={veuve.last_name} />
          </div>
        ),
      }))
    )
  )

  return (
    <div className="card">
      <CardHeader title={getTrad('navbar.list_veuve')} />
      {/* <RenderSearchSection filter={filter} /> */}
      {isLoading || isFetching ? (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Waiting />
        </div>
      ) : (
        <ShowTable
          data={veuves}
          keys={keys}
          header={header}
          noData={getTrad(`commun.noSearchData`)}
        />
      )}
    </div>
  )
}

export default Veuve
