import React, { useState } from 'react'
import CardHeader from './CardHeader'
import StatTable from './StatTable'
function StatCard(props) {
  const [show, setShow] = useState(props.show === true)

  const handleShow = () => setShow(!show)

  return (
    <div className="card mb-3">
      <CardHeader show={handleShow} title={props.annee} />
      <StatTable show={show} stats={props.stats} />
    </div>
  )
}

export default StatCard
