import { useState } from 'react'

export const useFilter = () => {
  const [matricule, setMatricule] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [matriculeCnrps, setMatriculeCnrps] = useState('')
  const [phone, setPhone] = useState('')
  const [birthDay, setBirthDay] = useState('')

  return {
    matricule,
    lastName,
    firstName,
    matriculeCnrps,
    phone,
    birthDay,
    setMatricule: event => setMatricule(event.target.value),
    setLastName: event => setLastName(event.target.value),
    setFirstName: event => setFirstName(event.target.value),
    setPhone: event => setPhone(event.target.value),
    setMatriculeCnrps: event => setMatriculeCnrps(event.target.value),
    setBirthDay: event => setBirthDay(event.target.value),
  }
}
