import React from 'react'
import { Link } from 'react-router-dom'
import { getTrad } from '../../../lang'
import { userStore } from '../../../store/userStore'

function ChangePassword() {
  const { user } = userStore()
  const { last_password_change } = user || {}

  if (!last_password_change) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        {getTrad('auth.sc_password')}{' '}
        <u>
          <b>
            <Link to="/change-password">{getTrad('commun.click_here')}</Link>
          </b>
        </u>
      </div>
    )
  }
  return <></>
}

export default ChangePassword
