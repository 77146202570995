import React from 'react'
import { Redirect } from 'react-router-dom'
import { removeJwt } from './index.js'

function Logout() {
  removeJwt()
  return <Redirect to="/login" />
}

export default Logout
