import React, { Component } from 'react'
import CardHeader from '../../../components/CardHeader'
import { getTrad } from '../../../lang'
import { getUrl } from '../../../Api'
import ShowTable from '../../../components/ShowTable'
import axios from 'axios'
import Export from '../../../components/Export'
import { Link } from 'react-router-dom'
import { showDate } from '../../../helpers'
import Import from './Import'

const cleanData = e => ({
  ...e,
  date: showDate(e.date),
  details: (
    <Link className="btn btn-sm btn-primary" to={`/parents/saisie/${e.number}`}>
      <i className="fas fa-eye" />
    </Link>
  ),
})

function DownloadModel() {
  let header = {}
  const keys = ['mat_cnrps', 'sous_compte', 'montant']

  for (var i = 0; i < keys.length; i++) {
    header[keys[i]] = getTrad(`saisie.${keys[i]}`)
  }
  return (
    <Export
      fileName="Modèle importation saisie"
      csvData={[{ mat_cnrps: '', sous_compte: '', montant: '' }]}
      icon="fas fa-download"
      color="dark"
      title={getTrad('commun.downloadmodel')}
      headers={header}
    />
  )
}

class Saisie extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    axios
      .get(getUrl('parent.saisie.get'))
      .then(res => this.setState({ list: res.data.success.map(cleanData) }))
      .catch(err => false)
  }

  render() {
    let header = {}

    const keys = ['number', 'date', 'nb_items', 'details']

    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`saisie.${keys[i]}`)
    }

    const showProps = {
      searching: this.state.loading,
      header: header,
      keys: keys,
      noData: getTrad('commun.noSearchData'),
      data: this.state.list,
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('parent.page.saisie')}
          actions={
            <div className="btn-group">
              <DownloadModel />
              <Import reload={this.refresh} />
            </div>
          }
        />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}

export default Saisie
