import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'

import axios from 'axios'
import { areYouSure, successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import { convertDate, showDate } from '../../../../helpers'
import Waiting from '../../../../components/Waiting'

import InfoPerso from './CrudFields/InfoPerso'
import Travail from './CrudFields/Travail'
import Charge from './CrudFields/Charge'

export default class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      sending: false,
      errors: {},
      member: {
        type: '',
        first_name: '',
        last_name: '',
        birth_day: '',
        sexe: '',
        situationsfamiliale: '',
        works: '',
        is_supported: '',
        profession: '',
        lvl_studies: '',
        tuteur: '',
        is_handicape: '',
      },
    }
  }

  componentDidMount() {
    let tmp = {
      id: this.props.member.id,
      type: this.props.member.type ? this.props.member.type.toString() : '',
      first_name: this.props.member.first_name ? this.props.member.first_name.toString() : '',
      last_name: this.props.member.last_name ? this.props.member.last_name.toString() : '',
      birth_day: this.props.member.birth_day ? showDate(this.props.member.birth_day) : '',
      sexe: this.props.member.sexe ? this.props.member.sexe.toString() : '',
      situationsfamiliale: this.props.member.situationsfamiliale
        ? this.props.member.situationsfamiliale.toString()
        : '',
      works: this.props.member.works ? this.props.member.works.toString() : '',
      is_supported: this.props.member.is_supported ? this.props.member.is_supported.toString() : '',
      profession: this.props.member.profession ? this.props.member.profession.toString() : '',
      lvl_studies: this.props.member.lvl_studies ? this.props.member.lvl_studies.toString() : '',
      tuteur: this.props.member.tuteur ? this.props.member.tuteur.toString() : '',
      is_handicape: this.props.member.is_handicape
        ? this.props.member.is_handicape.toString()
        : '0',
    }
    this.setState({ member: tmp })
  }

  submit = () => {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.edit)
    })
  }

  edit = () => {
    let data = {
      id: this.props.member.id,
      type: this.state.member.type,
      first_name: this.state.member.first_name,
      last_name: this.state.member.last_name,
      birth_day: convertDate(this.state.member.birth_day),
      sexe: this.state.member.sexe,
      situationsfamiliale: this.state.member.situationsfamiliale,
      works: this.state.member.works,
      is_supported: this.state.member.is_supported,
      profession: this.state.member.profession,
      lvl_studies: this.state.member.lvl_studies,
      tuteur: this.state.member.tuteur,
      is_handicape: this.state.member.is_handicape,
    }

    axios
      .patch(getUrl('prestataire.edit'), data)
      .then(res => {
        this.setState({ sending: false }, () => {
          successMsg(getTrad('success.updateMembreFamily'))
          this.props.refresh()
          this.handleShow()
        })
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({ errors: err.response.data.errors })
          } else if (err.response.status === 500) {
            errorMsg(getTrad('errors.cummonError'))
          } else if (err.response.status === 403) {
            errorMsg(err.response.data.message)
          }
        }
        this.setState({ sending: false })
      })
  }

  handleUpdate = e => {
    let member = JSON.parse(JSON.stringify(this.state.member))
    member[e.target.name] = e.target.value
    this.setState({ member: member })
  }

  handleShow = () => this.setState({ show: !this.state.show })

  render() {
    if (this.state.redirect) return <Redirect to="/" />

    let btnPops = {
      className: 'btn btn-success w-100',
      disabled: this.state.sending,
      onClick: this.submit,
    }

    return (
      <>
        <button className="btn btn-secondary btn-sm w-100" onClick={this.handleShow}>
          <i className="far fa-edit"></i>
        </button>
        <Modal size="lg" show={this.state.show} onHide={this.handleShow}>
          <Modal.Header closeButton>
            <Modal.Title>{getTrad('pages.addFamily')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InfoPerso {...this.props} {...this.state} update={this.handleUpdate} />
            <Travail {...this.props} {...this.state} update={this.handleUpdate} />
            <Charge {...this.props} {...this.state} update={this.handleUpdate} />
          </Modal.Body>

          <Modal.Footer>
            <button {...btnPops}>
              {this.state.sending ? <Waiting /> : getTrad('commun.edit')}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
