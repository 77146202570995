import React from 'react'
import { getTrad } from '../../../../lang'
import InputText from '../../../../components/Form/InputText'

function Filter(props) {
  let textField = ['mat_adherant', 'full_name', 'pres_full_name']
  return (
    <div className="card-body">
      <div className="row">
        {textField.map((e, k) => (
          <div key={k} className="col">
            <InputText
              label={getTrad(`condidature.${e}`)}
              handler={props.handleInput}
              value={props.values[`search_${e}`]}
              name={`search_${e}`}
            />
          </div>
        ))}

        <div className="col">
          <label>{getTrad('condidature.prix_name')}</label>
          {props.possibles.prix_name.map((e, k) => {
            let id = `search_prix_name_${e}`

            return (
              <div key={k} className="form-check">
                <input
                  value={e}
                  checked={props.values.search_prix_name.includes(e)}
                  data-field={'prix_name'}
                  className="form-check-input"
                  type="checkbox"
                  onChange={props.handleCheck}
                  name={id}
                  id={id}
                />
                <label className="form-check-label" htmlFor={id}>
                  {getTrad(`condidature.statusCode.${e}`)}
                </label>
              </div>
            )
          })}
        </div>

        <div className="col">
          <label>{getTrad('condidature.status')}</label>
          {Object.keys(props.possibles.status).map((e, k) => {
            let label = props.possibles.status[e]
            let id = `search_status_${e}`

            return (
              <div key={k} className="form-check">
                <input
                  data-field={'status'}
                  value={e}
                  checked={props.values.search_status.includes(e)}
                  className="form-check-input"
                  type="checkbox"
                  onChange={props.handleCheck}
                  name={id}
                  id={id}
                />
                <label className="form-check-label" htmlFor={id}>
                  {label}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Filter
