import React from 'react'
import InputText from '../../../components/Form/InputText'
import { getTrad } from '../../../lang'

export default function Filter(props) {
  return (
    <div className="card-body ">
      <div className="row">
        <div className="col">
          <InputText
            handler={props.handler}
            name="search_date"
            value={props.search_date}
            label={getTrad('log.search_date')}
          />
        </div>
        <div className="col">
          <InputText
            handler={props.handler}
            name="search_user"
            value={props.search_user}
            label={getTrad('log.search_user')}
          />
        </div>
        <div className="col">
          <InputText
            handler={props.handler}
            name="search_methode"
            value={props.search_methode}
            label={getTrad('log.search_methode')}
          />
        </div>
        <div className="col">
          <InputText
            handler={props.handler}
            name="search_route"
            value={props.search_route}
            label={getTrad('log.search_route')}
          />
        </div>
        <div className="col">
          <InputText
            handler={props.handler}
            name="search_data"
            value={props.search_data}
            label={getTrad('log.search_data')}
          />
        </div>
      </div>
    </div>
  )
}

/*

search_date: '',
search_user: '',
search_methode: '',
search_route: '',
search_data: '',

*/
