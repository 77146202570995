import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { validDate } from '../../helpers/'

export default class DateInput extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
  }

  handelInput(e) {
    this.setState({ value: e.target.value }, () => {
      if (validDate(this.state.value) === true) {
        this.props.handler(this.props.name, this.state.value)
      } else {
        this.props.handler(this.props.name, '')
      }
    })
  }

  clear() {
    this.setState({ value: '' })
  }

  render() {
    let label = this.props.label
    let name = this.props.name
    let inputClass = 'form-control w-100 form-control-sm'
    if (validDate(this.state.value) === true) {
      inputClass += ' is-valid'
    } else if (validDate(this.state.value) === false) {
      inputClass += ' is-invalid'
    }
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          name={name}
          id={name}
          placeholder="DD-MM-YYYY"
          onChange={e => this.handelInput(e)}
          className={inputClass}
          value={this.state.value}
        />
        <div className="invalid-feedback">DD-MM-YYYY</div>
      </div>
    )
  }
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
}
