import React, { Component } from 'react'
import { getTrad } from '../../../../lang'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import moment from 'moment'
import InputMounth from '../../../../components/Form/InputMounth'
import { Modal } from 'react-bootstrap'
import { getUrl } from '../../../../Api'
import axios from 'axios'
import Waiting from '../../../../components/Waiting'
import { convertDate } from '../../../../helpers'
import { fatalError } from '../../../../helpers/sweetAlerts'

import * as FileSaver from 'file-saver'

function downloadData(filename, data, data2) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const cleanData = data.map(e => ({
    'Matricule CNRPS': e.cnrps,
    Montant: e.montant,
    'Sous compte': e.sous_compte,
  }))

  const problemData = data2.map(e => ({
    'Matricule CNRPS': e.cnrps,
    Montant: e.montant,
    'Sous compte': e.sous_compte,
  }))

  const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

  const ws = XLSX.utils.json_to_sheet(cleanData)
  const ws1 = XLSX.utils.json_to_sheet(problemData)
  let wb = { Sheets: {}, SheetNames: [] }
  wb.SheetNames.push('cleanData')
  wb.SheetNames.push('problemData')
  wb.Sheets['cleanData'] = ws
  wb.Sheets['problemData'] = ws1

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const downData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(downData, filename + '-' + now + fileExtension)
}

const messages = {
  R: getTrad('saisie.import.reading'),
  V: getTrad('saisie.import.verify'),
  D: getTrad('saisie.import.DownloadCNRPS'),
  S: getTrad('saisie.import.sending'),
  E: getTrad('saisie.import.error'),
  F: getTrad('saisie.import.finish'),
}

function ImportModel(props) {
  const show = ['R', 'D', 'V', 'S', 'F'].includes(props.status)
  const message = messages[props.status]

  const Loading = () => {
    if (['V', 'S'].includes(props.status))
      return (
        <h2 className="text-center">
          <Waiting />
        </h2>
      )
    if (props.status === 'F')
      return (
        <button onClick={props.close} className="btn btn-success w-100">
          <i className="fas fa-check"></i>
        </button>
      )
    return <></>
  }

  if (props.status === 'D') {
    return (
      <Modal show={show} centered onHide={() => {}}>
        <Modal.Body>
          <InputMounth
            value={props.date}
            handler={props.handler}
            name="date"
            label="Date d'importation"
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={props.send} className="btn btn-success w-100">
            <i className="far fa-calendar-check mr-2"></i>
            {getTrad('commun.select')}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <Modal show={show} centered onHide={() => {}}>
      <Modal.Body>
        <h2 className="text-center">{message}</h2>
        <Loading />
      </Modal.Body>
    </Modal>
  )
}

class Import extends Component {
  constructor(props) {
    super(props)
    this.state = {
      /*
        N : New
        R : Reading
        D : Getting Date
        V : Verifying
        S : Sending
        F : Finish
        E : Fatal Error 
      */
      status: 'N', // N, R, D, V, S, F, E
      toImportSaisie: [],
      errors: [],
      date: moment().format('MM-YYYY'),
    }
  }

  confirmDate = () => {
    this.setState({ status: 'V' }, this.verify)
  }

  handleDate = e => this.setState({ [e.target.name]: e.target.value })

  clearInput = e => ({
    cnrps: e['Matricule CNRPS'],
    montant: e['Montant'],
    sous_compte: e['Sous compte'],
  })

  send = () => {
    const data = {
      date: convertDate('01-' + this.state.date),
      data: this.state.toImportSaisie,
    }
    axios
      .post(getUrl('saisie.store'), data)
      .then(res => {
        this.setState({ status: 'F' }, () => {
          downloadData(
            'cleanData',
            res.data.success.done,
            this.state.errors.concat(res.data.success.errors)
          )
          this.props.reload()
        })
      })
      .catch(() => this.setState({ status: 'E' }, fatalError))
  }

  verify = async () => {
    const cnrps = await axios
      .get(getUrl('credits.courent'))
      .then(res =>
        res.data.success.map(e => ({
          sous_compte: e.sous_compte,
          cnrps: e.adherant.matricule_cnrps,
        }))
      )
      .catch(() => [])

    const errors = []
    const toSend = []

    for (let i = 0; i < this.state.toImportSaisie.length; i++) {
      const element = this.state.toImportSaisie[i]
      if (isNaN(element.cnrps) || isNaN(element.montant) || isNaN(element.sous_compte)) {
        errors.push(element)
        continue
      }

      const condidat = cnrps.filter(
        e => element.sous_compte.toString() === e.sous_compte.toString()
      )

      if (condidat.length !== 1) {
        errors.push(element)
        continue
      }

      if (condidat[0].cnrps.toString() !== element.cnrps.toString()) {
        errors.push(element)
        continue
      }

      toSend.push(element)
    }
    this.setState({ toImportSaisie: toSend, errors: errors, status: 'S' }, this.send)
  }

  readData = e => {
    let reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = async e => {
      var data = new Uint8Array(reader.result)
      var wb = XLSX.read(data, { type: 'array' })
      const { value: sheet } = await Swal.fire({
        title: getTrad('commun.chooseSheet'),
        input: 'select',
        inputOptions: wb.SheetNames,
        inputPlaceholder: getTrad('commun.chooseSheet'),
        showCancelButton: true,
        cancelButtonText: getTrad('commun.cancel'),
        confirmButtonText: getTrad('commun.import'),
      })
      if (sheet !== undefined && sheet !== '') {
        this.setState({ status: 'R' }, () => {
          let jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[sheet]])
          this.setState({ toImportSaisie: jsonData.map(this.clearInput), status: 'D' })
        })
      }
    }
  }

  render() {
    return (
      <>
        <input
          type="file"
          onChange={this.readData}
          style={{ display: 'none' }}
          ref={fileInput => (this.fileInput = fileInput)}
        />
        <ImportModel
          handler={this.handleDate}
          send={this.confirmDate}
          {...this.state}
          close={() => this.setState({ status: 'N' })}
        />

        <button onClick={() => this.fileInput.click()} className="btn btn-primary">
          <i className="fas fa-file-import"></i> {getTrad('commun.import')}
        </button>
      </>
    )
  }
}
export default Import
