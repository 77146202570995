import { getTrad } from '../../../../../lang'
import NumInput from '../../../../../components/Form/NumInput'
import DateInput from '../../../../../components/Form/Date'
import TxtInput from '../../../../../components/Form/TxtInput'
import Prestataire from './Prestataire'

function BSHeaderInput({ bsInfo }) {
  const { matBs, dateBs, matAd, desc, complement, complementCalc, prestatire } = bsInfo
  return (
    <div className="card-body">
      <fieldset>
        <legend>{getTrad('bs.prestatire')}</legend>
        <div className="row">
          <div className="col">
            <NumInput {...matAd} />
          </div>
          <div className="col">
            <div className="form-group w-100">
              <label htmlFor="id_prestatire">{getTrad('bs.prestatire')}</label>
              <Prestataire {...prestatire} />
            </div>
          </div>
          <div className="col">
            <NumInput {...matBs} />
          </div>
          <div className="col">
            <DateInput {...dateBs} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group w-100">
              <TxtInput {...desc} />
            </div>
          </div>
          <div className="col">
            <div className="form-group w-100">
              <label>{getTrad('bs.complement')}</label>
              <input {...complementCalc} />
              <NumInput dontShow {...complement} />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}

export default BSHeaderInput
