import { adherantA } from './items/adherant'
import { bsA } from './items/bs'
import { creditA } from './items/credit'
import { aideA } from './items/aide'
import { scienceS } from './items/science'
import { smsA } from './items/sms'
import { adminS } from './items/admin'
import { historiqueS } from './items/historique'
import { aide_socialeS } from './items/aideSociale'
import { etablissementsA } from './items/etablissements'
import { parentsA } from './items/parents'

let navSU = [
  adherantA,
  bsA,
  creditA,
  parentsA,
  aideA,
  aide_socialeS,
  scienceS,
  smsA,
  adminS,
  historiqueS,
  etablissementsA,
  {
    icon: 'fas fa-cogs',
    text: 'params',
    link: 'parameters',
  },
]
export default navSU
