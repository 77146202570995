import React from 'react'
import StatTable from './StatTable'
import ShowBars from './ShowBars'

function ShowStats(props) {
  if (props.show)
    return (
      <>
        <ShowBars {...props} />
        <StatTable tableKeys={props.tableKeys} stats={props.stats} />
      </>
    )
  return <></>
}

export default ShowStats
