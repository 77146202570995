import React, { useContext, useRef } from 'react'
import QRCode from '../../../components/QrCode'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'
import { showDate } from '../../../helpers'
import Family from './Family'
import { getTrad, useTrad } from '../../../lang'
import { useQuery } from 'react-query'
import Waiting from '../../../components/Waiting'
import { LangContext } from '../../../context/LangContext'

function RenderIdentifiant({ matricule, matricule_cnrps, cin, date_inscription }) {
  return (
    <div className="row">
      <div className="col-10">
        <h4 className="card-title">{getTrad('fileds.identifiants')}</h4>
        <div className="row">
          <RenderField label={useTrad('adherant.matricule')} value={matricule} />
          <RenderField label={useTrad('adherant.matricule_cnrps')} value={matricule_cnrps} />
          <RenderField label={useTrad('adherant.cin')} value={cin} />
          <RenderField
            label={useTrad('adherant.date_inscription')}
            value={showDate(date_inscription)}
          />
        </div>
      </div>
      <div className="col-2 text-right">
        <QRCode value={`GAMM-AD :${matricule}`} />
      </div>
      <hr />
    </div>
  )
}

function RenderForeign({ label, foreign, value, width = 4 }) {
  const { extra } = useContext(LangContext)
  if (!extra[foreign]) return null
  return (
    <div className={`col-${width}`}>
      <span className="field-title">{label} :</span>
      {extra?.[foreign]?.[value]}
    </div>
  )
}

function RenderPersonal({
  first_name,
  first_name_ar,
  last_name,
  last_name_ar,
  email,
  phone,
  rib,
  sexe,
  birth_day,
  situationsfamiliale,
}) {
  return (
    <div className="row">
      <RenderField label={useTrad('adherant.first_name')} value={first_name} width={6} />
      <RenderField label={useTrad('adherant.first_name_ar')} value={first_name_ar} width={6} />
      <RenderField label={useTrad('adherant.last_name')} value={last_name} width={6} />
      <RenderField label={useTrad('adherant.last_name_ar')} value={last_name_ar} width={6} />
      <RenderField label={useTrad('adherant.email')} value={email} />
      <RenderField label={useTrad('adherant.phone')} value={phone} />
      <RenderField label={useTrad('adherant.sexe')} value={getTrad(`commun.sexe.${sexe}`)} />
      <RenderField label={useTrad('adherant.birth_day')} value={showDate(birth_day)} />
      <RenderForeign
        label={getTrad(`fileds.situationsfamiliale`)}
        foreign="situationsfamiliale"
        value={situationsfamiliale}
      />
      <RenderField label={useTrad('adherant.rib')} value={rib} />
    </div>
  )
}

function RenderWork({ categorie, grade, lieu, status }) {
  return (
    <div className="row">
      <RenderForeign
        label={getTrad(`fileds.categorie`)}
        foreign="categorie"
        value={categorie}
        width={3}
      />
      <RenderForeign label={getTrad(`fileds.grade`)} foreign="grade" value={grade} width={3} />
      <RenderForeign label={getTrad(`fileds.lieu`)} foreign="lieu" value={lieu} width={3} />
      <RenderForeign label={getTrad(`fileds.status`)} foreign="status" value={status} width={3} />
    </div>
  )
}

function RenderBody({ printRef, ...props }) {
  return (
    <div className="card-body" ref={printRef}>
      <RenderIdentifiant {...props} />
      <h4 className="card-title">{getTrad('adherant.infoperso')}</h4>
      <RenderPersonal {...props} />
      <Family {...props} defaultLast={props.last_name} />
      <h4 className="card-title">{getTrad('adherant.travail')}</h4>
      <RenderWork {...props} />
    </div>
  )
}

function RenderHader({ print, matricule }) {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-6">
          <h2 className="card-title">{getTrad('pages.showAdherant')}</h2>
        </div>
        <div className="col-6 text-right">
          <div className="btn-group" role="group" aria-label="Basic example">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button className="btn btn-primary" onClick={print}>
                <i className="fas fa-print"></i> {getTrad('commun.print')}
              </button>
              <Link to={`/adherants/${matricule}/edit`} className="btn btn-secondary">
                <i className="far fa-edit"></i> {getTrad('commun.edit')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function RenderField({ label, width = 4, value }) {
  return (
    value && (
      <div className={`col-${width}`}>
        <span className="field-title">{label} :</span>
        {value}
      </div>
    )
  )
}

function ShowAdherant({
  match: {
    params: { matricule: mat },
  },
}) {
  const {
    isError,
    isLoading,
    isFetching,
    data = {},
  } = useQuery(`get-ad-${mat}`, () => axios.get(`/adherants/${mat}`).then(res => res.data.success))

  const printRef = useRef()
  const print = () => {
    const qrcodeText = `GAMM-AD :${mat}`
    var divToPrint = printRef.current
    var newWin = window.open('', 'Print-Window')
    newWin.document.open()
    newWin.document.write(`
<html>
  <head>
    <script src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js"></script>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">
    <style>button{display:none!important}</style>
  </head>
  <body onload="window.print()">
  <div id="qrcode"></div>
    ${divToPrint.innerHTML}
  </body>
  <script type="text/javascript">
    var qrcode = new QRCode(document.getElementById("qrcode"), {
    text: "${qrcodeText}",
    width: 128,
    height: 128,
    colorDark : "#000000",
    colorLight : "#ffffff",
    correctLevel : QRCode.CorrectLevel.H
  });
  </script>
</html>`)
    newWin.document.close()
  }

  if (isError) return <Redirect to="/adherants" />

  if (isLoading || isFetching) return <Waiting />
  return (
    <div className="card">
      <RenderHader print={print} matricule={mat} />
      <RenderBody printRef={printRef} {...data} />
    </div>
  )
}

export default ShowAdherant

// export default class GestionAdmins extends Component {

// }
