import React, { Component, createRef } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import Swal from 'sweetalert2'
import { validDate, convertDate } from '../../../helpers'
import { fatalError, areYouSure } from '../../../helpers/sweetAlerts'
import { getTrad } from '../../../lang'

import BSFields from './components/bsFilds'
import BSHeader from './components/headerBS'
import NumInput from '../../../components/Form/NumInput'
import DateInput from '../../../components/Form/Date'
import TxtInput from '../../../components/Form/TxtInput'
import Waiting from '../../../components/Waiting'
import { Redirect } from 'react-router-dom'

function BSInfo(props) {
  return (
    <fieldset>
      <legend>{getTrad('bs.beneficiere')}</legend>
      <div className="row">
        <div className="col">
          <NumInput
            label={getTrad('bs.numBs')}
            name="mat_bultin"
            value={props.mat_bultin.value}
            handler={props.handler}
            error={props.mat_bultin.error}
          />
        </div>
        <div className="col">
          <DateInput
            label={getTrad('bs.date_soin')}
            name="date_soin"
            value={props.date_soin.value}
            handler={props.handler}
            error={props.date_soin.error}
          />
        </div>
        <div className="col">
          <div className="form-group w-100">
            <label htmlFor="id_beneficiere">{getTrad('bs.beneficiere')}</label>
            <Prestataire {...props.id_prestatire} handler={props.handler} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group w-100">
            <TxtInput
              label={getTrad('bs.description')}
              name="description"
              value={props.description.value}
              error={props.description.error}
              handler={props.handler}
            />
          </div>
        </div>
      </div>
    </fieldset>
  )
}

function Prestataire(props) {
  if (props.loading) return <Waiting />

  let name = 'id_prestatire'
  let value = props.value
  let inputClass = ['form-control', 'w-100', 'form-control-sm']
  let error = props.error

  if (props.error) inputClass.push('is-invalid')

  if (props.prestatires.length <= 0) return <></>
  return (
    <>
      <select
        value={value}
        onChange={props.handler}
        id={name}
        className={inputClass.join(' ')}
        name={name}
      >
        {props.prestatires.map((e, k) => {
          return (
            <option key={k} value={e.id}>
              {e.name}
            </option>
          )
        })}
      </select>
      {error}
    </>
  )
}

export default class AddBsSelf extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingPresta: false,
      prestatires: [],
      action: this.props.action ? this.props.action : 'A',
      bsFields: [],
      redirect: false,
      bsData: {},
      bsHeader: {
        mat_bultin: '',
        date_soin: '',
        id_prestatire: '',
        complement: '',
        description: '',
      },
      errors: { mat_bultin: null, date_soin: null },
    }
    this.calculators = {}
  }

  componentDidMount() {
    axios
      .get(getUrl('bs.confs'))
      .then(res => {
        let newState = { bsFields: res.data.success, bsData: {} }
        for (var i = 0; i < res.data.success.length; i++) {
          const id = res.data.success[i].id
          newState.bsData[id] = ''
          this.calculators[id] = createRef()
        }
        this.setState(newState, this.getData)
      })
      .catch(fatalError)

    axios
      .get(getUrl('adherants.profil'))
      .then(res => {
        let tmp = res.data.success.family
          .filter(e => e.is_supported)
          .map(e => ({ name: `${e.first_name} ${e.last_name}`, id: e.id.toString() }))
        tmp.unshift({
          name: `${res.data.success.first_name} ${res.data.success.last_name}`,
          id: '',
        })
        this.setState({
          loadingPresta: false,
          prestatires: tmp,
        })
      })
      .catch(fatalError)
  }

  send = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        let url = getUrl('bs.addDemande')
        let data = { values: this.state.bsData, ...this.state.bsHeader }
        if (validDate(data.date_soin)) data.date_soin = convertDate(data.date_soin)
        axios
          .post(url, data)
          .then(res => {
            Swal.fire({ title: getTrad('success.addBs'), icon: 'success' }).then(result => {
              this.setState({ redirect: true })
            })
          })
          .catch(err => {
            fatalError()
          })
      }
    })
  }

  handleCalculator = (id, value) => {
    let tmp = this.state.bsData
    tmp[id] = value
    this.setState({ bsData: tmp })
  }

  handleInput = e => {
    if (Object.keys(this.state.bsHeader).includes(e.target.name)) {
      let tmp = this.state.bsHeader
      tmp[e.target.name] = e.target.value
      this.setState({ bsHeader: tmp })
    } else {
      let tmp = this.state.bsData
      let field = e.target.name.substring(6)
      tmp[field] = e.target.value
      this.setState({ bsData: tmp })
    }
  }

  clearCalcs = () => {
    Object.keys(this.calculators).map(e => this.calculators[e].current.reset())
  }

  render() {
    if (this.state.redirect) return <Redirect to="/bultin-soins" />
    return (
      <>
        <div className="card mb-3">
          <BSHeader send={this.send} action="A" />
          <div className="card-body">
            <BSInfo
              mat_bultin={{
                value: this.state.bsHeader.mat_bultin,
                error: this.state.errors.mat_bultin,
              }}
              date_soin={{
                value: this.state.bsHeader.date_soin,
                error: this.state.errors.date_soin,
              }}
              complement={{
                value: this.state.bsHeader.complement,
                error: this.state.errors.complement,
              }}
              id_prestatire={{
                prestatires: this.state.prestatires,
                loading: this.state.loadingPresta,
                value: this.state.bsHeader.id_prestatire,
                error: this.state.errors.id_prestatire,
              }}
              description={{
                value: this.state.bsHeader.description,
                error: this.state.errors.description,
              }}
              handler={this.handleInput}
            />
          </div>
        </div>

        <div className="card-columns">
          {this.state.bsFields.map((e, k) => (
            <div key={k} className="card">
              <div className="card-header">{e.champs[0].label}</div>
              <div className="card-body">
                <BSFields
                  ref={this.calculators[e.id]}
                  handleCalculator={this.handleCalculator}
                  elements={e}
                  handler={this.handleInput}
                  values={this.state.bsData}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
}
