import { getTrad } from '../../../../../lang'
import BSField from './BSField'

function BSData({ bsFields = [], bsData: { bsData: data, calculators, handlerCalc, isEmpty } }) {
  return (
    <>
      {isEmpty && <div className="alert alert-danger text-center">{getTrad('bs.empty')}</div>}
      <div className="card-columns">
        {bsFields.map(({ id, champs }, k) => (
          <BSField
            key={k}
            id={id}
            handlerCalc={handlerCalc}
            calcRef={calculators.current[id]}
            value={data[id]}
            champs={champs[0] || {}}
          />
        ))}
      </div>
    </>
  )
}

export default BSData
