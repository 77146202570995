import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { successMsg, errorMsg, areYouSure, fatalError } from '../../../helpers/sweetAlerts'
import { convertDate, showDate } from '../../../helpers'

import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import InputDate from '../../../components/Form/InputDate'
import InputText from '../../../components/Form/InputText'

function Header(props) {
  return (
    <Modal.Header closeButton>
      <Modal.Title>
        {getTrad('credit.demandeCredit')} {props.id}
      </Modal.Title>
    </Modal.Header>
  )
}

function Footer(props) {
  return (
    <Modal.Footer>
      <button
        type="button"
        onClick={props.handleClose}
        className="btn btn-secondary"
        data-dismiss="modal"
      >
        {getTrad('commun.close')}
      </button>
      <button onClick={props.send} className="btn btn-success">
        {getTrad('credit.accept')}
      </button>
    </Modal.Footer>
  )
}

export default class Accept extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      montant: '',
      frais: '',
      echance: '',
      montant_echance: '',
      date_versement: '',
      element: {},
      errors: {},
    }
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.send = this.send.bind(this)
  }

  componentDidMount() {
    this.setState({
      montant: this.props.element.montant_demande.toString(),
      element: this.props.element,
      date_versement: showDate(new Date()),
    })
  }

  send() {
    areYouSure.fire().then(async result => {
      if (result.value) {
        axios
          .post(getUrl('credits.accept', [this.state.element.id]), {
            montant: this.state.montant,
            frais: this.state.frais,
            echance: this.state.echance,
            montant_echance: this.state.montant_echance,
            date_versement: convertDate(this.state.date_versement),
          })
          .then(res => {
            if (res.status === 200) {
              successMsg(getTrad('success.acceptDemandeCredit'))
              this.handleClose()
              this.props.handle()
            } else {
              errorMsg(getTrad('errors.cummonError'))
            }
          })
          .catch(err => {
            fatalError()
            if (err && err.response && err.response.status === 422 && err.response.data)
              this.setState({ errors: err.response.data.errors })
          })
      }
    })
  }

  renderBtn = () => (
    <button className="btn btn-success btn-sm" onClick={this.handleShow}>
      <i className="fas fa-check"></i>
    </button>
  )

  updateEchenace = () => {
    let montant_echance

    let { montant, frais, echance } = this.state
    if (isNaN(montant)) return
    if (isNaN(frais)) return
    if (isNaN(echance)) return

    montant = parseFloat(montant)
    frais = parseFloat(frais)
    echance = parseFloat(echance)

    montant_echance = Math.round((1000 * (montant + frais)) / echance)
    montant_echance /= 1000
    this.setState({ montant_echance: montant_echance })
  }

  handleShow = () =>
    this.setState(
      {
        show: true,
        montant: this.props.element.montant_demande.toString(),
        frais: '68',
        element: this.props.element,
        date_versement: showDate(new Date()),
        echance: '18',
        errors: {},
      },
      this.updateEchenace
    )
  handleClose = () => this.setState({ show: false })
  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value }, this.updateEchenace)
  }

  renderModelBody() {
    return (
      <Modal.Body>
        <InputText
          label={getTrad('fileds.montant')}
          name="montant"
          value={this.state.montant}
          handler={this.handleInput}
          error={this.state.errors.montant}
        />
        <InputText
          label={getTrad('fileds.frais')}
          name="frais"
          value={this.state.frais}
          handler={this.handleInput}
          error={this.state.errors.frais}
        />
        <InputText
          label={getTrad('fileds.echance')}
          name="echance"
          value={this.state.echance}
          handler={this.handleInput}
          error={this.state.errors.echance}
        />
        <InputText
          label={getTrad('fileds.montant_echance')}
          name="montant_echance"
          value={this.state.montant_echance}
          handler={this.handleInput}
          error={this.state.errors.montant_echance}
        />
        <InputDate
          label={getTrad('fileds.date_versement')}
          value={this.state.date_versement}
          handler={this.handleInput}
          name="date_versement"
          error={this.state.errors.date_versement}
        />
      </Modal.Body>
    )
  }

  render() {
    return (
      <>
        {this.renderBtn()}
        <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
          <Header id={this.state.element.id} />
          {this.renderModelBody()}
          <Footer handleClose={this.handleClose} send={this.send} />
        </Modal>
      </>
    )
  }
}

Accept.propTypes = {
  element: PropTypes.object.isRequired,
}
