import React from 'react'

import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import List from './List'
import Show from './Show'
import Add from './Add'
import Orphelin from './Orphelin'
import Retraite from './Retraite'
import Veuve from './Veuve'

import Enfants from './Enfants'
import { userStore } from '../../../store/userStore'

export default function Admin(props) {
  let { path } = useRouteMatch()

  const { user } = userStore()

  if (isNotAdmin(user?.role?.role)) {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route exact strict path={path}>
        <List />
      </Route>
      <Route exact path={`${path}/add`}>
        <Add new />
      </Route>
      <Route exact path={`${path}/enfants`}>
        <Enfants path={path} />
      </Route>
      <Route exact path={`${path}/retraites`}>
        <Retraite path={path} />
      </Route>
      <Route path={`${path}/orphelin`}>
        <Orphelin />
      </Route>
      <Route path={`${path}/veuve`}>
        <Veuve />
      </Route>
      <Route exact path={`${path}/:matricule`} component={Show}></Route>
      <Route exact path={`${path}/:matricule/edit`} component={Add}></Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
