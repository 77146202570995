import React, { Component, useState } from 'react'
import CardHeader from '../../../../components/CardHeader'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { showDate, showMoney } from '../../../../helpers'
import { areYouSure, successToast, fatalError } from '../../../../helpers/sweetAlerts'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import InputText from '../../../../components/Form/InputText'

function Add(props) {
  let [show, setShow] = useState(false)
  let [name, setName] = useState('')
  let [montant, setMontant] = useState('')
  let [forfait, setForfait] = useState(false)

  const handleShow = () => setShow(!show)

  const updateName = event => setName(event.target.value)

  const updateMontant = event => setMontant(event.target.value)

  const send = () => {
    axios
      .post(getUrl('aide_sociale.store'), { name: name, forfait: forfait, montant: montant })
      .then(res => {
        props.refresh()
        successToast.fire({ text: getTrad('aidesociale.success.add') })
        setName('')
        setForfait(false)
        setShow(false)
      })
      .catch(err => fatalError())
  }

  return (
    <>
      <button className="btn btn-sm btn-success" onClick={handleShow}>
        <i className="fas fa-plus" />
      </button>
      <Modal show={show} centered onHide={handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{getTrad('aidesociale.newAide')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputText
            handler={updateName}
            value={name}
            name="name"
            label={getTrad('aidesociale.name')}
          />

          <InputText
            handler={updateMontant}
            value={montant}
            name="montant"
            label={getTrad('aidesociale.montant')}
          />

          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              name="forfait"
              checked={forfait}
              onChange={() => setForfait(!forfait)}
              className="custom-control-input"
              id="forfait-switch"
            />
            <label className="custom-control-label" htmlFor="forfait-switch">
              {getTrad('aidesociale.forfait')}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success btn-sm w-100" onClick={send}>
            {getTrad('commun.add')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function Update(props) {
  let [show, setShow] = useState(false)
  let [montant, setMontant] = useState(props.montant)
  let [name, setName] = useState(props.name)
  let [forfait, setForfait] = useState(props.forfait)

  const handleShow = () => {
    setShow(!show)
    setName(props.name)
    setMontant(props.montant)
    setForfait(props.forfait)
  }

  const updateName = event => setName(event.target.value)

  const updateMontant = event => setMontant(event.target.value)

  const send = event => {
    axios
      .patch(getUrl('aide_sociale.update', [props.id]), {
        name: name,
        forfait: forfait,
        montant: montant,
      })
      .then(res => {
        props.refresh()
        successToast.fire({ text: getTrad('aidesociale.success.add') })
        setName('')
        setForfait(false)
        setShow(false)
      })
      .catch(err => fatalError())
  }

  return (
    <>
      <button className="btn btn-secondary btn-sm" onClick={handleShow}>
        <i className="fas fa-edit" />
      </button>
      <Modal show={show} centered onHide={handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{getTrad('aidesociale.updateAide', [props.name])}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputText
            handler={updateName}
            value={name}
            name="name"
            label={getTrad('aidesociale.name')}
          />

          <InputText
            handler={updateMontant}
            value={montant}
            name="montant"
            label={getTrad('aidesociale.montant')}
          />

          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              name="forfait"
              checked={forfait}
              onChange={() => setForfait(!forfait)}
              className="custom-control-input"
              id="forfait-switch"
            />
            <label className="custom-control-label" htmlFor="forfait-switch">
              {getTrad('aidesociale.forfait')}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={name === props.name && forfait === props.forfait && montant === props.montant}
            className="btn btn-success btn-sm w-100"
            onClick={send}
          >
            {getTrad('commun.edit')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function Delete(props) {
  const sendDelete = () => {
    areYouSure.fire({ text: getTrad('aidesociale.delete', [props.name]) }).then(result => {
      if (result.value)
        axios
          .delete(getUrl('aide_sociale.destroy', [props.id]))
          .then(res => {
            successToast.fire({ text: getTrad('aidesociale.success.delete') })
            props.refresh()
          })
          .catch(err => fatalError())
    })
  }

  return (
    <button className="btn btn-sm btn-danger" onClick={sendDelete}>
      <i className="far fa-trash-alt" />
    </button>
  )
}

function TableInner(props) {
  if (props.data.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center">
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.data.map((e, k) => (
        <tr key={k}>
          <td>{showDate(e.created_at)}</td>
          <td>{e.name}</td>
          <td>{e.nb}</td>
          <td>{getTrad(`aidesociale.${e.forfait ? '' : 'non'}forfait`)}</td>
          <td>{showMoney(e.montant)}</td>

          <td style={{ textAlign: 'right' }}>
            <div className="btn-group">
              <Update {...e} refresh={props.refresh} />
              <Delete id={e.id} name={e.name} refresh={props.refresh} />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [], name: '', forfait: true }
  }

  handleUpdate = e => this.setState({ [e.target.name]: e.target.value })

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    axios.get(getUrl('aide_sociale.get')).then(res => this.setState({ data: res.data.success }))
  }

  render() {
    return (
      <div className="card">
        <CardHeader title={getTrad('pages.aideSociale.config')} />
        <div className="card-body p-0">
          <table className="table table-stripped table-hover w-100">
            <thead>
              <tr>
                <th>{getTrad('aidesociale.created_at')}</th>
                <th>{getTrad('aidesociale.name')}</th>
                <th>{getTrad('aidesociale.number')}</th>
                <th>{getTrad('aidesociale.forfait')}</th>
                <th>{getTrad('aidesociale.montant')}</th>

                <th style={{ textAlign: 'right' }}>
                  <Add refresh={this.refresh} />
                </th>
              </tr>
            </thead>
            <TableInner data={this.state.data} refresh={this.refresh} />
          </table>
        </div>
      </div>
    )
  }
}
