import React, { Component } from 'react'
import axios from 'axios'
import { Api } from '../../../Api'
import Waiting from '../../../components/Waiting'
import { successMsg, errorMsg } from '../../../helpers/sweetAlerts'
import { getTrad } from '../../../lang'

const roles = [
  { id: '1', name: getTrad('commun.role.ROLE_SUPER_USER') },
  { id: '2', name: getTrad('commun.role.ROLE_ADMINISTRATOR') },
  { id: '4', name: getTrad('commun.role.ROLE_ADMINISTRATOR_LOCAL') },
]

class AddAdmin extends Component {
  // Component Life Cycle

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      username: '',
      email: '',
      name: '',
      last: '',
      sexe: '',
      role: '1',
      password: '',
      errors: {},
      confirm_password: '',
      inputSubmit: '',
      sending: false,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="col-md-6 mx-auto">
        <div className="card">
          <div className="card-header">
            <h2 className="card-title text-center">{getTrad('pages.addAdmin')}</h2>
          </div>
          {this.renderBody()}
        </div>
      </div>
    )
  }
  // Handlers

  handleRadio(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submit(e) {
    e.preventDefault()
    let url = Api.baseURL + Api.addAdmin
    let data = {
      username: this.state.username,
      name: this.state.name,
      last: this.state.last,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      sexe: this.state.sexe,
      role_id: this.state.role,
    }
    this.setState({ sending: true }, () => {
      axios
        .post(url, data)
        .then(res => {
          successMsg(getTrad('success.add'))
          this.setState({
            sending: false,
            username: '',
            name: '',
            last: '',
            email: '',
            password: '',
            confirm_password: '',
            sexe: '',
            role_id: '',
            errors: {},
          })
        })
        .catch(err => {
          let status = err.response.status
          if (status !== 422) {
            errorMsg('Error')
          } else {
            this.setState({
              errors: err.response.data.errors,
              password: '',
              confirm_password: '',
              sending: false,
            })
          }
          this.setState({ sending: false })
        })
    })
  }

  // Rendering functions

  renderSexe() {
    let sexeInputs = [
      {
        id: 'checkM',
        value: 'M',
        checked: this.state.sexe === 'M' ? true : false,
        label: getTrad('commun.sexe.M'),
      },
      {
        id: 'checkF',
        checked: this.state.sexe === 'F' ? true : false,
        value: 'F',
        label: getTrad('commun.sexe.F'),
      },
    ]
    let inputClassName = 'form-check-input'
    let errorMessage

    if (this.state.errors.sexe) {
      inputClassName += ' is-invalid'
      errorMessage = (
        <div className="invalid-feedback">
          <ul>
            {this.state.errors.sexe.map((e, k) => {
              return <li key={k}>{e}</li>
            })}
          </ul>
        </div>
      )
    }

    return (
      <div className="col">
        <div className="form-group">
          <label>{getTrad('fileds.sexe')} :</label>
          <br />
          {sexeInputs.map((e, k) => {
            let inputProps = {
              className: inputClassName,
              type: 'radio',
              onChange: e => this.handleRadio(e),
              checked: e.checked,
              id: e.id,
              name: 'sexe',
              value: e.value,
            }
            return (
              <div key={k} className="form-check form-check-inline">
                <input {...inputProps} />
                <label className="form-check-label" htmlFor={e.id}>
                  {e.label}
                </label>
              </div>
            )
          })}
          {errorMessage}
        </div>
      </div>
    )
  }

  renderRole() {
    return (
      <div className="col">
        <div className="form-group">
          <label className="mb-3">{getTrad('fileds.roles')} :</label>
          {roles.map((e, k) => (
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={e => this.handleRadio(e)}
                  checked={this.state.role === e.id}
                  name="role"
                  value={e.id}
                />
                <span>{e.name}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderInput(label, name, type) {
    let inputProps = {
      type: type,
      className: 'form-control w-100 form-control-sm',
      name: name,
      onChange: e => this.handleChange(e),
      id: name,
      placeholder: label,
      value: this.state[name],
    }
    let errorMessage = ''
    if (this.state.errors?.[name]) {
      inputProps.className += ' is-invalid'
      errorMessage = (
        <div className="invalid-feedback">
          <ul>
            {this.state.errors?.[name]?.map((e, k) => {
              return <li key={k}>{e}</li>
            })}
          </ul>
        </div>
      )
    }
    return (
      <div className="col">
        <div className="form-group">
          <label htmlFor={name}>{label} :</label>
          <input {...inputProps} />
          {errorMessage}
        </div>
      </div>
    )
  }

  renderBtn() {
    let btnProps = {
      type: 'submit',
      className: 'btn btn-success w-100',
    }
    let btnInner = getTrad('commun.add')
    if (this.state.sending) {
      btnProps.disabled = true
      btnInner = <Waiting />
    }
    return <button {...btnProps}>{btnInner}</button>
  }

  renderBody() {
    return (
      <div className="card-body">
        <form onSubmit={e => this.submit(e)}>
          <div className="row">
            {this.renderInput(getTrad('fileds.username'), 'username')}
            {this.renderInput(getTrad('fileds.email'), 'email', 'email')}
          </div>
          <div className="row">
            {this.renderInput(getTrad('fileds.first_name'), 'name')}
            {this.renderInput(getTrad('fileds.last_name'), 'last')}
          </div>
          <div className="row">
            <div className="col">{this.renderSexe()}</div>
            <div className="col">{this.renderRole()}</div>
          </div>
          <div className="row">
            {this.renderInput(getTrad('fileds.password'), 'password', 'password')}
            {this.renderInput(
              getTrad('fileds.confirm_new_password'),
              'confirm_password',
              'password'
            )}
          </div>
          {this.renderBtn()}
        </form>
      </div>
    )
  }
}
export default AddAdmin
