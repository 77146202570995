import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import Waiting from '../../../components/Waiting'
import { successMsg, errorMsg } from '../../../helpers/sweetAlerts'
import { Redirect } from 'react-router-dom'
import { getTrad } from '../../../lang'

function SendBtn({ loading }) {
  return (
    <button className="btn btn-success w-100" type="submit">
      {loading ? <Waiting /> : getTrad('auth.change')}
    </button>
  )
}

function RenderInput(props) {
  let name = props.name
  let label = getTrad(`fileds.${name}`)
  let params = {
    type: 'password',
    className: 'form-control w-100 form-control-sm',
    value: props.value,
    name: name,
    id: name,
    placeholder: label,
    required: true,
  }
  let errorText
  if (props.error) {
    params.className += ' is-invalid'
    errorText = (
      <div className="invalid-feedback">
        {props.error.map((e, k) => {
          return <li key={k}>{getTrad(e)}</li>
        })}
      </div>
    )
  }
  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} <span className="text-danger">*</span>
      </label>
      <input {...params} onChange={props.handler} />
      {errorText}
    </div>
  )
}

const fields = ['old_password', 'new_password', 'confirm_new_password']

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      old_password: '',
      new_password: '',
      confirm_new_password: '',
      errors: {},
      redirectToHome: false,
    }
    this.changeInput = this.changeInput.bind(this)
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitRequest() {}

  submit(e) {
    e.preventDefault()
    let url = getUrl('changePassword')
    let data = {}
    fields.map(e => (data[e] = this.state[e]))

    this.setState({ old_password: '', new_password: '', confirm_new_password: '' }, () => {
      axios
        .post(url, data)
        .then(res => {
          if (res.status === 200) {
            successMsg(getTrad('auth.password_changed'))
            this.setState({ redirectToHome: true })
          }
        })
        .catch(err => {
          let errorTxt = getTrad('auth.pwd_error_change')
          if (err.response) {
            this.setState({ errors: err.response.data.error })
            if (err.response.status !== 422) {
              errorMsg(errorTxt)
            }
          } else {
            errorMsg(errorTxt)
          }
        })
    })
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to="/" />
    }

    return (
      <div className="col-md-6 mx-auto">
        <div className="card">
          <div className="card-header text-center">
            <h2 className="card-title">{getTrad('auth.change')}</h2>
          </div>
          <div className="card-body">
            <form onSubmit={e => this.submit(e)}>
              {fields.map((e, k) => (
                <RenderInput
                  name={e}
                  key={k}
                  value={this.state[e]}
                  error={this.state.errors[e]}
                  handler={this.changeInput}
                />
              ))}
              <SendBtn loading={this.state.loading} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default ChangePassword
