import React from 'react'
import { areYouSure, fatalError, successToast } from '../../../../helpers/sweetAlerts'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import axios from 'axios'

function DeleteRemboursement(props) {
  const remove = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.value)
        axios
          .delete(getUrl('credits.rembours', [props.id]))
          .then(res => {
            successToast.fire(getTrad('success.delete.remboursement'))
            props.refresh()
          })
          .catch(err => {
            fatalError()
            props.refresh()
          })
    })
  }
  return (
    <button onClick={remove} className="btn btn-danger btn-sm">
      <i className="fas fa-trash-alt" />
    </button>
  )
}

export default DeleteRemboursement
