import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { showMoney, showDate } from '../../../../helpers'

function ShowTable(props) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>{getTrad('fileds.date')}</th>
          <th>{getTrad('fileds.montant')}</th>
        </tr>
      </thead>
      <tbody>
        {props.cesions.length === 0 ? (
          <tr>
            <td colSpan={2} className="text-center">
              {getTrad('commun.noSearchData')}
            </td>
          </tr>
        ) : (
          props.cesions.map((e, k) => (
            <tr key={k}>
              <td>{showDate(e.date)}</td>
              <td>{showMoney(e.montant)}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

function Details(props) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(!show)
  const fullname = props.adherant ? props.adherant.first_name + ' ' + props.adherant.last_name : ''
  return (
    <>
      <button onClick={handleShow} className="btn btn-sm btn-primary">
        <i className="fas fa-eye" />
      </button>

      <Modal size="lg" show={show} onHide={handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{getTrad('parent.show.title', [fullname])}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ShowTable {...props} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleShow}>
            <i className="fas fa-times" /> {getTrad('commun.close')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Details
