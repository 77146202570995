import React, { useState } from 'react'
import { fatalError, areYouSure, successMsg } from '../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import { Modal } from 'react-bootstrap'
import NumInput from '../../../components/Form/NumInput'

function Edit(props) {
  const [show, setShow] = useState(false)
  const [montant, setMontant] = useState(props.montant.toString())

  const handleShow = () => setShow(!show)
  const handleMontant = event => setMontant(event.target.value)

  const handleSuccess = () => {
    successMsg(getTrad('parent.success.editSaisie'))
    props.refresh()
    handleShow()
  }
  const handleError = () => {
    fatalError()
    props.refresh()
    handleShow()
  }

  const update = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.value) {
        axios
          .patch(getUrl('parent.saisie.show', [props.id]), { montant: montant })
          .then(handleSuccess)
          .catch(handleError)
      }
    })
  }

  return (
    <>
      <button onClick={handleShow} className="btn btn-sm btn-secondary">
        <i className="fas fa-edit" />
      </button>
      <Modal show={show} centered onHide={() => null}>
        <Modal.Header>
          <Modal.Title>{getTrad('parent.page.editSaisie', [props.id])}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NumInput
            value={montant}
            handler={handleMontant}
            name="montant"
            label={getTrad('fileds.montant')}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="col">
            <button
              disabled={montant === props.montant.toString()}
              onClick={update}
              className="btn btn-success w-100"
            >
              <i className="fas fa-check" /> {getTrad('commun.edit')}
            </button>
          </div>
          <div className="col">
            <button onClick={handleShow} className="btn btn-danger w-100">
              <i className="fas fa-times" /> {getTrad('commun.close')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Edit
