export const creditM = {
  icon: 'fas fa-money-bill-wave',
  text: 'crud_mag_credits',
  link: 'credit',
}

export const creditA = {
  icon: 'fas fa-money-bill-wave',
  text: 'crud_credits',
  link: 'credit',
  submenue: [
    { icon: 'fas fa-plus', text: 'add_demande_credits', link: 'add' },
    { icon: 'fas fa-list', text: 'list_demande_credits', link: 'demande' },
    { icon: 'fas fa-money-bill-wave', text: 'credit_en_cours', link: '' },
    { icon: 'fas fa-check', text: 'credit_en_terminee', link: 'all' },
    { icon: 'fas fa-ban', text: 'credit_non_payee', link: 'non-payee' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-cash-register', text: 'saisie_salaire', link: 'saisie' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-chart-bar', text: 'statistiques', link: 'statistiques' },
  ],
}
