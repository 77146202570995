import React from 'react'
import { getTrad } from '../../../../../lang'
import BodyJustif from './BodyJustif'

function Body(props) {
  if (props.justificatifs.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="4" className="text-center text-muted">
            {getTrad('errors.noJustificatifs')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.justificatifs.map((e, k) => (
        <BodyJustif refresh={props.refresh} dontShow={props.dontShow} key={k} {...e} />
      ))}
    </tbody>
  )
}

export default Body
