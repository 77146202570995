import React, { useContext } from 'react'

import { LangContext } from '../../context/LangContext'
import navData from '../../data/navData'
import { userStore } from '../../store/userStore'
import MenueItem from "./MenueItem"


function Menue() {
  const { lang } = useContext(LangContext)
  const { user } = userStore()
  const menu = user ? navData[user.role.role] : []

  return (
    <ul className={`navbar-nav ${lang === 'ar' ? 'ml-auto' : 'mr-auto'}`}>
      {menu.map((e, k) => (
        <MenueItem key={e.link} {...e} />
      ))}
    </ul>
  )
}

export default Menue
