import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'

function RenderInner(props) {
  const [img, setImage] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .post(getUrl('files.get'), { path: props.proof })
      .then(res => {
        setLoading(false)
        setImage(res.data.success)
      })
      .catch(() => {
        setLoading(false)
        setImage('')
      })
  }, [props.proof])

  if (props.loading || loading) return <Waiting />
  if (loading === false && img === '')
    <div className="alert alert-danger text-center mb-0">{getTrad('errors.loadingFile')}</div>
  return <img className="w-100" src={img} alt={props.pres_full_name} />
}

function ShowJustif(props) {
  const [show, setShow] = useState(false)
  return (
    <>
      <button className="btn btn-sm btn-primary" onClick={() => setShow(!show)}>
        <i className="fas fa-eye"></i>
      </button>
      <Modal show={show} centered onHide={() => setShow(!show)}>
        <Modal.Header>
          <Modal.Title>{getTrad('science.preuvede', [props.fullName])}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderInner proof={props.img} />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ShowJustif
