import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import axios from 'axios'
import { convertDate } from '../../../../helpers'
import { getUrl } from '../../../../Api'
import InputSelect from '../../../../components/Form/InputSelect'
import NumInput from '../../../../components/Form/NumInput'
import InputMounth from '../../../../components/Form/InputMounth'
import { successMsg, errorMsg, fatalError } from '../../../../helpers/sweetAlerts'
const today = new Date()

function AddPaiement(props) {
  const [show, setShow] = useState(false)
  const [sending, setSending] = useState(false)
  const [montant, setMontant] = useState('0')
  const [type, setType] = useState('')
  const [erros, setErrors] = useState({})
  const [date, setDate] = useState(
    (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getFullYear()
  )

  const send = () => {
    const data = {
      matricule: props.matricule,
      montant: montant,
      type: type,
      date: convertDate('01-' + date),
    }
    setSending(true)
    axios
      .post(getUrl('retraite.crud'), data)
      .then(res => {
        setSending(false)
        successMsg('Paiement ajouté avec success')
      })
      .catch(err => {
        setSending(false)
        if (err.response && err.response.status === 401) errorMsg('Paiement dupliqué')
        else if (err.response && err.response.status === 422) setErrors(err.response.data.errors)
        else fatalError()
      })
  }
  const close = () => {
    setMontant(0)
    setShow(false)
    setType('')
    setDate((today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getFullYear())
  }
  return (
    <>
      <button onClick={() => setShow(true)} className="btn btn-success">
        <i className="fas fa-cash-register"></i>
      </button>
      <Modal show={show} onHide={() => null}>
        <Modal.Header>
          <Modal.Title>{getTrad('adherant.addremboursement')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputMounth
            handler={event => setDate(event.target.value)}
            value={date}
            label={getTrad('fileds.date')}
            name="date"
            error={erros.date}
          />
          <NumInput
            handler={event => setMontant(event.target.value)}
            label={getTrad('fileds.montant')}
            name="montant_echance"
            value={montant}
            error={erros.montant ? erros.montant.map(e => getTrad(e)) : null}
          />

          <InputSelect
            name="type"
            value={type}
            label={getTrad('credit.type_remboursement_name')}
            handler={event => setType(event.target.value)}
            error={erros.type}
            data={[
              { value: 'E', text: getTrad('credit.type_remboursement.E') },
              { value: 'C', text: getTrad('credit.type_remboursement.C') },
              { value: 'B', text: getTrad('credit.type_remboursement.B') },
              { value: 'P', text: getTrad('credit.type_remboursement.P') },
              { value: 'V', text: getTrad('credit.type_remboursement.V') },
            ]}
          />
        </Modal.Body>
        <Modal.Footer>
          <button disabled={sending} onClick={send} className="btn btn-success">
            <i className="fas fa-check mr-2"></i> {getTrad('commun.add')}
          </button>
          <button disabled={sending} onClick={close} className="btn btn-danger">
            <i className="fas fa-times mr-2"></i> {getTrad('commun.close')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddPaiement
