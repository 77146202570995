import React, { useState } from 'react'
import { getTrad } from '../../../../lang'
import { showDate } from '../../../../helpers'
import { Modal } from 'react-bootstrap'

function ShowPrestatires(props) {
  const [show, setShow] = useState(false)
  const handleshow = () => setShow(!show)
  return (
    <>
      <button onClick={handleshow} className="btn btn-sm btn-primary">
        <i className="fas fa-eye" />
      </button>
      <Modal show={show} onHide={handleshow}>
        <Modal.Header>
          <Modal.Title>{props.aide}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <td>{getTrad('fileds.first_name')}</td>
                <td>{getTrad('fileds.last_name')}</td>
                <td>{getTrad('aidesociale.created_at')}</td>
              </tr>
            </thead>
            <tbody>
              {props.prestataies.map((e, k) => (
                <tr key={k}>
                  <td>{e.details.first_name}</td>
                  <td>{e.details.last_name}</td>
                  <td>{showDate(e.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShowPrestatires
