import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

function SMSIndivi({ matricule, firstName, lastName }) {
  const handleSend = () => {
    Swal.fire({
      title: `Envoie d'SMS à ${firstName} ${lastName}`,
      input: 'textarea',
      inputPlaceholder: `Envoie d'SMS à ${firstName} ${lastName}`,
      inputAttributes: {
        'aria-label': `Envoie d'SMS à ${firstName} ${lastName}`,
      },
      showCancelButton: true,
      confirmButtonText: 'Envoyer',
      inputValidator: value => {
        return new Promise(resolve => {
          if (value.length === 0) {
            resolve('Il faut écrire un message')
          } else if (value.length > 160) {
            resolve('Le message est trop long')
          } else {
            resolve()
          }
        })
      },
      preConfirm: message =>
        axios
          .post('/sms/send', { matricule, message })
          .then(res => res.data)
          .catch(err => {
            Swal.showValidationMessage(
              err?.response?.data?.error?.message?.[0] || 'une erreur est survenue'
            )
          }),
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then(result => {
        if (result.isConfirmed) Swal.fire({ title: `SMS envoyé avec success` })
      })
      .catch(err => {
        Swal.fire({ title: err })
      })
  }
  return (
    <button onClick={handleSend} className="btn btn-sm btn-success">
      <i className="fas fa-sms"></i>
    </button>
  )
}

export default SMSIndivi
