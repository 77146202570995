import React, { Component } from 'react'
import axios from 'axios'

import { showDate } from '../../../../helpers'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'

import Add from './Add'
import Edit from './Edit'
import Show from './Show'
import Delete from './Delete'
import Justificatif from './Justificatif'
export default class Family extends Component {
  // Component Life Cycle

  constructor(props) {
    super(props)
    this.state = { family: [] }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.matricule !== this.props.matricule) this.refresh()
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    if (this.props.matricule) {
      axios.get(getUrl('prestataire.get', [this.props.matricule])).then(res => {
        if (res.data.success) this.setState({ family: res.data.success })
      })
    }
  }

  renderThead() {
    this.refresh()
    return (
      <thead>
        <tr>
          <th>{getTrad('fileds.relation')}</th>
          <th>{getTrad('fileds.first_name')}</th>
          <th>{getTrad('fileds.last_name')}</th>
          <th>{getTrad('fileds.sexe')}</th>
          <th>{getTrad('fileds.birth_day')}</th>
          <th>{getTrad('fileds.encharge')}</th>
          <th>{getTrad('fileds.is_handicape')}</th>
          <th className="non-printable" width="1">
            <Add
              defaultLast={this.props.defaultLast}
              familyHandle={this.handleFamily}
              {...this.props}
              {...this.state}
            />
          </th>
        </tr>
      </thead>
    )
  }

  renderTbody() {
    if (this.state.family.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="7" className="text-center">
              {getTrad(`commun.noFamilyData`)}
            </td>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody>
        {this.state.family.map(e => {
          return (
            <tr key={e.id}>
              <td>{getTrad(`type.${e.type}`)}</td>
              <td>{e.first_name}</td>
              <td>{e.last_name}</td>
              <td>{getTrad(`commun.sexe.${e.sexe}`)}</td>
              <td>{showDate(e.birth_day)}</td>
              <td>{getTrad(`commun.binary.${e.is_supported}`)}</td>
              <td>{getTrad(`commun.binary.${e.is_handicape}`)}</td>

              <td className="text-right non-printable">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <Justificatif {...e} refresh={this.refresh} />
                  <Show
                    member={e}
                    familyHandle={this.handleFamily}
                    {...this.props}
                    {...this.state}
                  />
                  <Edit member={e} refresh={this.refresh} {...this.props} {...this.state} />
                  <Delete
                    member={e}
                    familyHandle={this.handleFamily}
                    {...this.props}
                    {...this.state}
                  />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <table className="table table-hover table-striped familyMembers">
            {this.renderThead()}
            {this.renderTbody()}
          </table>
        </div>
      </div>
    )
  }

  handleFamily = family => this.setState({ family: family })
}
