export const fr = {
  logout: 'Se déconnecter',
  change_password: 'Modifier le mot de passe',
  welecome: 'Bienvenue',
  crud_adherant: 'Adhérents',
  crud_bultin_soins: 'Bulletins de soin',
  crud_credits: 'Crédits',
  crud_SMS: 'SMS',
  crud_aide: 'Aides',
  nontraite_aide: 'Liste des aides non traitées',
  traite_aide: 'Liste des aides traitées',
  refusee_aide: 'Liste des aides refusées',
  crud_admins: 'Admins',
  crud_journee_science: 'Journée de savoir',
  crud_mag_credits: 'Mes crédits',
  crud_mag_aide: 'Mes aides',
  demande_bultin_soins: 'Demandes de bulletin de soin',
  credit_non_payee: 'Remboursements non payés',
  list_retraite: 'Liste des retraités',
  list_orphelin: 'Liste des enfants orphelins',
  virement: 'Ordre de virement',

  add_bultin_soins: 'Ajouter un bulletin de soin',
  add_demande_credits: 'Ajouter une demande de crédit',
  add_mag_demande_credits: 'Demander un crédit',

  list_log: 'Historique',
  list_bultin_soins: 'Liste des bulletins de soin',
  list_bordereaux: 'Liste des bordereaux',
  list_demande_credits: 'Liste des demandes de crédits',
  list_mag_demande_credits: 'Mes demandes de crédits',
  list_adherants: 'Liste des adhérents',
  list_enfants: 'Liste des enfants à charge',
  list_parents: 'Liste de tous les parents à charge',
  list_parents_active: 'Liste des parents à charge',
  mes_aides: 'Mes aides',
  aide_sociale_versements: 'Liste des versements',
  aide_sociale_historique: 'Historique des adhérents',

  science_demande: 'Liste des demandes',
  science_settings: 'Paramètres',
  science_acceptee: 'Liste des demandes acceptées',
  science_refusee: 'Liste des demandes refusées',

  crud_mag_aide_sociale: 'Mes aides sociales',
  crud_aide_sociale: 'Aides sociales',
  aide_sociale_demande: 'Liste des aides sociales',
  aide_sociale_settings: 'Paramètres',

  profil: 'Mon profil',
  mag_bs: 'Mes bulletins de soins',
  saisie_salaire: 'Cession sur salaire',

  parent_a_charge: 'Parents à charge',

  search_bultin_soins: "Recherche d'un bulletin de soins",
  credit_en_cours: 'Liste des crédits en cours',
  credit_en_terminee: 'Liste des crédits remboursés',
  credit_mag_en_cours: 'Mes crédits en cours',
  sms_multi: 'SMS multiple',
  sms_indivi: 'SMS individuel',
  etablissements: 'Établissement',
  params: 'Paramètres',
  gestion_etabs: 'Gérer les établissements',
  gestion_gouvs: 'Gérer les gouvernorats',
  statistiques: 'Statistiques',
  statistiques_ad: 'Statistiques adhérents',
  list_veuve: 'Liste des veuf(ve)s',
}

export const ar = {
  logout: 'تسجيل الخروج',
  change_password: 'تغيير كلمة السر',
  welecome: 'مرحبا',
  crud_adherant: 'الأعضاء',
  crud_bultin_soins: 'بطاقات العلاج',
  crud_credits: 'القروض',
  crud_SMS: 'SMS',
  crud_aide: 'المساعدات',
  nontraite_aide: 'قائمة المساعدات غير المدروسة',
  traite_aide: 'قائمة المساعدات المدروسة',
  refusee_aide: 'قائمة المساعدات المرفوضة',
  crud_admins: 'المشرفون',
  crud_journee_science: 'يوم المعرفة',
  crud_mag_credits: 'ديوني',
  crud_mag_aide: 'اعاناتي',
  demande_bultin_soins: 'مطالب بطاقات العلاج',
  credit_non_payee: 'اﻷقساط غير المدفوعة',
  list_retraite: 'قائمة المتقاعدين',
  list_orphelin: 'قائمة اﻷطفال اليتامى',

  add_bultin_soins: 'أضف بطاقة العلاج',
  add_demande_credits: 'أضف طلب القرض',
  add_mag_demande_credits: 'التقديم لقرض مالي',

  list_log: 'تاريخ ولوجي',
  list_bultin_soins: 'قائمة بطاقات العلاج',
  list_bordereaux: 'قائمة القسيمات',
  list_demande_credits: 'قائمة طلبات القروض',
  list_mag_demande_credits: 'طلبات القروض الخاصة بي',
  list_adherants: 'قائمة الأعضاء',
  list_enfants: 'قائمة الأطفال المعالين',
  list_parents: 'قائمة الآباء المعالين',
  list_parents_active: 'قائمة الآباء المعالين',
  mes_aides: 'مساعداتي',
  aide_sociale_versements: 'قائمة الدّفوعات',
  aide_sociale_historique: 'تاريخ ولوج اﻷعضاء',

  science_demande: 'قائمة المطالب',
  science_settings: 'التّعديلات',
  science_acceptee: 'قائمة المطالب المقبولة',
  science_refusee: 'قائمة المطالب المرفوضة',

  crud_mag_aide_sociale: 'مساعدتي الاجتماعية',
  crud_aide_sociale: 'المساعدات الاجتماعية',
  aide_sociale_demande: 'قائمة المساعدات الاجتماعية',
  aide_sociale_settings: 'التّعديلات',

  profil: 'ملفي',
  mag_bs: 'بطاقة العلاج',
  saisie_salaire: 'التّنازل عن اﻷجر',

  parent_a_charge: 'الآباء المعالين',

  search_bultin_soins: 'ابحث عن بطاقة العلاج',
  credit_en_cours: 'قائمة القروض الحالية',
  credit_en_terminee: 'قائمة القروض المستردة',
  credit_mag_en_cours: 'قروضي الحالية',
  sms_multi: 'SMS متعدّد',
  sms_indivi: 'SMS فرديّ',
  etablissements: 'المؤسسات',
  params: 'التّعديلات',
  gestion_etabs: 'إدارة المنشآت',
  gestion_gouvs: 'إدارة الولايات',
  statistiques: 'الاحصائيات',
}

export const en = {
  logout: 'Log out',
  change_password: 'Change the password',
  welecome: 'Welcome',
  crud_adherant: 'Members',
  crud_bultin_soins: 'Treatment bulletins',
  crud_credits: 'Credits',
  crud_SMS: 'SMS',
  crud_aide: 'Support',
  nontraite_aide: 'untreated aid list',
  traite_aide: 'Treated aid list',
  refusee_aide: 'Refused aid list',
  crud_admins: 'Admins',
  crud_journee_science: 'Day of knowledge',
  crud_mag_credits: 'My credits',
  crud_mag_aide: 'My support',
  demande_bultin_soins: 'Care bulletin requests',
  credit_non_payee: 'Unpaid refunds',
  list_retraite: 'List of retirees',
  list_orphelin: 'List of orphans',

  add_bultin_soins: 'Add a care bulletin',
  add_demande_credits: 'Add a credit request',
  add_mag_demande_credits: 'Request a credit',

  list_log: 'Historical',
  list_bultin_soins: 'Care bulletin list',
  list_bordereaux: 'Tax treatment list',
  list_demande_credits: 'Credit requests list',
  list_mag_demande_credits: 'My credit requests',
  list_adherants: 'Members` list',
  list_enfants: 'List of dependent children',
  list_parents: 'List of all dependent parents',
  list_parents_active: 'List of dependent parents',
  mes_aides: 'My support',
  aide_sociale_versements: 'List of payments',
  aide_sociale_historique: 'Members historical',

  science_demande: 'List of requests',
  science_settings: 'Settings',
  science_acceptee: 'List of accepted requests',
  science_refusee: 'List of refused requests',

  crud_mag_aide_sociale: 'My social support',
  crud_aide_sociale: 'Social aid',
  aide_sociale_demande: 'List of social support',
  aide_sociale_settings: 'Settings',

  profil: 'My profile',
  mag_bs: 'My care bulletins',
  saisie_salaire: 'Assignment on salary',

  parent_a_charge: 'Dependent parents',

  search_bultin_soins: 'Search for a care bulletin',
  credit_en_cours: 'List of current credits',
  credit_en_terminee: 'List of refunded credits',
  credit_mag_en_cours: 'My current credits',
  sms_multi: 'Multiple SMS',
  sms_indivi: 'Individual SMS',
  etablissements: 'Establishment',
  params: 'Settings',
  gestion_etabs: 'Manage establishments',
  gestion_gouvs: 'Manage governorates',
  statistiques: 'Statistics',
}
