import React from 'react'
import Export from '../../../../components/Export'
import { getTrad } from '../../../../lang'
// import Export
function InfoVersement(props) {
  const keys = ['name', 'date', 'number_adherants', 'number', 'forfait', 'montant', 'total']
  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="row">
          <div className="col-10">
            <h3>{getTrad('pages.aideSociale.details', [props.name, props.date])}</h3>
          </div>
          <div className="col-2">
            <Export
              fileName={getTrad('pages.aideSociale.details', [props.name, props.date])}
              csvData={props.data}
              title={getTrad('commun.export')}
              headers={props.headers}
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row row-cols-3">
          {keys.map((e, k) => (
            <div key={k} className="col">
              <span className="font-weight-bold mr-3">{getTrad('aidesociale.' + e)} :</span>
              <span>{props[e]}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InfoVersement
