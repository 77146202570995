import React from 'react'
import { getTrad } from '../../../../../lang'
import ShowJustif from './ShowJustif'
import ActionJustificatif from './ActionJustificatif'

function BodyJustif(props) {
  return (
    <tr>
      <td>{props.year}</td>
      <td>
        <ShowJustif proof={props.path} />
      </td>
      <td>{getTrad(`adherant.prestataire.justificatif.status.${props.status}`)}</td>
      <td>
        <ActionJustificatif {...props} />
      </td>
    </tr>
  )
}

export default BodyJustif
