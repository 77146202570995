import React, { useState } from 'react'
import { showMoney, validDate } from '../../../../../helpers'
import { getUrl } from '../../../../../Api'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import axios from 'axios'
import moment from 'moment'
import InputText from '../../../../../components/Form/InputText'
import NumInput from '../../../../../components/Form/NumInput'
import { getTrad } from '../../../../../lang'
import { fatalError } from '../../../../../helpers/sweetAlerts'
import Waiting from '../../../../../components/Waiting'
import { Link } from 'react-router-dom'

const cnVal = 'font-weight-bold align-top px-1'
const cnDataVal = 'align-top px-1'

const SubmitBtn = ({ sending, disabled }) => {
  const Inner = () => (sending ? <Waiting /> : <i className="fas fa-check" />)
  return (
    <button className="btn btn-success" disabled={sending || disabled} type="submit">
      <Inner />
    </button>
  )
}

const ResetBtn = ({ sending }) => {
  const Inner = () => (sending ? <Waiting /> : <i className="fas fa-times" />)
  return (
    <button className="btn btn-danger" disabled={sending} type="reset">
      <Inner />
    </button>
  )
}

function EditEdition(props) {
  return (
    <form onSubmit={props.send} onReset={() => props.handleEdit('edition')}>
      <div className="input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          placeholder={getTrad(`science.edition`)}
          value={props.value}
          onChange={props.handler}
          name="edition"
        />
        <div className="input-group-append" id="button-addon4">
          <SubmitBtn sending={props.sending} />
          <ResetBtn sending={props.sending} />
        </div>
      </div>
    </form>
  )
}

function ShowEdition(props) {
  if (props.editing === 'edition') return <EditEdition {...props} />

  const EditBtn = () => {
    if (props.editable) return <Edit handleClick={() => props.handleEdit('edition')} />
    return <></>
  }

  return (
    <div className="prix-row">
      <EditBtn />
      {props.value}
    </div>
  )
}

export function ShowEditionName(props) {
  return (
    <tr>
      <td className={`${cnVal} w-25`}>{getTrad(`science.edition`)}</td>
      <td className={cnVal} style={{ width: 1 }}>
        :
      </td>
      <td className={cnDataVal}>
        <ShowEdition {...props} />
      </td>
    </tr>
  )
}

function HandleDate(props) {
  if (props.editing === props.field) return <EditDate {...props} />
  const EditBtn = () => {
    if (props.editable) return <Edit handleClick={() => props.handleEdit(props.field)} />
    return <></>
  }

  return (
    <div className="prix-row">
      <EditBtn />
      {props.value}
    </div>
  )
}

export function ShowDate(props) {
  return (
    <tr>
      <td className={`${cnVal} w-25`}>{getTrad(`science.${props.field}`)}</td>
      <td className={cnVal} style={{ width: 1 }}>
        :
      </td>
      <td className={cnDataVal}>
        <HandleDate {...props} />
      </td>
    </tr>
  )
}

function EditDate(props) {
  const problem = !validDate(props.value)
  return (
    <form onSubmit={props.send} onReset={() => props.handleEdit(props.field)}>
      <div className="input-group input-group-sm">
        <input
          type="text"
          className={`form-control${problem ? ' is-invalid' : ''}`}
          placeholder={getTrad(`science.edition`)}
          value={props.value}
          onChange={props.handler}
          name={'input' + props.field}
        />
        <div className="input-group-append" id="button-addon4">
          <SubmitBtn sending={props.sending} disabled={problem} />
          <ResetBtn sending={props.sending} />
        </div>
      </div>
    </form>
  )
}

export function GenStats(props) {
  let tdClass = 'px-1'
  let tot_nt = 0
  let tot_a = 0
  let tot_r = 0
  let tot = 0
  return (
    <table className="w-100 mt-3">
      <thead>
        <tr className="border-top border-dark">
          <td className={tdClass}>{getTrad('science.prix')}</td>
          <td className={tdClass}>{getTrad('science.nb_condidats_non_trait')}</td>
          <td className={tdClass}>{getTrad('science.nb_condidats_acceptee')}</td>
          <td className={tdClass}>{getTrad('science.nb_condidats_refusee')}</td>
          <td className={tdClass}>{getTrad('science.nb_condidats_total')}</td>
          <td className={tdClass}>{getTrad('science.total_prix')}</td>
        </tr>
      </thead>
      <tbody className="border-top border-dark">
        {props.data.map((e, k) => {
          let total = e.nb_condidats_acceptee + e.nb_condidats_non_trait + e.nb_condidats_refusee

          tot_nt += e.nb_condidats_non_trait
          tot_a += e.nb_condidats_acceptee
          tot_r += e.nb_condidats_refusee
          tot += total
          return (
            <tr className="border-top border-dark" key={k}>
              <td className={tdClass}>{e.niveau}</td>
              <td className={tdClass}>{e.nb_condidats_non_trait}</td>
              <td className={tdClass}>{e.nb_condidats_acceptee}</td>
              <td className={tdClass}>{e.nb_condidats_refusee}</td>
              <td className={tdClass}>{total}</td>
              <td className={tdClass}>{showMoney(e.nb_condidats_acceptee * e.prix)}</td>
            </tr>
          )
        })}
        <tr className="border-top border-dark">
          <td className={tdClass}>{getTrad('commun.total')}</td>
          <td className={tdClass}>{tot_nt}</td>
          <td className={tdClass}>{tot_a}</td>
          <td className={tdClass}>{tot_r}</td>
          <td className={tdClass}>{tot}</td>
          <td className={tdClass}>
            {showMoney(
              props.data.map(e => e.nb_condidats_acceptee * e.prix).reduce((a, b) => a + b, 0)
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function Edit(props) {
  return (
    <button
      className="btn btn-custom mx-1 btn-sm text-dark"
      onClick={() => props.handleClick(props.id, props.lvl)}
    >
      <i className="fas fa-edit" />
    </button>
  )
}

function Delete(props) {
  return (
    <button
      className="btn btn-custom mx-1 btn-sm text-danger"
      onClick={() => props.handleClick(props.id, props.lvl)}
    >
      <i className="fas fa-trash" />
    </button>
  )
}

export function Prix(props) {
  if (props.id === props.editing) {
    return (
      <EditFields
        errors={[]}
        handleData={props.handleData}
        niveauInput={props.niveauInput}
        handleSend={props.handleUpdate}
        prixInput={props.prixInput}
        lvl={props.niveau}
        id={props.id}
        adding={true}
        handleCancel={props.handleEdit}
        editable={true}
      />
    )
  }

  let actionBtn = <></>
  if (props.editable) {
    actionBtn = (
      <>
        <Edit id={props.id} lvl={props.niveau} handleClick={props.handleEdit} />
        <Delete id={props.id} lvl={props.niveau} handleClick={props.handleDelete} />
      </>
    )
  }
  return (
    <div className="prix-row">
      {actionBtn}
      <b>{props.niveau}</b> ({showMoney(props.prix)})
    </div>
  )
}

export function AddBtn(props) {
  if (!props.editable) return <></>
  let btnClass = 'btn btn-sm btn-custom text-success '
  let btnInner = 'fas fa-plus'

  if (props.adding) {
    btnClass = 'btn btn-sm btn-custom text-danger'
    btnInner = 'fas fa-times'
  }

  return (
    <button className={btnClass} onClick={props.handle}>
      <i className={btnInner} />
    </button>
  )
}

export function AddFields(props) {
  if (!props.editable || !props.adding) return <></>
  if (props.sending) return <Waiting />

  return (
    <div className="row">
      <div className="col">
        <InputText
          name="niveauInput"
          value={props.niveauInput}
          handler={props.handleData}
          label={getTrad(`science.niveauInput`)}
          error={props.errors.niveauInput}
          dontShow={true}
        />
      </div>
      <div className="col">
        <NumInput
          name="prixInput"
          value={props.prixInput.toString()}
          handler={props.handleData}
          label={getTrad(`science.prixInput`)}
          error={props.errors.prixInput}
          dontShow={true}
        />
      </div>

      <div className="col">
        <button className="btn btn-sm btn-success" onClick={props.handleSend}>
          <i className="fas fa-plus" />
        </button>
      </div>
    </div>
  )
}

export function EditFields(props) {
  if (!props.editable || !props.adding) {
    return <></>
  }
  if (props.sending) {
    return <Waiting />
  }
  return (
    <div className="row">
      <div className="col">
        <InputText
          name="niveauInput"
          value={props.niveauInput}
          handler={props.handleData}
          label={getTrad(`science.niveauInput`)}
          error={props.errors.niveauInput}
          dontShow={true}
        />
      </div>
      <div className="col">
        <NumInput
          name="prixInput"
          value={props.prixInput.toString()}
          handler={props.handleData}
          label={getTrad(`science.prixInput`)}
          error={props.errors.prixInput}
          dontShow={true}
        />
      </div>

      <div className="col-1">
        <button className="btn btn-sm btn-success w-100" onClick={props.handleSend}>
          <i className="fas fa-check" />
        </button>
      </div>
      <div className="col-1">
        <button
          className="btn btn-sm btn-danger w-100"
          onClick={() => props.handleCancel(props.id, props.lvl)}
        >
          <i className="fas fa-times" />
        </button>
      </div>
    </div>
  )
}

function clearStats(element) {
  return {
    [getTrad('science.niveauInput')]: element.niveau,
    [getTrad('science.prix')]: element.prix,
    [getTrad('science.nb_condidats_total')]:
      element.nb_condidats_non_trait + element.nb_condidats_acceptee + element.nb_condidats_refusee,
    [getTrad('science.nb_condidats_non_trait')]: element.nb_condidats_non_trait,
    [getTrad('science.nb_condidats_acceptee')]: element.nb_condidats_acceptee,
    [getTrad('science.nb_condidats_refusee')]: element.nb_condidats_refusee,
  }
}

export function ExportEdition(props) {
  const [status, setStatus] = useState(false)

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = async () => {
    setStatus(true)
    const { edition, dataStats, slug } = props
    const dataCon = await axios
      .get(getUrl('journeeSciences.get.archive', [slug]))
      .then(res => {
        return res.data.success.data.map(e => ({
          [getTrad(`condidature.mat_adherant`)]: e.mat_adherant,
          [getTrad(`condidature.full_name`)]: e.full_name,
          [getTrad(`condidature.pres_full_name`)]: e.pres_full_name,
          [getTrad(`condidature.phone`)]: e.phone,
          [getTrad(`condidature.prix_name`)]: e.prix_name,
          [getTrad(`condidature.status`)]: getTrad(`condidature.statusCode.${e.status}`),
        }))
      })
      .catch(err => fatalError())

    if (dataCon === undefined) {
      setStatus(false)
      return false
    }
    const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
    const ws1 = XLSX.utils.json_to_sheet(dataStats.map(clearStats))
    const ws = XLSX.utils.json_to_sheet(dataCon)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws1, getTrad('commun.stats'))
    XLSX.utils.book_append_sheet(wb, ws, getTrad('science.condidatures'))
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, edition + '-' + now + fileExtension)
    setStatus(false)
  }
  return (
    <div className="col text-right">
      <div className="btn-group">
        <Link to={`/journee-savoir/${props.slug}`} className="btn btn-sm btn-primary">
          <i className="fas fa-eye" />
        </Link>
        <button className="btn btn-sm btn-secondary" disabled={status} onClick={exportToCSV}>
          {status ? (
            <Waiting />
          ) : (
            <>
              <i className="fas fa-save" /> {getTrad('commun.export')}
            </>
          )}
        </button>
      </div>
    </div>
  )
}
