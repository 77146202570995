import local_FR from './multi/fr'
import local_AR from './multi/ar'
import local_EN from './multi/en'
import { getLang } from '../helpers'
import { useContext } from 'react'
import { LangContext } from '../context/LangContext'

export const local = {
  fr: local_FR,
  ar: local_AR,
  en: local_EN,
}

function dontAddUndefined(str) {
  return str ? str : ''
}

export const getTrad = (str, args) => {
  if (args === undefined) args = []

  let tmp = local[getLang()]
  let test = str.split('.')

  while (test.length !== 0) {
    let field = test[0]

    if (tmp[field]) {
      tmp = tmp[field]
    } else {
      if (field === 'null') {
        return ' '
      }
      console.error('Lang not found :', str)
      return str
    }
    test = test.slice(1)
  }

  if (typeof tmp === 'string') {
    let r = tmp.indexOf('{}')
    if (r === -1) {
      return tmp
    }
    let c = 0
    while (r !== -1) {
      c++
      r = tmp.indexOf('{}', r + 1)
    }
    if (args.length !== c) {
      console.error(`Expected ${c} args`)
      return str
    }
    let tmpArr = tmp.split('{}')
    tmp = ''
    while (tmpArr.length > 0) {
      tmp += dontAddUndefined(tmpArr[0])
      tmp += dontAddUndefined(args[0])
      tmpArr.shift()
      args.shift()
    }
    return tmp
  }
  console.error('Lang not found :', str)
  return str
}

export const useTrad = (str, args) => {
  const { lang } = useContext(LangContext)
  if (args === undefined) args = []

  let tmp = local[lang]
  let test = str.split('.')

  while (test.length !== 0) {
    let field = test[0]

    if (tmp[field]) {
      tmp = tmp[field]
    } else {
      if (field === 'null') {
        return ' '
      }
      console.error('Lang not found :', str)
      return str
    }
    test = test.slice(1)
  }

  if (typeof tmp === 'string') {
    let r = tmp.indexOf('{}')
    if (r === -1) {
      return tmp
    }
    let c = 0
    while (r !== -1) {
      c++
      r = tmp.indexOf('{}', r + 1)
    }
    if (args.length !== c) {
      console.error(`Expected ${c} args`)
      return str
    }
    let tmpArr = tmp.split('{}')
    tmp = ''
    while (tmpArr.length > 0) {
      tmp += dontAddUndefined(tmpArr[0])
      tmp += dontAddUndefined(args[0])
      tmpArr.shift()
      args.shift()
    }
    return tmp
  }
  console.error('Lang not found :', str)
  return str
}

export default local
