import React, { useState } from 'react'
import { getTrad } from '../../../../lang'
import { areYouSure, successMsg, fatalError } from '../../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'

function Generate(props) {
  const [sending, setSending] = useState(false)

  const ShowMessage = () => (
    <>
      <i className="fas fa-cogs mr-2" />
      {getTrad('commun.generateBordo')}
    </>
  )
  const generate = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.isConfirmed) {
        setSending(true)
        axios
          .post(getUrl('borderau.crud'))
          .then(res => {
            setSending(false)
            successMsg('success.genBorderau')
          })
          .catch(err => {
            setSending(false)
            fatalError()
          })
      }
    })
  }
  return (
    <button disabled={sending} onClick={generate} className="btn btn-primary">
      {sending ? <Waiting /> : <ShowMessage />}
    </button>
  )
}

export default Generate
