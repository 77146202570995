import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import ListeEdition from './ListeEdition'
import AddEdition from './AddEdition'
// import EditEdition from './EditEdition'
import Waiting from '../../../../components/Waiting'

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, found: false, actuelle: {} }

    this.refresh = this.refresh.bind(this)
  }

  refresh() {
    this.setState({ loading: true, found: false, actuelle: {} }, () => {
      axios
        .get(getUrl('journeeSciences.active'))
        .then(res => {
          this.setState({ found: true, loading: false, actuelle: res.data.success })
        })
        .catch(err => {
          this.setState({ found: false, loading: false })
        })
    })
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    if (this.state.loading) {
      return <Waiting />
    } else if (this.state.found) {
      return (
        <div className="row">
          <div className="col-12">
            <ListeEdition {...this.props} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col-md-7">
            <ListeEdition {...this.props} />
          </div>
          <div className="col-md-5">
            <AddEdition handler={this.refresh} {...this.props} />
          </div>
        </div>
      )
    }
  }
}
export default Settings
