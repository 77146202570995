import React, { Component } from 'react'
import { getTrad } from '../../../../lang'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import { fatalError } from '../../../../helpers/sweetAlerts'
import { showMoney, showDate } from '../../../../helpers'
import ShowPrestatires from './ShowPrestatires'
import Add from './Add'
import MyVersements from './MyVersements'
import Waiting from '../../../../components/Waiting'

function ShowTable(props) {
  if (props.loading)
    return (
      <tbody>
        <tr>
          <td colSpan="8" className="text-center">
            <Waiting />
          </td>
        </tr>
      </tbody>
    )

  if (props.list.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="8" className="text-center text-muted">
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.list.map((e, k) => (
        <tr key={k}>
          <td>{e.aide}</td>
          <td>{e.nb_beneficiaire}</td>
          <td>{getTrad(`aidesociale.${e.forfait ? '' : 'non'}forfait`)}</td>
          <td>{showMoney(e.montant)}</td>
          <td>{showMoney(e.total)}</td>
          <td>{showDate(e.created_at)}</td>
          <td>{getTrad(`aidesociale.status.${e.status}`)}</td>
          <td>
            <ShowPrestatires {...e} />
          </td>
        </tr>
      ))}
    </tbody>
  )
}

class MyList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      list: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  cleanData = e => e

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('aide_sociale.myData'))
        .then(res => this.setState({ loading: false, list: res.data.success.map(this.cleanData) }))
        .catch(err => {
          this.setState({ loading: false, list: [] })
          fatalError()
        })
    })
  }

  render() {
    return (
      <>
        <Add refresh={this.refresh} />

        <div className="card mb-3">
          <div className="card-header">
            <h3>{getTrad('pages.aideSociale.my')}</h3>
          </div>
          <div className="card-body p-0">
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>{getTrad('aidesociale.name')}</th>
                  <th>{getTrad('aidesociale.number')}</th>
                  <th>{getTrad('aidesociale.forfait')}</th>
                  <th>{getTrad('aidesociale.montant')}</th>
                  <th>{getTrad('commun.total')}</th>
                  <th>{getTrad('aidesociale.created_at')}</th>
                  <th>{getTrad('commun.status')}</th>
                  <th></th>
                </tr>
              </thead>
              <ShowTable {...this.state} />
            </table>
          </div>
        </div>
        <MyVersements />
      </>
    )
  }
}

export default MyList
