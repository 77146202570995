import React, { Component } from 'react'
import axios from 'axios'
import { Api } from '../../Api'
import local from '../../lang'
import { setJwt, getJwt, getLang } from '../../helpers'
import { Link, Redirect } from 'react-router-dom'
import Waiting from '../../components/Waiting'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      local: local[getLang()],
      loading: true,
      error: false,
      loginError: false,
      connecting: false,
      internetError: false,
      username: '',
      password: '',
      redirectToHome: false,
    }
    axios.defaults.headers.common['Content-Type'] = 'application/json'
  }

  componentDidMount() {
    if (getJwt()) this.setState({ redirectToHome: true })
    else this.setState({ loading: false })
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  login(e) {
    e.preventDefault()
    this.setState(
      { connecting: true, error: false, internetError: false, loginError: false },
      () => {
        let url = Api.baseURL + Api.login
        let data = { username: this.state.username, password: this.state.password }
        axios
          .post(url, data)
          .then(res => {
            setJwt(res.data.success)
            this.setState({
              redirectToHome: true,
              connecting: false,
              error: false,
              internetError: false,
              loginError: false,
            })
          })
          .catch(err => {
            let loginError = false
            let internetError = true
            if (err.response) {
              internetError = false
              if (err.response.status === 401) {
                loginError = true
              }
            }
            this.setState({
              redirectToHome: false,
              connecting: false,
              error: true,
              internetError: internetError,
              loginError: loginError,
            })
          })
      }
    )
  }

  renderHeader() {
    let error
    if (this.state.error) {
      let errorMsg = this.state.local.login.connectionProblem
      if (this.state.internetError) {
        errorMsg = this.state.local.login.internetConnection
      }
      if (this.state.loginError) {
        errorMsg = this.state.local.login.loginProblem
      }
      error = (
        <div className="alert alert-danger" role="alert">
          {errorMsg}
        </div>
      )
    }

    return (
      <>
        <div className="text-center mb-4">
          <img
            className="mb-4 border"
            src="/assets/img/logo-mutuelle-magistrats.png"
            alt="Logo mutuelle magistrats"
            width="118"
            height="148"
          />
          <h1 className="h2 mb-3 font-weight-normal">{this.state.local.login.welcome}</h1>
        </div>
        {error}
      </>
    )
  }

  renderUsername() {
    let inputClass = 'form-control'
    if (this.state.error) {
      inputClass += ' is-invalid'
    }
    return (
      <div className="form-label-group">
        <input
          type="text"
          name="username"
          onChange={e => {
            this.changeInput(e)
          }}
          value={this.state.username}
          className={inputClass}
          placeholder={this.state.local.fileds.username}
          required
          autoFocus
        />
        <label htmlFor="inputEmail">{this.state.local.fileds.username}</label>
      </div>
    )
  }

  renderPassword() {
    let inputClass = 'form-control'
    if (this.state.error) {
      inputClass += ' is-invalid'
    }
    return (
      <div className="form-label-group">
        <input
          type="password"
          id="inputPassword"
          onChange={e => {
            this.changeInput(e)
          }}
          value={this.state.password}
          name="password"
          className={inputClass}
          placeholder={this.state.local.fileds.password}
          required
        />
        <label htmlFor="inputPassword">{this.state.local.fileds.password}</label>
      </div>
    )
  }

  renderBtn() {
    if (this.state.connecting) {
      return (
        <button className="btn btn-lg btn-primary btn-block disa" disabled>
          <Waiting />
        </button>
      )
    }

    return (
      <button type="submit" className="btn btn-lg btn-primary btn-block">
        {this.state.local.fileds.login_btn}
      </button>
    )
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to="/" />
    }
    return (
      <div className="form-signin-container">
        <form className="form-signin" onSubmit={e => this.login(e)}>
          <div className="">
            {this.renderHeader()}
            {this.renderUsername()}
            {this.renderPassword()}
            {this.renderBtn()}
            <Link className="btn btn-link" to="/reset">
              {this.state.local.fileds.forgot_password}
            </Link>
            <p
              className="mt-5 mb-3 text-muted text-center"
              dangerouslySetInnerHTML={{
                __html: this.state.local.pages.copyright,
              }}
            ></p>
          </div>
        </form>
      </div>
    )
  }
}
export default Login
