export const fr = {
  num: 'Numéro bordereau',
  date_bord: 'Date de bordereau',
  nb_bs_nt: 'N° de BS non traités',
  nb_bs_t: 'N° de BS traités',
  nb_bs: 'N° de BS',
  action: 'Actions à prendre',
}

export const ar = {
  num: 'رقم القسيمة',
  date_bord: 'تاريخ القسيمة',
  nb_bs_nt: 'عدد بطاقات العلاج غير المعالجة',
  nb_bs_t: 'عدد بطاقات العلاج المعالجة',
  nb_bs: 'عدد بطاقات العلاج',
  action: 'الإجراءات الواجب اتّخاذها',
}

export const en = {
  num: 'Number of tax statement',
  date_bord: 'Date of tax statement',
  nb_bs_nt: 'N° of untreated treatment bulletin',
  nb_bs_t: 'N° of treated treatment bulletin',
  nb_bs: 'N° of treatment bulletin',
  action: 'Actions to take',
}
