export const fr = {
  addDemandeTitle: "Ajouter une demande d'aide",
  nonTraite: 'Liste des demandes non traitées',
  acceptedAmount: 'Montant accepté',
  motif: 'Motif de refus',
  engagement: 'Aide avec engagement',
  montantEngagement: "Montant de l'engagement",
  update: 'Modifier aide N°{}',
  nb_aides: "Nombre d'aides",
  montant_aides: 'Montant des aides',

  accept: {
    title: 'Accepter aide N°{}',
  },
  mag: {
    created_at: 'Date de demande',
    montant: 'Montant demandé',
    montant_accepte: 'Montant accepté',
    motif: 'Motif de demande',
    obs: 'Réponse',
    status: 'Statut de la demande',
    updated_at: 'Date de la dernière modification',
  },
  engagementVal: {
    0: 'Sans',
    1: 'Avec',
  },
  filter: {
    A: 'Tous',
    Y: 'Avec',
    N: 'Sans',
    D: 'Définie',
    NU: 'Nulle',
  },
  status: {
    N: 'Non traitée',
    A: 'Acceptée',
    R: 'Refusée',
  },
}

export const ar = {
  addDemandeTitle: 'اضافة طلب مساعدة',
  nonTraite: 'قائمة الطلبات التي لم تتم معالجتها',
  acceptedAmount: 'المبلغ الموافق عليه',
  motif: 'دافع الرّفض',
  engagement: 'مع الاعانة',
  montantEngagement: 'المبلغ المعان به',
  update: 'ْتعديل الاعانة رقم',
  nb_aides: 'عدد الاعانات',
  montant_aides: 'مبلغ الاعانات',

  accept: {
    title: 'ْقبول الاعانة رقم',
  },
  mag: {
    created_at: 'تاريخ الطلب',
    montant: 'المبلغ المطلوب',
    montant_accepte: 'المبلغ الموافق عليه',
    motif: 'الدافع',
    obs: 'الرد',
    status: 'وضع الطلب',
    updated_at: 'تاريخ آخر التعديلات',
  },
  engagementVal: {
    0: 'بدون',
    1: 'مع',
  },
  filter: {
    A: 'الكل',
    Y: 'مع',
    N: 'بدون',
    D: 'معرّف بها',
    NU: 'خالية',
  },
  status: {
    N: 'لم تتمّ معالجتها',
    A: 'موافق عليها',
    R: 'مرفوضة',
  },
}

export const en = {
  addDemandeTitle: 'Add an aid request',
  nonTraite: 'List of unprocessed requests',
  acceptedAmount: 'Accepted amount',
  motif: 'Reason for refusal',
  engagement: 'Aid with commitment',
  montantEngagement: 'Commitment amount',
  update: 'Rectify aid n°',
  nb_aides: 'Aid number',
  montant_aides: 'Aid total amount',

  accept: {
    title: 'Accept aid N°{}',
  },
  mag: {
    created_at: 'Date of request',
    montant: 'Requested amount',
    montant_accepte: 'Accepted amount',
    motif: 'Reason for request',
    obs: 'Reply',
    status: 'Request status',
    updated_at: 'Date of last modification',
  },
  engagementVal: {
    0: 'Without',
    1: 'With',
  },
  filter: {
    A: 'All',
    Y: 'With',
    N: 'without',
    D: 'Defined',
    NU: 'Null',
  },
  status: {
    N: 'Untreated',
    A: 'Accepted',
    R: 'Refused',
  },
}
