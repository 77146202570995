import React, { Component } from 'react'
import { getTrad } from '../../../lang'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { getUrl } from '../../../Api'
import InputText from '../../../components/Form/InputText'
import Waiting from '../../../components/Waiting'
import { successMsg, fatalError } from '../../../helpers/sweetAlerts'
import NumInput from '../../../components/Form/NumInput'

function ShowAdherant(props) {
  if (props.value === '') return <></>
  return (
    <div className={`alert alert-${props.adherant ? 'success' : 'danger'}`}>
      {props.adherant ? (
        <>
          <div>
            <span className="font-weight-bold">{getTrad('fileds.matricule')} :</span>
            <span className="ml-1">{props.adherant.matricule}</span>
          </div>
          <div>
            <span className="font-weight-bold">{getTrad('fileds.first_name')} :</span>
            <span className="ml-1">{props.adherant.first_name}</span>
          </div>
          <div>
            <span className="font-weight-bold">{getTrad('fileds.last_name')} :</span>
            <span className="ml-1">{props.adherant.last_name}</span>
          </div>
        </>
      ) : (
        getTrad('errors.giveValidMatricule')
      )}
    </div>
  )
}

// 'total',
// 'nb_echeance',

class Add extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      matricule: '',
      sous_compte: '',
      total: '',
      nb_echeance: '',
      adherant: null,
      sending: false,
    }
  }

  handleShow = () => {
    if (!this.state.sending) {
      this.setState({ show: !this.state.show, matricule: '', sous_compte: '', adherant: null })
    }
  }

  handleData = event => {
    const callback = event.target.name === 'matricule' ? this.updateAdherant : () => null
    this.setState({ [event.target.name]: event.target.value }, callback)
  }

  add = event => {
    event.preventDefault()
    this.setState({ sending: true }, () => {
      const data = {
        matricule: this.state.matricule,
        sous_compte: this.state.sous_compte,
        total: this.state.total,
        nb_echeance: this.state.nb_echeance,
      }
      axios
        .post(getUrl('parent.contract.create'), data)
        .then(res => {
          this.setState(
            {
              show: false,
              matricule: '',
              sous_compte: '',
              adherant: null,
              sending: false,
            },
            () => successMsg(getTrad('success.add'))
          )
        })
        .catch(err => {
          this.setState({ sending: false }, fatalError)
        })
    })
  }

  updateAdherant = () => {
    axios
      .get(getUrl('adherants.info', [this.state.matricule]))
      .then(res => {
        this.setState({ adherant: res.data.success })
      })
      .catch(err => {
        this.setState({ adherant: null })
      })
  }

  render() {
    return (
      <>
        <button onClick={this.handleShow} className="btn btn-success">
          <i className="fas fa-plus" /> {getTrad('commun.add')}
        </button>
        <Modal show={this.state.show} onHide={() => null}>
          <form onSubmit={this.add}>
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <InputText
                label={getTrad('fileds.matricule')}
                value={this.state.matricule}
                handler={this.handleData}
                name="matricule"
              />
              <ShowAdherant value={this.state.matricule} adherant={this.state.adherant} />
              <InputText
                label={getTrad('fileds.sous_compte')}
                value={this.state.sous_compte}
                handler={this.handleData}
                name="sous_compte"
              />

              <NumInput
                label={getTrad('fileds.total')}
                value={this.state.total}
                handler={this.handleData}
                name="total"
              />

              <NumInput
                label={getTrad('fileds.nb_echeance')}
                value={this.state.nb_echeance}
                handler={this.handleData}
                name="nb_echeance"
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                disabled={this.state.adherant === null || this.state.sending}
                type="submit"
                className="btn btn-success"
              >
                {this.state.sending ? (
                  <Waiting />
                ) : (
                  <span>
                    <i className="fas fa-plus" /> {getTrad('commun.add')}
                  </span>
                )}
              </button>
              <button type="reset" className="btn btn-danger" onClick={this.handleShow}>
                <i className="fas fa-times" /> {getTrad('commun.cancel')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}
export default Add
