import React, { Component } from 'react'
import { getUrl } from '../../../Api'
import axios from 'axios'
import Waiting from '../../../components/Waiting'
import { errorMsg, fatalError, successMsg } from '../../../helpers/sweetAlerts'

function Details(props) {
  if (props.last_name && props.first_name) {
    return (
      <div className="mb-3">
        <div>
          <span className="info-adherant-label mr-2">Prénom</span>
          <span className="info-adherant-text">{props.first_name}</span>
        </div>
        <div>
          <span className="info-adherant-label mr-2">Nom</span>
          <span className="info-adherant-text">{props.last_name}</span>
        </div>
        <div>
          <span className="info-adherant-label mr-2">Mobile</span>
          <span className="info-adherant-text">{props.phone}</span>
        </div>
      </div>
    )
  }
  return <></>
}

export default class Indivi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false,
      matricule: '',
      message: '',
      adherant: {},
    }
  }

  send = () => {
    this.setState({ sending: true })
    axios
      .post(getUrl('sms'), { matricule: this.state.matricule, message: this.state.message })
      .then(() => {
        this.setState({ sending: false, matricule: '', message: '', adherant: {} })
        successMsg('SMS envoyée avec success')
      })
      .catch(err => {
        this.setState({ sending: false })
        if (err?.response?.status === 401) errorMsg(this.props.local.errors.noSMS)
        else fatalError()
      })
  }

  refreshAd = () => {
    axios
      .get(getUrl('adherants.info', [this.state.matricule]))
      .then(res => {
        this.setState({ adherant: res.data.success })
      })
      .catch(() => {
        this.setState({ adherant: {} })
      })
  }

  render() {
    return (
      <div className="col-md-6 mx-auto">
        <div className="card ">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h2 className="card-title">Envoie de SMS individuel</h2>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="matricule">Matricule interne </label>
              <input
                type="text"
                className="form-control w-100 form-control-sm ui-autocomplete-input"
                value={this.state.matricule}
                onChange={event => this.setState({ matricule: event.target.value }, this.refreshAd)}
                name="matricule"
                id="matricule"
                placeholder="Matricule interne"
              />
            </div>
            <Details {...this.state.adherant} />
            <div className="form-group">
              <label htmlFor="message">
                Message
                <span className="" id="lenSMS">
                  ({this.state.message.length}/160)
                </span>
              </label>
              <textarea
                value={this.state.message}
                onChange={event => this.setState({ message: event.target.value })}
                className="form-control"
                id="message"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <button
              disabled={this.state.sending}
              onClick={this.send}
              className="btn-sm w-100 btn btn-success"
            >
              {this.state.sending ? <Waiting /> : 'Envoyer'}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
