export const fr = {
  add: 'Ajouter',
  import: 'Importer',
  export: 'Exporter',
  print: 'Imprimer',
  send: 'Envoyer',
  deleteBtn: 'Effacer bulletin',
  status: 'Statut',
  all: 'Tous',
  mounth: {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Séptembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre',
  },
  nb_justificatifs: 'Justificatifs à valider',
  nb_demandes_bs: 'Demandes de bulletins de soin',
  select: 'Sélectionner',
  cancel: 'Annuler',
  accept: 'Accepter',
  refuse: 'Refuser',
  close: 'Fermer',
  nbEtabs: 'Nombre d`établissements',
  edit: 'Modifier',
  dbToAdd: 'Faire un double-click pour ajouter',
  stats: 'Statistiques',
  downloadmodel: "Modèle d'importation",
  exportBtn: {
    all: 'Exporter tous',
    filtred: 'Exporter après filtrage',
  },
  gouv: 'Gouvernorat',
  refound: 'Rembourser',
  search: 'Rechercher',
  chooseSheet: 'Choisisser une feuille',
  areyousure: 'Êtes-vous sûr(e) ?',
  yes: 'Oui',
  total: 'Total',
  click_here: 'Cliquer ici',
  generateBordo: 'Générer un bordereau',
  no: 'Non',
  noSearchData: 'Aucun résultat',
  noFamilyData: 'Aucun membre de famille',
  noAdmins: 'Pas d`administrateur',
  nb_credits: 'Demandes de crédit non traitées',
  nb_condidatures_js: 'Nombre de candidatures non traitées',
  nb_demande_aide: "Demandes d'aide non traitées",
  nb_demande_aide_solidaire: "Demandes d'aide sociale non traitées",
  role: {
    ROLE_SUPER_USER: 'Super Administrateur',
    ROLE_ADMINISTRATOR: 'Administrateur',
    ROLE_MAGISTRAT: 'Adhérent',
    ROLE_ADMINISTRATOR_LOCAL: 'Administateur Local',
  },
  sexe: {
    M: 'Mâle',
    null: ' ',
    F: 'Femelle',
  },
  greating: 'Bonjour',
  civility: {
    M: 'M.',
    F: 'Mme.',
    null: ' ',
  },
  binary: ['Non', 'Oui'],
  boolean: {
    non: 'Non',
    oui: 'Oui',
  },
  tuteur: {
    P: 'Père',
    M: 'Mère',
    null: ' ',
  },
  statusAide: {
    N: 'En attente',
    null: ' ',
    A: 'Accepté',
    R: 'Refusé',
  },
  local: {
    ar: 'Arabe',
    fr: 'Français',
    en: 'Anglais',
  },
  month: 'Mois',
  months: {
    0: 'Janvier',
    1: 'Février',
    2: 'Mars',
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Août',
    8: 'Septembre',
    9: 'Octobre',
    10: 'Novembre',
    11: 'Décembre',
  },
}

export const ar = {
  add: 'اضافة',
  import: 'استيراد',
  export: 'تصدير',
  print: 'طباعة',
  send: 'إرسال',
  deleteBtn: 'حذف الايصال',
  cancel: 'إلغاء',
  accept: 'قبول',
  refuse: 'رفض',
  close: 'اغلاق',
  nbEtabs: 'عدد المنشآت',
  edit: 'تعديل',
  dbToAdd: 'انقر مرّتين للإضافة',
  stats: 'إحصائيات',
  areyousure: 'هل انت متأكد ؟',
  yes: 'نعم',
  total: 'المجموع',
  click_here: 'انقر هنا',
  exportBtn: { all: 'تصدير الكل', filtred: 'تصدير بعد الفلترة' },
  gouv: 'الولاية',
  downloadmodel: 'نموذج للتّحميل',
  refound: 'تسديد الدّين',
  search: 'بحث',
  chooseSheet: 'اختيار الورقة الاداريّة',
  generateBordo: 'انشاء القسيمة',
  no: 'لا',

  noSearchData: 'لا نتائج',
  noFamilyData: 'لا وجود لفرد من العائلة',
  noAdmins: 'لا وجود لمسؤول للتّطبيق',
  nb_credits: 'طلبات الدّين غير المدروسة',
  nb_condidatures_js: 'عدد طلبات التّرشّح غير المعالجة',
  nb_demande_aide: 'طلبات المساعدة غير المدروسة',
  nb_demande_aide_solidaire: 'طلبات الاعانة الاجتماعية غير المعالجة',

  role: {
    ROLE_SUPER_USER: 'المشرف اﻷعلى',
    ROLE_ADMINISTRATOR: 'المشرف',
    ROLE_MAGISTRAT: 'العضو',
  },
  sexe: { M: 'ذكر', null: ' ', F: 'أنثى' },
  greating: 'مرحبا',
  civility: { M: 'السيد ', F: 'السيدة ', null: ' ' },
  binary: ['لا', 'نعم'],
  tuteur: { P: 'الأب', M: 'الأم', null: ' ' },
  statusAide: { N: 'في الانتظار', null: ' ', A: 'قُبلت', R: 'رُفضت' },
  local: { ar: 'العربية', fr: 'الفرنسية', en: 'الإنجليزية' },
}

export const en = {
  add: 'Add ',
  import: 'Import ',
  export: 'Export ',
  print: 'Print',
  send: 'Send',
  deleteBtn: 'Delete bulletin',
  status: 'Status',
  all: 'All',
  mounth: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },
  nb_justificatifs: 'Supporting documents to validate',
  nb_demandes_bs: 'Requests of treatment bulletins',
  select: 'Select',
  cancel: 'Cancel',
  accept: 'Accept',
  refuse: 'Refuse',
  close: 'Close',
  nbEtabs: 'Number of establishment',
  edit: 'Rectify',
  dbToAdd: 'A double click to add',
  stats: 'Statistics',
  downloadmodel: 'Import model',
  exportBtn: {
    all: 'Export all',
    filtred: 'Export after filtering',
  },
  gouv: ' Governorate',
  refound: 'Refund',
  search: 'Search',
  chooseSheet: 'Choose sheet',
  areyousure: 'Are you sure ?',
  yes: 'Yes',
  total: 'Total',
  click_here: 'Click here',
  generateBordo: 'Generate a tax treatment',
  no: 'No',
  noSearchData: 'No result',
  noFamilyData: 'No family member',
  noAdmins: 'No administrator',
  nb_credits: 'Unprocessed credit requests',
  nb_condidatures_js: 'Number of unprocessed applications',
  nb_demande_aide: 'Unprocessed aid requests',
  nb_demande_aide_solidaire: 'Unprocessed social aid claims',
  role: {
    ROLE_SUPER_USER: 'Super Administrator',
    ROLE_ADMINISTRATOR: 'Administrator',
    ROLE_MAGISTRAT: 'Member',
    ROLE_ADMINISTRATOR_LOCAL: 'Local administrator',
  },
  sexe: {
    M: 'Male',
    null: ' ',
    F: 'Female',
  },
  greating: 'Hello',
  civility: {
    M: 'M.',
    F: 'Mme.',
    null: ' ',
  },
  binary: ['No', 'Yes'],
  boolean: {
    non: 'No',
    oui: 'Yes',
  },
  tuteur: {
    P: 'Father',
    M: 'Mother',
    null: ' ',
  },
  statusAide: {
    N: 'Pending',
    null: ' ',
    A: 'Accepted',
    R: 'Refused',
  },
  local: {
    ar: 'Arabic',
    fr: 'French',
    en: 'English',
  },
  month: 'Month',
  months: {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
}
