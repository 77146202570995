import React, { useEffect, useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { showDate, showMoney } from '../../../../helpers'
import Waiting from '../../../../components/Waiting'
import { areYouSure, fatalError } from '../../../../helpers/sweetAlerts'
import axios from 'axios'

function ShowLine(props) {
  const delteItem = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        axios
          .delete(getUrl('retraite.list', [props.id]))
          .then(props.refresh)
          .catch(() => fatalError())
      }
    })
  }

  return (
    <tr>
      <td>{showDate(props.date)}</td>
      <td>{showMoney(props.montant)}</td>
      <td>{getTrad(`credit.type_remboursement.${props.type}`)}</td>
      <td className="text-right">
        <button onClick={delteItem} className="btn btn-danger btn-sm">
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  )
}
function ParseData(props) {
  const [selected, setSelected] = useState('')
  if (props.loading) return <Waiting />
  return (
    <>
      <ul className="nav nav-tabs">
        {Object.keys(props.data)
          .map(e => parseInt(e))
          .sort()
          .map((e, k) => (
            <li className="nav-item" key={k} onClick={() => setSelected(e)}>
              <button className={`nav-link${selected === e ? ' active' : ''}`}>{e}</button>
            </li>
          ))}
      </ul>
      <table className="table">
        <thead>
          <tr>
            <th>{getTrad('fileds.date')}</th>
            <th>{getTrad('fileds.montant')}</th>
            <th>{getTrad('credit.type_remboursement_name')}</th>
            <th className="text-right">{getTrad('fileds.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {props.data[selected] &&
            props.data[selected]
              .sort((a, b) => {
                return new Date(a.date) < new Date(b.date)
              })
              .reverse()
              .map((e, k) => <ShowLine refresh={props.refresh} key={k} {...e} />)}
        </tbody>
      </table>
    </>
  )
}

function Details(props) {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const refresh = useCallback(() => {
    setLoading(true)
    axios
      .get(getUrl('retraite.list', [props.matricule]))
      .then(res => {
        setLoading(false)
        let tmp = {}
        res.data.success.forEach(e => {
          const date = new Date(e.date)
          const year = date.getFullYear()
          if (tmp[year] === undefined) tmp[year] = []
          tmp[year].push(e)
        })
        setData(tmp)
      })
      .catch(() => {
        setLoading(false)
        fatalError()
        setData({})
      })
  }, [props.matricule])

  useEffect(() => {
    setLoading(true)
    refresh()
  }, [props.matricule, refresh])

  return (
    <>
      <button onClick={() => setShow(true)} className="btn btn-primary">
        <i className="fas fa-list"></i>
      </button>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{getTrad('adherant.listecotisation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ParseData refresh={refresh} loading={loading} data={data} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Details
