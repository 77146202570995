import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import Gouvs from './Gouvs'
import Etabs from './Etabs'
import { userStore } from '../../../store/userStore'

function Etablissements(props) {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: 'ROLE_SUPER_USER' } }
  if (isNotAdmin(role)) {
    return (
      <Switch>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact strict path={`/etablissements/gouvernaurats`}>
        <Gouvs {...props} />
      </Route>

      <Route exact strict path={`/etablissements`}>
        <Etabs {...props} />
      </Route>

      <Route>
        <Redirect to="/etablissements" />
      </Route>
    </Switch>
  )
}
/*






import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import Gouvs from './Gouvs'
import Etabs from './Etabs'


const cleanEtabs = item => {
    return {
        ...item,
        locals: item.locals.map(cleanLocals),
    }
}

const cleanData = item => {
    return {
        id: item.id,
        etabs: item.etablissements.map(cleanEtabs),
        locals: item.locals.map(cleanLocals),
    }
}

class Etablissements extends Component {
    constructor(props) {
        super(props)
        this.state = {
            liste_gouvernaurats: [],
            loading: true,
            selected: null,
        }
    }

    componentDidMount() {
        this.refresh()
    }



    select = id => this.setState({ selected: id })

    render() {
        let selectedList = this.state.liste_gouvernaurats.filter(
            e => e.id === this.state.selected
        )[0]

        let etabs = []

        if (selectedList) etabs = selectedList.etabs ? selectedList.etabs : []

        return (
            <>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <h2>{getTrad('pages.etablissements')}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-6">
                        <Gouvs
                            {...this.state}
                            locals={locals}
                            select={this.select}
                            refresh={this.refresh}
                        />
                    </div>
                    <div className="col col-6">
                        <Etabs
                            {...this.state}
                            locals={locals}
                            select={this.select}
                            etabs={etabs}
                            refresh={this.refresh}
                        />
                    </div>
                </div>
            </>
        )
    }
}
*/
export default Etablissements
