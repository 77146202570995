import React from 'react'
import { getTrad } from '../../../../lang'
import { Link } from 'react-router-dom'
import Generate from './Generate'

function ListHeader(props) {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{getTrad('pages.listBS')}</h2>
        </div>
        <div className="col text-right">
          <div className="btn-group">
            <Link to="add" className="btn btn-success">
              <i className="fas fa-plus mr-2" />
              {getTrad('commun.add')}
            </Link>
            <Generate />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListHeader
