import React, { Component } from 'react'
import CardHeader from '../../../components/CardHeader'
import { fatalError } from '../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import { showDate, showMoney, inString } from '../../../helpers'
import ShowTable from '../../../components/ShowTable'
import InputText from '../../../components/Form/InputText'
import Export from '../../../components/Export'

import Delete from './Delete'
import Edit from './Edit'

const cleanData = (e, refresh) => ({
  id: e.id,
  last_name: e.adherant.last_name,
  first_name: e.adherant.first_name,
  matricule: e.adherant.matricule,
  matricule_cnrps: e.adherant.matricule_cnrps,
  montant: showMoney(e.montant),
  created_at: showDate(e.created_at),
  updated_at: showDate(e.updated_at),
  actions: (
    <div className="btn-group">
      <Edit {...e} refresh={refresh} />
      <Delete {...e} />
    </div>
  ),
})

function Filter(props) {
  return (
    <div className="card-body">
      <div className="row">
        {['matricule', 'matricule_cnrps', 'last_name', 'first_name'].map((e, k) => (
          <div className="col" key={k}>
            <InputText
              label={getTrad(`fileds.${e}`)}
              handler={props.handler}
              name={e}
              value={props[e]}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      id: null,
      loading: true,
      matricule: '',
      matricule_cnrps: '',
      last_name: '',
      first_name: '',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.refresh()
  }

  refresh = () => {
    const id = this.props.match.params.id
    this.setState({ id: id, loading: true }, () => {
      axios
        .get(getUrl('parent.saisie.show', [id]))
        .then(res =>
          this.setState({
            loading: false,
            list: res.data.success.parents.map(e => cleanData(e, this.refresh)),
          })
        )
        .catch(fatalError)
    })
  }

  handelFilter = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    const { matricule, matricule_cnrps, last_name, first_name } = this.state

    if (!inString(matricule, e.matricule)) return false
    if (!inString(matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(last_name, e.last_name)) return false
    if (!inString(first_name, e.first_name)) return false

    return true
  }

  render() {
    const keys = [
      'id',
      'matricule',
      'matricule_cnrps',
      'last_name',
      'first_name',
      'montant',
      'created_at',
      'updated_at',
      'actions',
    ]

    let header = { id: 'id' }

    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`fileds.${keys[i]}`)
    }

    const showProps = {
      searching: this.state.loading,
      noData: getTrad('commun.noSearchData'),
      header: header,
      keys: keys,
      data: this.state.list.filter(this.filter),
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('parent.page.detailsSaisie', [this.state.id])}
          actions={
            <div className="btn-group">
              <Export
                fileName={getTrad('parent.page.detailsSaisie', [this.state.id])}
                csvData={this.state.list}
                title={getTrad('commun.exportBtn.all')}
                headers={header}
                color="dark"
              />

              <Export
                fileName={getTrad('parent.page.detailsSaisie', [this.state.id]) + ' filtré'}
                csvData={this.state.list.filter(this.filter)}
                title={getTrad('commun.exportBtn.filtred')}
                headers={header}
              />
            </div>
          }
        />
        <Filter handler={this.handelFilter} {...this.state} />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}

export default Show
