export const fr = {
  required: 'Ce champ est obligatoire.',
  regex: 'Format incorrect',
  same: 'Les champs {} et {} doivent être identiques.',
  min: {
    numeric: 'Ce champ doit être supérieur à zéro',
  },
}

export const ar = {
  required: 'هذه الخانة اجبارية',
  regex: 'تنسيق غير صحيح',
  same: 'الخانات {} و {} يجب أن تكون متتطابقة',
  min: {
    numeric: 'يجب أن يكون محتوى الخانة أكبر من الصفر',
  },
}

export const en = {
  required: 'This field is compulsory.',
  regex: 'Format incorrect',
  same: 'The fields {} and {} must be identical.',
  min: {
    numeric: 'This field must be greater than zero',
  },
}
