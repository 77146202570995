import React from 'react'
import InputText from '../../../../components/Form/InputText'
import { getTrad } from '../../../../lang'

function RenderSearchSection({ filter }) {
  const {
    matricule,
    lastName,
    firstName,
    matriculeCnrps,
    birthDay,
    setMatricule,
    setLastName,
    setFirstName,
    setMatriculeCnrps,
    setBirthDay,
  } = filter
  const rows = [
    [
      {
        key: 'matricule',
        label: getTrad(`adherant.matricule`),
        value: matricule,
        handler: setMatricule,
      },
      {
        key: 'last_name',
        label: getTrad(`adherant.last_name`),
        value: lastName,
        handler: setLastName,
      },
      {
        key: 'first_name',
        label: getTrad(`adherant.first_name`),
        value: firstName,
        handler: setFirstName,
      },
    ],
    [
      {
        key: 'matricule_cnrps',
        label: getTrad(`adherant.matricule_cnrps`),
        value: matriculeCnrps,
        handler: setMatriculeCnrps,
      },
      {
        key: 'birth_day',
        label: getTrad(`adherant.birth_day`),
        value: birthDay,
        handler: setBirthDay,
      },
    ],
  ]

  return (
    <div className="card-body">
      {rows.map((row, index) => (
        <div key={index} className="row">
          {row.map(item => (
            <div key={item.key} className="col">
              <InputText {...item} name={item.key} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default RenderSearchSection
