import React from 'react'
import { getTrad } from '../../../../lang'
import Waiting from '../../../../components/Waiting'
import { showDate, showMoney } from '../../../../helpers'

function ShowTable(props) {
  const size = 6
  if (props.loading)
    return (
      <tbody>
        <tr>
          <td className="text-center" colSpan={size}>
            <Waiting />
          </td>
        </tr>
      </tbody>
    )

  if (props.list.length === 0)
    return (
      <tbody>
        <tr>
          <td className="text-center text-muted" colSpan={size}>
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.list.map((e, k) => (
        <tr>
          <td>{e.sous_compte}</td>
          <td>{getTrad(`parent.status.${e.status}`)}</td>
          <td>{showDate(e.created_at)}</td>
          <td>{showMoney(e.montant)}</td>
          <td>{e.nb_cessions}</td>
          <td>{showMoney(e.total)}</td>
        </tr>
      ))}
    </tbody>
  )
}

function Contracts(props) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h3 className="card-title">{getTrad('pages.parent.contract')}</h3>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>{getTrad('fileds.sous_compte')}</th>
              <th>{getTrad('fileds.status')}</th>
              <th>{getTrad('fileds.created_at')}</th>
              <th>{getTrad('fileds.montant')}</th>
              <th>{getTrad('parent.nb_cessions')}</th>
              <th>{getTrad('commun.total')}</th>
            </tr>
          </thead>
          <ShowTable {...props} />
        </table>
      </div>
    </div>
  )
}

export default Contracts
