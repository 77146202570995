import React, { useState } from 'react'
import axios from 'axios'
import { useTrad } from '../../../../lang'
import Waiting from '../../../../components/Waiting'
import { useQuery } from 'react-query'

import OneCol from './OneCol'
import Header from './Header'
import RenderInput from './RenderInput'
import TableRow from './TableRow'

function RenderTbody({ loading, data, refresh }) {
  const noAdmin = useTrad('commun.noAdmins')
  if (loading)
    return (
      <OneCol cols={7}>
        <Waiting />
      </OneCol>
    )
  if (data.length === 0) {
    return <OneCol cols={7}>{noAdmin}</OneCol>
  }
  return (
    <tbody>
      {data.map(admin => (
        <TableRow key={admin.id} {...admin} refresh={refresh} />
      ))}
    </tbody>
  )
}

function Body({ data = [], loading = false, refresh }) {
  return (
    <div className="card-body p-0">
      <table className="table table-striped table-hover table-sm" id="listeAdherants">
        <thead>
          <tr>
            <th>{useTrad('fileds.username')}</th>
            <th>{useTrad('fileds.first_name')}</th>
            <th>{useTrad('fileds.last_name')}</th>
            <th>{useTrad('fileds.sexe')}</th>
            <th>{useTrad('fileds.email')}</th>
            <th>{useTrad('fileds.roles')}</th>
            <th width="1">{useTrad('fileds.actions')}</th>
          </tr>
        </thead>
        <RenderTbody loading={loading} data={data} refresh={refresh} />
      </table>
    </div>
  )
}

function RenderRoleSearch({ checkSU, checkAD, checkAL, toogleSU, toogleAD, toogleAL }) {
  return (
    <div className="col">
      <div className="form-group">
        <label>{useTrad('fileds.roles')} :</label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={toogleSU}
            checked={checkSU}
            id="checkSU"
            name="checkSU"
          />
          <label className="form-check-label" htmlFor="checkSU">
            {useTrad('commun.role.ROLE_SUPER_USER')}
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={toogleAD}
            checked={checkAD}
            id="checkAD"
            name="checkAD"
          />
          <label className="form-check-label" htmlFor="checkAD">
            {useTrad('commun.role.ROLE_ADMINISTRATOR')}
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={toogleAL}
            checked={checkAL}
            id="checkAL"
            name="checkAL"
          />
          <label className="form-check-label" htmlFor="checkAL">
            {useTrad('commun.role.ROLE_ADMINISTRATOR_LOCAL')}
          </label>
        </div>
      </div>
    </div>
  )
}

function RenderSexeSearch({ valueM, valueF, handleM, handleF }) {
  return (
    <div className="col">
      <div className="form-group">
        <label className="d-block">{useTrad('fileds.sexe')} :</label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleM}
            checked={valueM}
            id="checkM"
            name="checkM"
            value="M"
          />
          <label className="form-check-label" htmlFor="checkM">
            {useTrad('commun.sexe.M')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleF}
            checked={valueF}
            id="checkF"
            name="checkF"
            value="F"
          />
          <label className="form-check-label" htmlFor="checkF">
            {useTrad('commun.sexe.F')}
          </label>
        </div>
      </div>
    </div>
  )
}

function Search({ filterHook }) {
  const {
    name,
    setName,
    last,
    setLast,
    checkF,
    checkM,
    toogleF,
    toogleM,
    checkSU,
    checkAD,
    checkAL,
    toogleSU,
    toogleAD,
    toogleAL,
  } = filterHook
  return (
    <div className="card-body">
      <div className="row">
        <RenderInput
          label={useTrad('fileds.first_name')}
          name={'searchName'}
          value={name}
          handle={setName}
        />
        <RenderInput
          label={useTrad('fileds.last_name')}
          name={'searchLast'}
          value={last}
          handle={setLast}
        />
        <RenderSexeSearch valueM={checkM} valueF={checkF} handleM={toogleM} handleF={toogleF} />
        <RenderRoleSearch
          checkSU={checkSU}
          checkAD={checkAD}
          checkAL={checkAL}
          toogleSU={toogleSU}
          toogleAD={toogleAD}
          toogleAL={toogleAL}
        />
      </div>
    </div>
  )
}

function useSearch() {
  const [name, setName] = useState('')
  const [last, setLast] = useState('')
  const [checkF, setCheckF] = useState(true)
  const [checkM, setCheckM] = useState(true)
  const [checkSU, setCheckSU] = useState(true)
  const [checkAD, setCheckAD] = useState(true)
  const [checkAL, setCheckAL] = useState(true)

  return {
    name,
    last,
    checkF,
    checkM,
    checkSU,
    checkAD,
    checkAL,
    toogleF: () => setCheckF(!checkF),
    toogleM: () => setCheckM(!checkM),
    toogleSU: () => setCheckSU(!checkSU),
    toogleAD: () => setCheckAD(!checkAD),
    toogleAL: () => setCheckAL(!checkAL),
    setLast: event => setLast(event.target.value),
    setName: event => setName(event.target.value),
  }
}

function GestionAdmins() {
  const {
    isLoading,
    isFetching,
    refetch,
    data = [],
  } = useQuery('fetch-admin-list', () => axios.get('/gestion-admins').then(res => res.data.success))

  const filterHook = useSearch()

  const filter = ({ name, last, sexe, role: { id: roleId } }) => {
    const sexes = `${filterHook.checkF ? 'F' : ''}${filterHook.checkM ? 'M' : ''}`

    const searchRole = `${filterHook.checkSU ? '1' : ''}${filterHook.checkAD ? '2' : ''}${
      filterHook.checkAL ? '4' : ''
    }`

    return (
      name.includes(filterHook.name) &&
      last.includes(filterHook.last) &&
      sexes.includes(sexe) &&
      searchRole.includes(roleId)
    )
  }

  return (
    <div className="card">
      <Header />
      <Search filterHook={filterHook} />
      <Body data={data.filter(filter)} loading={isLoading || isFetching} refresh={refetch} />
    </div>
  )
}

export default GestionAdmins
