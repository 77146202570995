import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

export default function Export(props) {
  let { fileName, csvData, title, headers, icon, color } = props
  icon = icon ? icon : 'fas fa-file-export'
  color = color ? color : 'primary'
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  let data = csvData

  if (headers) {
    data = csvData.map(e => {
      let tmp = {}
      for (var i = 0; i < Object.keys(headers).length; i++) {
        let key = Object.keys(headers)[i]
        tmp[headers[key]] = e[key]
      }
      return tmp
    })
  }

  const exportToCSV = (data_input, fileName) => {
    const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

    const ws = XLSX.utils.json_to_sheet(data_input)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + '-' + now + fileExtension)
  }

  return (
    <button className={`btn btn-${color}`} onClick={e => exportToCSV(data, fileName)}>
      <i className={icon}></i> {title}
    </button>
  )
}
