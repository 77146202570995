import React, { Component, forwardRef } from 'react'

function calcAdd(str) {
  let tmp = str
    .split('+')
    .map(e => parseFloat(e))
    .reduce((a, b) => a + b, 0)
  return tmp
}

class Calculator extends Component {
  constructor(props) {
    super(props)
    this.state = { val: '' }
  }

  componentDidMount() {
    if (this.props.val) {
      this.setState({ val: this.props.val })
    }
  }

  handle = event => {
    this.setState({ val: event.target.value })
    this.props.handleCalculator(this.props.id, calcAdd(event.target.value))
  }

  reset = () => this.setState({ val: '' })

  render() {
    return (
      <input
        type="text"
        value={this.state.val}
        className="form-control w-100 mb-3 form-control-sm"
        onChange={this.handle}
      />
    )
  }
}

function BsElement(props) {
  let input
  if (props.value.length > 0) {
    input = (
      <select
        onChange={props.handler}
        value={props.val}
        name={`input_${props.id}`}
        id={`input_${props.id}`}
        className="form-control form-control-sm"
      >
        <option value="">{props.label}</option>
        {props.value.map((e, k) => {
          return (
            <option key={k} value={e.id}>
              {e.value}
            </option>
          )
        })}
      </select>
    )
  } else {
    input = (
      <input
        type="number"
        className="form-control w-100 form-control-sm"
        onChange={props.handler}
        value={props.val}
        name={`input_${props.id}`}
        id={`input_${props.id}`}
        placeholder={props.label}
      />
    )
  }
  return input
}

const BSFields = forwardRef((props, ref) => (
  <>
    {props.elements.champs.map((e, k) => (
      <div className="form-group" key={k}>
        <Calculator
          ref={ref}
          {...e}
          handleCalculator={props.handleCalculator}
          val={props.values[e.id]}
        />
        <BsElement {...e} handler={props.handler} val={props.values[e.id]} />
      </div>
    ))}
  </>
))

export default BSFields
