import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isNotAdmin, isNotSuperAdmin } from '../../../helpers'

import Settings from './Settings'
import Liste from './Liste'
import Statistiques from './Statistiques'
import Show from './Show'
import MajView from './MajView'
import Versements from './Versements'
import VersementsShow from './Versements/VersementsShow'
import Historique from './Historique'
import { userStore } from '../../../store/userStore'

const listeDemandes = (
  <>
    <Route exact strict path={`/aide-sociale/:id`} component={Show}></Route>
    <Route exact path={`/aide-sociale`}>
      <Liste current={true} path="/aide-sociale" />
    </Route>
  </>
)

const adminRouter = (
  <Switch>
    <Route exact strict path={`/aide-sociale/historique`}>
      <Historique />
    </Route>
    <Route exact strict path={`/aide-sociale/versements`}>
      <Versements current={true} path="/aide-sociale" seed={Math.random()} />
    </Route>
    <Route exact strict path={`/aide-sociale/versements/:id`} component={VersementsShow}></Route>
    {listeDemandes}
    <Route>
      <Redirect to="/aide-sociale" />
    </Route>
  </Switch>
)

const superRouter = (
  <Switch>
    <Route exact strict path={`/aide-sociale/statistiques`}>
      <Statistiques />
    </Route>
    <Route exact strict path={`/aide-sociale/historique`}>
      <Historique />
    </Route>
    <Route exact strict path={`/aide-sociale/versements/:id`} component={VersementsShow}></Route>
    <Route exact strict path={`/aide-sociale/parametres`}>
      <Settings path="/aide-sociale" />
    </Route>
    <Route exact strict path={`/aide-sociale/versements`}>
      <Versements current={true} path="/aide-sociale" seed={Math.random()} />
    </Route>
    {listeDemandes}
    <Route>
      <Redirect to="/aide-sociale" />
    </Route>
  </Switch>
)

export default function AideSociale(props) {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  if (isNotAdmin(role)) return <MajView path="/aide-sociale" />
  else if (isNotSuperAdmin(role)) return adminRouter
  return superRouter
}
