import React, { Component } from 'react'
import { getTrad } from '../../lang'
import StatCard from './StatCard'
import axios from 'axios'
import Waiting from '../Waiting'
import { getUrl } from '../../Api'

const thisYear = new Date().getFullYear()

class Statistiques extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl(this.props.statsUrl))
        .then(res => {
          const data = res.data.success ? res.data.success : []
          data.sort((a, b) => b.annee - a.annees)
          this.setState({ loading: false, data: data })
        })
        .catch(err => this.setState({ loading: false }))
    })
  }

  render() {
    if (this.state.loading) return <Waiting />
    return (
      <>
        <div className=" card bg-light rounded p-3 my-3">
          <h1 className="text-dark p-0 m-0 text-center">{getTrad(this.props.page)}</h1>
        </div>
        {this.state.data
          .sort((b, a) => a.annee - b.annee)
          .map((e, k) => (
            <StatCard
              tableKeys={this.props.tableKeys}
              barKeys={this.props.barKeys}
              show={thisYear.toString() === e.annee.toString()}
              key={k}
              {...e}
            />
          ))}
      </>
    )
  }
}

export default Statistiques
