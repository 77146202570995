import React, { useState } from 'react'
import { areYouSure, successMsg, fatalError } from '../../../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../../../Api'

function ActionJustificatif(props) {
  const [loading, setLoading] = useState(false)
  const deleteItem = () => {
    setLoading(true)
    areYouSure.fire().then(result => {
      if (result.value)
        axios
          .delete(getUrl('justificatif.crud', [props.id]))
          .then(res => {
            props.refresh()
            setLoading(false)
            successMsg('Element effacé avec success')
          })
          .catch(err => {
            fatalError()
            setLoading(false)
            props.refresh()
          })
    })
  }
  const acceptItem = () => {
    setLoading(true)
    areYouSure.fire().then(result => {
      if (result.value)
        axios
          .patch(getUrl('justificatif.crud', [props.id]))
          .then(res => {
            setLoading(false)
            props.refresh()
            successMsg('Element modifié avec success')
          })
          .catch(err => {
            setLoading(false)
            fatalError()
            props.refresh()
          })
    })
  }

  if (props.dontShow) return <></>
  if (props.status === 'N')
    return (
      <div className="btn-group btn-group-sm  w-100">
        <button disabled={loading} onClick={acceptItem} className="btn w-100 btn-sm btn-success">
          {loading ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-check"></i>}
        </button>
        <button disabled={loading} onClick={deleteItem} className="btn w-100 btn-sm btn-danger">
          {loading ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-trash"></i>}
        </button>
      </div>
    )
  return (
    <button disabled={loading} onClick={deleteItem} className="btn w-100 btn-sm btn-danger">
      {loading ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-trash"></i>}
    </button>
  )
}

export default ActionJustificatif
