import React, { Component } from 'react'
import axios from 'axios'
import { getTrad } from '../../../lang'
import { getUrl } from '../../../Api'
import { fatalError } from '../../../helpers/sweetAlerts'
import { showDateTime, inString } from '../../../helpers'
import CardHeader from '../../../components/CardHeader'
import ShowTable from '../../../components/ShowTable'
import Export from '../../../components/Export'
import Filter from './Filter'

export default class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      historyList: [],
      historyListFiltred: [],
      search_date: '',
      search_user: '',
      search_methode: '',
      search_route: '',
      search_data: '',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  filter = element => {
    let test = true
    test &= inString(this.state.search_date, element.created_at)
    test &= inString(this.state.search_user, element.user)
    test &= inString(this.state.search_methode, element.method)
    test &= inString(this.state.search_route, element.route)
    test &= inString(this.state.search_data, element.data)

    return test
  }

  handler = e => this.setState({ [e.target.name]: e.target.value }, this.fresh)

  fresh = () => {
    this.setState({ historyListFiltred: this.state.historyList.filter(this.filter) })
  }

  refresh = () => {
    this.setState({ loading: true }, () =>
      axios
        .get(getUrl('historique.get'))
        .then(res => {
          this.setState(
            {
              historyList: res.data.success.map(e => ({
                ...e,
                created_at: showDateTime(e.created_at),
              })),
              loading: false,
            },
            this.fresh
          )
        })
        .catch(err => fatalError())
    )
  }

  render() {
    let header = {}
    let keys = ['created_at', 'user', 'method', 'route', 'ip', 'data']

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`log.${k}`)
    }

    let showProps = {
      data: this.state.historyListFiltred,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
      searching: this.state.loading || this.state.searching,
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('pages.log')}
          actions={
            <div className="btn-group">
              <Export
                title={getTrad('commun.exportBtn.filtred')}
                fileName={'hisitorique_gamm_filtre'}
                csvData={this.state.historyListFiltred.map(e => ({
                  [getTrad(`log.created_at`)]: e.created_at,
                  [getTrad(`log.user`)]: e.user,
                  [getTrad(`log.method`)]: e.method,
                  [getTrad(`log.route`)]: e.route,
                  [getTrad(`log.ip`)]: e.ip,
                  [getTrad(`log.data`)]: e.data,
                }))}
              />
              <Export
                title={getTrad('commun.exportBtn.all')}
                fileName={'hisitorique_gamm'}
                csvData={this.state.historyList.map(e => ({
                  [getTrad(`log.created_at`)]: e.created_at,
                  [getTrad(`log.user`)]: e.user,
                  [getTrad(`log.method`)]: e.method,
                  [getTrad(`log.route`)]: e.route,
                  [getTrad(`log.ip`)]: e.ip,
                  [getTrad(`log.data`)]: e.data,
                }))}
              />
              <button className="btn btn-dark" onClick={this.refresh}>
                <i className="fas fa-sync-alt"></i>
              </button>
            </div>
          }
        />
        <Filter {...this.state} handler={this.handler} />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}
