import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import ShowEdition from './ShowEdition'
import CardHeader from '../../../../components/CardHeader'
import { getTrad } from '../../../../lang'

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, listeEditions: [] }
    this.refresh = this.refresh.bind(this)
  }

  refresh() {
    this.setState({ loading: true }, () => {
      axios.get(getUrl('journeeSciences.listAll')).then(res => {
        this.setState({ listeEditions: res.data.success, loading: false })
      })
    })
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <>
        <div className="card">
          <CardHeader title={getTrad('pages.listEditionScience')} />
        </div>
        {this.renderContent()}
      </>
    )
  }

  renderContent() {
    if (this.state.loading) {
      return <Waiting />
    } else {
      return (
        <div className="row mt-3">
          {this.state.listeEditions.map((e, k) => (
            <ShowEdition reload={this.refresh} {...e} key={k} />
          ))}
        </div>
      )
    }
  }
}
export default Settings
