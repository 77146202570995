export const fr = {
  first: 'Première page',
  last: 'Dernière page',
}

export const ar = {
  first: 'الصفحة الأولى',
  last: 'الصفحة اﻷخيرة',
}

export const en = {
  first: 'First page',
  last: 'Last page',
}
