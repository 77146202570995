import { createRef, useEffect, useRef, useState } from 'react'
import { calcAdd } from '../helpers'

function useBsData(fields) {
  const [bsData, setBsData] = useState({})
  const calculators = useRef({})

  useEffect(() => {
    const newData = {}
    fields.forEach(element => {
      newData[element.id] = 0
      calculators.current[element.id] = createRef()
      setBsData(newData)
    })
  }, [fields])

  const handlerCalc = (event, id) =>
    setBsData({
      ...bsData,
      [id]: calcAdd(event.target.value),
    })

  const reset = () => {
    Object.values(calculators.current).forEach(calc => {
      calc.current.value = ''
    })
    const newData = {}
    fields.forEach(element => {
      newData[element.id] = 0
    })
    setBsData(newData)
  }

  const isEmpty = Object.values(bsData).filter(field => field !== 0).length === 0
  const hasNegative = Object.values(bsData).filter(field => field < 0).length !== 0

  return {
    bsData,
    calculators,
    handlerCalc,
    reset,
    isValid: !(isEmpty || hasNegative),
    isEmpty,
  }
}

export default useBsData
