import Dropdown from './Dropdown'
import { NavLink } from 'react-router-dom'
import { useTrad } from '../../lang'

function MenueItem({ icon, text, link, submenue }) {
  const title = useTrad('navbar.' + text)

  let linkProps = {
    className: 'nav-link ',
    to: '/' + link,
    id: 'nav-menu-' + link,
    title,
  }

  if (submenue) {
    linkProps.className += ' dropdown-toggle'
    linkProps.role = 'button'
    linkProps['data-toggle'] = 'dropdown'
    linkProps['aria-haspopup'] = 'true'
    linkProps['aria-expanded'] = 'false'
  }

  return (
    <li className={`nav-item${submenue && ' dropdown'}`}>
      <NavLink {...linkProps}>
        <i className={icon}></i>
      </NavLink>
      <Dropdown id={linkProps.id} parentLink={link} submenue={submenue} />
    </li>
  )
}
export default MenueItem
