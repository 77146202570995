let origin = window.location.origin
let api_URL = 'https://dev.mutuellemagistrat.com.tn/api'
let localhosts = ['http://localhost:3000', 'http://127.0.0.1:3000']

if (localhosts.includes(origin)) {
  api_URL = 'http://localhost:8000/api'
}

export const Api = {
  baseURL: api_URL,
  user: { changeLang: '/update-lang/@' },
  login: '/login',
  userInfo: '/user-info',
  changePassword: '/change-password',
  crud_Admins: '/gestion-admins',
  addAdmin: '/gestion-admins/add',
  changeRole: '/gestion-admins/change-role',
  AdminDeactivate: '/gestion-admins/deactivate',
  crudAdherants: '/adherants',
  adherantFamily: '/adherants/family/',
  crudPrestataire: '/prestataies',
  historique: { get: '/historique' },
  aide_sociale: {
    myVersements: '/aide-sociale/my/myVersements',
    historique: '/aide-sociale/historique',
    sendDemande: '/aide-sociale/aide/demande',
    versements: '/aide-sociale/versements',
    showVersements: '/aide-sociale/versements/@',
    stats: '/aide-sociale/stats',
    new: '/aide-sociale/new',
    generate: '/aide-sociale/@/generate',
    accept: '/aide-sociale/@/accept',
    refuse: '/aide-sociale/@/refuse',
    demandes: '/aide-sociale/@/demandes',
    get: '/aide-sociale',
    store: '/aide-sociale',
    destroy: '/aide-sociale/@',
    update: '/aide-sociale/@/update',
    show: '/aide-sociale/@',
    delete_aide: '/item_aide_sociale/@',
    add_aide: '/item_aide_sociale',
    update_aide: '/aide-sociale/@',
    prestataies: '/aide-sociale/prestataires/@',
    updatePresta: '/item_aide_sociale',
    myData: '/aide-sociale/my',
    icangot: '/aide-sociale/icangot',
  },
  prestataire: {
    get: '/prestataies/@',
    add: '/prestataies',
    edit: '/prestataies',
    delete: '/prestataies/@',
    justify: '/prestataies/justificatif/@',
  },
  retraite: { crud: '/retraite', list: '/retraite/@' },
  adherants: {
    info: '/adherants/@',
    profil: '/profil',
    famille: '/adherants/family/@',
    parents: '/adherants/parents',
    retraites: '/adherants/retraites',
    enfants: '/adherants/enfants',
  },
  bs: {
    myBs: '/mesbulletins',
    confs: '/bsconfs',
    search: '/bulletin/search',
    count: '/bulletin/count',
    demandes: '/bulletin/demandes',
    plafond: '/bulletins/@/plafond',
    crud2: '/bulletins/@',
    crud: '/bulletin',
    addDemande: '/bulletin/my',
    getNew: '/bulletin/new',
    rembours: '/bulletin/@/rembours',
    stats: '/bulletin/stats',
  },
  aidesociale: {
    crud: '/aidesociale',
    accept: '/aidesociale/accept',
    refuse: '/aidesociale/refuse',
    refusee: '/aidesociale/refusee',
    traite: '/aidesociale/traite',
    count: '/aidesociale/count',
    myData: '/aidesociale/myData',
    stats: '/aidesociale/stats',
    update: '/aidesociale/update/@',
  },
  credits: {
    add_sous_compte: '/credit/add_sous_compte/@',
    crud: '/credit',
    all: '/credit/all',
    update: '/credit/@',
    count: '/credit/count',
    show: '/credit/show/@',
    refuse: '/credit/refuse/@',
    accept: '/credit/accept/@',
    courent: '/credit/courent',
    rembours: '/credit/rembours/@',
    myData: '/credit/myData',
    stats: '/credit/stats',
    nonpayee: '/credit/nonpayee',
  },
  sms: '/sms/send',
  lang: {
    categorie: '/lang/categories',
    lieu: '/lang/etablissements',
    gouvernaurat: '/lang/gouvernorats',
    grade: '/lang/grades',
    status: '/lang/status-adherants',
  },
  borderau: {
    crud: '/borderau',
    details: '/borderau/@/details',
    parents: '/borderau/@/parents',
  },
  journeeSciences: {
    active: '/journee-sciences/active',
    listAll: '/journee-sciences',
    update: '/journee-sciences/@',
    laureat: '/journee-sciences/laureat',
    myData: '/journee-sciences/mydata',
    status: '/journee-sciences/stats',
    get: {
      archive: '/journee-sciences/archive/@',
      simple: '/journee-sciences/current/@',
      all: '/journee-sciences/archive/@',
      current: '/journee-sciences/current',
    },
    condidat: {
      acceptCondidat: '/journee-sciences/condidats/@/@',
      liste: '/journee-sciences/condidats/@',
      proof: '/journee-sciences/proof/@',
      update: '/condidature/@/@',
      myCondidature: '/condidature/new',
    },
    niveau: {
      add: '/journee-sciences/ajout-niveau',
      update: '/journee-sciences/modifier-niveau/@',
      delete: '/journee-sciences/supprime-niveau/@',
    },
  },
  saisie: {
    get: '/saisie',
    store: '/saisie',
    show: '/saisie/@',
    import: '/saisie/@/import',
  },
  etablissements: {
    index: '/etablissements',
    create: '/etablissements',
    destroy: '/etablissements/@',
    update: '/etablissements/@',
  },
  gouvernaurats: {
    index: '/gouvernorats',
    create: '/gouvernorats',
    destroy: '/gouvernorats/@',
  },
  local: {
    update: '/locals/@',
    create: '/locals',
  },
  files: {
    get: '/files',
  },
  justificatif: {
    crud: '/justificatif/@',
    count: '/justificatif/count',
  },
  params: {
    crud: '/params',
  },
  parent: {
    saisie: {
      get: '/parents',
      show: '/parents/@',
      store: '/parents/@',
      update: '/parents/@',
      destroy: '/parents/@',
      import: '/parents/import',
      stats: '/contract/stats',
      hadSession: '/parents/had-cession',
      my: '/parents/my/cessions',
    },
    contract: {
      imported: '/contract/imported',
      demandes: '/contract/demandes',
      rejected: '/contract/rejected',
      finis: '/contract/finis',
      all: '/contract',
      create: '/contract',
      show: '/contract/@',
      accept: '/contract/@/accept',
      refuse: '/contract/@/refuse',
      stop: '/contract/@',
      update: '/contract/@',
      my: '/parents/my/contracts',
    },
  },
}

function dontAddUndefined(str) {
  return str ? str : ''
}

export const getUrl = (str, args) => {
  if (args === undefined) args = []
  let tmp = Api

  let test = str.split('.')

  while (test.length !== 0) {
    let field = test[0]
    test = test.slice(1)
    if (tmp[field]) tmp = tmp[field]
    else return str
  }

  if (typeof tmp === 'string') {
    let r = tmp.indexOf('@')
    if (r === -1) {
      return Api.baseURL + tmp
    }
    let c = 0
    while (r !== -1) {
      c++
      r = tmp.indexOf('@', r + 1)
    }
    if (args.length !== c) {
      console.error(`Expected ${c} args`)
      return str
    }
    let tmpArr = tmp.split('@')
    tmp = Api.baseURL
    while (tmpArr.length > 0) {
      tmp += dontAddUndefined(tmpArr[0])
      tmp += dontAddUndefined(args[0])
      tmpArr.shift()
      args.shift()
    }
    return tmp
  }
  return str
}
