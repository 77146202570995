import React from 'react'

import ShowTable from '../../../../components/ShowTable'
import { getTrad } from '../../../../lang'

function RenderBody(props) {
  let header = {}
  let keys = [
    'matricule_adh',
    'full_name',
    'prestataire',
    'bs_number',
    'bs_date',
    'total_depense',
    //'estimation',
    'observations',
    'complement',
    'total_remboursement',
    'status',
    'action',
  ]
  for (var i = 0; i < keys.length; i++) {
    header[keys[i]] = getTrad(`bs.${keys[i]}`)
  }

  let showProps = {
    data: props.data,
    keys: keys,
    header: header,
    noData: getTrad('commun.noSearchData'),
  }
  if (props.searching) {
    showProps.searching = true
  }
  return (
    <div className="card-body p-0">
      <ShowTable {...showProps} />
    </div>
  )
}

export default RenderBody
