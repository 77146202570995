import React from 'react'

import { Redirect } from 'react-router-dom'
import { showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'

export default function ShowDemande(props) {
  let credit = props.listCredits.filter(e => e.status === 'N')[0]
  if (credit) {
    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-6">
              <h2 className="card-title">{getTrad('pages.maDemandeCredit')}</h2>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <b>{getTrad('credit.montant_demande')} :</b>
              <br />
              {showMoney(credit.montant_demande)}
            </div>

            <div className="col">
              <b>{getTrad('credit.date_demande')} :</b>
              <br />
              {showDate(credit.date_demande)}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <b>{getTrad('credit.motif')} :</b>
              <br />
              {credit.motif}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <Redirect to="/" />
  // return 'SHOWCREDIT'
}
