import React from 'react'

function Waiting() {
  return (
    <footer className="page-footer font-small blue ">
      <div className="footer-copyright text-center py-3">
        © {new Date().getFullYear()} Copyright: Application GAMM -
        <a href="https://www.mutuellemagistrat.com.tn/" rel="noopener noreferrer" target="_blank">
          {' '}
          Mutuelle des magistrats
        </a>
      </div>
    </footer>
  )
}

export default Waiting
