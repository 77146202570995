import React from 'react'
import PropTypes from 'prop-types'

export default function TxtInput(props) {
  let label = props.label
  let name = props.name
  let value = props.value
  let inputClass = ['form-control', 'w-100', 'form-control-sm']
  let error = []

  if (props.error) {
    props.error.map(e => {
      return error.push(e)
    })
    inputClass.push('is-invalid')
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        id={name}
        placeholder={label}
        onChange={props.handler}
        className={inputClass.join(' ')}
        value={value}
      ></textarea>

      <div className="invalid-feedback">
        {error.map((e, k) => {
          return <li key={k}>{e}</li>
        })}
      </div>
    </div>
  )
}

TxtInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}
