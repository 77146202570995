import React, { Component } from 'react'

import axios from 'axios'
import { getTrad } from '../../../../lang'
import NumInput from '../../../../components/Form/NumInput'
import TxtInput from '../../../../components/Form/TxtInput'
import Waiting from '../../../../components/Waiting'
import { getUrl } from '../../../../Api'
import { areYouSure, fatalError } from '../../../../helpers/sweetAlerts'

export default class AideSociales extends Component {
  constructor(props) {
    super(props)
    this.state = { errors: {}, montant: '', motif: '' }
  }

  sendRequest = () => {
    axios
      .post(getUrl('aidesociale.myData'), {
        montant: this.state.montant,
        motif: this.state.motif,
      })
      .then(res => {
        this.setState({ sending: false, montant: '', motif: '' })
        this.props.refresh()
      })
      .catch(err => {
        if (err.response.status !== 422) {
          this.setState({ sending: false })
          fatalError()
          this.props.refresh()
        } else {
          this.setState({ errors: err.response.data.errors })
        }
      })
  }

  send = () => {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.sendRequest)
    })
  }

  handler = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    let btn = this.state.sending ? <Waiting /> : getTrad('pages.demanderAide')

    if (this.props.listeDemande.filter(e => e.status === 'N').length) return <></>
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h2 className="card-title">{getTrad('pages.demanderAide')}</h2>
        </div>
        <div className="card-body">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <NumInput
                  handler={this.handler}
                  value={this.state.montant}
                  name="montant"
                  label={getTrad('fileds.montant')}
                  error={this.state.errors.montant}
                />
              </div>
              <div className="col">
                <br />
                <button
                  onClick={this.send}
                  className="btn btn-success w-100 btn-sm"
                  disabled={this.state.sending || this.state.montant === ''}
                >
                  {btn}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TxtInput
                  handler={this.handler}
                  value={this.state.motif}
                  name="motif"
                  label={getTrad('fileds.motif')}
                  error={this.state.errors.motif}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/*


import React, { Component } from 'react'
import axios from 'axios'
import { showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'



class AddDemande extends Component {


}

*/
