export const aideM = {
  icon: 'fas fa-hand-holding-heart',
  text: 'crud_mag_aide',
  link: 'aide',
}

export const aideA = {
  icon: 'fas fa-hand-holding-heart',
  text: 'crud_aide',
  link: 'aide',
  submenue: [
    { icon: 'fas fa-list', text: 'crud_aide', link: '' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-check', text: 'traite_aide', link: 'traite' },
    { icon: 'fas fa-times', text: 'refusee_aide', link: 'refusee' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-chart-bar', text: 'statistiques', link: 'statistiques' },
  ],
}
