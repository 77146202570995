import React from 'react'
import { getTrad } from '../../../../lang'
import ShowTable from '../../../../components/ShowTable'

function ListBody(props) {
  let header = {}

  const keys = [
    // 'id',
    'bs_number',
    'matricule_adh',
    'full_name',
    'prestataire',
    'bs_date',
    'status',
    'depenses',
    'estimation',
    'action',
  ]

  for (var i = 0; i < keys.length; i++) {
    let k = keys[i]
    header[k] = getTrad(`bs.${k}`)
  }

  let showProps = {
    data: props.data,
    keys: keys,
    header: header,
    noData: getTrad('commun.noSearchData'),
    loading: props.loading,
  }

  return (
    <div className="card-body p-0">
      <ShowTable {...showProps} />
    </div>
  )
}

export default ListBody
