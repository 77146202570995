import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import { Api, getUrl } from '../../Api'
import { getJwt, removeJwt, setLang } from '../../helpers'
import Header from '../../components/Header/index'
import Footer from '../../components/Footer'
import Logout from '../../helpers/Logout'

// Dashboard components
import ChangePassword from './ChangePassword'
import Overview from './Overview'
import Admin from './Admin'
import Adherant from './Adherant'
import BultinSoins from './BultinSoins'
import Aide from './Aide'
import Credit from './Credit'
import Sms from './Sms'
import ScienceDay from './ScienceDay'
import Profile from './Profile'
import Logs from './Logs'
import AideSociale from './AideSociale'
import Etablissements from './Etablissements'
import Parents from './Parents'
import Parametres from './Parametres'

import { Switch, Redirect, Route } from 'react-router-dom'
// import local from '../../lang'
import { userStore } from '../../store/userStore'
import SuperUserMiddleWare from '../../middleware/SuperUserMiddleWare'

function Dashboard() {
  const [loaded, setLoaded] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const { setUser } = userStore()
  // class Dashboard extends Component {
  //   constructor(props) {
  //     super(props)
  //     this.state = {
  //       user: {},
  //       local: local[getLang()],
  //       loggedIn: false,
  //       redirectToLogin: false,
  //       pathName: '/',
  //     }
  //   }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     this.setState({ pathName: this.props.location.pathname })
  //   }
  // }

  useEffect(() => {
    const jwt = getJwt()
    if (!jwt) {
      setLoaded(true)
    } else {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
      axios.defaults.headers.common['Content-Type'] = 'application/json'
      axios
        .get('/user-info')
        .then(res => res.data.success)
        .then(user => {
          setLang(user.local)
          setLoggedIn(true)
          setLoaded(true)
          setUser(user)
          // this.setState({
          //   pathName: this.props.location.pathname,
          // })
        })
        .catch(() => {
          removeJwt()
          setLoggedIn(false)
          setLoaded(true)
          setUser(null)
        })
    }
  }, [setUser])

  if (!loaded) return null
  if (loaded && !loggedIn) return <Redirect to="/login" />
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="container-fluid main root p-3">
          <Switch>
            <Route path="/logout" exact strict component={Logout} />

            <Route path="/gestion-admins">
              <Admin />
            </Route>

            <Route path="/mon-profil">
              <Profile />
            </Route>

            <Route path="/adherants">
              <Adherant />
            </Route>

            <Route path="/change-password" exact strict>
              <ChangePassword />
            </Route>

            <Route path="/bultin-soins">
              <BultinSoins />
            </Route>
            <Route path="/aide">
              <Aide />
            </Route>

            <Route path="/aide-sociale">
              <AideSociale />
            </Route>

            <Route path="/credit">
              <Credit />
            </Route>

            <Route path="/journee-savoir">
              <ScienceDay />
            </Route>

            <Route path="/sms">
              <Sms />
            </Route>

            <Route path="/logs" exact strict>
              <SuperUserMiddleWare>
                <Logs />
              </SuperUserMiddleWare>
            </Route>

            <Route path="/etablissements">
              <Etablissements />
            </Route>

            <Route path="/parents">
              <Parents />
            </Route>

            <Route path="/parameters">
              <SuperUserMiddleWare>
                <Parametres />
              </SuperUserMiddleWare>
            </Route>

            <Route path="/" exact strict>
              <Overview />
            </Route>

            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Dashboard
