import React from 'react'
import { getTrad } from '../../../../lang'

export default function BSInfo(props) {
  let title = props.action === 'U' ? getTrad('pages.editBs') : getTrad('pages.addBs')
  let btnText = props.action === 'U' ? getTrad('commun.edit') : getTrad('commun.add')

  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{title}</h2>
        </div>
        <div className="col">
          <button type="submit" onClick={props.send} className="btn-sm w-100 btn btn-success">
            {btnText}
          </button>
        </div>
      </div>
    </div>
  )
}
