export const adherantM = { icon: 'fas fa-user-tie', text: 'profil', link: 'mon-profil' }

export const adherantA = {
  icon: 'fas fa-user-tie',
  text: 'crud_adherant',
  link: 'adherants',
  submenue: [
    { icon: 'fas fa-user-tie', text: 'list_adherants', link: '' },
    { icon: 'fas fa-child', text: 'list_enfants', link: 'enfants' },
    { icon: 'fas fa-ellipsis-h', text: 'list_retraite', link: 'retraites' },
    { icon: 'fas fa-child', text: 'list_orphelin', link: 'orphelin' },
    { icon: 'fas fa-list', text: 'list_veuve', link: 'veuve' },
  ],
}
