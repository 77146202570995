import React from 'react'
import PropTypes from 'prop-types'

export default function InputSelect({ name = 'unknown', value = '', data = [], ...props }) {
  let label = props.label ? props.label : name
  let inputClass = ['form-control', 'form-control-sm']
  let error = []

  if (Array.isArray(props.error)) {
    if (props.error.length) {
      inputClass.push('is-invalid')
    }

    props.error.map(e => {
      return error.push(e)
    })
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{props.label}</label>

      <select
        name={name}
        id="selectType"
        onChange={props.handler}
        value={value.toString()}
        className={inputClass.join(' ')}
      >
        <option value="">{`--- ${label} ---`}</option>
        {data.map((e, k) => {
          return (
            <option key={k} value={e.value}>
              {e.text}
            </option>
          )
        })}
      </select>
    </div>
  )
}

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  error: PropTypes.array,
}
