import React from 'react'
import PropTypes from 'prop-types'
import { getTrad } from '../../../lang'

function Selector(props) {
  const value = props.value.split('-')
  const year = parseInt(value[1])
  const mounth = parseInt(value[0])

  const handleMounth = event => {
    props.handler({
      target: {
        name: props.name,
        value: `${event.target.name < 10 ? '0' : ''}${event.target.name}-${year}`,
      },
    })
  }

  const handleBtnYear = event => {
    const newYear = event.target.name === 'add' ? year + 1 : year - 1

    props.handler({
      target: {
        name: props.name,
        value: `${mounth < 10 ? '0' : ''}${mounth}-${newYear}`,
      },
    })
  }

  const handleTextYear = event => {
    props.handler({
      target: {
        name: props.name,
        value: `${mounth < 10 ? '0' : ''}${mounth}-${event.target.value}`,
      },
    })
  }

  if (!props.show) return <></>

  return (
    <div className="mt-3 ">
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <button
            onClick={handleBtnYear}
            name="sub"
            className="btn btn-outline-primary"
            type="button"
          >
            <i className="fas fa-angle-left"></i>
          </button>
        </div>
        <input
          onChange={handleTextYear}
          type="text"
          value={year.toString()}
          className="form-control text-center"
        />
        <div className="input-group-append">
          <button
            onClick={handleBtnYear}
            name="add"
            className="btn btn-outline-primary"
            type="button"
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      </div>

      <div className="mounth-grid has-3">
        {Array.from({ length: 12 }).map((_, k) => {
          const classList = ['btn', 'btn-sm', 'rounded-0']

          if (k + 1 === mounth) {
            classList.push('btn-primary')
          } else {
            classList.push('btn-light')
          }

          return (
            <button
              type="button"
              onClick={handleMounth}
              name={k + 1}
              className={classList.join(' ')}
              key={k}
            >
              {getTrad(`commun.mounth.${k + 1}`)}
            </button>
          )
        })}
      </div>
    </div>
  )
}

Selector.propTypes = {
  value: PropTypes.string.isRequired,
}

export default Selector
