import React, { Component } from 'react'
import CardHeader from '../../../../components/CardHeader'
import ShowTable from '../../../../components/ShowTable'
import { getTrad } from '../../../../lang'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Export from '../../../../components/Export'
import { inString } from '../../../../helpers'
import Add from '../Add'
import Filter from './Filter'
import Accept from './Accept'
import Refuse from './Refuse'

const thisYear = new Date().getFullYear()

const keys = ['matricule', 'matricule_cnrps', 'sous_compte', 'first_name', 'last_name', 'actions']

class ListDemande extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      loading: true,
      matricule: '',
      matricule_cnrps: '',
      first_name: '',
      last_name: '',
      sous_compte: '',
      has_matricule_cnrps: 'A',
      has_sous_compte: 'A',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  cleanData = e => ({
    matricule_adh: e.adherant.matricule,
    matricule: e.adherant.matricule,
    // matricule: (
    //   <a className="text-primary" rel="noopener noreferrer" target="_blank" href={`/adherants/${e.adherant.matricule}`}>
    //     {e.adherant.matricule}
    //   </a>
    // ),
    nb_cessions: e.cesions.length,
    cesions: e.cesions,
    matricule_cnrps: e.adherant.matricule_cnrps,
    first_name: e.adherant.first_name,
    last_name: e.adherant.last_name,
    sous_compte: e.sous_compte,
    actions: (
      <div className="btn-group">
        <Accept {...e} refresh={this.refresh} />
        <Refuse {...e} refresh={this.refresh} />
      </div>
    ),
  })

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('parent.contract.imported'))
        .then(res => this.setState({ list: res.data.success.map(this.cleanData), loading: false }))
        .catch(err => this.setState({ loading: false }))
    })
  }

  deleteItem = data => {
    this.setState({
      list: this.state.list
        .map(e => ({ ...e, cession_parents: e.cession_parents.filter(ele => ele.id !== data.id) }))
        .map(this.cleanData),
    })
  }

  handleInput = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    if (!e) return false

    if (this.state.has_matricule_cnrps === 'Y' && e.matricule_cnrps === null) return false
    if (this.state.has_matricule_cnrps === 'N' && e.matricule_cnrps !== null) return false

    if (this.state.has_sous_compte === 'Y' && e.sous_compte === null) return false
    if (this.state.has_sous_compte === 'N' && e.sous_compte !== null) return false
    if (!inString(this.state.matricule, e.matricule)) return false
    if (!inString(this.state.matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.sous_compte, e.sous_compte)) return false
    if (!inString(this.state.first_name, e.first_name)) return false
    if (!inString(this.state.last_name, e.last_name)) return false
    return true
  }

  render() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      if (isNaN(keys[i])) header[keys[i]] = getTrad(`fileds.${keys[i]}`)
      else header[keys[i]] = keys[i]
    }

    const tablePorps = {
      searching: this.state.loading,
      noData: getTrad('commun.noSearchData'),
      header: header,
      keys: keys,
      data: this.state.list.filter(this.filter).sort((a, b) => a[thisYear] < b[thisYear]),
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('parent.page.list_importation')}
          actions={
            <div className="btn-group">
              <Add />
              <Export
                fileName={getTrad('parent.page.list')}
                csvData={this.state.list}
                title={getTrad('commun.exportBtn.all')}
                headers={header}
              />
            </div>
          }
        />
        <div className="card-body">
          <Filter
            handler={this.handleInput}
            matricule={this.state.matricule}
            matricule_cnrps={this.state.matricule_cnrps}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            has_sous_compte={this.state.has_sous_compte}
            has_matricule_cnrps={this.state.has_matricule_cnrps}
            sous_compte={this.state.sous_compte}
          />
        </div>
        <div className="card-body p-0">
          <ShowTable {...tablePorps} />
        </div>
      </div>
    )
  }
}

export default ListDemande
