import React from 'react'
import axios from 'axios'
import ShowTable from '../../../components/ShowTable'
import { getTrad } from '../../../lang'
import { showDate } from '../../../helpers'
import { useQuery } from 'react-query'

function RenderBody({ data = [], loading = true }) {
  let header = {
    matricule_adh: getTrad('bs.matricule_adh'),
    full_name: getTrad('bs.full_name'),
    prestataire: getTrad('bs.prestataire'),
    bs_number: getTrad('bs.bs_number'),
    bs_date: getTrad('bs.bs_date'),
    status: getTrad('bs.status'),
    total_depense: getTrad('bs.total_depense'),
    total_remboursement: getTrad('bs.total_remboursement'),
    observations: getTrad('bs.observations'),
    complement: getTrad('bs.complement'),
  }
  let keys = [
    'matricule_adh',
    'full_name',
    'prestataire',
    'bs_number',
    'bs_date',
    'status',
    'total_depense',
    'total_remboursement',
    'observations',
    'complement',
  ]

  let showProps = {
    data,
    keys: keys,
    header: header,
    noData: getTrad('commun.noSearchData'),
  }
  if (loading) {
    showProps.searching = true
  }
  return (
    <div className="card-body p-0">
      <ShowTable {...showProps} />
    </div>
  )
}

function RenderHeader() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{getTrad('pages.myBs')}</h2>
        </div>
      </div>
    </div>
  )
}

export default function List() {
  const {
    isLoading,
    isFetching,
    data = [],
  } = useQuery('get-my-bss', () =>
    axios.get('/mesbulletins').then(res =>
      res.data.success.map(e => ({
        ...e,
        bs_date: showDate(e.bs_date),
        status: getTrad(`bs.status_${e.status}`),
      }))
    )
  )

  return (
    <div className="card">
      <RenderHeader />
      <RenderBody loading={isLoading || isFetching} data={data} />
    </div>
  )
}
