import React, { useEffect, useState } from 'react'
import Waiting from '../../../components/Waiting'
import { getTrad } from '../../../lang'
import { fatalError } from '../../../helpers/sweetAlerts'
import { showDate, showMoney } from '../../../helpers'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { Link } from 'react-router-dom'
import ShowTable from '../../../components/ShowTable'

const cleanData = e => {
  const payee = e.remboursements.reduce((a, b) => a + b.montant_rembours, 0)
  return {
    id: e.id,
    sous_compte: e.sous_compte,
    matricule_adh: e.adherant.matricule,
    matricule_cnrps: e.adherant.matricule_cnrps,
    first_name: e.adherant.first_name,
    last_name: e.adherant.last_name,
    date_versement: showDate(e.date_versement),
    montant: showMoney(e.montant_rembours),
    montantRembourse: showMoney(payee),
    montant_echance: showMoney(e.montant_echance),
    rest: showMoney(e.montant_rembours - payee),
    echeance: `${e.remboursements.length}/${e.nb_echeance}`,
    actions: (
      <Link to={`/credit/${e.id}`} className="btn btn-success btn-sm">
        <i className="fas fa-eye"></i>
      </Link>
    ),
  }
}
function NonPayee() {
  const [loading, setLoading] = useState(true)
  const [selected, setselected] = useState('hap')
  const [data, setData] = useState({ hap: [], nop: [] })
  const keys = [
    'id',
    'matricule_adh',
    'sous_compte',
    'matricule_cnrps',
    'first_name',
    'last_name',
    'date_versement',
    'montant',
    'montantRembourse',
    'montant_echance',
    'rest',
    'echeance',
    'actions',
  ]
  const header = {
    id: getTrad('fileds.id'),
    matricule_adh: getTrad('fileds.matricule_adh'),
    sous_compte: getTrad('fileds.sous_compte'),
    matricule_cnrps: getTrad('fileds.matricule_cnrps'),
    first_name: getTrad('fileds.first_name'),
    last_name: getTrad('fileds.last_name'),
    date_versement: getTrad('fileds.date_versement'),
    montant: getTrad('fileds.montant'),
    montantRembourse: getTrad('fileds.montantRembourse'),
    montant_echance: getTrad('fileds.montant_echance'),
    rest: getTrad('fileds.rest'),
    echeance: getTrad('fileds.echeance'),
    actions: getTrad('fileds.actions'),
  }

  const refresh = () => {
    setLoading(true)
    setData({ hap: [], nop: [] })
    axios
      .get(getUrl('credits.nonpayee'))
      .then(res => {
        setLoading(false)
        setData({
          hap: res.data.success.hap.map(e => cleanData(e)),
          nop: res.data.success.nop.map(e => cleanData(e)),
        })
      })
      .catch(() => {
        fatalError()
        setLoading(false)
        setData({ hap: [], nop: [] })
      })
  }

  useEffect(refresh, [])

  if (loading) return <Waiting />

  let showProps = {
    data: data?.[selected] ?? [],
    keys: keys,
    header: header,
    noData: getTrad(`commun.noSearchData`),
    searching: loading,
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h2 className="card-title">{getTrad('pages.listnonpayee')}</h2>
          </div>
          <div className="col">
            <ul className="nav justify-content-end nav-pills">
              {['hap', 'nop'].map((e, k) => {
                return (
                  <li className="nav-item" key={k}>
                    <button
                      className={`btn nav-link${e === selected ? ' active' : ''}`}
                      onClick={() => setselected(e)}
                    >
                      {getTrad(`credit.nonRembours.${e}`)}
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    </div>
  )
}
export default NonPayee
