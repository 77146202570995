export const fr = {
  demande: 'Demander un crédit',
  demandeCredit: 'Demande de crédit N°',
  accept: 'Accepter crédit',
  rembours: 'Ajouter un remboursement de crédit N°{}',
  remboursBtn: 'Rembourser',
  detailsAd: 'Détails de l`adhérent',
  detailsCr: 'Détails du crédit',
  downloadForms: 'Télécharger les formulaires',
  montant_demande: 'Montant de crédit',
  montant_rembours: 'Montant à rembourser',
  nb_echeance: "Nombre d'échéances",
  date_demande: 'Date de demande',
  date_acceptation: "Date d'acceptation de la demande",
  motif: 'Motif de crédit',
  rest: 'Reste à rembourser',
  updated_at: "Date d'acceptation",
  date_rembours: 'Date de remboursement',
  demander: 'Demander un crédit',
  addSousCompte: 'Ajouter sous-compte à {} {} CNRPS: {}',
  soldee: 'Soldé',
  nonRembours: {
    hap: 'Avec remboursements',
    nop: 'Sans remboursement',
  },
  type_remboursement_name: 'Type de versement',
  type_remboursement: {
    E: 'Espèces',
    C: 'Chèque bancaire',
    B: 'Carte Bleu',
    P: 'Paierie',
    V: 'Virement',
  },
  update: {
    rembours: 'Modifier le remboursement N°{} du {}',
  },

  nb_credits: 'Nombre de crédits',
  montant_credits: 'Montant des crédits',

  success: {
    addSousCompte: 'Sous-compte ajouté avec succès',
  },
  status: {
    N: 'Non traité',
    A: 'Accepté',
    R: 'Refusé',
    P: 'Payé',
  },
}

export const ar = {
  demande: 'التقدم بطلب للحصول على قرض',
  demandeCredit: 'طلب قرض رقمْ',
  accept: 'قبول طلب القرض',
  rembours: 'إضافة المبلغ المسترد للقرض رقمْ {}',
  remboursBtn: 'تسديد المبلغ',
  detailsAd: 'تفاصيل ملفّ العضو',
  detailsCr: 'تفاصيل القرض',
  downloadForms: 'تنزيل الاستمارات',
  montant_demande: 'مبلغ القرض',
  montant_rembours: 'المبلغ للتّسديد',
  nb_echeance: 'عدد الاقساط',
  date_demande: 'تاريخ المطلب',
  date_acceptation: 'تاريخ قبول المطلب',
  motif: 'سبب طلب القرض',
  rest: 'المبلغ المتبقّي لتسديده',
  updated_at: 'تاريخ الموافقة',
  date_rembours: 'تاريخ سداد جزء من القرض',
  demander: 'طلب قرض',
  addSousCompte: 'إضافة حساب إلى {} {} CNRPS: {}',
  soldee: 'مدفوع',
  nonRembours: {
    hap: 'مع امكانية استرداد المال',
    nop: 'بدون امكانية استرداد المال',
  },
  type_remboursement_name: 'طريقة الدّفع',
  type_remboursement: {
    E: 'نقد',
    C: 'صك بنكي',
    B: 'بطاقة زرقاء',
    P: 'كشف راتب',
    V: 'تحويل بنكي',
  },
  update: {
    rembours: 'تعديل استرداد المال رقمْ {} من {}',
  },

  nb_credits: 'عدد القروض',
  montant_credits: 'مبلغ القروض',

  success: {
    addSousCompte: 'اضافة الحساب بنجاح',
  },
  status: {
    N: 'غير مدروس',
    A: 'موافق عليه',
    R: 'مرفوض',
    P: 'تمّ دفعه',
  },
}

export const en = {
  demande: 'Request a credit',
  demandeCredit: 'Credit request N°',
  accept: 'Accept credit',
  rembours: 'Add a refund for credit N°{}',
  remboursBtn: 'Refund',
  detailsAd: 'Member details',
  detailsCr: 'Credit details',
  downloadForms: 'Download files',
  montant_demande: 'Credit amount',
  montant_rembours: 'Amount to refund',
  nb_echeance: 'Number of installments',
  date_demande: 'Request date',
  date_acceptation: 'Acceptance date of the request',
  motif: 'Credit motive',
  rest: 'Remains to refund',
  updated_at: 'Acceptance date',
  date_rembours: 'Refund date',
  demander: 'Credit request',
  addSousCompte: 'Add sub-account to {} {} CNRPS: {}',
  soldee: 'Payed',
  nonRembours: {
    hap: 'With refunds',
    nop: 'Without refund',
  },
  type_remboursement_name: 'Payment type',
  type_remboursement: {
    E: 'Cash',
    C: 'Bank check',
    B: 'Blue card',
    P: 'Payroll',
    V: 'Bank transfer',
  },
  update: {
    rembours: 'Modify the refund N°{} of {}',
  },

  nb_credits: 'Number of credits',
  montant_credits: 'Credits amount',

  success: {
    addSousCompte: 'Sub-saccount successfully added',
  },
  status: {
    N: 'Untreated',
    A: 'Accepted',
    R: 'Refused',
    P: 'Payed',
  },
}
