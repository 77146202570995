export const fr = {
  created_at: 'Date',
  ip: 'IP',
  user: 'Utilisateur',
  method: 'Méthode',
  route: 'Route',
  data: 'Requête',

  search_date: 'Date',
  search_user: 'Utilisateur',
  search_methode: 'Méthode',
  search_route: 'Route',
  search_data: 'Requête',
}

export const ar = {
  created_at: 'بتاريخ',
  ip: 'IP',
  user: 'المستعمل',
  method: 'الطريقة',
  route: 'المسلك',
  data: 'الطّلب',

  search_date: 'بتاريخ',
  search_user: 'المستعمل',
  search_methode: 'الطريقة',
  search_route: 'المسلك',
  search_data: 'الطّلب',
}

export const en = {
  created_at: 'Date',
  ip: 'IP',
  user: 'User',
  method: 'Method',
  route: 'Route',
  data: 'Request',

  search_date: 'Date',
  search_user: 'User',
  search_methode: 'Method',
  search_route: 'Route',
  search_data: 'Request',
}
