import React, { Component } from 'react'
import { showDate } from '../../../helpers'
import { getTrad } from '../../../lang'
import Justificatif from '../Adherant/Family/Justificatif'

function RednerThead() {
  return (
    <thead>
      <tr>
        <th>{getTrad('fileds.relation')}</th>
        <th>{getTrad('fileds.first_name')}</th>
        <th>{getTrad('fileds.last_name')}</th>
        <th>{getTrad('fileds.sexe')}</th>
        <th>{getTrad('fileds.birth_day')}</th>
        <th>{getTrad('fileds.encharge')}</th>
      </tr>
    </thead>
  )
}

export default class Family extends Component {
  renderTbody() {
    if (!this.props.family) return <></>

    if (this.props.family.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="6" className="text-center">
              {getTrad('commun.noFamilyData')}
            </td>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody>
        {this.props.family.map((e, k) => {
          return (
            <tr key={k}>
              <td>{getTrad(`type.${e.type}`)}</td>
              <td>{e.first_name}</td>
              <td>{e.last_name}</td>
              <td>{getTrad(`commun.sexe.${e.sexe}`)}</td>
              <td>{showDate(e.birth_day)}</td>
              <td>{getTrad(`commun.binary.${e.is_supported}`)}</td>
              <td>
                <Justificatif dontShow {...e} />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <table className="table table-hover table-striped familyMembers">
            <RednerThead />
            {this.renderTbody()}
          </table>
        </div>
      </div>
    )
  }
}
