export const fr = {
  change: 'Changer mot de passe',
  password_changed: 'Votre mot de passe a été mis-à-jour',
  pwd_error_change: 'Un problème lors du changement du mot de passe',
  sc_password: 'Vous devez changer votre mot de passe',
}

export const ar = {
  change: 'تغيير كلمة السرَ',
  password_changed: 'تم تحديث كلمة السرّ الخاصة بك',
  pwd_error_change: 'مشكلة عند تغيير كلمة السرّ',
  sc_password: 'يجب عليك تغيير كلمة المرور الخاصة بك',
}

export const en = {
  change: 'Change password',
  password_changed: 'Your password has been updated',
  pwd_error_change: 'A problem has occured while changing the password',
  sc_password: 'You have to change your password',
}
