export const DEVISESTR = 'Dt'
export const DEVISEAFTER = 3
export const TENPOWAFTER = Math.pow(10, DEVISEAFTER)

export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const SHOW_DATE_FORMAT = 'DD-MM-YYYY'
export const SHOW_MOUNTH_FORMAT = 'MM-YYYY'
export const SHOW_DATETIME_FORMAT = 'H:mm:ss DD-MM-YYYY'
export const ROLE_SUPER_USER = 'ROLE_SUPER_USER'
export const ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR'
export const ROLE_MAGISTRAT = 'ROLE_MAGISTRAT'

export const AVALIBLE_LANGS = ['ar', 'fr', 'en']

export const DATEEXPRESSION = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
export const MOUNTHEXPRESSION = /^(0[1-9]|1[012])[- /.](19|20)\d\d$/
