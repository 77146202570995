import React, { useState } from 'react'
import ShowStats from './ShowStats'

function CardHeader(props) {
  return (
    <div className="card-header" onClick={props.show}>
      <h3 className="card-title">{props.title}</h3>
    </div>
  )
}

function StatCard(props) {
  const [show, setShow] = useState(props.show === true)

  const handleShow = () => setShow(!show)

  return (
    <div className="card mb-3">
      <CardHeader show={handleShow} title={props.annee} />
      <ShowStats {...props} show={show} />
    </div>
  )
}

export default StatCard
