import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Waiting from '../../../../components/Waiting'
import RenderHader from './RenderHader'
import { useFilter } from './hooks'
import RenderBody from './RenderBody'
import RenderSearchSection from './RenderSearchSection'
import { inString, showDate } from '../../../../helpers'
import { getTrad } from '../../../../lang'

function Orphelin() {
  const [orphelin, setOrphelin] = useState([])
  const [loading, setLoading] = useState(true)
  const filter = useFilter()

  const { matricule, lastName, firstName, matriculeCnrps, phone, birthDay } = filter

  const filterFn = e => {
    if (e === undefined) return false
    if (!inString(matricule, e.matricule || '')) return false
    if (!inString(matriculeCnrps, e.matricule_cnrps || '')) return false
    if (!inString(firstName, e.first_name || '')) return false
    if (!inString(lastName, e.last_name || '')) return false
    if (!inString(phone, e.phone || '')) return false
    if (!inString(birthDay, e.birth_day || '')) return false
    return true
  }

  useEffect(() => {
    setLoading(true)
    axios
      .get('/orphelin')
      .then(res => {
        setOrphelin(
          res.data.success.map(prestatire => ({
            ...prestatire,
            sexe: getTrad(`commun.sexe.${prestatire.sexe}`),
            birth_day: showDate(prestatire.birth_day),
          }))
        )
        setLoading(false)
      })
      .catch(() => {
        setOrphelin([])
        setLoading(false)
      })
  }, [])

  return (
    <div className="card">
      <RenderHader data={orphelin.filter(filterFn)} />
      <RenderSearchSection filter={filter} />
      {loading ? (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Waiting />
        </div>
      ) : (
        <RenderBody data={orphelin.filter(filterFn)} />
      )}
    </div>
  )
}

export default Orphelin
