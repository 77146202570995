export const etablissementsA = {
  icon: 'fas fa-landmark',
  text: 'etablissements',
  link: 'etablissements',
  submenue: [
    { icon: 'fas fa-landmark', text: 'gestion_etabs', link: '' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-cog', text: 'gestion_gouvs', link: 'gouvernaurats' },
  ],
}
