import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Waiting from '../../../components/Waiting'
import { useQuery } from 'react-query'

function RenderCard({ show = true, tester = false, icon = '', text = '', link = '', url }) {
  const [reload, setReload] = useState(true)
  const {
    isLoading,
    isFetching,
    isSuccess,
    data = 0,
  } = useQuery(url, () => axios.get(url).then(res => res.data.success), {
    onError: () => setReload(false),
    refetchInterval: reload && 5000,
    retry: false,
  })

  return (
    isSuccess &&
    show &&
    tester && (
      <div className="col-md-6 my-3">
        <Link to={link}>
          <div className="info-custom">
            <div className="icon">
              <i className={icon}></i>
            </div>
            <div className="text">
              <b>{text} : </b>
              {isLoading || isFetching ? <Waiting /> : data}
            </div>
          </div>
        </Link>
      </div>
    )
  )
}

export default RenderCard
