import React, { Component } from 'react'
import CardHeader from '../../../../components/CardHeader'
import { Link } from 'react-router-dom'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { showDate } from '../../../../helpers'
import axios from 'axios'

function TableInner(props) {
  if (props.data.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center">
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.data.map((e, k) => (
        <tr key={e.id}>
          <td>{showDate(e.created_at)}</td>
          <td>{e.name}</td>
          <td>{getTrad(`aidesociale.${e.forfait ? '' : 'non'}forfait`)}</td>
          <td>{e.nb_adherants}</td>
          <td>{e.nb_benificieres}</td>
          <td>
            <Link to={`${props.path}/${e.id}`} className="btn btn-primary btn-sm w-100">
              <i className="far fa-eye" />
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default class Liste extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [] }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    axios.get(getUrl('aide_sociale.get')).then(res => this.setState({ data: res.data.success }))
  }

  render() {
    return (
      <div className="card">
        <CardHeader title={getTrad('pages.aideSociale.list')} />
        <div className="card-body p-0">
          <table className="table table-stripped table-hover w-100">
            <thead>
              <tr>
                <th>{getTrad('aidesociale.created_at')}</th>
                <th>{getTrad('aidesociale.name')}</th>
                <th>{getTrad('aidesociale.forfait')}</th>
                <th>{getTrad('aidesociale.number_adherants')}</th>
                <th>{getTrad('aidesociale.number')}</th>
                <th></th>
              </tr>
            </thead>
            <TableInner path={this.props.path} data={this.state.data} />
          </table>
        </div>
      </div>
    )
  }
}
