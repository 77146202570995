import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import { getTrad } from '../../../../lang'
import { showDate, showMoney } from '../../../../helpers'

import Condidatures from './Condidatures'

function Details(props) {
  let cnVal = 'font-weight-bold align-top px-1'
  return (
    <div className="card">
      <div className="card-header">
        <h2>{props.edition.edition}</h2>
      </div>
      <div className="card-body p-0">
        <table className="w-100">
          <tbody>
            <tr>
              <td className={`${cnVal} w-25`}>{getTrad('science.date')}</td>
              <td className={cnVal} style={{ width: 1 }}>
                :
              </td>
              <td>{showDate(props.edition.date)}</td>
            </tr>
            <tr>
              <td className={`${cnVal} w-25`}>{getTrad('science.deadline')}</td>
              <td className={cnVal} style={{ width: 1 }}>
                :
              </td>
              <td>{showDate(props.edition.deadline)}</td>
            </tr>
          </tbody>
          <tbody className="border-top border-dark">
            <tr>
              <td className={`${cnVal} w-25`}>{getTrad('science.listePrix')}</td>
              <td className={cnVal} style={{ width: 1 }}>
                :
              </td>
              <td>
                {props.edition.prix.map((e, k) => (
                  <div key={k} className="prix-row">
                    <b>{e.niveau}</b> ({showMoney(e.prix)})
                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

class Demande extends Component {
  constructor(props) {
    super(props)
    this.state = { edition: undefined, loading: true, family: [], condidatures: [] }
  }

  componentDidMount() {
    this.getActive()
  }

  getActive = () => {
    this.setState({ edition: undefined, loading: true, family: [], condidatures: [] }, () =>
      axios
        .get(getUrl('journeeSciences.myData'))
        .then(res =>
          this.setState({
            edition: res.data.success.active,
            loading: false,
            condidatures: res.data.success.condidatures,
            family: res.data.success.family,
          })
        )
        .catch(err => this.setState({ edition: undefined, loading: false }))
    )
  }

  render() {
    if (this.state.loading) return <Waiting />
    if (this.state.edition === undefined)
      return (
        <div className="card">
          <div className="card-body text-center">
            <h1>{getTrad('errors.noedition')}</h1>
          </div>
        </div>
      )

    return (
      <>
        <Details edition={this.state.edition} />
        <Condidatures
          refresh={this.getActive}
          condidatures={this.state.condidatures}
          family={this.state.family}
          condidats={this.state.family}
          prix={this.state.edition.prix}
        />
      </>
    )
  }
}
export default Demande
