import React, { Component } from 'react'

import Waiting from './Waiting'

function Thead({ keys, header }) {
  return (
    <thead>
      <tr>
        <th>N°</th>
        {keys.map((ele, key) => {
          return <th key={key}>{header[ele]}</th>
        })}
      </tr>
    </thead>
  )
}

function Tbody({ page, perPage, data, searching, keys, noData }) {
  let shown = []
  let start = (page - 1) * perPage
  let end = page * perPage
  for (var i = start; i < end; i++) if (data[i]) shown.push(data[i])

  if (searching) {
    return (
      <tbody>
        <tr>
          <td colSpan={keys.length + 1} className="text-center ">
            <Waiting />
          </td>
        </tr>
      </tbody>
    )
  } else if (shown.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={keys.length + 1} className="text-center ">
            {noData}
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {shown.map((e, k) => (
        <tr key={k}>
          <td>{start + k + 1}</td>
          {keys.map((ele, key) => (
            <td key={key}>{e[ele]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

export default class ShowTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      perPage: 15,
      paginationLength: 11,
      pagination: [],
    }
  }

  changePagination() {
    let paginationLength = this.state.paginationLength
    let showingPages = []
    let last = Math.floor(this.props.data.length / this.state.perPage)

    if (this.props.data.length / this.state.perPage - last > 0) {
      last += 1
    }

    let page = this.state.page
    if (last <= paginationLength) {
      for (let i = 0; i < last; i++) {
        showingPages.push(i + 1)
      }
    } else {
      showingPages.push(page)
      let before = page
      let after = page
      while (showingPages.length < paginationLength) {
        before--
        after++
        if (before > 0) {
          showingPages.unshift(before)
        }
        if (after <= last) {
          showingPages.push(after)
        }

        if (before <= 0 && after > last) {
          break
        }
      }
    }
    this.setState({ pagination: showingPages })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.length !== prevProps.data.length) {
      this.changePagination()
    }

    if (this.state.page !== prevState.page) {
      this.changePagination()
    }
  }

  renderPagination() {
    let last = Math.floor(this.props.data.length / this.state.perPage)
    if (this.props.data.length / this.state.perPage - last > 0) {
      last += 1
    }

    return (
      <ul className="pagination my-0">
        <li className="page-item">
          <a
            className="page-link"
            href="?page=1"
            onClick={e => {
              e.preventDefault()
              this.setState({ page: 1 })
            }}
          >
            &laquo;
          </a>
        </li>

        {this.state.pagination.map((e, k) => {
          let liClass = 'page-item'
          if (e === this.state.page) {
            liClass += ' active'
          }

          return (
            <li key={k} className={liClass}>
              <a
                className="page-link"
                href={`?page=${e}`}
                onClick={event => {
                  event.preventDefault()
                  this.setState({ page: e })
                }}
              >
                {e}
              </a>
            </li>
          )
        })}

        <li className="page-item">
          <a
            className="page-link"
            href={`?page=${last}`}
            onClick={e => {
              e.preventDefault()
              this.setState({ page: last })
            }}
          >
            &raquo;
          </a>
        </li>
      </ul>
    )
  }

  render() {
    const start = (this.state.page - 1) * this.state.perPage + 1
    const endPage = this.state.page * this.state.perPage
    return (
      <>
        <div className="px-3 mb-3">
          <div className="row">
            <div className="col">
              <nav>
                {start} - {this.props.data.length < endPage ? this.props.data.length : endPage} /{' '}
                {this.props.data.length}
              </nav>
            </div>
            <div className="col">{this.renderPagination()}</div>
          </div>
        </div>

        <table className="table table-striped">
          <Thead keys={this.props.keys} header={this.props.header} />
          <Tbody
            page={this.state.page}
            perPage={this.state.perPage}
            data={this.props.data}
            searching={this.props.searching}
            keys={this.props.keys}
            noData={this.props.noData}
          />
        </table>
      </>
    )
  }
}
