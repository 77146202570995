import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../lang'
import NumInput from '../../../components/Form/NumInput'
import TxtInput from '../../../components/Form/TxtInput'
import { getUrl } from '../../../Api'
import axios from 'axios'
import { fatalError, successToast, areYouSure } from '../../../helpers/sweetAlerts'

function Edit(props) {
  const [show, setShow] = useState(false)

  const [montant, setMontant] = useState(props.montant ? props.montant.toString() : '')
  const [montantAccepte, setMontantAccepte] = useState(
    props.montant_accepte ? props.montant_accepte.toString() : ''
  )

  const [montantEngagement, setMontantEngagement] = useState(
    props.montant_engagement ? props.montant_engagement.toString() : ''
  )

  const [engagement, setEngagement] = useState(props.engagement === 1)
  const [status, setStatus] = useState(props.status)
  const [obs, setObs] = useState(props.obs ? props.obs : '')

  const handleShow = () => {
    setShow(!show)
    setMontant(props.montant ? props.montant.toString() : '')
    setMontantAccepte(props.montant_accepte ? props.montant_accepte.toString() : '')
    setEngagement(props.engagement === 1)
    setStatus(props.status)
    setObs(props.obs ? props.obs : '')
    setMontantEngagement(props.montant_engagement ? props.montant_engagement.toString() : '')
  }

  const handleMontant = event => setMontant(event.target.value)
  const handleMontantAccepte = event => setMontantAccepte(event.target.value)
  const handleMontantEngagement = event => setMontantEngagement(event.target.value)
  const handleMontantStatus = event => setStatus(event.target.value)
  const handleObs = event => setObs(event.target.value)
  const handleEngagement = event => setEngagement(!engagement)

  const send = () => {
    const data = {
      montant: montant,
      montantAccepte: montantAccepte,
      montantEngagement: montantEngagement,
      engagement: engagement,
      status: status,
      obs: obs,
    }

    const url = getUrl('aidesociale.update', [props.id])

    areYouSure.fire().then(response => {
      if (response.value)
        axios
          .patch(url, data)
          .then(res => {
            successToast.fire({ title: getTrad('success.update.aide') })
            props.refresh()
            handleShow()
          })
          .catch(err => {
            fatalError()
            props.refresh()
            handleShow()
          })
    })
  }

  return (
    <>
      <button onClick={handleShow} className="btn btn-secondary btn-sm w-100">
        <i className="fas fa-edit" />
      </button>
      <Modal show={show} onHide={() => false}>
        <Modal.Header>
          <Modal.Title>{getTrad('aides.update', [props.id])}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <NumInput
            handler={handleMontant}
            value={montant}
            name="montant"
            label={getTrad('fileds.montant_demande')}
          />

          <NumInput
            handler={handleMontantAccepte}
            value={montantAccepte}
            name="montantAccepte"
            label={getTrad('fileds.montant_accepte')}
          />

          <div className="custom-control custom-switch pb-3">
            <input
              type="checkbox"
              checked={engagement}
              onChange={handleEngagement}
              className="custom-control-input"
              id="engagement_switch"
            />
            <label className="custom-control-label" htmlFor="engagement_switch">
              {getTrad('aides.engagement')}
            </label>
          </div>

          <NumInput
            handler={handleMontantEngagement}
            value={montantEngagement}
            name="montantEngagement"
            label={getTrad('fileds.montant_engagement')}
          />

          <TxtInput handler={handleObs} value={obs} name="obs" label={getTrad('fileds.obs')} />

          <div className="form-group">
            <label htmlFor={`status-${props.id}`}>{getTrad('fileds.status')}</label>
            <select
              onChange={handleMontantStatus}
              className="form-control"
              value={status}
              id={`status-${props.id}`}
            >
              {['N', 'A', 'R'].map((e, k) => (
                <option key={k} value={e}>
                  {getTrad(`aides.status.${e}`)}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col">
            <button className="btn btn-success w-100" onClick={send}>
              <i className="fas fa-check" /> {getTrad('commun.edit')}
            </button>
          </div>
          <div className="col">
            <button className="btn btn-danger w-100" onClick={handleShow}>
              <i className="fas fa-times" /> {getTrad('commun.close')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Edit
