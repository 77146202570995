import React, { useState } from 'react'
import { getTrad } from '../../../lang'
import axios from 'axios'
import { getUrl } from '../../../Api'
import Waiting from '../../../components/Waiting'
import { successToast, fatalError } from '../../../helpers/sweetAlerts'

function Local(props) {
  const randID = Math.floor(Math.random() * 1000)
  const { local, superId, supertype, id } = props

  let { value } = props

  let [sending, setSending] = useState(false)
  let [editing, setEditing] = useState(false)
  let [val, setVal] = useState(value ? value : '')

  let spanClass = val ? '' : 'text-muted'
  const showForm = () => setEditing(true)
  const hideForm = () => {
    setEditing(false)
    setVal(value)
  }

  const refresh = () => props.refresh()
  const handle = event => setVal(event.target.value)

  const SendBtn = () => {
    const sendForm = () => {
      setSending(true)
      const action = id ? axios.patch : axios.post
      const url = id ? getUrl('local.update', [id]) : getUrl('local.create')
      const data = id
        ? { value: val }
        : { value: val, local: local, superid: superId, supertype: supertype }
      action(url, data)
        .then(res => {
          setSending(false)
          successToast.fire()
          value = res.data.success.value
          hideForm()
          refresh()
        })
        .catch(err => {
          setSending(false)
          fatalError()
          hideForm()
          refresh()
        })
    }

    const props = {
      className: 'btn btn-success btn-sm',
      type: 'button',
      disabled: sending,
      onClick: sendForm,
    }

    const inner = sending ? <Waiting /> : <i className="fas fa-check" />

    return <button {...props}>{inner}</button>
  }

  let inner = value ? value : getTrad('commun.dbToAdd')

  if (editing) {
    return (
      <div className="input-group">
        <input
          type="text"
          value={val}
          onChange={handle}
          className="form-control form-control-sm"
          aria-describedby={`button-addon-${randID}`}
        />
        <div className="input-group-append" id={`button-addon-${randID}`}>
          <button className="btn btn-danger btn-sm" type="button" onClick={hideForm}>
            <i className="fas fa-times" />
          </button>
          <SendBtn {...props} />
        </div>
      </div>
    )
  }

  return (
    <span className={spanClass} onDoubleClick={showForm}>
      {inner}
    </span>
  )
}

export default Local
