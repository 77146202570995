import React from 'react'
import InputText from '../../../../components/Form/InputText'
import { getTrad } from '../../../../lang'

function Filter(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <InputText
            label={getTrad('fileds.matricule')}
            value={props.matricule}
            name="matricule"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('fileds.matricule_cnrps')}
            value={props.matricule_cnrps}
            name="matricule_cnrps"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('fileds.full_name')}
            value={props.full_name}
            name="full_name"
            handler={props.handler}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
