import navSU from './navSU.js'
import navAdmin from './navAdmin.js'
import navMag from './navMag.js'

let navData = {
  ROLE_SUPER_USER: navSU,
  ROLE_ADMINISTRATOR: navAdmin,
  ROLE_MAGISTRAT: navMag,
  ROLE_ADMINISTRATOR_LOCAL: navAdmin,
}
export default navData
