import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { beforeToday, inThisYear, validDate } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import { calcAdd } from '../helpers'

function useBsInfo() {
  const [matBs, setMatBs] = useState('')
  const [dateBs, setDateBs] = useState('')
  const [matAd, setMatAd] = useState('')
  const [desc, setDesc] = useState('')
  const [complement, setComplement] = useState('')
  const [prestataire, setPrestataire] = useState('')
  const calcRef = useRef()

  const [errors, setErrors] = useState({
    matBs: null,
    dateBs: null,
    matAd: null,
    prestataire: null,
  })

  const reset = () => {
    setMatBs('')
    setDateBs('')
    setMatAd('')
    setDesc('')
    setComplement('')
    setPrestataire('')
    calcRef.current.value = ''
  }

  const {
    mutate: getFamilyMembers,
    data: listPrestataires = [],
    isLoading: loadingListPrestataires,
  } = useMutation(
    () => (matAd ? axios.get('/adherants/family/' + matAd).then(res => res.data.success) : []),
    {
      onError: () => setErrors({ ...errors, matAd: ['notfound'] }),
      onSuccess: () => setErrors({ ...errors, matAd: null, prestataire: null }),
    }
  )

  useEffect(() => {
    getFamilyMembers()
  }, [getFamilyMembers, matAd])

  useEffect(() => {
    setPrestataire('')
  }, [matAd])

  const validate = () => {
    const matBsError = matBs === '' ? [getTrad('errors.invalid.required')] : null
    const matAdError = matAd === '' ? [getTrad('errors.invalid.required')] : errors.matAd
    const dateBsError =
      dateBs === ''
        ? [getTrad('errors.invalid.required')]
        : !validDate(dateBs)
        ? [getTrad('errors.invalid.date')]
        : !beforeToday(dateBs)
        ? [getTrad('errors.date.beforeToday')]
        : !inThisYear(dateBs)
        ? [getTrad('errors.date.afterOneYear')]
        : null

    setErrors({
      matBs: matBsError,
      dateBs: dateBsError,
      matAd: matAdError,
      prestataire: null,
    })

    return matBsError === null && matAdError === null && dateBsError === null
  }

  return {
    validate,
    matBs: {
      label: getTrad('bs.numBs'),
      name: 'mat_bultin',
      value: matBs,
      handler: data => setMatBs(data.target.value),
      error: errors.matBs,
    },
    dateBs: {
      label: getTrad('bs.date_soin'),
      name: 'date_soin',
      value: dateBs,
      handler: data => setDateBs(data.target.value),
      error: errors.dateBs,
    },
    matAd: {
      label: getTrad('bs.mat_adherant'),
      name: 'mat_adherant',
      value: matAd,
      handler: data => setMatAd(data.target.value),
      error: errors.matAd,
    },
    desc: {
      label: getTrad('bs.description'),
      name: 'description',
      value: desc,
      handler: data => setDesc(data.target.value),
    },
    complement: {
      label: getTrad('bs.complement'),
      name: 'complement',
      handler: data => setComplement(data.target.value),
      value: complement,
    },
    complementCalc: {
      type: 'text',
      className: 'form-control w-100 form-control-sm mb-2',
      ref: calcRef,
      onChange: event => {
        setComplement(calcAdd(event.target.value).toString())
      },
    },
    prestatire: {
      loading: loadingListPrestataires,
      prestatires: listPrestataires,
      error: errors.prestataire,
      value: prestataire,
      handler: data => setPrestataire(data.target.value),
    },
    reset,
  }
}
export default useBsInfo
