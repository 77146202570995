export const fr = {
  newAide: 'Nouvelle aide sociale',
  updateAide: 'Modifier « {} »',
  name: 'Nom',
  created_at: 'Date de création',
  number: 'Nombre des bénéficiaires',
  nb_demande: 'Nombre de demandes non traitées',
  editmodel: '{} {} #{}',
  forfait: 'Aide forfaitaire',
  montant: 'Montant',
  number_adherants: 'Nombre des adhérents',
  delete: 'Supprimer « {} »',
  nonforfait: 'Aide non forfaitaire',
  name_aide: "Type de l'aide",
  generate: 'Générer un ordre de versement',
  iCantApplay: 'Vous ne pouvez pas demander une nouvelle aide',

  // nb_adherants: 'Nombre d\'adhéra',
  nb_benificieres: '',

  success: {
    add: 'Aide ajoutée avec succès',
    delete: 'Aide supprimée avec succès',
    addMember: 'Adhérent ajouté avec succès',
    accept: 'Demande acceptée avec succès',
    refuse: 'Demande refusée avec succès',
    generate: 'Génération effectuée avec succès',
    update: 'Aide modifiée avec succès',
  },

  date: 'Date',
  total: 'Total',
  details: 'Détails',
  status: {
    A: 'Acceptée',
    N: 'Non traitée',
    E: 'Refusée',
  },
  error: {
    deja: 'Cet adhérent est déja inscrit',
    notexists: "Cette matricule n'existe pas",
  },
  fileds: {
    details: 'Détails',
    montant: 'Montant',
    matricule: 'Matricule',
    matricule_cnrps: 'Matricule CNRPS',
    first_name: 'Nom',
    last_name: 'Prénom',
    depuis: 'Inscrit depuis',
    date_demande: 'Date de demande',
    nb_beneficiaire: 'Nombre de bénéficiaires',
    edit: 'Editer',
    show: 'Montrer',
    delete: 'Supprimer',
  },
}

export const ar = {
  newAide: 'اعانة اجتماعية جديدة',
  name: 'الاسم',
  created_at: 'تاريخ الانشاء',
  number: 'عدد المستفيدين',
  nb_demande: 'عدد الطلبات التي لم تتم معالجتها',
  editmodel: '{} {} #{}',
  success: {
    add: 'تمت إضافة المساعدة بنجاح',
    delete: 'تم حذف المساعدة بنجاح',
    addMember: 'تمت إضافة العضو بنجاح',
    accept: 'تم قبول الطلب بنجاح',
    refuse: 'تم رفض الطلب',
    update: 'تم تعديل المساعدة بنجاح',
  },
  error: {
    deja: 'تمّ تسجيل هذا العضو سابقا',
    notexists: 'رقم التسجيل غير موجود',
  },
  fileds: {
    matricule: 'رقم العضوية',
    matricule_cnrps: 'رقم تسجيل CNRPS',
    last_name: 'اللقب',
    first_name: 'الاسم',
    depuis: 'مسجّل منذ',
    date_demande: 'تاريخ الطلب',
    nb_beneficiaire: 'عدد المستفيدين',
    edit: 'تعديل',
    show: 'اظهار',
    delete: 'حذف',
  },
}

export const en = {
  newAide: 'New social aid',
  updateAide: 'Rectify « {} »',
  name: 'Name',
  created_at: 'Creation date',
  number: 'Number of beneficiaries',
  nb_demande: 'Number of unprocessed requests',
  editmodel: '{} {} #{}',
  forfait: 'Included aid',
  montant: 'Amount',
  number_adherants: ' Number of members',
  delete: 'Delete « {} »',
  nonforfait: 'Unincluded aid',
  name_aide: 'Kind of aid',
  generate: 'Generate a payment order',
  iCantApplay: 'You can not ask for new aid',
  success: {
    add: 'Aid added with success',
    delete: 'Aid deleted with success',
    addMember: 'Member added with success',
    accept: ' Request added with success',
    refuse: 'Request refused',
    generate: 'Generation performed with success',
    update: 'Aid rectified with success',
  },

  date: 'Date',
  total: 'Total',
  details: 'Details',
  status: {
    A: 'Accepted',
    N: 'untreated',
    E: 'Refused',
  },
  error: {
    deja: 'An already-registered member',
    notexists: 'inexisted number',
  },
  fileds: {
    montant: 'Amount',
    matricule: 'Membership',
    matricule_cnrps: 'CNRPS registration number',
    first_name: 'First name',
    last_name: 'Last name',
    depuis: 'Subscribed since',
    date_demande: 'Request date',
    nb_beneficiaire: 'Number of beneficiaries',
    edit: 'Edit',
    show: 'Show',
    delete: 'Delete',
  },
}
