import React from 'react'
import { getTrad } from '../../../../lang'
import InputText from '../../../../components/Form/InputText'

function RenderSearch(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <InputText
            label={getTrad('bs.matricule_adh')}
            handler={props.handler}
            value={props.searchMatAd}
            name="searchMatAd"
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.bs_number')}
            handler={props.handler}
            value={props.searchMatBs}
            name="searchMatBs"
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.bs_date')}
            handler={props.handler}
            value={props.searchDateBs}
            name="searchDateBs"
          />
        </div>
        <div className="col">
          <div className="form-group">
            <label className="d-block">{getTrad('bs.status')}</label>
            {['ET', 'T'].map((e, k) => (
              <div key={k} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={props.handler}
                  checked={props['check' + e]}
                  id={'check' + e}
                  name={'check' + e}
                  value={e}
                />
                <label className="form-check-label" htmlFor={'check' + e}>
                  {getTrad('bs.status_' + e)}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default RenderSearch
