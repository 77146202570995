import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../../Api'
import Export from '../../../../components/Export'
import { getTrad } from '../../../../lang'
import CardHeader from '../../../../components/CardHeader'
import ShowTable from '../../../../components/ShowTable'
import { showDate, showMoney } from '../../../../helpers'

const keys = ['number', 'full_name', 'mat_adh', 'mat_cnrps', 'sous_compte', 'montant']

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      liste_saisies: [],
      searching: true,
      number: '',
      date: '',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  clearData = (e, k) => ({
    number: k + 1,
    mat_adh: e.matricule,
    full_name: e.last_name + ' ' + e.first_name,
    mat_cnrps: e.matricule_cnrps,
    sous_compte: e.sous_compte,
    montant: showMoney(e.montant),
    date: showDate(e.date),

    details: (
      <Link to={`/credit/saisie/${e.id}`} className="btn btn-primary btn-sm">
        <i className="fas fa-eye" />
      </Link>
    ),
  })

  refresh = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.setState({ searching: true }, () => {
      axios.get(getUrl('saisie.show', [id])).then(res => {
        this.setState({
          searching: false,
          liste_saisies: res.data.success.items.map(this.clearData),
          number: res.data.success.id,
          date: showDate(res.data.success.created_at),
        })
      })
    })
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props
    const {
      match: {
        params: { id: oldId },
      },
    } = prevProps

    if (id !== oldId) this.refresh()
  }

  render() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`saisie.${keys[i]}`)
    }

    let showProps = {
      data: this.state.liste_saisies,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
      searching: this.state.searching,
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('pages.showsaisie', [this.state.number, this.state.date])}
          actions={
            <Export
              fileName={getTrad('pages.showsaisie', [this.state.number, this.state.date])}
              csvData={this.state.liste_saisies}
              title={getTrad('commun.export')}
              headers={header}
            />
          }
        />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}
export default Show
