import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { showDate } from '../../../../helpers'

function Element(props) {
  if (props.value === null) return <></>
  return (
    <div className="col-6 my-3">
      <b>{props.title} : </b>
      {props.value}
    </div>
  )
}

function Show(props) {
  const [show, setShow] = useState(false)

  let title =
    props.member.first_name +
    ' ' +
    props.member.last_name +
    ` (${getTrad(`type.${props.member.type}`)})`

  let infoperso = [
    { title: getTrad('fileds.last_name'), value: props.member.last_name },
    { title: getTrad('fileds.first_name'), value: props.member.first_name },
    { title: getTrad('fileds.birth_day'), value: showDate(props.member.birth_day) },
    { title: getTrad('fileds.sexe'), value: getTrad(`commun.sexe.${props.member.sexe}`) },
    { title: getTrad('fileds.type'), value: getTrad(`type.${props.member.type}`) },
  ]

  let travail = []
  if (props.member.lvl_studies) {
    travail.push({
      title: getTrad('fileds.lvl_studies'),
      value: getTrad(`lvl_studies.${props.member.lvl_studies}`),
    })
  }

  if (props.member.works) {
    travail.push({ title: getTrad('fileds.works'), value: getTrad('commun.yes') })
  } else {
    travail.push({ title: getTrad('fileds.works'), value: getTrad('commun.no') })
  }
  if (props.member.profession) {
    travail.push({ title: getTrad('fileds.profession'), value: props.member.profession })
  }

  let encharge = []
  if (props.member.is_supported) {
    encharge.push({ title: getTrad('fileds.is_supported'), value: getTrad('commun.yes') })
  } else {
    encharge.push({ title: getTrad('fileds.is_supported'), value: getTrad('commun.no') })
  }

  if (props.member.tuteur) {
    let e = props.member.tuteur
    encharge.push({ title: getTrad('fileds.tuteur'), value: getTrad(`commun.tuteur.${e}`) })
  }

  return (
    <>
      <button className="btn btn-primary btn-sm" onClick={() => setShow(!show)}>
        <i className="far fa-eye"></i>
      </button>
      <Modal size="lg" show={show} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>{getTrad('fileds.infoperso')}</h2>
          <div className="row">
            {infoperso.map((e, k) => (
              <Element key={k} {...e} />
            ))}
          </div>

          <h2>{getTrad('fileds.travail')}</h2>
          <div className="row">
            {travail.map((e, k) => (
              <Element key={k} {...e} />
            ))}
          </div>

          <h2>{getTrad('fileds.encharge')}</h2>
          <div className="row">
            {encharge.map((e, k) => (
              <Element key={k} {...e} />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default Show
