import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import { userStore } from '../../../store/userStore'

import Indivi from './Indivi'
import Multiple from './Multiple'

export default function Credit(props) {
  const { user } = userStore()

  if (isNotAdmin(user.role.role)) {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route exact strict path={`/sms/multiple`}>
        <Multiple {...props} path="/sms" />
      </Route>

      <Route exact strict path={`/sms/individuel`}>
        <Indivi {...props} path="/sms" />
      </Route>

      <Route>
        <Redirect to="/sms" />
      </Route>
    </Switch>
  )
}
