import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import InputMounth from '../../../../components/Form/InputMounth'
import NumInput from '../../../../components/Form/NumInput'
import { validDate, showDate, convertDate } from '../../../../helpers'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import { areYouSure, successMsg, fatalError } from '../../../../helpers/sweetAlerts'
import Waiting from '../../../../components/Waiting'
import moment from 'moment'

const today = new Date()

class AddCession extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      sending: false,
      montant: '',
      date: '',
    }
  }

  InputHandler = event => this.setState({ [event.target.name]: event.target.value })

  handleShow = () =>
    this.setState({
      show: !this.state.show,
      montant: this.props.montant,
      date: moment().format('MM-YYYY'),
    })

  isNotValid = () =>
    this.state.sending || isNaN(this.state.montant) || !validDate('01-' + this.state.date)

  send = event => {
    event.preventDefault()
    if (this.isNotValid()) return
    areYouSure.fire().then(response => {
      if (response.isConfirmed) {
        this.setState({ sending: true }, () => {
          const data = { montant: this.state.montant, date: convertDate('01-' + this.state.date) }
          axios
            .post(getUrl('parent.saisie.store', [this.props.id]), data)
            .then(res =>
              this.setState(
                { sending: false, show: false, montant: this.props.montant, date: showDate(today) },
                () => {
                  successMsg(getTrad('parent.success.addCession'))
                  this.props.refresh()
                }
              )
            )
            .catch(() => this.setState({ sending: false }, fatalError))
        })
      }
    })
  }

  render() {
    return (
      <>
        <button onClick={this.handleShow} className="btn btn-sm btn-success">
          <i className="fas fa-plus" />
        </button>

        <Modal show={this.state.show} onHide={() => null}>
          <form onSubmit={this.send}>
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <NumInput
                handler={this.InputHandler}
                value={this.state.montant}
                name="montant"
                label={getTrad('fileds.montant')}
              />
              <InputMounth
                handler={this.InputHandler}
                value={this.state.date}
                name="date"
                label={getTrad('fileds.date')}
              />
            </Modal.Body>

            <Modal.Footer>
              <button type="submit" disabled={this.isNotValid()} className="btn btn-success">
                {this.state.sending ? (
                  <Waiting />
                ) : (
                  <>
                    <i className="fas fa-plus mr-2" />
                    {getTrad('commun.add')}
                  </>
                )}
              </button>
              <button
                type="reset"
                disabled={this.state.sending}
                onClick={this.handleShow}
                className="btn btn-danger"
              >
                <i className="fas fa-times mr-2" />
                {getTrad('commun.close')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}
export default AddCession
