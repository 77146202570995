import React from 'react'

import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import Show from './Show'
import Edit from './Edit'
import { userStore } from '../../../store/userStore'

export default function Admin(props) {
  let { path } = useRouteMatch()
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }
  if (isNotAdmin(role)) {
    return (
      <Switch>
        <Route exact strict path={path}>
          <Show />
        </Route>
        <Route exact path={`${path}/edit`}>
          <Edit />
        </Route>
        <Route>
          <Redirect to={path} />
        </Route>
      </Switch>
    )
  }
  return <Redirect to="/" />
}
