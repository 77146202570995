import React, { Component, useEffect, useState } from 'react'

import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'

import { successToast, fatalError, areYouSure } from '../../../../helpers/sweetAlerts'

const bcls = { N: 'btn-primary', R: 'btn-danger', A: 'btn-success' }
const btns = { N: ['A', 'R'], R: ['N', 'A'], A: ['N', 'R'] }

function RenderInner(props) {
  const [img, setImage] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .post(getUrl('files.get'), { path: props.proof })
      .then(res => {
        setLoading(false)
        setImage(res.data.success)
      })
      .catch(() => {
        setLoading(false)
        setImage('')
      })
  }, [props.proof])

  if (props.loading || loading) return <Waiting />
  if (loading === false && img === '')
    <div className="alert alert-danger text-center mb-0">{getTrad('errors.loadingFile')}</div>
  return <img className="w-100" src={img} alt={props.pres_full_name} />
}

function RenderBtns(props) {
  let status = props.status

  return (
    <Modal.Footer>
      <div className="row w-100">
        {btns[status].map((e, k) => (
          <div key={k} className="col">
            <button className={`btn w-100 ${bcls[e]}`} onClick={() => props.update(e)}>
              {getTrad(`condidature.actionsTxt.${e}`)}
            </button>
          </div>
        ))}
      </div>
    </Modal.Footer>
  )
}

export default class Details extends Component {
  constructor(props) {
    super(props)
    this.state = { ...props, show: false, loading: true, proof: null }
  }

  componentDidMount() {
    this.setState({ loading: true }, this.refresh)
  }

  switchShown = () => {
    this.setState({ show: !this.state.show })
  }

  refresh = () => {
    this.setState({ ...this.props, show: false, loading: true, proof: null }, () => {
      axios.get(getUrl('journeeSciences.condidat.proof', [this.state.id])).then(res => {
        this.setState({ proof: res.data.success, loading: false })
      })
    })
  }

  update = status => {
    areYouSure.fire().then(result => {
      if (result.value) {
        axios
          .post(getUrl('journeeSciences.condidat.update', [this.state.id, status]))
          .then(res => {
            successToast.fire({ title: getTrad(`success.condidature.${status}`) })
            this.props.refresh()
          })
          .catch(err => {
            fatalError()
            this.props.refresh()
          })
      }
    })
  }

  componentDidUpdate() {
    if (this.props.id !== this.state.id) this.setState({ loading: true }, this.refresh)
  }

  render() {
    return (
      <>
        <button className="btn btn-primary m-0 w-100 btn-sm" onClick={this.switchShown}>
          <i className="fas fa-eye"></i>
        </button>
        <Modal show={this.state.show} onHide={this.switchShown} dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {getTrad('condidature.condidature', [this.state.pres_full_name])}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <b>{getTrad('condidature.prix_name')} :</b> {this.props.prix_name}
            </div>
            <RenderInner {...this.state} />
          </Modal.Body>

          <RenderBtns {...this.props} update={this.update} />
        </Modal>
      </>
    )
  }
}
