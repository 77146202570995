import React from 'react'
import InputText from '../../../../components/Form/InputText'
import { getTrad } from '../../../../lang'
function Filter(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <InputText
            label={getTrad('bs.bs_number')}
            value={props.num_bs}
            name="num_bs"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.matricule_adh')}
            value={props.matricule}
            name="matricule"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.full_name')}
            value={props.adherant}
            name="adherant"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.prestataire')}
            value={props.presta}
            name="presta"
            handler={props.handler}
          />
        </div>

        <div className="col">
          <InputText
            label={getTrad('bs.bs_date')}
            value={props.date_bs}
            name="date_bs"
            handler={props.handler}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
// ={this.state.matricule}
// ={this.state.adherant}
// ={this.state.presta}
// ={this.state.num_bs}
// ={this.state.date_bs}
// handler={this.handleInput}
