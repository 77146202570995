import React, { Component } from 'react'
import { errorMsg } from '../../../helpers/sweetAlerts'
export default class Indivi extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  send() {
    errorMsg(this.props.local.errors.noSMS)
  }

  render() {
    let etabs = [
      'Ariana',
      'Béja',
      'Ben',
      'Arous',
      'Bizerte',
      'Gabès',
      'Gafsa',
      'Jendouba',
      'Kairouan',
      'Kesrine',
      'Kébili',
      'Le',
      'Kef',
      'Mahdia',
      'La',
      'Manouba',
      'Médenine',
      'Monastir',
      'Nabeul',
      'Sfax',
      'Sidi',
      'Bouzid',
      'Siliana',
      'Sousse',
      'Tataouine',
      'Tozeur',
      'Tunis',
      'Zaghouan',
    ]
    return (
      <div className="col-md-6 mx-auto">
        <div className="card ">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h2 className="card-title">Envoie de SMS</h2>
              </div>
            </div>
          </div>
          <div className="card-body">
            <table className="w-100">
              <tbody>
                <tr className="border-bottom">
                  <th>Catégorie :</th>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Judiciaire</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Administratif</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Financière</label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Grade :</th>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Grade 1</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Grade 2</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Grade 3</label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Etablissement :</th>
                  <td>
                    {etabs.map((e, k) => (
                      <div key={k} className="form-check">
                        <input className="form-check-input" type="checkbox" />
                        <label className="form-check-label">{e}</label>
                      </div>
                    ))}
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Status :</th>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Magistrat actif</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Magistrat inactif</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Magistrat en détachement</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">
                        Magistrat en congé maladie longue durée
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Magistrat en retraite</label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Situation Familiale :</th>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Célibataire</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Mariée</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Divorcé</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Veuf</label>
                    </div>
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Sexe :</th>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Male</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">Femme</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="form-group mt-5">
              <label htmlFor="message">
                Message{' '}
                <span className="" id="lenSMS">
                  {' '}
                  (0/160){' '}
                </span>
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <button
              onClick={() => {
                this.send()
              }}
              className="btn-sm w-100 btn btn-success"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    )
  }
}
