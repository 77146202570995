import React from 'react'
import { getTrad } from '../../../../lang'
import { showDate, showMoney } from '../../../../helpers'

function Inner(props) {
  if (props.data.length === 0) return <></>

  return (
    <tbody>
      {props.data.map((e, k) => (
        <tr key={k}>
          <td>{showDate(e.created_at)}</td>
          <td>{showMoney(e.montant)}</td>
          <td>{e.montant_accepte ? showMoney(e.montant_accepte) : ''}</td>
          <td>{e.motif}</td>
          <td>{e.obs}</td>
          <td>{getTrad(`aides.status.${e.status}`)}</td>
          <td>{showDate(e.updated_at)}</td>
        </tr>
      ))}
    </tbody>
  )
}

export default function Liste(props) {
  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{getTrad('pages.myAides')}</h2>
      </div>
      <div className="card-body p-0">
        <table className="table table-striped table-hover mb-0">
          <thead>
            <tr>
              <th>{getTrad('aides.mag.created_at')}</th>
              <th>{getTrad('aides.mag.montant')}</th>
              <th>{getTrad('aides.mag.montant_accepte')}</th>
              <th>{getTrad('aides.mag.motif')}</th>
              <th>{getTrad('aides.mag.obs')}</th>
              <th>{getTrad('aides.mag.status')}</th>
              <th>{getTrad('aides.mag.updated_at')}</th>
            </tr>
          </thead>
          <Inner data={props.listeDemande} />
        </table>
      </div>
    </div>
  )
}
