import { adherantA } from './items/adherant'
import { bsA } from './items/bs'
import { creditA } from './items/credit'
import { aideA } from './items/aide'
import { scienceA } from './items/science'
import { smsA } from './items/sms'
import { aide_socialeA } from './items/aideSociale'
import { etablissementsA } from './items/etablissements'
import { parentsA } from './items/parents'

let navAdmin = [
  adherantA,
  bsA,
  creditA,
  parentsA,
  aideA,
  aide_socialeA,
  scienceA,
  smsA,
  etablissementsA,
]

export default navAdmin
