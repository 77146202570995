import React from 'react'
import { getTrad } from '../../../../../lang'
import InputText from '../../../../../components/Form/InputText'
import InputSelect from '../../../../../components/Form/InputSelect'

function LvlStudies(props) {
  let data = [
    { value: '1', text: getTrad('lvl_studies.1') },
    { value: '2', text: getTrad('lvl_studies.2') },
    { value: '3', text: getTrad('lvl_studies.3') },
    { value: '4', text: getTrad('lvl_studies.4') },
    { value: '5', text: getTrad('lvl_studies.5') },
  ]
  if (props.member.type !== '5') return <></>

  return (
    <div className="col-6">
      <InputSelect
        handler={props.update}
        name="lvl_studies"
        value={props.member.lvl_studies}
        data={data}
        label={getTrad('fileds.lvl_studies')}
      />
    </div>
  )
}

function Works(props) {
  let data = [
    { value: '1', text: getTrad('commun.yes') },
    { value: '0', text: getTrad('commun.no') },
  ]
  return (
    <div className="col-6">
      <InputSelect
        handler={props.update}
        name="works"
        value={props.member.works}
        data={data}
        label={getTrad('fileds.works')}
      />
    </div>
  )
}

function Profession(props) {
  if (props.member.works !== '1') return <></>
  return (
    <div className="col-6">
      <InputText
        handler={props.update}
        name="profession"
        value={props.member.profession}
        label={getTrad('fileds.profession')}
      />
    </div>
  )
}

export default function Travail(props) {
  if (props.member.type !== '') {
    return (
      <fieldset id="FieldSetInfoPerso">
        <legend>{getTrad('fileds.travail')}</legend>
        <div className="row">
          <LvlStudies {...props} />
          <Works {...props} />
          <Profession {...props} />
        </div>
      </fieldset>
    )
  }

  return <></>
}
