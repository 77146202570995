export const fr = {
  page: {
    saisie: 'Cessions sur salaire',
    detailsSaisie: 'Détails de la cession N°{}',
    editSaisie: 'Modification de la cession N°{}',
    list: 'Liste des adhérents avec des parents-à-charge',

    list_active: 'Liste des cessions parents-à-charge actives',
    list_demandes: 'Liste des demandes de cessions parents-à-charge',
    list_finis: 'Liste des cessions parents-à-charge soldées',
    list_rejet: 'Liste des cessions parents-à-charge rejetées',
    list_importation: 'Liste des demandes de cessions parents-à-charge par importation',
  },

  cessions: {
    add: 'Ajouter une cession au contrat de {} N°{}',
  },

  show: {
    title: 'Contrat du parent de {}',
  },
  edit: {
    title: 'Modifier le contrat de {} N°{}',
  },
  status: {
    A: 'Actives',
    D: 'Demandes',
    F: 'Soldées',
    R: 'Rejetées',
    I: 'Importation',
  },

  nb_cessions: 'Nombre de cessions',
  montant_cessions: 'Montant de cessions',
  nb_bss: 'Nombre de bulletins',
  montant_bss: 'Montant des bulletins',
  nb_inscrit: 'Nombre de parents inscrits',

  success: {
    addCession: 'Cession ajoutée avec succès',
    editSaisie: 'Modification effectuée avec succès',
    delete: 'Cession effacée avec succès',
    deleteParent: 'Elément effacé avec succès',
  },
}

export const ar = {
  page: {
    saisie: 'تحويلات اﻷجور',
    detailsSaisie: 'تفاصيل التّحويلات رقمْ {}',
    editSaisie: 'تعديل التّحويل رقمْ {}',
    list: 'قائمة الأعضاء ذوي اﻵباء المعالين',

    list_active: ' قائمة الأعضاء ذوي اﻵباء المعالين الجاري تفعيلها',
    list_demandes: 'قائمة طلبات التحويلات للأولياء المعالين',
    list_finis: 'قائمة التحويلات المتعلّقة باﻵباء المغلقة',
    list_rejet: 'قائمة التحويلات المتعلّقة باﻵباء المرفوضة',
    list_importation: 'قائمة طلبات التّحويلات الخاصّة باﻵباء عن طريق الاستيراد',
  },

  cessions: {
    add: 'أضف تحويل إلى عقد {} رقمْ {}',
  },

  show: {
    title: 'عقد وليّ {} ',
  },
  edit: {
    title: 'تعديل عقد {} رقمْ {}',
  },
  status: {
    A: 'النّاشطة',
    D: 'المطالب',
    F: 'مغلقَة',
    R: 'المرفوضة',
    I: 'استيراد',
  },

  nb_cessions: 'عدد التّحويلات',
  montant_cessions: 'مبلغ التّحويلات',
  nb_bss: 'عدد بطاقات العلاج',
  montant_bss: 'مبلغ بطاقات العلاج',
  nb_inscrit: 'عدد اﻵباء المسجّلين',

  success: {
    addCession: 'تمت إضافة التّحويل بنجاح',
    editSaisie: 'تم التعديل بنجاح',
    delete: 'تم حذف التّحويل بنجاح',
    deleteParent: 'تم حذف العنصر المختار بنجاح',
  },
}

export const en = {
  page: {
    saisie: 'Salary transferts or cessions',
    detailsSaisie: 'Details of the cession N°{}',
    editSaisie: 'Modification of the cession N°{}',
    list: 'List of members with dependent parents',

    list_active: 'List of active cessions with dependent parents',
    list_demandes: 'List of parent-dependent cession requests',
    list_finis: 'List of closed parent-dependent cessions',
    list_rejet: 'List of rejected parent-dependent cessions',
    list_importation: 'List of parent-dependent cession requests by import',
  },

  cessions: {
    add: 'Add a cession to the contract {} N°{}',
  },

  show: {
    title: 'Parent contract of {}',
  },
  edit: {
    title: 'Rectify contract of {} N°{}',
  },
  status: {
    A: 'Active',
    D: 'Requests',
    F: 'Closed',
    R: 'Rejected',
    I: 'Import',
  },

  nb_cessions: 'Number of cessions',
  montant_cessions: 'Amount of cessions',
  nb_bss: 'Number of care bulletins',
  montant_bss: 'Amount of care bulletins',
  nb_inscrit: 'Number of subscribed parents',

  success: {
    addCession: 'Successfully added cession',
    editSaisie: 'Successfully done modification',
    delete: 'Successfully deleted cession',
    deleteParent: 'Successfully deleted element',
  },
}
