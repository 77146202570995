import React, { useState } from 'react'
import { getTrad } from '../../../../lang'
import { showMoney, showDate } from '../../../../helpers'
import Waiting from '../../../../components/Waiting'

function ShowTable(props) {
  const size = 3
  if (props.loading)
    return (
      <tbody>
        <tr>
          <td className="text-center" colSpan={size}>
            <Waiting />
          </td>
        </tr>
      </tbody>
    )

  if (props.list.length === 0)
    return (
      <tbody>
        <tr>
          <td className="text-center text-muted" colSpan={size}>
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.list.map((e, k) => (
        <tr>
          <td>{e.sous_compte}</td>
          <td>{showDate(e.date)}</td>
          <td>{showMoney(e.montant)}</td>
        </tr>
      ))}
    </tbody>
  )
}

function Prelevements(props) {
  const [annee, setAnnee] = useState(new Date().getFullYear())

  const data = props.list.filter(e => new Date(e.date).getFullYear() === annee)

  const annees = [...new Set(props.list.map(e => new Date(e.date).getFullYear()))].sort(
    (a, b) => a < b
  )
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h3 className="card-title">{getTrad('pages.parent.cession')}</h3>
      </div>
      <div className="card-body p-0">
        <nav className="nav nav-tabs">
          {annees.map((e, k) => (
            <button
              className={`nav-link${annee === e ? ' active' : ' qsd'}`}
              onClick={() => setAnnee(e)}
            >
              {e}
            </button>
          ))}
        </nav>

        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>{getTrad('fileds.sous_compte')}</th>
              <th>{getTrad('fileds.montant')}</th>
              <th>{getTrad('fileds.date')}</th>
            </tr>
          </thead>
          <ShowTable {...props} list={data} />
          <tfoot>
            <tr>
              <th>{getTrad('commun.total')}</th>
              <td>{data.length}</td>
              <td>{showMoney(data.reduce((a, b) => a + b.montant, 0))}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default Prelevements
