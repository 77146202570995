import axios from 'axios'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../../Api'
import ShowTable from '../../../../components/ShowTable'
import { showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'

class Versements extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      list: [],
    }
  }

  cleanData = e => ({
    ...e,
    date: showDate(e.date),
    forfait: getTrad(`commun.boolean.${e.forfait ? 'oui' : 'non'}`),
    total: showMoney(e.total),
    details: (
      <Link className="btn btn-primary btn-sm" to={`/aide-sociale/versements/${e.id}`}>
        <i className="fas fa-eye"></i>
      </Link>
    ),
  })

  filter = e => true

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    axios
      .get(getUrl('aide_sociale.versements'))
      .then(res => this.setState({ list: res.data.success.map(this.cleanData) }))
      .catch(err => this.setState({ list: [] }))
  }

  render() {
    let keys = [
      'date',
      'name_aide',
      'number_adherants',
      'number',
      'forfait',
      'montant',
      'total',
      'details',
    ]

    let header = {}

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`aidesociale.${k}`)
    }

    let showProps = {
      data: this.state.list.filter(this.filter),
      keys: keys,
      searching: this.state.loading,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }

    return (
      <div className="card">
        <div className="card-header">
          <h3>{getTrad('navbar.aide_sociale_versements')}</h3>
        </div>
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}

export default Versements
