import React from 'react'
import { getTrad } from '../../../../lang'

function RenderHeader({ bordId }) {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">
            {getTrad('pages.DetailsBord')} {bordId}
          </h2>
        </div>
      </div>
    </div>
  )
}
export default RenderHeader
