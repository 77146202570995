import Axios from 'axios'
import React, { Component } from 'react'
import { getUrl } from '../../../../Api'
import ShowTable from '../../../../components/ShowTable'
import { inString, showDate, showMoney } from '../../../../helpers'
import { fatalError } from '../../../../helpers/sweetAlerts'
import { getTrad } from '../../../../lang'
import Filter from './Filter'
import Details from './Details'

function Header() {
  return (
    <div className="card-header">
      <h3 className="card-title">{getTrad('navbar.aide_sociale_historique')}</h3>
    </div>
  )
}

class Historique extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      list: [],
      matricule: '',
      matricule_cnrps: '',
      full_name: '',
    }
  }

  cleanDetails = e => ({
    ...e,
    date: showDate(e.date),
    forfait: getTrad(`aidesociale.${e.forfait ? '' : 'non'}forfait`),
    montant: showMoney(e.montant),
    total: showMoney(e.total),
  })

  cleanData = e => ({
    ...e,
    total: showMoney(e.total),
    details: <Details name={e.full_name} data={e.details.map(this.cleanDetails)} />,
  })

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      Axios.get(getUrl('aide_sociale.historique'))
        .then(res => this.setState({ list: res.data.success.map(this.cleanData), loading: false }))
        .catch(() => this.setState({ list: [], loading: false }, fatalError))
    })
  }

  handleFilter = e => this.setState({ [e.target.name]: e.target.value })

  filter = e => {
    if (!inString(this.state.matricule, e.matricule)) return false
    if (!inString(this.state.matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.full_name, e.full_name)) return false
    return true
  }

  render() {
    let keys = ['matricule', 'matricule_cnrps', 'full_name', 'nb_versement', 'total', 'details']

    let header = {}

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`fileds.${k}`)
    }

    let showProps = {
      data: this.state.list.filter(this.filter),
      keys: keys,
      searching: this.state.loading,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }

    return (
      <div className="card">
        <Header />
        <Filter
          matricule={this.state.matricule}
          matricule_cnrps={this.state.matricule_cnrps}
          full_name={this.state.full_name}
          handler={this.handleFilter}
        />
        <ShowTable {...showProps} />
      </div>
    )
  }
}

export default Historique
