import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import ListHeader from './ListHeader'
import ListBody from './ListBody'
import Delete from './Delete'
import Filter from './Filter'
import { showDate, showMoney } from '../../../../helpers'
import { inString } from '../../../../helpers'
class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      listBS: [],
      matricule: '',
      adherant: '',
      presta: '',
      num_bs: '',
      date_bs: '',
    }
  }

  cleanData = e => {
    return {
      ...e,
      bs_date: showDate(e.bs_date),
      depenses: showMoney(e.depenses),
      estimation: showMoney(e.estimation),
      action: (
        <div className="btn-group">
          <Link to={`/bultin-soins/${e.bs_number}/edit`} className="btn btn-secondary btn-sm">
            <i className="far fa-edit"></i>
          </Link>
          <Delete {...e} refresh={this.refresh} />
        </div>
      ),
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('bs.getNew'))
        .then(res =>
          this.setState({ listBS: res.data.success.map(this.cleanData), loading: false })
        )
        .catch(() => this.setState({ listBS: [], loading: false }))
    })
  }

  filter = e => {
    if (!inString(this.state.matricule, e.matricule_adh)) return false
    if (!inString(this.state.adherant, e.full_name)) return false
    if (!inString(this.state.presta, e.prestataire)) return false
    if (!inString(this.state.num_bs, e.bs_number)) return false
    if (!inString(this.state.date_bs, e.bs_date)) return false

    return true
  }

  handleInput = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="card">
        <ListHeader />
        <Filter
          matricule={this.state.matricule}
          adherant={this.state.adherant}
          presta={this.state.presta}
          num_bs={this.state.num_bs}
          date_bs={this.state.date_bs}
          handler={this.handleInput}
        />
        <ListBody loading={this.state.loading} data={this.state.listBS.filter(this.filter)} />
      </div>
    )
  }
}

export default List
