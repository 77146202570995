export const fr = {
  deadline: 'Date limite de la remise de dossier',
  date: "Date de l'événement",
  edition: "Nom de l'édition",
  listePrix: 'Liste des prix',
  prix: 'Prix',
  preuve: 'Justificatif',
  newCondidat: 'Ajouter une candidature',
  preuvede: 'Justificatif de {}',
  noCondidat: 'Pas de candidats disponibles',
  full_name: 'Nom et prénom',
  condidat: 'Candidat',
  date_con: 'Date de candidature',
  nb_condidats_acceptee: 'Nombre de candidats acceptés',
  nb_condidats_non_trait: 'Nombre de candidats non traités',
  nb_condidats_refusee: 'Nombre de candidats refusés',
  nb_condidats_total: 'Nombre total de candidats',
  total_prix: 'Total des prix',
  niveauInput: 'Niveau',
  prixInput: 'Valeur des prix',

  successCondiature: 'Candidature envoyée avec succès',
  sure: {
    delete: "Êtes-vous sûr(e) de supprimer le prix « {} » de l'édition « {} » ?",
  },
  condidatures: 'Candidatures',
  myCondidature: 'Mes candidatures',
}

export const ar = {
  deadline: 'التّاريخ النهائي لتقديم الملف',
  date: 'تاريخ الحدث',
  edition: 'اسم الإصدار',
  listePrix: 'قائمة الجوائز',
  prix: 'الجوائز',
  preuve: 'الإثبات',
  newCondidat: 'إضافة ترشّح',
  preuvede: 'إثبات {}',
  noCondidat: 'ليس لديك مترشحين متاحين',
  full_name: 'الإسم واللقب',
  condidat: 'المترشح',
  date_con: 'تاريخ الترشّح',
  nb_condidats_acceptee: 'قبل',
  nb_condidats_non_trait: 'لم يعالج بعد',
  nb_condidats_refusee: 'رُفض',
  nb_condidats_total: 'مجموع التّرشّحات',
  niveauInput: 'المستوى',
  prixInput: 'قيمة الجائزة',
  successCondiature: 'تم إرسال الترشّح بنجاح',
  sure: {
    delete: 'هل أنت متأكد من إزالة الجائزة "{}" من الإصدار "{}"',
  },
  condidatures: 'التّرشّحات',
  myCondidature: 'ترشّحاتي',
}

export const en = {
  deadline: 'Deadline for submission',
  date: 'Event date',
  edition: 'Edition name',
  listePrix: 'Prizes` list',
  prix: 'Prize',
  preuve: 'Proof',
  newCondidat: 'Add application',
  preuvede: 'Proof of {}',
  noCondidat: 'No available candidates',
  full_name: 'Full name',
  condidat: 'Candidate',
  date_con: 'Application date',
  nb_condidats_acceptee: 'Number of accepted candidates',
  nb_condidats_non_trait: 'Number of untreated candidates',
  nb_condidats_refusee: 'Number of refused candidates',
  nb_condidats_total: 'Total number of candidates',
  total_prix: 'Total of prizes',
  niveauInput: 'Level',
  prixInput: 'Prizes` value',

  successCondiature: 'Application successfully sent',
  sure: {
    delete: 'Are you sure you want to remove the price « {} » of the edition « {} » ?',
  },
  condidatures: 'Applications',
  myCondidature: 'My applications',
}
