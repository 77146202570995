import React, { Component } from 'react'
import { getUrl } from '../../../../Api'
import axios from 'axios'
import { getTrad } from '../../../../lang'
import Waiting from '../../../../components/Waiting'
import { showDate, showMoney } from '../../../../helpers'
function ShowTable(props) {
  if (props.loading)
    return (
      <tbody>
        <tr>
          <td colSpan="6" className="text-center">
            <Waiting />
          </td>
        </tr>
      </tbody>
    )

  if (props.list.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="6" className="text-center text-muted">
            {getTrad('commun.noSearchData')}
          </td>
        </tr>
      </tbody>
    )
  return (
    <tbody>
      {props.list.map((e, k) => (
        <tr>
          <td>{e.name}</td>
          <td>{e.number}</td>
          <td>{getTrad(`aidesociale.${e.forfait ? '' : 'non'}forfait`)}</td>
          <td>{showMoney(e.montant)}</td>
          <td>{showMoney(e.total)}</td>
          <td>{showDate(e.date)}</td>
        </tr>
      ))}
    </tbody>
  )
}

class MyVersements extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      loading: true,
    }
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('aide_sociale.myVersements'))
        .then(res => this.setState({ list: res.data.success, loading: false }))
        .catch(err => this.setState({ list: [], loading: false }))
    })
  }
  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>{getTrad('pages.aideSociale.myVersements')}</h3>
        </div>
        <div className="card-body p-0">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>{getTrad('aidesociale.name')}</th>
                <th>{getTrad('aidesociale.number')}</th>
                <th>{getTrad('aidesociale.forfait')}</th>
                <th>{getTrad('aidesociale.montant')}</th>
                <th>{getTrad('commun.total')}</th>
                <th>{getTrad('fileds.date')}</th>
              </tr>
            </thead>
            <ShowTable {...this.state} />
          </table>
        </div>
      </div>
    )
  }
}
export default MyVersements
