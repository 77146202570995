import React from 'react'
import { getTrad } from '../../../../lang'
import { showMoney } from '../../../../helpers'

function StatTable(props) {
  if (props.show)
    return (
      <div className="card-body p-0">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>{getTrad('aidesociale.name_aide')}</th>
              <th>{getTrad('aidesociale.number_adherants')}</th>
              <th>{getTrad('aidesociale.number')}</th>
              <th>{getTrad('aidesociale.forfait')} ?</th>
              <th>{getTrad('aidesociale.montant')}</th>
              <th>{getTrad('commun.total')}</th>
            </tr>
          </thead>

          <tbody>
            {props.stats.map((e, k) => (
              <tr key={k}>
                <th>{e.name}</th>
                <td>{e.number_adherants}</td>
                <td>{e.number}</td>
                <td>{e.forfait ? getTrad('commun.boolean.oui') : getTrad('commun.boolean.non')}</td>
                <td>{showMoney(e.montant)}</td>
                <td>{showMoney(e.total)}</td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <th>{getTrad('commun.total')}</th>
              <th colSpan="4"></th>
              <th>{showMoney(props.stats.map(e => e.total).reduce((a, b) => a + b, 0))}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  return <></>
}

export default StatTable
