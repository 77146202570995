import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import Waiting from '../../../components/Waiting'
import { successToast, areYouSure } from '../../../helpers/sweetAlerts'
import { LangContext } from '../../../context/LangContext'

function RenderHader() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-12">
          <h2 className="card-title">{getTrad('pages.editProfil')}</h2>
        </div>
      </div>
    </div>
  )
}

function RenderBtn(props) {
  let email = props.email ? props.email.toString() : ''
  let oldValuesemail = props.oldValues.email ? props.oldValues.email.toString() : ''

  let phone = props.phone ? props.phone.toString() : ''
  let oldValuesphone = props.oldValues.phone ? props.oldValues.phone.toString() : ''

  let lieu = props.lieu ? props.lieu.toString() : ''
  let oldValueslieu = props.oldValues.lieu ? props.oldValues.lieu.toString() : ''

  let inner = props.sending ? <Waiting /> : getTrad('pages.editProfil')

  let disabled = props.loading || props.sending

  let changed = false
  changed = changed || email !== oldValuesemail
  changed = changed || phone !== oldValuesphone
  changed = changed || lieu !== oldValueslieu
  changed = !changed

  let btnProps = {
    disabled: disabled || changed,
    className: 'w-100 btn btn-sm btn-success',
    onClick: props.click,
  }
  return <button {...btnProps}>{inner}</button>
}

export default class GestionAdmins extends Component {
  static contextType = LangContext

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      phone: '',
      lieu: '',
      oldValues: {},
      sending: false,
      loading: false,
      redirect: false,
      errors: {},
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSend = e => {
    let data = {
      email: this.state.email,
      phone: this.state.phone,
      lieu: this.state.lieu,
    }
    areYouSure.fire().then(result => {
      if (result.value) {
        this.setState({ sending: true }, () => {
          axios
            .patch(getUrl('adherants.profil'), data)
            .then(res => {
              successToast.fire({ title: getTrad('success.updateProfil') })
              this.setState({ sending: false })
            })
            .catch(err => this.setState({ sending: false, errors: err.response.data.errors }))
        })
      }
    })
  }

  refresh() {
    this.setState({ loading: true }, () =>
      axios
        .get(getUrl('adherants.profil'))
        .then(res => {
          this.setState({
            phone: res.data.success.phone,
            email: res.data.success.email,
            lieu: res.data.success.lieu,
            loading: false,
            oldValues: res.data.success,
          })
        })
        .catch(err => this.setState({ redirect: true }))
    )
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    if (this.state.redirect) return <Redirect to="/" />
    if (this.state.loading) return <Waiting />
    return (
      <div className="card">
        <RenderHader />
        <div className="card-body">
          <div className="row">
            {this.renderInputText('email', 12, 'email')}
            {this.renderInputText('phone', 12, 'number')}
            {this.renderForeign('lieu', 12)}
          </div>
          <RenderBtn click={this.handleSend} {...this.state} />
        </div>
      </div>
    )
  }

  renderInputText(e, width, type) {
    let typ = type ? type : 'text'
    let w = width ? width : 6
    return (
      <div className={`col-${w}`}>
        <div className="form-group">
          <label htmlFor={e}>{getTrad(`adherant.${e}`)}</label>
          <input
            type={typ}
            className="form-control w-100 form-control-sm"
            value={this.state[e]}
            onChange={this.handleChange}
            name={e}
            id={e}
            placeholder={getTrad(`adherant.${e}`)}
          />
          {this.getErrors(e)}
        </div>
      </div>
    )
  }

  renderForeign(key, width) {
    let w = width ? width : 6
    let field
    let selectProps = {
      name: key,
      onChange: this.handleChange,
      value: this.state[key],
      className: 'form-control form-control-sm',
    }

    const { extra } = this.context
    if (extra[key]) {
      field = (
        <select {...selectProps}>
          {Object.keys(extra[key]).map((e, k) => {
            return (
              <option key={k} value={e}>
                {extra[key][e]}
              </option>
            )
          })}
        </select>
      )
    }

    return (
      <div className={`col-${w}`}>
        <div className="form-group">
          <label htmlFor={key}>{getTrad(`fileds.${key}`)}</label>
          {field}
          {this.getErrors(key)}
        </div>
      </div>
    )
  }

  getErrors(e) {
    let errorMessage
    if (this.state.errors[e]) {
      errorMessage = (
        <div className="text-sm text-danger">
          {this.state.errors[e].map((ele, k) => {
            return <li key={k}>{ele}</li>
          })}
        </div>
      )
    }
    return errorMessage
  }
}
