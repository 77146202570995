import React from 'react'
import { isNotAdmin } from '../../../helpers'
import { getTrad, useTrad } from '../../../lang'
import { getUrl } from '../../../Api'

import Greating from './Greating'
import RenderCard from './RenderCard'
import ChangePassword from './ChangePassword'
import { userStore } from '../../../store/userStore'

function Overview() {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  return (
    <>
      <ChangePassword />
      <Greating />
      <div className="row mt-3">
        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-copy"
          text={useTrad('commun.nb_demandes_bs')}
          url={getUrl('bs.count')}
          link="/bultin-soins/demande"
        />
        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-file"
          text={getTrad('commun.nb_justificatifs')}
          link="/bultin-soins/demande"
          url={getUrl('justificatif.count')}
        />
        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-money-bill-wave"
          text={getTrad('commun.nb_credits')}
          link="/credit/demande"
          url={getUrl('credits.count')}
        />
        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-hand-holding-heart"
          text={getTrad('commun.nb_demande_aide')}
          url={getUrl('aidesociale.count')}
          link="/aide"
        />
        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-award"
          text={getTrad('commun.nb_condidatures_js')}
          url={getUrl('journeeSciences.status')}
          link="/journee-science/demande"
        />

        <RenderCard
          tester={!isNotAdmin(role)}
          icon="fas fa-heart"
          text={getTrad('commun.nb_demande_aide_solidaire')}
          url={getUrl('aide_sociale.new')}
          link="/aide-sociale"
        />
      </div>
    </>
  )
}
export default Overview
