import React, { Component } from 'react'
import axios from 'axios'
import NumInput from '../../../../components/Form/NumInput'
import TxtInput from '../../../../components/Form/TxtInput'
import Waiting from '../../../../components/Waiting'
import { showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { areYouSure, fatalError, successMsg } from '../../../../helpers/sweetAlerts'
import Swal from 'sweetalert2'

function ShowHistory(props) {
  return (
    <div className="card mt-3 p-0">
      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <h2 className="card-title">{getTrad('pages.historyCredit')}</h2>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-striped table-hover m-0">
          <thead>
            <tr>
              <th>{getTrad('credit.date_demande')}</th>
              <th>{getTrad('credit.montant_demande')}</th>
              <th>{getTrad('fileds.motif')}</th>
              <th>{getTrad('fileds.status')}</th>
              <th>{getTrad('fileds.reponse')}</th>
            </tr>
          </thead>
          <tbody>
            {props.listCredits.map((e, k) => {
              return (
                <tr key={k}>
                  <td>{showDate(e.date_demande)}</td>
                  <td>{showMoney(e.montant_demande)}</td>
                  <td>{e.motif}</td>
                  <td>{getTrad(`credit.status.${e.status}`)}</td>
                  <td>{e.reponse}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

class AddDemande extends Component {
  constructor(props) {
    super(props)
    this.state = { sending: false, montant: '', motif: '', errors: {} }
  }

  handler = e => this.setState({ [e.target.name]: e.target.value })

  sendRequest = () => {
    axios
      .post(getUrl('credits.myData'), {
        montant: this.state.montant,
        motif: this.state.motif,
      })
      .then(res => {
        this.setState({ sending: false, montant: '', motif: '' })
        this.props.refresh()
        successMsg('Demande envoyée avec success')
      })
      .catch(err => {
        this.setState({ sending: false })

        if (err.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: getTrad('errors.hasOtherCredit'),
          })
        } else if (err.response.status !== 422) {
          this.setState({ sending: false })
          fatalError()
          this.props.refresh()
        } else {
          this.setState({ errors: err.response.data.errors })
        }
      })
  }

  send = () => {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.sendRequest)
    })
  }

  render() {
    let btn = this.state.sending ? <Waiting /> : getTrad('credit.demander')

    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-6">
              <h2 className="card-title">{getTrad('pages.demandeCredit')}</h2>
            </div>
            <div className="col-6 text-right">
              <a className="btn btn-primary btn-sm" href="/">
                <i className="fas fa-download"></i> {getTrad('credit.downloadForms')}
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <NumInput
                handler={this.handler}
                value={this.state.montant}
                name="montant"
                label={getTrad('fileds.montant')}
                error={this.state.errors.montant}
              />
            </div>
            <div className="col">
              <br />
              <button
                onClick={this.send}
                className="btn btn-success w-100 btn-sm"
                disabled={this.state.sending || this.state.montant === ''}
              >
                {btn}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TxtInput
                handler={this.handler}
                value={this.state.motif}
                name="motif"
                label={getTrad('fileds.motif')}
                error={this.state.errors.motif}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Demande(props) {
  return (
    <>
      <AddDemande refresh={props.refresh} />
      <ShowHistory {...props} />
    </>
  )
}
