import React from 'react'
import { getTrad } from '../../../../../lang'
import BSHeaderInput from './BSHeaderInput'
import Plafond from './Plafond'

export default function BSHeader({ bsInfo, action = 'A', send }) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h2 className="card-title">
              {action === 'U' ? getTrad('pages.editBs') : getTrad('pages.addBs')}
            </h2>
          </div>
          <div className="col">
            <button type="submit" onClick={send} className="btn-sm w-100 btn btn-success">
              {action === 'U' ? getTrad('commun.edit') : getTrad('commun.add')}
            </button>
          </div>
        </div>
      </div>
      <Plafond bsInfo={bsInfo} />
      <BSHeaderInput bsInfo={bsInfo} />
    </div>
  )
}
