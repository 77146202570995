import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { validDate, convertDate, showDate, showMoney } from '../../../helpers'
import ShowTable from '../../../components/ShowTable'
import { getTrad } from '../../../lang'
import NumInput from '../../../components/Form/NumInput'
import DateInput from '../../../components/Form/Date'
import Rembours from './components/Rembours'
import { Link } from 'react-router-dom'
import Delete from './List/Delete'

const CardHeader = () => {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{getTrad('pages.searchBS')}</h2>
        </div>
      </div>
    </div>
  )
}

const RenderSearch = props => {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <NumInput
            label={getTrad('fileds.matricule_adh')}
            name="matAdh"
            value={props.matAdh}
            handler={props.handler}
          />
        </div>
        <div className="col">
          <NumInput
            label={getTrad('bs.numBs')}
            name="matBS"
            value={props.matBS}
            handler={props.handler}
          />
        </div>
        <div className="col">
          <DateInput
            label={getTrad('bs.bs_date')}
            name="dateBS"
            value={props.dateBS}
            handler={props.handler}
          />
        </div>
        <div className="col">
          <RenderBtn {...props} />
        </div>
      </div>
    </div>
  )
}

function Actions(props) {
  if (props.status === 'NT')
    return (
      <div classNames="btn-group">
        <Link className="btn btn-sm btn-secondary" to={`/bultin-soins/${props.bs_number}/show`}>
          <i className="fas fa-eye" />
        </Link>
        <Link className="btn btn-sm btn-secondary" to={`/bultin-soins/${props.bs_number}/edit`}>
          <i className="fas fa-edit" />
        </Link>
        <Delete {...props} refresh={props.send} />
      </div>
    )
  if (['ET', 'T'].includes(props.status))
    return (
      <div classNames="btn-group">
        <Link className="btn btn-sm btn-secondary" to={`/bultin-soins/${props.bs_number}/show`}>
          <i className="fas fa-eye" />
        </Link>
        <Rembours
          observation={props.observations}
          remboursemement={props.total_remboursement}
          matricule={props.bs_number}
          reload={props.send}
        />
      </div>
    )
  return (
    <Link className="btn btn-sm btn-secondary" to={`/bultin-soins/${props.bs_number}/show`}>
      <i className="fas fa-eye" />
    </Link>
  )
}

function RenderBtn(props) {
  let btnProps = {
    id: 'searchBS',
    type: 'button',
    disabled: !(props.matAdh !== '' || props.matBS !== '' || validDate(props.dateBS)),
    onClick: props.send,
    className: 'btn-sm w-100 btn btn-success',
  }
  return (
    <div className="form-group">
      <label>&nbsp;</label>
      <button {...btnProps}>{getTrad('commun.search')}</button>
    </div>
  )
}

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listBS: [],
      loading: false,
      searched: false,
      matBS: '',
      matAdh: '',
      dateBS: '',
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  cleanData = e => ({
    ...e,
    estimation: showMoney(e.estimation),
    total_depense: showMoney(e.total_depense),
    total_remboursement: showMoney(e.total_remboursement),
    status: getTrad(`bs.status_${e.status}`),
    date_bord: showDate(e.date_bord),
    bs_date: showDate(e.bs_date),
    last_update: showDate(e.last_update),
    action: <Actions {...e} send={this.send} />,
  })

  send = event => {
    if (event) event.preventDefault()
    let data = {
      matBS: this.state.matBS,
      matAdh: this.state.matAdh,
      dateBS: null,
    }

    if (validDate(this.state.dateBS)) {
      data.dateBS = convertDate(this.state.dateBS)
    }
    this.setState({ loading: true, searched: true }, () =>
      axios
        .post(getUrl('bs.search'), data)
        .then(res => {
          this.setState({ loading: false, listBS: res.data.success.map(this.cleanData) })
        })
        .catch(err => this.setState({ loading: false, listBS: [] }))
    )
  }

  renderBody() {
    if (!this.state.searched) {
      return <></>
    }
    let header = {}
    let keys = [
      'matricule_adh',
      'full_name',
      'prestataire',
      'id_bord',
      'date_bord',
      'bs_number',
      'bs_date',
      'total_depense',
      'estimation',
      'status',
      'observations',
      'total_remboursement',
      'complement',
      'last_update',
      'action',
    ]

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`bs.${k}`)
    }

    let showProps = {
      data: this.state.listBS,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }
    if (this.state.searching) {
      showProps.searching = true
    }

    return (
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    )
  }

  render() {
    return (
      <div className="card">
        <CardHeader />
        <RenderSearch
          matAdh={this.state.matAdh}
          matBS={this.state.matBS}
          dateBS={this.state.dateBS}
          handler={this.handleChange}
          send={this.send}
        />
        {this.renderBody()}
      </div>
    )
  }
}
