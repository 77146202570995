import React from 'react'
import { getTrad } from '../../../../lang'
import Add from './Add'

import { showDate } from '../../../../helpers'
import ShowJustif from './ShowJustif'

function ListeCondidatures(props) {
  if (props.data.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan="4">No data</td>
        </tr>
      </tbody>
    )

  return (
    <tbody className="align-center">
      {props.data.map((e, k) => (
        <tr key={k}>
          <td>
            {e.eleve.first_name} {e.eleve.last_name}
          </td>
          <td>{e.condidat.niveau}</td>
          <td>{getTrad(`condidature.statusCode.${e.status}`)}</td>
          <td>{showDate(e.created_at)}</td>
          <td>
            <ShowJustif img={e.proof} fullName={`${e.eleve.first_name} ${e.eleve.last_name}`} />
          </td>
        </tr>
      ))}
    </tbody>
  )
}

function Condidatures(props) {
  return (
    <div className="card mt-3">
      <div className="card-header">
        <h2>
          {getTrad('science.myCondidature')}{' '}
          <Add
            family={props.family}
            condidats={props.condidatures}
            prix={props.prix}
            refresh={props.refresh}
          />
        </h2>
      </div>
      <div className="card-body p-0">
        <table className="w-100">
          <thead>
            <tr>
              <th>{getTrad('science.full_name')}</th>
              <th>{getTrad('science.prix')}</th>
              <th>{getTrad('fileds.status')}</th>
              <th>{getTrad('science.date_con')}</th>
              <th>{getTrad('science.preuve')}</th>
            </tr>
          </thead>
          <ListeCondidatures data={props.condidatures} />
        </table>
      </div>
    </div>
  )
}

export default Condidatures
