import React, { Component } from 'react'
import { getTrad } from '../../../lang'
import { RenderAddBtn, RenderAddFields, DeleteBtn, cleanLocals } from './utils'
import { getUrl } from '../../../Api'
import axios from 'axios'
import { successToast, fatalError } from '../../../helpers/sweetAlerts'
import Local from './Local'
import { AVALIBLE_LANGS } from '../../../helpers/consts'

let locals = AVALIBLE_LANGS

const cleanData = e => {
  let tmp = {}
  e.locals.forEach((item, i) => {
    tmp[item.lang] = item.value
  })

  return {
    id: e.id,
    locals: e.locals.map(cleanLocals),
    etabs: e.etablissements,
  }
}

const cleanerData = item => {
  let tmp = { id: item.id }
  locals.forEach((loc, i) => {
    const thisLoc = item.locals.filter(e => loc === e.lang)
    tmp[loc] = {}
    if (thisLoc[0]) {
      tmp[loc] = {
        id: thisLoc[0].id,
        value: thisLoc[0].value,
      }
    }
  })

  tmp.etabs = item.etabs.map(e => {
    let etabtmp = {
      id: e.id,
      id_gouvernaurat: e.id_gouvernaurat,
    }

    locals.forEach((loc, i) => {
      const thisLoc = e.locals.filter(e => loc === e.lang)
      etabtmp[loc] = {}
      if (thisLoc[0]) {
        etabtmp[loc] = {
          id: thisLoc[0].id,
          value: thisLoc[0].value,
        }
      }
    })
    return etabtmp
  })
  return tmp
}

class Gouvs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      toEdit: null,
      adding: false,
      sendAdd: false,
      toAdd: { fr: '', ar: '', en: '' },
      listGouvs: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  addFields = () => this.setState({ adding: true })
  closeFields = () =>
    this.setState({ adding: false, toAdd: { fr: '', ar: '', en: '' }, sendAdd: false })

  handleAdd = e => {
    let tmp = {}
    Object.assign(tmp, this.state.toAdd)
    tmp[e.target.name] = e.target.value
    this.setState({ toAdd: tmp })
  }

  refresh = () => {
    axios.get(getUrl('gouvernaurats.index')).then(res => {
      const clean = res.data.success.map(cleanData)

      this.setState({ loading: false, listGouvs: clean.map(cleanerData) })
    })
  }

  add = () => {
    this.setState({ sendAdd: true }, () => {
      axios
        .post(getUrl('gouvernaurats.create'), this.state.toAdd)
        .then(res => {
          successToast.fire({ title: getTrad('success.gouv.add') })
          this.refresh()
          this.closeFields()
        })
        .catch(err => {
          fatalError()
          this.refresh()
          this.closeFields()
        })
    })
  }

  delete = id => this.setState({ toEdit: id })

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>{getTrad('pages.listGouvs')}</h3>
        </div>
        <div className="card-body p-0">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>{getTrad(`commun.nbEtabs`)}</th>
                {locals.map((e, k) => (
                  <th key={k}>{getTrad(`commun.local.${e}`)}</th>
                ))}
                <th width="1">
                  <RenderAddBtn
                    addFields={this.addFields}
                    closeFields={this.closeFields}
                    {...this.state}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <RenderAddFields
                {...this.props}
                {...this.state}
                locals={locals}
                handler={this.handleAdd}
                add={this.add}
              />
              {this.state.listGouvs.map((e, k) => (
                <tr key={k}>
                  <td>{e.id}</td>
                  <td>{e.etabs.length}</td>
                  {locals.map((i, key) => (
                    <td key={key}>
                      <Local
                        superId={e.id}
                        local={i}
                        supertype="gouv"
                        {...e[i]}
                        refresh={this.refresh}
                      />
                    </td>
                  ))}
                  <td>
                    <div className="btn-group">
                      <DeleteBtn {...this.state} id={e.id} refresh={this.refresh} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Gouvs
