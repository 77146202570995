import React from 'react'
import { getTrad } from '../../lang'
import Bar from '../Chart/Bar'

const months = Array.from({ length: 12 }).map((_, k) => getTrad(`commun.months.${k}`))

function ShowBars(props) {
  if (props.barKeys.length !== 0)
    return (
      <div className="card-body">
        <div className="row">
          {props.barKeys.map((e, k) => (
            <div key={k} className="col col-md-6">
              <Bar
                labels={months}
                label={getTrad(e.label)}
                data={props.stats.map(ele => ele[e.key])}
              />
            </div>
          ))}
        </div>
      </div>
    )
  return <></>
}

export default ShowBars
