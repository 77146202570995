import React, { Component } from 'react'
import CardHeader from '../../../components/CardHeader'
import ShowTable from '../../../components/ShowTable'
import { getTrad } from '../../../lang'
import axios from 'axios'
import { getUrl } from '../../../Api'
import Export from '../../../components/Export'
import ShowCessions from './ShowCessions'
import NumInput from '../../../components/Form/NumInput'
import InputText from '../../../components/Form/InputText'
import { inString } from '../../../helpers'

const thisYear = new Date().getFullYear()

const keys = [
  'matricule',
  'matricule_cnrps',
  'first_name',
  'last_name',
  thisYear - 2,
  thisYear - 1,
  thisYear,
  'actions',
]

const filterThisYear = e => new Date(e.date).getFullYear() === thisYear
const filterLastYear = e => new Date(e.date).getFullYear() === thisYear - 1
const filterBeforeLastYear = e => new Date(e.date).getFullYear() === thisYear - 2

function Filter(props) {
  return (
    <div className="row">
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule}
          label={getTrad('fileds.matricule')}
          name="matricule"
        />
      </div>
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule_cnrps}
          label={getTrad('fileds.matricule_cnrps')}
          name="matricule_cnrps"
        />
      </div>
      <div className="col">
        <InputText
          handler={props.handler}
          value={props.first_name}
          label={getTrad('fileds.first_name')}
          name="first_name"
        />
      </div>
      <div className="col">
        <InputText
          handler={props.handler}
          value={props.last_name}
          label={getTrad('fileds.last_name')}
          name="last_name"
        />
      </div>
    </div>
  )
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      loading: true,
      matricule: '',
      matricule_cnrps: '',
      first_name: '',
      last_name: '',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  cleanData = e => {
    return {
      matricule: e.matricule,
      matricule_cnrps: e.matricule_cnrps,
      first_name: e.first_name,
      last_name: e.last_name,
      cession_parents: e.cession_parents,

      [thisYear]: e.cession_parents
        .map(cess => cess.cesions)
        .map(cess =>
          cess
            .filter(filterThisYear)
            .map(e => e.montant)
            .reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0),

      [thisYear - 1]: e.cession_parents
        .map(cess => cess.cesions)
        .map(cess =>
          cess
            .filter(filterLastYear)
            .map(e => e.montant)
            .reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0),

      [thisYear - 2]: e.cession_parents

        .map(cess => cess.cesions)
        .map(cess =>
          cess
            .filter(filterBeforeLastYear)
            .map(e => e.montant)
            .reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0),

      actions: <ShowCessions deleteItem={this.deleteItem} {...e} />,
    }
  }

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('parent.saisie.hadSession'))
        .then(res => this.setState({ list: res.data.success.map(this.cleanData), loading: false }))
        .catch(err => this.setState({ loading: false }))
    })
  }

  deleteItem = data => {
    this.setState({
      list: this.state.list
        .map(e => ({
          ...e,
          cession_parents: e.cession_parents.filter(ele => ele.id !== data.id),
        }))
        .map(this.cleanData),
    })
  }

  handleInput = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    if (!inString(this.state.matricule, e.matricule)) return false
    if (!inString(this.state.matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.first_name, e.first_name)) return false
    if (!inString(this.state.last_name, e.last_name)) return false
    return true
  }

  render() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      if (isNaN(keys[i])) header[keys[i]] = getTrad(`fileds.${keys[i]}`)
      else header[keys[i]] = keys[i]
    }

    const tablePorps = {
      searching: this.state.loading,
      noData: getTrad('commun.noSearchData'),
      header: header,
      keys: keys,
      data: this.state.list.filter(this.filter).sort((a, b) => a[thisYear] < b[thisYear]),
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('parent.page.list')}
          actions={
            <Export
              fileName={getTrad('parent.page.list')}
              csvData={this.state.list}
              title={getTrad('commun.exportBtn.all')}
              headers={header}
            />
          }
        />
        <div className="card-body">
          <Filter
            handler={this.handleInput}
            matricule={this.state.matricule}
            matricule_cnrps={this.state.matricule_cnrps}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
          />
        </div>
        <div className="card-body p-0">
          <ShowTable {...tablePorps} />
        </div>
      </div>
    )
  }
}

export default List
