export const aide_socialeM = {
  icon: 'fas fa-heart',
  text: 'crud_mag_aide_sociale',
  link: 'aide-sociale',
  // submenue: [
  //   {
  //     icon: 'fas fa-list',
  //     text: 'mes_aides',
  //     link: 'mes-aides',
  //   },
  // ],
}

export const aide_socialeS = {
  icon: 'fas fa-heart',
  text: 'crud_aide_sociale',
  link: 'aide-sociale',
  submenue: [
    { icon: 'fas fa-list', text: 'aide_sociale_demande', link: '' },
    { icon: 'fas fa-money-bill-wave', text: 'aide_sociale_versements', link: 'versements' },
    { icon: 'fas fa-history', text: 'aide_sociale_historique', link: 'historique' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-cog', text: 'aide_sociale_settings', link: 'parametres' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-chart-bar', text: 'statistiques', link: 'statistiques' },
  ],
}

export const aide_socialeA = {
  icon: 'fas fa-heart',
  text: 'crud_aide_sociale',
  link: 'aide-sociale',
}
