import React from 'react'
import PropTypes from 'prop-types'
import { getTrad } from '../../lang'

export default function InputSexe(props) {
  let data = [
    { value: 'M', label: getTrad('commun.sexe.M') },
    { value: 'F', label: getTrad('commun.sexe.F') },
  ]

  let name = props.name ? props.name : 'sexe'
  let value = props.value.toString()
  let label = props.label ? <label htmlFor={name}>{props.label}</label> : null

  return (
    <div className="form-group">
      {label}
      <br />
      {data.map((e, k) => (
        <div key={k} className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            checked={e.value === value}
            onChange={props.handler}
            name={name}
            id={`${name}-${e.value}`}
            value={e.value}
          />
          <label className="form-check-label" htmlFor={`${name}-${e.value}`}>
            {e.label}
          </label>
        </div>
      ))}
    </div>
  )
}

InputSexe.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}
