import Waiting from '../../../../../components/Waiting'

function Prestataire({ loading, prestatires = [], error = false, value, handler = () => null }) {
  if (loading) return <Waiting />
  const inputClass = ['form-control', 'w-100', 'form-control-sm']
  if (error) inputClass.push('is-invalid')
  if (prestatires.length <= 0) return null

  return (
    <select
      value={value}
      onChange={handler}
      id="id_prestatire"
      className={inputClass.join(' ')}
      name="id_prestatire"
    >
      {prestatires.map((e, k) => {
        return (
          <option key={k} value={e.id}>
            {e.name}
          </option>
        )
      })}
    </select>
  )
}

export default Prestataire
