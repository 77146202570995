import React from 'react'
import { getTrad } from '../../../../../lang'
import InputText from '../../../../../components/Form/InputText'

const thisYear = new Date().getFullYear()

function AddJustif(props) {
  const testSubmit =
    props.annee === '' ||
    isNaN(props.annee) ||
    parseInt(props.annee) > thisYear ||
    parseInt(props.annee) < thisYear - 3
  if (!props.show) return <></>
  return (
    <tr>
      <th className="align-top">
        <InputText
          value={props.annee}
          name="annee"
          dontShow
          required
          label={getTrad('fileds.year')}
          handler={props.handler}
          error={testSubmit ? [''] : null}
        />
      </th>
      <th colSpan="2" className="align-top">
        {props.proof ? (
          <img className="w-100" src={props.proof} alt={props.annee} />
        ) : (
          <div className="custom-file custom-file-sm">
            <input
              onChange={props.handleFile}
              type="file"
              className="custom-file-input"
              id="customFile"
            />
            <label data-browse="Selectionner" className="custom-file-label" htmlFor="customFile">
              Selectionner
            </label>
          </div>
        )}
      </th>
      <th className="align-top">
        <button
          disabled={testSubmit}
          onClick={props.send}
          className={`w-100 btn btn-sm btn-success`}
        >
          <i className={`fas fa-check`}></i>
        </button>
      </th>
    </tr>
  )
}

export default AddJustif
