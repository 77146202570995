export const fr = {
  cummonError: 'Un problème est survenu',
  matriculeNotFound: 'Matricule inexistante',
  mustHaveSuperUsers: 'Il faut avoir au moins un super administrateur',
  contactAdmin: "Veuillez contacter l'administrateur",
  montantmustinteger: 'Le montant doit être un entier positif',
  motifRequired: 'vous devez mentionner le motif de refus',
  hasOtherCredit: 'Plafond dépassé',
  noSMS: "Vous n'avez plus de SMS",
  noJustificatifs: "Il n'y a pas de justificatifs",
  js401: 'Vous avez déjà une édition en cours',
  noedition: "Il n'y a pas d'appel à candidatures.",
  loadingFile: "Un problème lors de l'importation de l'image",
  jsData: 'Veuillez vérifier les données d`entrée',
  giveValidMatricule: 'Veuillez saisir une matricule valide',
  bigFile: 'Le fichier justificatif ne doit pas dépasser 3 Mb',
  required: "Vous devez indiquer l'année du justificatif",
  tooearly: "L'année ne doit pas être antérieure à 5 ans",
  beforetoday: "L'année ne doit pas être postérieure à celle courante",
  duplicited: "L'année entrée existe déjà",
  invalid: {
    required: 'Ce champ est obligatoir',
    date: 'Veuillez vérifier la date de saisie',
  },
  date: {
    beforeToday: "Cette date doit être antérieur à la date d'aujourd'hui",
    afterOneYear: 'Cette date ne doit pas dépasser une année',
  },
}

export const ar = {
  cummonError: 'هناك خطأ ما',
  matriculeNotFound: 'رقم تسجيل غير موجود',
  mustHaveSuperUsers: 'يجب أن يكون لديك مشرف مميز واحد على الأقل',
  contactAdmin: 'يرجى الاتصال بالمسؤول',
  montantmustinteger: 'يجب أن يكون المبلغ عددًا صحيحًا ايجابيا',
  motifRequired: 'يجب أن تذكر سبب رفض المطلب',
  hasOtherCredit: 'هذا العضو لديه قرض اخر غير مسدد',
  noSMS: 'ليس لديك المزيد من الرسائل القصيرة',
  noJustificatifs: 'لا توجد مستندات داعمة للمطلب',
  js401: 'لديك إصدار قيد المراجعة',
  noedition: 'لا توجد دعوة لتقديم المطالب',
  loadingFile: 'حدثت مشكلة أثناء ادخال الصورة',
  jsData: 'يرجى التحقق من البيانات المدخلة',
  giveValidMatricule: 'أدخل من فضلك رقم تسجيل صالحا',
  bigFile: 'يجب ألا يتجاوز حجم الملف الداعم 3 ميغا بايت',
  required: 'يجب تحديد سنة الملف الدّاعم',
  tooearly: 'يجب ألا يكون تاريخ العام المسجّل قبل 5 سنوات',
  beforetoday: 'يجب ألا يكون تاريخ العام المسجّل سابقا للسّنة الجارية',
  duplicited: 'لسنة التي تم إدخالها موجودة بالفعل',
  invalid: {
    date: 'يرجى التحقق من التاريخ الذي تم إدخاله',
  },
}

export const en = {
  cummonError: 'An error has occurred',
  matriculeNotFound: 'Inexistent registration number',
  mustHaveSuperUsers: 'You must have at least one super administrator',
  contactAdmin: 'Please contact the administrator',
  montantmustinteger: 'The amount must be a positive integer',
  motifRequired: 'you must mention the reason for refusal',
  hasOtherCredit: 'Exceeded amount limit',
  noSMS: 'You have no more SMS',
  noJustificatifs: 'There are no supporting documents',
  js401: 'You already have a current edition',
  noedition: 'There is no call for applications yet.',
  loadingFile: 'An error while importing the image',
  jsData: 'Please check the input data',
  giveValidMatricule: 'Please enter a valid registration number',
  bigFile: 'The supporting file must not exceed the size of 3 Mb',
  required: 'You must indicate the year of the supporting document',
  tooearly: 'The year must not be earlier than 5 years',
  beforetoday: 'The year must not be later than the current one',
  duplicited: 'The entered year already exists',
  invalid: {
    date: 'Please check the data entry date',
  },
}
