import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import axios from 'axios'

import InputSelect from '../../../components/Form/InputSelect'
import NumInput from '../../../components/Form/NumInput'
import InputMounth from '../../../components/Form/InputMounth'
import { showMounth, convertDate } from '../../../helpers'
import { successMsg, fatalError, areYouSure } from '../../../helpers/sweetAlerts'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'

const today = new Date()

class Rembours extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      montant_echance: '',
      type: '',
      date: '',
    }
  }

  handleShow = () =>
    this.setState({
      show: !this.state.show,
      montant_echance: this.props.montant_echance,
      type: '',
      date: showMounth(today),
    })

  send = event => {
    event.preventDefault()
    areYouSure.fire({ title: getTrad('commun.areyousure') }).then(result => {
      if (result.value) {
        const data = {
          value: this.state.montant_echance,
          type: this.state.type,
          date: convertDate('01-' + this.state.date),
        }
        axios
          .post(getUrl('credits.rembours', [this.props.id]), data)
          .then(res => {
            if (res.status === 200) {
              successMsg(getTrad('success.reboursAdd'))
              this.handleShow()
              this.props.handler()
            } else {
              fatalError()
            }
          })
          .catch(err => fatalError())
      }
    })
  }

  reset = event => {
    event.preventDefault()
    this.setState({
      show: !this.state.show,
      type: '',
      date: showMounth(today),
      montant_echance: this.props.montant_echance,
    })
  }

  handleData = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <>
        <button className="btn btn-primary btn-sm " onClick={this.handleShow}>
          <i className="fas fa-cash-register"></i>
        </button>
        <Modal show={this.state.show} onHide={() => null}>
          <form onSubmit={this.send} onReset={this.reset}>
            <Modal.Header>
              <Modal.Title>{getTrad('credit.rembours', [this.props.id])}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputMounth
                handler={this.handleData}
                value={this.state.date}
                label={getTrad('fileds.date')}
                name="date"
              />
              <NumInput
                handler={this.handleData}
                label={getTrad('fileds.montant')}
                name="montant_echance"
                value={this.state.montant_echance.toString()}
              />

              <InputSelect
                name="type"
                value={this.state.type}
                label={getTrad('credit.type_remboursement_name')}
                handler={this.handleData}
                data={[
                  { value: 'E', text: getTrad('credit.type_remboursement.E') },
                  { value: 'C', text: getTrad('credit.type_remboursement.C') },
                  { value: 'B', text: getTrad('credit.type_remboursement.B') },
                  { value: 'P', text: getTrad('credit.type_remboursement.P') },
                  { value: 'V', text: getTrad('credit.type_remboursement.V') },
                ]}
              />
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-success">
                <i className="fas fa-cash-register mr-2"></i>
                {getTrad('credit.remboursBtn')}
              </button>
              <button type="reset" className="btn btn-danger" data-dismiss="modal">
                <i className="fas fa-times mr-2"></i>
                {getTrad('commun.close')}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

// export default class Rembours extends Component {
//
//

// Rembours.propTypes = { element: PropTypes.object.isRequired }

export default Rembours
