import React, { useContext } from 'react'
import { getTrad } from '../../../../../lang'
import Waiting from '../../../../../components/Waiting'
import { LangContext } from '../../../../../context/LangContext'

export default function Type(props) {
  let types = [{ value: '', inner: getTrad('fileds.selectElement') }]
  const {
    extra: { typePresta },
  } = useContext(LangContext)
  let Newfamille = props.family;
  let countConjoint = 0;
  let countpere = 0;
  let countmere = 0;
  if (typePresta) {

     

     Newfamille.map(fam => {
         if(fam.type === 2 ){
            countConjoint++;
         }
         if(fam.type === 3 ){
            countpere++;
         }
         if(fam.type === 4 ){
            countmere++;
         }
        
    });

    if (props.situationsfamiliale === 2) {
      if (countConjoint === 0){
        types.push({ value: 2, inner: typePresta[2] })
      }
    }
    if (countpere === 0)
      types.push({ value: 3, inner: typePresta[3] })

    if (countmere === 0)
      types.push({ value: 4, inner: typePresta[4] })

    console.log(props);
    /*if (props.situationsfamiliale === 2) {
      if (props.family.filter(e => e.type === '2').length === 0){
        types.push({ value: 2, inner: typePresta[2] })
      }
    }
    if (props.family.filter(e => e.type === '3').length === 0)
      types.push({ value: 3, inner: typePresta[3] })

    if (props.family.filter(e => e.type === '4').length === 0)
      types.push({ value: 4, inner: typePresta[4] })
    */
    types.push({ value: 5, inner: typePresta[5] })
    //console.log(props)
    return (
      <div className="form-group">
        <label htmlFor="selectType">{getTrad('fileds.type')} :</label>
        <select
          name="type"
          id="selectType"
          onChange={props.update}
          value={props.value}
          className="form-control form-control-sm"
        >
          {types.map((e, k) => {
            return (
              <option key={k} value={e.value}>
                {e.inner}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
  return <Waiting />
}
