import React, { useState } from 'react'
import { getTrad } from '../../../../lang'
import ListActive from './ListActive'
import ListDemande from './ListDemande'
import ListFinis from './ListFinis'
import ListRejet from './ListRejet'
import ListImportation from './ListImportation'

function SwitchList({ show }) {
  if (show === 'A') return <ListActive />
  if (show === 'D') return <ListDemande />
  if (show === 'F') return <ListFinis />
  if (show === 'R') return <ListRejet />
  if (show === 'I') return <ListImportation />
  return <ListActive />
}

function Navigation({ show, handler }) {
  const nav = [
    { title: getTrad('parent.status.A'), state: 'A' },
    { title: getTrad('parent.status.D'), state: 'D' },
    { title: getTrad('parent.status.F'), state: 'F' },
    { title: getTrad('parent.status.R'), state: 'R' },
    { title: getTrad('parent.status.I'), state: 'I' },
  ]
  return (
    <nav className="nav nav-pills justify-content-center mb-3">
      {nav.map((e, k) => (
        <button
          key={k}
          onClick={() => handler(e.state)}
          className={`btn nav-link${e.state === show ? ' active' : ''}`}
        >
          {e.title}
        </button>
      ))}
    </nav>
  )
}

function List(props) {
  const [show, setShow] = useState('A')
  return (
    <>
      <Navigation show={show} handler={setShow} />
      <SwitchList show={show} />
    </>
  )
}

export default List
