import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
    this.clear = this.clear.bind(this)
  }

  componentDidMount() {}

  handelInput(e) {
    this.setState({ value: e.target.value }, () => {
      this.props.handler(this.props.name, this.state.value)
    })
  }

  clear() {
    this.setState({ value: '' })
  }

  render() {
    let label = this.props.label
    let name = this.props.name
    let inputClass = 'form-control w-100 form-control-sm'

    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          name={name}
          id={name}
          placeholder={label}
          onChange={e => this.handelInput(e)}
          className={inputClass}
          value={this.state.value}
        />
      </div>
    )
  }
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
}
