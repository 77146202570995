import React from 'react'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import Swal from 'sweetalert2'
import Waiting from '../../../components/Waiting'
import { getTrad } from '../../../lang'
import BSHeader from './components/BSHeader'
import BSData from './components/BSData'
import useBsInfo from './hooks/useBsInfo'
import useBsData from './hooks/useBsData'
import { areYouSure, fatalError } from '../../../helpers/sweetAlerts'
import { convertDate, validDate } from '../../../helpers'

function BultinDeSoins({ action = 'A' }) {
  const {
    isFetching,
    isLoading,
    data: bsFields = [],
  } = useQuery('get-bs-info', () => axios.get('/bsconfs').then(res => res.data.success), {
    onError: () =>
      Swal.fire({
        icon: 'error',
        title: getTrad('errors.contactAdmin'),
      }),
  })

  const bsInfo = useBsInfo()
  const bsData = useBsData(bsFields)

  const validate = () => {
    return new Promise(
      resolve =>
        bsInfo.validate() &&
        bsData.isValid &&
        areYouSure.fire().then(result => {
          if (result.value) resolve()
        })
    )
  }

  const { mutate: send } = useMutation(
    () =>
      axios.post('/bulletin', {
        mat_bultin: bsInfo.matBs.value,
        date_soin: validDate(bsInfo.dateBs.value) ? convertDate(bsInfo.dateBs.value) : '',
        mat_adherant: bsInfo.matAd.value,
        id_prestatire: bsInfo.prestatire.value,
        description: bsInfo.desc.value,
        complement: bsInfo.complement.value,
        values: bsData.bsData,
      }),
    {
      onSuccess: () => {
        Swal.fire({ title: getTrad('success.addBs'), icon: 'success' })
        bsInfo.reset()
        bsData.reset()
      },
      onError: err => {
        fatalError()
      },
      onMutate: validate,
    }
  )

  if (isFetching || isLoading) return <Waiting />

  return (
    <>
      <BSHeader bsInfo={bsInfo} action={action} send={send} />
      <BSData bsData={bsData} bsFields={bsFields} />
      <div className="card mb-3">
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h2 className="card-title">
              {action === 'U' ? getTrad('pages.editBs') : getTrad('pages.addBs')}
            </h2>
          </div>
          <div className="col">
            <button type="submit" onClick={send} className="btn-sm w-100 btn btn-success">
              {action === 'U' ? getTrad('commun.edit') : getTrad('commun.add')}
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default BultinDeSoins
