import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'

import { getTrad } from '../../../lang'
import Accept from './Accept'
import ShowTable from '../../../components/ShowTable'
import { getUrl } from '../../../Api'
import { successMsg, errorMsg, areYouSure } from '../../../helpers/sweetAlerts'

export default class Demandes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      listDemandes: [],
    }
    this.handleRefresh = this.handleRefresh.bind(this)
  }

  render() {
    return (
      <div className="card">
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  }

  handleRefresh() {
    this.refresh()
  }

  refresh() {
    axios.get(getUrl('credits.crud')).then(res => {
      let tmp = res.data.success.map(e => ({
        id: e.id,
        matricule_adh: e.adherant.matricule,
        first_name: e.adherant.first_name,
        last_name: e.adherant.last_name,
        montant_demande: e.montant_demande,
        motif: e.motif,
        date_demande: e.date_demande,
        actions: (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Accept handle={this.handleRefresh} element={e} />

            <button onClick={() => this.refuse(e)} className="btn btn-danger btn-sm">
              <i className="fas fa-times"></i>
            </button>
          </div>
        ),
      }))
      this.setState({ listDemandes: tmp, loading: false })
    })
  }

  componentDidMount() {
    this.refresh()
  }

  refuseRequest(id, data) {
    axios
      .post(getUrl('credits.refuse', [id]), data)
      .then(res => {
        if (res.status === 200) {
          successMsg(getTrad('success.refuseDemandeCredit'))
          this.refresh()
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            errorMsg(getTrad('errors.cummonError'))
            this.refresh()
          } else {
            errorMsg(getTrad('errors.cummonError'))
          }
        } else {
          errorMsg(getTrad('errors.cummonError'))
        }
      })
  }

  refuse(e) {
    areYouSure.fire().then(async result => {
      if (result.value) {
        const { value: text } = await Swal.fire({
          title: getTrad('aides.motif'),
          confirmButtonColor: '#30d637',
          cancelButtonColor: '#d33',
          confirmButtonText: getTrad('commun.refuse'),
          cancelButtonText: getTrad('commun.cancel'),
          input: 'textarea',
          showCancelButton: true,
        })

        if (text) {
          this.refuseRequest(e.id, { reponse: text })
        } else {
          errorMsg(getTrad('errors.motifRequired'))
        }
      }
    })
  }

  exportData() {}

  renderBody() {
    let header = {}

    let keys = [
      'id',
      'matricule_adh',
      'first_name',
      'last_name',
      'montant_demande',
      'motif',
      'date_demande',
      'actions',
    ]

    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`fileds.${keys[i]}`)
    }

    let showProps = {
      data: this.state.listDemandes,
      keys: keys,
      header: header,
      noData: getTrad(`commun.noSearchData`),
      searching: this.state.loading,
    }

    return (
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h2 className="card-title">{getTrad('pages.listDemandesCredits')}</h2>
          </div>
          <div className="col text-right">
            <div className="btn-group" role="group">
              <button className="btn btn-primary" onClick={this.exportData}>
                <i className="fas fa-file-export"></i> {getTrad('commun.export')}
              </button>
              <Link to={`/credit/add`} className="btn btn-success">
                <i className="fas fa-plus"></i> {getTrad('commun.add')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
