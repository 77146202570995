import { getTrad } from '../../../../lang'

export const keys = [
  'matricule',
  'matricule_cnrps',
  'first_name',
  'last_name',
  'sexe',
  'profession',
  'birth_day',
]

export const exportheaders = {
  matricule: getTrad('adherant.matricule'),
  matricule_cnrps: getTrad('adherant.matricule_cnrps'),
  first_name: getTrad('adherant.first_name'),
  last_name: getTrad('adherant.last_name'),
  sexe: getTrad('adherant.sexe'),
  profession: getTrad('adherant.profession'),
  birth_day: getTrad('adherant.birth_day'),
}
