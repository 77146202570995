import React, { useState } from 'react'
import { Api } from '../../../../../Api'

function ShowJustif({ proof }) {
  const [show, setShow] = useState(false)
  return (
    <>
      <button onClick={() => setShow(!show)} className={`btn btn-sm btn-primary`}>
        {show ? <i className="fas fa-times"></i> : <i className="fas fa-eye"></i>}
      </button>
      {show && (
        <img
          width="100%"
          style={{ display: 'block' }}
          src={Api.baseURL + '/..' + proof}
          alt="preuve"
        />
      )}
    </>
  )
}
export default ShowJustif
