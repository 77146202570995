export const fr = {
  1: 'Primaire',
  2: 'Collège',
  3: 'Lycée',
  4: 'Formation professionnelle',
  5: 'Université',
  6: 'Aucun',
  null: ' ',
}

export const ar = {
  1: 'ابتدائي',
  2: 'اعدادية',
  3: 'ثانوية',
  4: 'تدريب مهني',
  5: 'جامعة',
  6: 'لا تفاصيل',
  null: ' ',
}

export const en = {
  1: 'Elementary',
  2: 'Middle school',
  3: 'High school',
  4: 'Professional training',
  5: 'University',
  6: 'None',
  null: ' ',
}
