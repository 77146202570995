import React, { Component } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { Link } from 'react-router-dom'
import ShowTable from '../../../components/ShowTable'
import Export from '../../../components/Export'
import { getTrad } from '../../../lang'
import { showDate, showSexe, inString, has18 } from '../../../helpers'
import InputText from '../../../components/Form/InputText'
import InputSelect from '../../../components/Form/InputSelect'
import { LangContext } from '../../../context/LangContext'

const keys = ['matricule', 'last_name', 'first_name', 'sexe', 'birth_day', 'lvl_studies', 'actions']
const exportheaders = {
  matricule: getTrad('adherant.matricule'),
  last_name: getTrad('adherant.last_name'),
  first_name: getTrad('adherant.first_name'),
  sexe: getTrad('adherant.sexe'),
  birth_day: getTrad('adherant.birth_day'),
  lvl_studies: getTrad('adherant.lvl_studies'),
}

class Enfants extends Component {
  static contextType = LangContext
  constructor(props) {
    super(props)
    this.state = {
      searching: true,
      list_enfants: [],
      list_enfants_filtred: [],
      lvl_studies: '',
      matricule: '',
      first: '',
      last: '',
      ddn: '',
      has18: 'A',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value }, this.filter)
  }

  filter = () => {
    this.setState({ searching: true }, () => {
      const tmp = this.state.list_enfants.filter(e => {
        let tmp =
          inString(this.state.matricule, e.matricule) &&
          inString(this.state.last, e.last_name) &&
          inString(this.state.first, e.first_name) &&
          inString(this.state.ddn, e.birth_day)
        if (this.state.has18 !== 'A') {
          const test = has18(e.birth_day)
          tmp &= test && this.state.has18 === 'Y'
        }

        if (this.state.lvl_studies) {
          tmp &= e.lvl_studies_id?.toString() === this.state.lvl_studies
        }
        return tmp
      })
      this.setState({ searching: false, list_enfants_filtred: tmp })
    })
  }

  refresh = () => {
    axios.get(getUrl('adherants.enfants')).then(res => {
      let tmp = res.data.success.map(e => ({
        matricule: e.adherant.matricule,
        last_name: e.last_name,
        first_name: e.first_name,
        lvl_studies_id: e.lvl_studies,
        lvl_studies: getTrad(`lvl_studies.${e.lvl_studies}`),
        birth_day: showDate(e.birth_day),
        actions: (
          <div className="btn-group" role="group">
            <Link to={`/adherants/${e.adherant.matricule}`} className="btn btn-sm btn-primary">
              <i className="far fa-eye"></i>
            </Link>
          </div>
        ),
        sexe: showSexe(e.sexe),
      }))

      this.setState({ list_enfants: tmp, searching: false }, this.filter)
    })
  }

  renderFilters() {
    const { lvlStudies } = this.context.extra

    const lvlSudies = Object.keys(lvlStudies).map(k => ({
      text: lvlStudies[k],
      value: k,
    }))

    return (
      <div className="card-body">
        <div className="row">
          <div className="col">
            <InputText
              handler={this.handleInput}
              label={getTrad('adherant.matricule_adh')}
              name="matricule"
              value={this.state.matricule}
            />
          </div>
          <div className="col">
            <InputText
              handler={this.handleInput}
              label={getTrad('adherant.last_name')}
              name="last"
              value={this.state.last}
            />
          </div>
          <div className="col">
            <InputText
              handler={this.handleInput}
              label={getTrad('adherant.first_name')}
              name="first"
              value={this.state.first}
            />
          </div>

          <div className="col">
            <InputText
              handler={this.handleInput}
              label={getTrad('adherant.birth_day')}
              name="ddn"
              value={this.state.ddn}
            />
          </div>
          <div className="col">
            <InputSelect
              data={lvlSudies}
              handler={this.handleInput}
              label={getTrad('adherant.lvl_study')}
              name="lvl_studies"
              value={this.state.lvl_studies}
            />
          </div>
          <div className="col">
            <div className="form-group">
              <label className="d-block">{getTrad('adherant.has18')}</label>
              {['Y', 'N', 'A'].map((e, k) => {
                const check = this.state.has18 === e
                const id = `h18${e}`
                return (
                  <div key={k} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={check}
                      onChange={this.handleInput}
                      name="has18"
                      id={id}
                      value={e}
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {getTrad(`adherant.h18.${e}`)}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHader() {
    return (
      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <h2 className="card-title">{getTrad('navbar.list_enfants')}</h2>
          </div>

          <div className="col-6 text-right">
            <div className="btn-group" role="group" aria-label="Basic example">
              <Export
                headers={exportheaders}
                title={getTrad('commun.export')}
                fileName={'Liste_enfants_charge'}
                csvData={this.state.list_enfants_filtred}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`adherant.${k}`)
    }

    let showProps = {
      data: this.state.list_enfants_filtred,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }

    if (this.state.searching) {
      showProps.searching = true
    }

    return (
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    )
  }
  render() {
    return (
      <div className="card">
        {this.renderHader()}
        {this.renderFilters()}
        {this.renderBody()}
      </div>
    )
  }
}

export default Enfants
