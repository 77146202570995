import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Api } from '../../../Api'
import Export from '../../../components/Export'
import { getTrad, local } from '../../../lang'
import ShowTable from '../../../components/ShowTable'
import InputText from '../../../components/Form/InputText'
import InputSelect from '../../../components/Form/InputSelect'
import { showDate, showSexe, inString, has_age, filterSelect } from '../../../helpers'
import Import from './Import'
import SMSIndivi from './SMSIndivi'
import { LangContext } from '../../../context/LangContext'

const keys = [
  'matricule',
  'matricule_cnrps',
  'last_name',
  'first_name',
  'sexe',
  'email',
  'phone',
  'birth_day',
  'actions',
]

const exportheaders = {
  matricule: getTrad('adherant.matricule'),
  matricule_cnrps: getTrad('adherant.matricule_cnrps'),
  cin: getTrad('adherant.cin'),
  first_name: getTrad('adherant.first_name'),
  last_name: getTrad('adherant.last_name'),
  first_name_ar: getTrad('adherant.first_name_ar'),
  last_name_ar: getTrad('adherant.last_name_ar'),
  sexe: getTrad('adherant.sexe'),
  birth_day: getTrad('adherant.birth_day'),
  email: getTrad('adherant.email'),
  phone: getTrad('adherant.phone'),
  rib: getTrad('adherant.rib'),
  status: getTrad('adherant.status'),
  grade: getTrad('adherant.grade'),
  lieu: getTrad('adherant.lieu'),
  situationsfamiliale: getTrad('adherant.situationsfamiliale'),
  categorie: getTrad('adherant.categorie'),
}

class GestionAdmins extends Component {
  // Component Life Cycle
  static contextType = LangContext

  constructor(props) {
    super(props)
    this.state = {
      searching: true,
      listAdherants: [],
      listAdherantsFiltred: [],
      has62: 'A',
      matricule: '',
      matricule_cnrps: '',
      first_name: '',
      last_name: '',
      phone: '',
      birth_day: '',
      status: '',
      grade: '',
      lieu: '',
      situationsfamiliale: '',
      categorie: '',
    }
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <div className="card">
        {this.renderHader()}
        {this.renderSearchSection()}
        {this.renderBody()}
      </div>
    )
  }

  // Handlers

  // Action Bottons

  refresh() {
    let url = Api.baseURL + Api.crudAdherants
    axios.get(url).then(res => {
      let tmp = res.data.success.map(e => ({
        ...e,
        birth_day: showDate(e.birth_day),
        status: this.context.extra.status[e.status] || '',
        status_id: e.status,
        grade: this.context.extra.grade[e.grade] || '',
        grade_id: e.grade,
        lieu: this.context.extra.lieu[e.lieu] || '',
        lieu_id: e.lieu,
        situationsfamiliale: this.context.extra.situationsfamiliale[e.situationsfamiliale] || '',
        situationsfamiliale_id: e.situationsfamiliale,
        categorie: this.context.extra.categorie[e.categorie] || '',
        categorie_id: e.categorie,
        sexe: showSexe(e.sexe),
        actions: (
          <div className="btn-group" role="group">
            <SMSIndivi matricule={e.matricule} firstName={e.first_name} lastName={e.last_name} />
            <Link to={`/adherants/${e.matricule}`} className="btn btn-sm btn-primary">
              <i className="far fa-eye"></i>
            </Link>
            <Link to={`/adherants/${e.matricule}/edit`} className="btn btn-sm btn-secondary">
              <i className="far fa-edit"></i>
            </Link>
          </div>
        ),
      }))

      this.setState({ listAdherants: tmp, searching: false }, this.filter)
    })
  }

  handler = e => {
    this.setState({ [e.target.name]: e.target.value }, this.filter)
  }

  filter = () => {
    this.setState({ searching: true }, () => {
      let tmp = this.state.listAdherants.filter(e => {
        let test = true
        test &= inString(this.state.matricule, e.matricule)
        test &= inString(this.state.first_name, e.first_name)
        test &= inString(this.state.last_name, e.last_name)
        test &= inString(this.state.phone, e.phone)
        test &= inString(this.state.matricule_cnrps, e.matricule_cnrps)
        test &= inString(this.state.birth_day, e.birth_day)
        test &= filterSelect(e.status_id, this.state.status)
        test &= filterSelect(e.grade_id, this.state.grade)
        test &= filterSelect(e.lieu_id, this.state.lieu)
        test &= filterSelect(e.situationsfamiliale_id, this.state.situationsfamiliale)
        test &= filterSelect(e.categorie_id, this.state.categorie)
        if (this.state.has62 !== 'A') {
          test &= this.state.has62 === 'Y' ? has_age(e.birth_day, 62) : true
          test &= this.state.has62 === 'N' ? !has_age(e.birth_day, 62) : true
        }
        return test
      })

      this.setState({
        searching: false,
        listAdherantsFiltred: tmp,
      })
    })
  }

  // Rendering functions

  renderSearchSection() {
    return (
      <div className="card-body">
        <div className="row">
          {['matricule', 'matricule_cnrps', 'last_name', 'first_name', 'phone'].map((e, k) => (
            <div key={k} className="col">
              <InputText
                label={getTrad(`adherant.${e}`)}
                value={this.state[e]}
                name={e}
                handler={this.handler}
              />
            </div>
          ))}
          <div className="col">
            <div className="form-group">
              <label className="d-block">{getTrad('adherant.has62')}</label>
              {['Y', 'N', 'A'].map((e, k) => {
                const check = this.state.has62 === e
                const id = `h62${e}`
                return (
                  <div key={k} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={check}
                      onChange={this.handler}
                      name="has62"
                      id={id}
                      value={e}
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {getTrad(`adherant.h62.${e}`)}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row">
          {['status', 'grade', 'lieu', 'situationsfamiliale', 'categorie'].map((e, k) => {
            let dataObj = this.context.extra[e] ? this.context.extra[e] : local[e]
            let data = []
            if (dataObj) {
              data = Object.keys(dataObj).map(item => ({
                text: dataObj[item],
                value: item,
              }))
            }
            return (
              <div key={k} className="col">
                <InputSelect
                  data={data}
                  label={getTrad(`adherant.${e}`)}
                  value={this.state[e]}
                  name={e}
                  handler={this.handler}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderHader() {
    return (
      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <h2 className="card-title">{getTrad('pages.ListAdherants')}</h2>
          </div>

          <div className="col-6 text-right">
            <div className="btn-group" role="group" aria-label="Basic example">
              <Link to={`/adherants/add`} className="btn btn-success">
                <i className="fas fa-plus"></i> {getTrad('commun.add')}
              </Link>
              <Export
                headers={exportheaders}
                title={getTrad('commun.export')}
                fileName={'listAdherants'}
                csvData={this.state.listAdherantsFiltred}
              />
              <Import />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`adherant.${k}`)
    }

    let showProps = {
      data: this.state.listAdherantsFiltred,
      keys: keys,
      header: header,
      noData: getTrad(`commun.noSearchData`),
    }

    if (this.state.searching) {
      showProps.searching = true
    }

    return (
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    )
  }
}
export default GestionAdmins
