import React from 'react'
import { getTrad } from '../../../../lang'
import NumInput from '../../../../components/Form/NumInput'
import InputText from '../../../../components/Form/InputText'

function Filter(props) {
  return (
    <div className="row">
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule}
          label={getTrad('fileds.matricule')}
          name="matricule"
        />
      </div>
      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.matricule_cnrps}
          label={getTrad('fileds.matricule_cnrps')}
          name="matricule_cnrps"
        />
      </div>

      <div className="col">
        <div className="form-check-label">{getTrad('fileds.matricule_cnrps')}</div>
        {['A', 'Y', 'N'].map((e, k) => (
          <div key={k} className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id={`has_matricule_cnrps-${e}`}
              name="has_matricule_cnrps"
              onChange={props.handler}
              checked={props.has_matricule_cnrps === e}
              value={e}
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor={`has_matricule_cnrps-${e}`}>
              {getTrad(`aides.filter.${e}`)}
            </label>
          </div>
        ))}
      </div>

      <div className="col">
        <NumInput
          handler={props.handler}
          value={props.sous_compte}
          label={getTrad('fileds.sous_compte')}
          name="sous_compte"
        />
      </div>
      <div className="col">
        <div className="form-check-label">{getTrad('fileds.sous_compte')}</div>
        {['A', 'Y', 'N'].map((e, k) => (
          <div key={k} className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id={`has_sous_compte-${e}`}
              name="has_sous_compte"
              onChange={props.handler}
              checked={props.has_sous_compte === e}
              value={e}
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor={`has_sous_compte-${e}`}>
              {getTrad(`aides.filter.${e}`)}
            </label>
          </div>
        ))}
      </div>
      <div className="col">
        <InputText
          handler={props.handler}
          value={props.first_name}
          label={getTrad('fileds.first_name')}
          name="first_name"
        />
      </div>
      <div className="col">
        <InputText
          handler={props.handler}
          value={props.last_name}
          label={getTrad('fileds.last_name')}
          name="last_name"
        />
      </div>
    </div>
  )
}

export default Filter
