import React, { useState } from 'react'
import Waiting from '../../../../components/Waiting'
import Swal from 'sweetalert2'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import axios from 'axios'
import { successMsg, fatalError } from '../../../../helpers/sweetAlerts'

function Delete(props) {
  const [removing, setRemoving] = useState(false)

  const remove = () => {
    Swal.fire({
      title: getTrad('bs.modifdelete'),
      input: 'textarea',
      inputAttributes: { autocapitalize: 'off' },
      showCancelButton: true,
      confirmButtonColor: '#dd3333',
      cancelButtonColor: '#11cc11',
      confirmButtonText: getTrad('commun.deleteBtn'),
      cancelButtonText: getTrad('commun.cancel'),
      showLoaderOnConfirm: true,
      preConfirm: obs => {
        if (!obs) remove()
        else {
          let data = { message: obs }
          const url = getUrl('bs.crud') + '/' + props.bs_number + '/delete'
          axios
            .patch(url, data)
            .then(res => {
              successMsg(getTrad('success.deleteBS'))
              setRemoving(false)
              props.refresh()
            })
            .catch(err => {
              fatalError()
              setRemoving(false)
              props.refresh()
            })
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })

    setRemoving(true)
  }
  return (
    <button disabled={removing} onClick={remove} className="btn btn-danger btn-sm">
      {removing ? <Waiting /> : <i className="far fa-trash-alt"></i>}
    </button>
  )
}
export default Delete
