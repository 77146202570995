import React from 'react'

import { Redirect } from 'react-router-dom'
import { showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'

export default function ShowCredit(props) {
  let credit = props.listCredits.filter(e => e.status === 'A')[0]
  if (credit) {
    let reste = credit.montant_rembours

    let remboursement = credit.remboursements
      .map(e => ({ ...e, date: new Date(e.created_at) }))
      .sort((a, b) => a.date - b.date)

    return (
      <>
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <h2 className="card-title">{getTrad('pages.monCreditEnCour')}</h2>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <b>{getTrad('credit.montant_demande')} :</b>
                <br />
                {showMoney(credit.montant_demande)}
              </div>
              <div className="col">
                <b>{getTrad('credit.montant_rembours')} :</b>
                <br />
                {showMoney(credit.montant_rembours)}
              </div>
              <div className="col">
                <b>{getTrad('credit.nb_echeance')} :</b>
                <br />
                {credit.nb_echeance}
              </div>
              <div className="col">
                <b>{getTrad('credit.date_demande')} :</b>
                <br />
                {showDate(credit.date_demande)}
              </div>
              <div className="col">
                <b>{getTrad('credit.date_acceptation')} :</b>
                <br />
                {showDate(credit.updated_at)}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <b>{getTrad('credit.motif')} :</b>
                <br />
                {credit.motif}
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3 p-0">
          <table className="table">
            <thead>
              <tr>
                <th>{getTrad('credit.date_rembours')}</th>
                <th>{getTrad('credit.montant_rembours')}</th>
                <th>{getTrad('credit.rest')}</th>
              </tr>
            </thead>
            <tbody>
              {remboursement.map((e, k) => {
                reste -= e.montant_rembours
                return (
                  <tr key={k}>
                    <td>{showDate(e.date)}</td>
                    <td>{showMoney(e.montant_rembours)}</td>
                    <td>{showMoney(reste)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return <Redirect to="/" />
}
