import React from 'react'
import PropTypes from 'prop-types'

export default function NumInput(props) {
  let label = props.label
  let name = props.name
  let value = props.value
  let inputClass = ['form-control', 'w-100', 'form-control-sm']
  let error = []

  if (Array.isArray(props.error)) {
    if (props.error.length) {
      inputClass.push('is-invalid')
    }

    props.error.map(e => {
      return error.push(e)
    })
  }

  const Label = () => {
    if (props.dontShow) return <></>
    return <label htmlFor={name}>{label}</label>
  }

  return (
    <div className="form-group">
      <Label />
      <input
        type="number"
        name={name}
        id={name}
        placeholder={label}
        onChange={props.handler}
        className={inputClass.join(' ')}
        value={value}
      />
      <div className="invalid-feedback">
        {error.map((e, k) => {
          return <li key={k}>{e}</li>
        })}
      </div>
    </div>
  )
}

NumInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}
