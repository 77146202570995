import React, { useState } from 'react'
import Waiting from '../../../../components/Waiting'
import { areYouSure, fatalError, successMsg } from '../../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'

function Refuse(props) {
  const [sending, setSending] = useState(false)
  const send = () => {
    setSending(true)
    areYouSure.fire().then(confirm => {
      if (confirm.isConfirmed) {
        axios
          .put(getUrl('parent.contract.refuse', [props.id]))
          .then(res => {
            setSending(false)
            successMsg(getTrad('success.parent.deleteContract'))
            props.refresh()
          })
          .catch(() => {
            setSending(false)
            fatalError()
          })
      } else {
        setSending(false)
      }
    })
  }

  return (
    <button onClick={send} disabled={sending} className="btn btn-sm btn-danger">
      {sending ? <Waiting /> : <i className="fas fa-times" />}
    </button>
  )
}

export default Refuse
