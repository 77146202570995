import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import axios from 'axios'
import Waiting from '../../../../components/Waiting'
import { areYouSure, fatalError, errorToast, successToast } from '../../../../helpers/sweetAlerts'

class Add extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false,
      show: false,
      prix: '',
      condidat: '',
      proof: '',
      proofVal: '',
    }
  }

  handleShow = () => this.setState({ show: !this.state.show })

  handleFile = e => {
    let reader = new FileReader()
    if (e.target.files.length < 1) {
      return false
    }
    if (e.target.files[0].size > 3 * 1024 * 1024) {
      errorToast.fire({ text: getTrad('errors.bigFile') })
      return false
    }

    reader.readAsDataURL(e.target.files[0])
    reader.onload = evnt => this.setState({ proof: evnt.target.result })
  }

  send = e => {
    let data = {
      prix: this.state.prix,
      condidat: this.state.condidat,
      proof: this.state.proof,
    }

    axios
      .post(getUrl('journeeSciences.condidat.myCondidature'), data)
      .then(res => {
        this.setState({ sending: false, show: false, prix: '', condidat: '', proof: '' })
        successToast.fire({ text: getTrad('science.successCondiature') })
        this.props.refresh()
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            this.props.refresh()
          }
        }
        this.setState({ sending: false, show: true })
        fatalError()
      })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  resetImg = e => this.setState({ proof: '' })

  render() {
    let listCondidat = this.props.family.filter(e => {
      for (var i = 0; i < this.props.condidats.length; i++) {
        if (this.props.condidats[i].eleve.id === e.id && this.props.condidats[i].status !== 'R')
          return false
      }
      return true
    })

    if (listCondidat.length === 0)
      return <span className="badge badge-danger">{getTrad('science.noCondidat')}</span>

    return (
      <>
        <button className="btn btn-sm btn-success" onClick={this.handleShow}>
          <i className="fas fa-plus"></i>
        </button>
        <Modal show={this.state.show} centered onHide={this.handleShow}>
          <Modal.Header closeButton>
            <Modal.Title>{getTrad('science.newCondidat')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="prix">{getTrad('science.prix')} :</label>
              <select
                className="form-control"
                id="prix"
                name="prix"
                value={this.state.prix}
                onChange={this.handleChange}
              >
                <option value=""></option>
                {this.props.prix.map((e, k) => (
                  <option key={k} value={e.id}>
                    {e.niveau}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="condidat">{getTrad('science.condidat')} :</label>
              <select
                className="form-control"
                id="condidat"
                name="condidat"
                value={this.state.condidat}
                onChange={this.handleChange}
              >
                <option value=""></option>
                {listCondidat.map((e, k) => (
                  <option key={k} value={e.id}>
                    {e.last_name} {e.first_name}
                  </option>
                ))}
              </select>
            </div>
            {this.state.proof === '' ? (
              <div className="form-group">
                <label htmlFor="preuve">{getTrad('science.preuve')}</label>
                <input
                  type="file"
                  className="form-control-file"
                  value={this.state.proofVal}
                  id="preuve"
                  accept="image/*"
                  onChange={this.handleFile}
                />
              </div>
            ) : (
              <>
                <button className="btn" onClick={this.resetImg}>
                  <i className="fas fa-times" />
                </button>
                <img src={this.state.proof} className="w-100" alt={getTrad('science.preuve')} />
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() =>
                areYouSure.fire().then(result => {
                  if (result.value) this.setState({ sending: true }, this.send)
                })
              }
              disabled={this.state.sending}
            >
              {this.state.sending ? (
                <Waiting />
              ) : (
                <>
                  {getTrad('commun.send')} <i className="fas fa-paper-plane"></i>
                </>
              )}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Add
