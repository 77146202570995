import React from 'react'
// import { getTrad } from '../../../../lang'

function Demande(props) {
  return <></>

  // return (
  //   <div className="card mb-3">
  //     <div className="card-header">
  //       <h3 className="card-title">{getTrad('pages.parent.demande')}</h3>
  //     </div>
  //     <div className="card-body"></div>
  //   </div>
  // )
}

export default Demande
