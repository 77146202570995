import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import ShowCredit from './ShowCredit'
import { getTrad } from '../../../../lang'

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      credit: null,
      redirect: false,
    }
  }

  refresh = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    axios
      .get(getUrl('credits.show', [id]))
      .then(res => {
        let credit = res.data.success
        credit.remboursements = credit.remboursements.map(e => ({
          ...e,
          typeString: getTrad(`credit.type_remboursement.${e.type}`),
        }))
        this.setState({ loading: false, credit: credit })
      })
      .catch(err => this.setState({ redirect: true, loading: false }))
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const {
      match: {
        params: { id: oldId },
      },
    } = prevProps

    if (id !== oldId) this.refresh()
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    let { loading, credit, redirect } = this.state

    if (loading) return <Waiting />

    if (credit) return <ShowCredit {...credit} refresh={this.refresh} />

    if (redirect) return <Redirect to="/credit" />

    return <Redirect to="/" />
  }
}

export default Show
