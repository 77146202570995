import { ar as bs } from './locals/bs'
import { ar as log } from './locals/log'
import { ar as auth } from './locals/auth'
import { ar as type } from './locals/type'
import { ar as login } from './locals/login'
import { ar as pages } from './locals/pages'
import { ar as aides } from './locals/aides'
import { ar as saisie } from './locals/saisie'
import { ar as fileds } from './locals/fileds'
import { ar as navbar } from './locals/navbar'
import { ar as errors } from './locals/errors'
import { ar as credit } from './locals/credit'
import { ar as commun } from './locals/commun'
import { ar as success } from './locals/success'
import { ar as science } from './locals/science'
import { ar as adherant } from './locals/adherant'
import { ar as bordoreau } from './locals/bordoreau'
import { ar as pagination } from './locals/pagination'
import { ar as validation } from './locals/validation'
import { ar as aidesociale } from './locals/aidesociale'
import { ar as condidature } from './locals/condidature'
import { ar as lvl_studies } from './locals/lvl_studies'
import { ar as situationsfamiliale } from './locals/situationsfamiliale'
import { fr as parent } from './locals/parent'

let local = {
  bs: bs,
  log: log,
  auth: auth,
  type: type,
  login: login,
  pages: pages,
  aides: aides,
  saisie: saisie,
  fileds: fileds,
  parent: parent,
  navbar: navbar,
  errors: errors,
  credit: credit,
  commun: commun,
  success: success,
  science: science,
  adherant: adherant,
  bordoreau: bordoreau,
  pagination: pagination,
  validation: validation,
  aidesociale: aidesociale,
  condidature: condidature,
  lvl_studies: lvl_studies,
  situationsfamiliale: situationsfamiliale,
}

export default local
