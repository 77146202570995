import React, { Component } from 'react'
import { getTrad } from '../../../lang'
import Swal from 'sweetalert2'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { getUrl } from '../../../Api'
import InputMounth from '../../../components/Form/InputMounth'
import moment from 'moment'
import { fatalError } from '../../../helpers/sweetAlerts'
import { convertDate } from '../../../helpers'

function downloadData(filename, successData, failedData) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const cleanData = successData.map(e => ({
    'Matricule CNRPS': e.cnrps,
    'Sous compte': e.sous_compte,
    Montant: e.montant,
  }))

  const problemData = failedData.map(e => ({
    'Matricule CNRPS': e.cnrps,
    'Sous compte': e.sous_compte,
    Montant: e.montant,
  }))

  const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

  const ws = XLSX.utils.json_to_sheet(cleanData)
  const ws1 = XLSX.utils.json_to_sheet(problemData)
  let wb = { Sheets: {}, SheetNames: [] }
  wb.SheetNames.push('Success')
  wb.SheetNames.push('Erreurs')
  wb.Sheets['Success'] = ws
  wb.Sheets['Erreurs'] = ws1

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const downData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(downData, filename + '-' + now + fileExtension)
}

function ImportModel(props) {
  const messages = {
    R: getTrad('saisie.import.reading'),
    V: getTrad('saisie.import.verify'),
    S: getTrad('saisie.import.sending'),
    E: getTrad('saisie.import.error'),
    // D: getTrad('saisie.import.date'),
    F: getTrad('saisie.import.finish'),
  }

  const show = ['V', 'D', 'S', 'F', 'E'].includes(props.status)

  const CloseBtn = () => {
    if (props.status === 'F') {
      return (
        <Modal.Footer>
          <button onClick={props.close} className="btn btn-success w-100">
            {getTrad('commun.close')}
          </button>
        </Modal.Footer>
      )
    }
    return <></>
  }

  if (props.status === 'D') {
    return (
      <Modal show={show} centered onHide={() => false}>
        <Modal.Body>
          {/* saveDate={this.confirmDate} */}
          <InputMounth
            handler={props.handler}
            value={props.date}
            name="date"
            label="Date d'importation"
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={props.saveDate} className="btn btn-success w-100">
            <i className="far fa-calendar-check mr-2"></i>
            {getTrad('commun.select')}
          </button>
        </Modal.Footer>
        <CloseBtn />
      </Modal>
    )
  }

  return (
    <Modal show={show} centered onHide={() => false}>
      <Modal.Body>
        <h3 className="text-center">{messages[props.status]}</h3>
      </Modal.Body>
      <CloseBtn />
    </Modal>
  )
}

const clearInput = e => ({
  cnrps: e['Matricule CNRPS'],
  sous_compte: e['Sous compte'],
  montant: e['Montant'],
})

class Import extends Component {
  constructor(props) {
    super(props)
    this.state = {
      /*
        N: New
        R: Reading from file
        D: Reading Date
        V: Verifiying
        S: Sending
        F: Finish
        E: Error
      */

      status: 'N', // N, R, D, V, S, F, E
      toImportSaisie: [],
      toSend: [],
      errors: [],
      date: moment().format('MM-YYYY'),
    }
  }

  download = async () => {
    const cnrps = await axios.get(getUrl('crudAdherants')).then(res =>
      res.data.success
        .map(e => e.matricule_cnrps)
        .filter(e => e !== null)
        .map(e => e.toString())
    )

    let errors = this.state.toImportSaisie.filter(
      e => e.cnrps === undefined || e.sous_compte === undefined || e.montant === undefined
    )

    let data = this.state.toImportSaisie.filter(
      e => e.cnrps !== undefined && e.sous_compte !== undefined && e.montant !== undefined
    )

    let errors2 = data.filter(e => !cnrps.includes(e.cnrps.toString()))

    this.setState(
      {
        status: 'S',
        toSend: data.filter(e => cnrps.includes(e.cnrps.toString())),
        errors: errors.concat(errors2),
      },
      this.upload
    )
  }

  upload = () => {
    axios
      .post(getUrl('parent.saisie.import'), {
        date: convertDate('01-' + this.state.date),
        data: this.state.toSend,
      })
      .then(res => {
        downloadData(
          'Importation Saisie parents',
          res.data.success.done,
          res.data.success.errors.concat(this.state.errors)
        )

        this.setState({ status: 'F' }, this.props.reload)
      })
      .catch(fatalError)
  }

  handleDate = e => this.setState({ [e.target.name]: e.target.value })

  confirmDate = () => this.setState({ status: 'V' }, this.download)

  readData = e => {
    let reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = async e => {
      var data = new Uint8Array(reader.result)
      var wb = XLSX.read(data, { type: 'array' })
      const { value: sheet } = await Swal.fire({
        title: getTrad('commun.chooseSheet'),
        input: 'select',
        inputOptions: wb.SheetNames,
        inputPlaceholder: getTrad('commun.chooseSheet'),
        showCancelButton: true,
        cancelButtonText: getTrad('commun.cancel'),
        confirmButtonText: getTrad('commun.import'),
      })
      if (sheet !== undefined && sheet !== '') {
        this.setState({ status: 'R' }, () => {
          let jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[sheet]])
          this.setState({ toImportSaisie: jsonData.map(clearInput), status: 'D' })
        })
      }
    }
  }

  render() {
    return (
      <>
        <input
          type="file"
          onChange={this.readData}
          style={{ display: 'none' }}
          ref={fileInput => (this.fileInput = fileInput)}
        />

        <ImportModel
          date={this.state.date}
          saveDate={this.confirmDate}
          handler={this.handleDate}
          status={this.state.status}
          close={() => this.setState({ status: 'C' })}
        />

        <button
          id="excelFileBtn"
          onClick={() => this.fileInput.click()}
          className="btn btn-primary"
        >
          <i className="fas fa-file-import"></i> {getTrad('commun.import')}
        </button>
      </>
    )
  }
}

export default Import
