import React, { Component } from 'react'
import Swal from 'sweetalert2'

import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import { areYouSure } from '../../../../helpers/sweetAlerts'

export default class Family extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  render() {
    return (
      <button className="btn btn-danger btn-sm" onClick={this.delete}>
        {this.state.loading ? <Waiting /> : <i className="far fa-trash-alt"></i>}
      </button>
    )
  }

  // Action Bottons

  delete = () => {
    this.setState({ loading: true }, () => {
      areYouSure
        .fire()
        .then(result => {
          if (result.value) {
            // let url =  //`${Api.baseURL}${Api.crudPrestataire}/${}`
            axios
              .delete(getUrl('prestataire.delete', [this.props.member.id]))
              .then(res => {
                Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
                let tmp = []
                this.props.family.map(e => {
                  if (e.id !== this.props.member.id) {
                    tmp.push(e)
                    return true
                  }
                  return false
                })
                this.props.familyHandle(tmp)
              })
              .catch(err => {
                this.setState({ loading: false })
              })
          } else {
            this.setState({ loading: false })
          }
        })
        .catch(err => {
          this.setState({ loading: false })
        })
    })
  }
}
