import React, { Component, useState } from 'react'
import { getTrad } from '../../../lang'
import { AVALIBLE_LANGS } from '../../../helpers/consts'
import { getUrl } from '../../../Api'
import axios from 'axios'
import { RenderAddBtn } from './utils'
import { getLang } from '../../../helpers'
import InputText from '../../../components/Form/InputText'
import Local from './Local'
import { areYouSure, successToast, fatalError } from '../../../helpers/sweetAlerts'

let locals = AVALIBLE_LANGS

function DeleteBtn(props) {
  const { id } = props
  const destroy = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        axios.delete(getUrl('etablissements.destroy', [id])).then(res => {
          successToast.fire()
          props.refresh()
        })
      }
    })
  }

  return (
    <button className="btn btn-danger btn-sm" onClick={destroy}>
      <i className="fas fa-trash" />
    </button>
  )
}

function SendBtn(props) {
  const { id, newGouv } = props

  const send = () => {
    axios
      .patch(getUrl('etablissements.update', [id]), { id_gouvernaurat: newGouv })
      .then(res => {
        successToast.fire()
        props.close()
        props.refresh()
      })
      .catch(err => fatalError())
  }
  return (
    <button className="btn btn-success btn-sm" type="button" onClick={send}>
      <i className="fas fa-check" />
    </button>
  )
}

function ShowGouv(props) {
  let randId = Math.floor(Math.random() * 1000)

  let { id_gouvernaurat } = props

  id_gouvernaurat = id_gouvernaurat ? id_gouvernaurat.toString() : ''

  let [gouvId, setGouvId] = useState(id_gouvernaurat)
  let [edit, setEdit] = useState(false)

  const showEdit = () => setEdit(true)
  const hideEdit = () => {
    setEdit(false)
    setGouvId(id_gouvernaurat)
  }

  const handle = event => {
    setGouvId(event.target.value)
  }

  let spanClass = id_gouvernaurat ? '' : 'text-muted'
  let inner = id_gouvernaurat
    ? props.gouvs.filter(e => {
        return e.id.toString() === id_gouvernaurat
      })[0].value
    : getTrad('commun.dbToAdd')

  if (edit) {
    return (
      <div className="input-group">
        <select
          className="form-control form-control-sm w-100"
          aria-describedby={`button-addon-${randId}`}
          onChange={handle}
          value={gouvId}
        >
          <option value=""></option>
          {props.gouvs.map((e, k) => (
            <option key={k} value={e.id}>
              {e.value}
            </option>
          ))}
        </select>
        <div className="input-group-append" id={`button-addon-${randId}`}>
          <button className="btn btn-danger btn-sm" type="button" onClick={hideEdit}>
            <i className="fas fa-times" />
          </button>
          <SendBtn {...props} newGouv={gouvId} close={hideEdit} />
        </div>
      </div>
    )
  }
  return (
    <span className={spanClass} onDoubleClick={showEdit}>
      {inner}
    </span>
  )
}

function AddFields(props) {
  let gov = props.toAdd.gouv ? props.toAdd.gouv : ''
  if (!props.adding) return <></>
  return (
    <tr>
      <td>#</td>
      <td>
        <select value={gov} name="gouv" className="form-control-sm w-100" onChange={props.handler}>
          <option value=""></option>
          {props.listGouvs.map((e, k) => {
            return (
              <option key={k} value={e.id}>
                {e.value}
              </option>
            )
          })}
        </select>
      </td>
      {locals.map((e, k) => (
        <td key={k}>
          <InputText
            dontShow
            value={props.toAdd[e]}
            handler={props.handler}
            name={e}
            label={getTrad(`commun.local.${e}`)}
          />
        </td>
      ))}

      <td>
        <button className="btn btn-sm btn-success" onClick={props.add}>
          <i className="fas fa-check" />
        </button>
      </td>
    </tr>
  )
}

const clearGouvs = item => {
  let value = ''
  if (item.locals.length !== 0) {
    value = item.locals[0].value
    for (var i = 0; i < item.locals.length; i++) {
      if (item.locals[i].lang === getLang()) {
        value = item.locals[i].value
      }
    }
  }

  return { id: item.id, value: value }
}

const clearEtab = item => {
  let etabtmp = {
    id: item.id,
    id_gouvernaurat: item.id_gouvernaurat,
  }

  locals.forEach((loc, i) => {
    const thisLoc = item.locals.filter(e => loc === e.lang)
    etabtmp[loc] = {}
    if (thisLoc[0]) {
      etabtmp[loc] = {
        id: thisLoc[0].id,
        value: thisLoc[0].value,
      }
    }
  })
  return etabtmp
}

class Etabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      adding: false,
      toAdd: {
        gouv: null,
        fr: '',
        ar: '',
        en: '',
      },
      listEtabs: [],
      listGouvs: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    axios.get(getUrl('gouvernaurats.index')).then(res => {
      this.setState({ listGouvs: res.data.success.map(clearGouvs) }, () => {
        axios.get(getUrl('etablissements.index')).then(res => {
          this.setState({ loading: false, listEtabs: res.data.success.map(clearEtab) })
        })
      })
    })
  }

  handler = event => {
    let tmp = {}
    Object.assign(tmp, this.state.toAdd)
    tmp[event.target.name] = event.target.value
    this.setState({ toAdd: tmp })
  }

  addFields = () => this.setState({ adding: true })
  closeFields = () =>
    this.setState({
      adding: false,
      toAdd: { gouv: null, fr: '', ar: '', en: '' },
    })

  add = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        axios.post(getUrl('etablissements.create'), this.state.toAdd).then(res => {
          successToast.fire()
          this.refresh()
          this.closeFields()
        })
      }
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>{getTrad('pages.listEtabs')}</h3>
        </div>
        <div className="card-body p-0">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>{getTrad(`commun.gouv`)}</th>
                {locals.map((e, k) => (
                  <th key={k}>{getTrad(`commun.local.${e}`)}</th>
                ))}
                <th width="1">
                  <RenderAddBtn
                    addFields={this.addFields}
                    closeFields={this.closeFields}
                    {...this.state}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <AddFields
                {...this.props}
                {...this.state}
                locals={locals}
                handler={this.handler}
                add={this.add}
              />
              {this.state.listEtabs.map((e, k) => {
                return (
                  <tr key={k}>
                    <td>{e.id}</td>
                    <td>
                      <ShowGouv {...e} refresh={this.refresh} gouvs={this.state.listGouvs} />
                    </td>
                    {locals.map((i, key) => (
                      <td key={key}>
                        <Local
                          superId={e.id}
                          local={i}
                          supertype="etab"
                          {...e[i]}
                          refresh={this.refresh}
                        />
                      </td>
                    ))}
                    <td>
                      <DeleteBtn id={e.id} refresh={this.refresh} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Etabs
