import React from 'react'
import { Link } from 'react-router-dom'
import { useTrad } from '../../../../lang'

function Header() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-6">
          <h2 className="card-title">{useTrad('pages.crudAdmin_page')}</h2>
        </div>
        <div className="col-6 text-right">
          <div className="btn-group" role="group" aria-label="Basic example">
            <Link to="/gestion-admins/add" className="btn btn-success">
              <i className="fas fa-plus"></i> {useTrad('commun.add')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
