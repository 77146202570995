import React from 'react'
import PropTypes from 'prop-types'
import { validDate } from '../../helpers/'

function InputDate(props) {
  let name = props.name
  let value = props.value
  let label = props.label ? <label htmlFor={name}>{props.label}</label> : null
  let inputClassArray = ['form-control', 'w-100', 'form-control-sm', 'm-0']

  let error = []

  if (props.error) {
    props.error.map(e => error.push(e))
    inputClassArray.push('is-invalid')
  }

  if (props.value !== '' && !validDate(props.value)) {
    inputClassArray.push('is-invalid')
    error.push('DD-MM-YYYY')
  }

  let inputClass = inputClassArray.join(' ')

  if (props.dontShow) label = <></>

  return (
    <div className="form-group">
      {label}
      <input
        type="text"
        name={name}
        id={name}
        placeholder="DD-MM-YYYY"
        onChange={props.handler}
        value={value}
        className={inputClass}
      />
      <div className="invalid-feedback">
        {error.map((e, k) => {
          return <li key={k}>{e}</li>
        })}
      </div>
    </div>
  )
}

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}

export default InputDate
