import React from 'react'
import InputText from '../../../components/Form/InputText'
import { getTrad } from '../../../lang'
import Waiting from '../../../components/Waiting'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { areYouSure, successToast, fatalError } from '../../../helpers/sweetAlerts'
import { AVALIBLE_LANGS } from '../../../helpers/consts'

let locals = AVALIBLE_LANGS

export const cleanLocals = item => {
  if (!locals.includes(item.lang)) {
    locals.push(item.lang)
  }

  return {
    id: item.id,
    lang: item.lang,
    value: item.value,
  }
}

export function RenderAddBtn(props) {
  if (props.adding)
    return (
      <button className="btn btn-danger btn-sm w-100" onClick={props.closeFields}>
        <i className="fas fa-times" />
      </button>
    )
  return (
    <button className="btn btn-success btn-sm w-100" onClick={props.addFields}>
      <i className="fas fa-plus" />
    </button>
  )
}

export function DeleteBtn(props) {
  const { id } = props
  const destroy = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        axios
          .delete(getUrl('gouvernaurats.destroy', [id]))
          .then(res => {
            successToast.fire({ title: getTrad('success.gouv.delete') })
            props.refresh()
          })
          .catch(err => {
            fatalError()
            props.refresh()
          })
      }
    })
  }
  return (
    <button className="btn btn-sm btn-danger" onClick={destroy}>
      <i className="fas fa-trash-alt"></i>
    </button>
  )
}

export function SelectBtn(props) {
  const { id, selected } = props

  if (id === selected)
    return (
      <button className="btn btn-sm btn-primary" onClick={() => props.select(null)}>
        <i className="fas fa-arrow-left"></i>
      </button>
    )

  return (
    <button className="btn btn-sm btn-info" onClick={() => props.select(id)}>
      <i className="fas fa-arrow-right"></i>
    </button>
  )
}

export function RenderAddFields(props) {
  if (!props.adding) return <></>
  let btn = props.sendAdd ? (
    <Waiting />
  ) : (
    <button className="btn btn-sm btn-success" onClick={props.add}>
      <i className="fas fa-plus" />
    </button>
  )
  return (
    <tr>
      <td>#</td>
      <td>#</td>
      {props.locals.map((i, key) => (
        <td key={key}>
          <InputText
            dontShow
            value={props.toAdd[i]}
            handler={props.handler}
            name={i}
            label={getTrad(`commun.local.${i}`)}
          />
        </td>
      ))}
      <td width="1">{btn}</td>
    </tr>
  )
}
