import { fr as bs } from './locals/bs'
import { fr as log } from './locals/log'
import { fr as auth } from './locals/auth'
import { fr as type } from './locals/type'
import { fr as login } from './locals/login'
import { fr as pages } from './locals/pages'
import { fr as aides } from './locals/aides'
import { fr as parent } from './locals/parent'
import { fr as saisie } from './locals/saisie'
import { fr as fileds } from './locals/fileds'
import { fr as navbar } from './locals/navbar'
import { fr as errors } from './locals/errors'
import { fr as credit } from './locals/credit'
import { fr as commun } from './locals/commun'
import { fr as success } from './locals/success'
import { fr as science } from './locals/science'
import { fr as adherant } from './locals/adherant'
import { fr as bordoreau } from './locals/bordoreau'
import { fr as pagination } from './locals/pagination'
import { fr as validation } from './locals/validation'
import { fr as aidesociale } from './locals/aidesociale'
import { fr as condidature } from './locals/condidature'
import { fr as lvl_studies } from './locals/lvl_studies'
import { fr as situationsfamiliale } from './locals/situationsfamiliale'

let local = {
  bs: bs,
  log: log,
  auth: auth,
  type: type,
  login: login,
  pages: pages,
  aides: aides,
  saisie: saisie,
  fileds: fileds,
  navbar: navbar,
  errors: errors,
  parent: parent,
  credit: credit,
  commun: commun,
  success: success,
  science: science,
  adherant: adherant,
  bordoreau: bordoreau,
  pagination: pagination,
  validation: validation,
  aidesociale: aidesociale,
  condidature: condidature,
  lvl_studies: lvl_studies,
  situationsfamiliale: situationsfamiliale,
}

export default local
