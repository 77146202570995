import moment from 'moment'
import { getTrad } from '../lang'
import { DEVISESTR, DEVISEAFTER, TENPOWAFTER, DB_DATE_FORMAT, SHOW_DATE_FORMAT } from './consts'
import { SHOW_DATETIME_FORMAT, ROLE_SUPER_USER, AVALIBLE_LANGS, DATEEXPRESSION } from './consts'
import { ROLE_MAGISTRAT, MOUNTHEXPRESSION, SHOW_MOUNTH_FORMAT } from './consts'

export const isGoodPassword = pass => pass.toString().length >= 8
export const formatFloat = number => Math.round(number * TENPOWAFTER) / TENPOWAFTER
export const showMoney = num =>
  formatFloat(num).toFixed(DEVISEAFTER).toString().split('.').join(',') + ` ${DEVISESTR}`
export const validDate = date => DATEEXPRESSION.test(date)
export const validMounth = date => MOUNTHEXPRESSION.test(date)
export const getJwt = () => localStorage.getItem('jwt')
export const removeJwt = () => localStorage.removeItem('jwt')
export const getLang = () => (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr')
export const setJwt = jwt => localStorage.setItem('jwt', jwt)
export const setLang = lang => localStorage.setItem('lang', lang)
export const isLang = str => AVALIBLE_LANGS.includes(str)

export const isNotSuperAdmin = role => role !== ROLE_SUPER_USER
export const isNotAdmin = role => role === ROLE_MAGISTRAT
export const isAdmin = role => !isNotAdmin(role)
export const isSuperAdmin = role => role === ROLE_SUPER_USER

/**
 * Return True if it found the substring in the string and false else
 * @param {String} s1 string to search
 * @param {String} s2 String to search in
 * @return {Boolean}
 */
export const inString = (s1, s2) => {
  s1 = s1 ? s1 : ''
  s2 = s2 ? s2 : ''
  let str1 = s1.toString().toUpperCase()
  let str2 = s2.toString().toUpperCase()
  let m = str1.length
  let n = str2.length
  for (let i = 0; i <= n - m; i++) {
    let j
    for (j = 0; j < m; j++) if (str2[i + j] !== str1[j]) break
    if (j === m) return true
  }
  return false
}

export const convertDate = date => {
  let d = moment(date, SHOW_DATE_FORMAT)
  if (d._isValid) return d.format(DB_DATE_FORMAT)
  return date
}

export const beforeToday = date => {
  let d = moment(date, SHOW_DATE_FORMAT)
  const today = new Date()
  const compareDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
  return compareDate - d > 0
}

export const inThisYear = date => {
  let d = moment(date, SHOW_DATE_FORMAT)
  const today = new Date()
  const compareDate = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0,
    0
  )
  return compareDate - d < 0
}

export const showDate = date => {
  let d = moment(date)
  if (d._isValid) return d.format(SHOW_DATE_FORMAT)
  return date
}

export const showMounth = date => {
  let d = moment(date)
  if (d._isValid) return d.format(SHOW_MOUNTH_FORMAT)
  return date
}

export const showDateTime = date => {
  let d = moment(date)
  if (d._isValid) return d.format(SHOW_DATETIME_FORMAT)
  return date
}

export const has18 = date => {
  const today = moment()
  let d = moment(date, SHOW_DATE_FORMAT)
  return today.diff(d, 'years') > 18
}

export const has_age = (date, age) => {
  const today = moment()
  let d = moment(date, SHOW_DATE_FORMAT)
  return today.diff(d, 'years') > age
}

export const template = {
  AM: [1],
  BP: [28],
  D: [26],
  DIVER: [10, 14, 15, 20, 25, 30, 31],
  K: [21, 22, 23, 24],
  KE_RK: [12, 13],
  OPTI: [6, 7],
  PH: [4],
  TSK: [8, 18],
  V: [16],
}

export const calcBS = (values, template, estimationtemplate) => {
  let calcs = { V: 0, PH: 0, AM: 0, D: 0, BP: 0, KE_RK: 0, TSK: 0, K: 0, OPTI: 0, DIVER: 0 }
  let depenses = 0
  values.map(ele => {
    Object.keys(template).map(e => {
      if (template[e].includes(ele.bs_champs_id)) {
        calcs[e] += ele.bs_champs_value
        depenses += ele.bs_champs_value
        return true
      }
      return false
    })
    return true
  })
  let estimation = depenses - (depenses * 30) / 100

  return { total: depenses, estimation: estimation, details: calcs }
}

export const showSexe = sexe => getTrad(`commun.sexe.${sexe}`)

export const filterSelect = (itemValue, filterValue) => {
  itemValue = itemValue ? itemValue : ''
  filterValue = filterValue ? filterValue : ''

  return filterValue ? itemValue.toString() === filterValue.toString() : true
}
