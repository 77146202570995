import ShowTable from '../../../../components/ShowTable'
import { getTrad } from '../../../../lang'
import { keys } from './consts'

function RenderBody({ data = [] }) {
  let header = {}

  for (var i = 0; i < keys.length; i++) {
    let k = keys[i]
    header[k] = getTrad(`adherant.${k}`)
  }
  return (
    <div className="card-body p-0">
      <ShowTable data={data} keys={keys} header={header} noData={getTrad(`commun.noSearchData`)} />
    </div>
  )
}
export default RenderBody
