import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import CardHeader from '../../../../components/CardHeader'
import ShowTable from '../../../../components/ShowTable'
import { showDate } from '../../../../helpers'
import Export from '../../../../components/Export'
import Import from './Import'
const keys = ['number', 'date', 'nb_items', 'details']

function DownloadModel() {
  let header = {}
  const keys = ['mat_cnrps', 'sous_compte', 'montant']

  for (var i = 0; i < keys.length; i++) {
    header[keys[i]] = getTrad(`saisie.${keys[i]}`)
  }
  return (
    <Export
      fileName="Modèle importation saisie"
      csvData={[{ mat_cnrps: '', sous_compte: '', montant: '' }]}
      icon="fas fa-download"
      color="dark"
      title={getTrad('commun.downloadmodel')}
      headers={header}
    />
  )
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      liste_saisies: [],
      searching: true,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({ searching: true }, () => {
      axios.get(getUrl('saisie.get')).then(res => {
        this.setState({
          searching: false,
          liste_saisies: res.data.success.map(e => ({
            number: e.id,
            date: showDate(e.date),
            nb_items: e.nb_items,
            details: (
              <Link to={`/credit/saisie/${e.id}`} className="btn btn-primary btn-sm">
                <i className="fas fa-eye" />
              </Link>
            ),
          })),
        })
      })
    })
  }

  render() {
    let header = {}

    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`saisie.${keys[i]}`)
    }

    let showProps = {
      data: this.state.liste_saisies,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
      searching: this.state.searching,
    }

    return (
      <div className="card">
        <CardHeader
          title={getTrad('pages.saisie')}
          actions={
            <div className="btn-group">
              <DownloadModel />
              <Import reload={this.refresh} />
            </div>
          }
        />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}
export default List
