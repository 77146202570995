import React from 'react'
import QRCode from 'qrcode.react'
import Waiting from './Waiting'

export default function QRCodeCustom(props) {
  let { value } = props

  if (value === null) {
    return <Waiting />
  }
  if (value === undefined) {
    return <Waiting />
  }

  return <QRCode value={value} />
}
