import { adherantM } from './items/adherant'
import { bsM } from './items/bs'
import { aideM } from './items/aide'
import { scienceM } from './items/science'
import { creditM } from './items/credit'
import { aide_socialeM } from './items/aideSociale'
import { parentsM } from './items/parents'

let navMag = [adherantM, bsM, creditM, aideM, parentsM, aide_socialeM, scienceM]

export default navMag
