import React from 'react'
import { showMoney } from '../../helpers'
import { getTrad } from '../../lang'
// const months = Array.from({ length: 12 }).map((_, k) => getTrad(`commun.months.${k}`))

function StatTable(props) {
  return (
    <div className="card-body p-0">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{getTrad('commun.month')}</th>
            {props.tableKeys.map((keyLang, keyIndex) => (
              <th key={keyIndex}>{getTrad(keyLang.label)}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {props.stats.map((row, rowk) => (
            <tr key={rowk}>
              <th>{getTrad(`commun.months.${rowk}`)}</th>
              {props.tableKeys.map((keyLang, keyIndex) => {
                let inner = row[keyLang.key]
                if (keyLang.isMoney) inner = showMoney(inner)
                return <td key={keyIndex}>{inner}</td>
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>{getTrad('commun.total')}</th>
            {props.tableKeys.map((keyLang, keyIndex) => {
              let inner = props.stats.map(e => e[keyLang.key]).reduce((a, b) => a + b, 0)
              if (keyLang.isMoney) inner = showMoney(inner)
              return <th key={keyIndex}>{inner}</th>
            })}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default StatTable
