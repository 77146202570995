export const scienceM = {
  icon: 'fas fa-award',
  text: 'crud_journee_science',
  link: 'journee-savoir',
}

export const scienceA = {
  icon: 'fas fa-award',
  text: 'crud_journee_science',
  link: 'journee-savoir',
}

export const scienceS = {
  icon: 'fas fa-award',
  text: 'crud_journee_science',
  link: 'journee-savoir',
  submenue: [
    { icon: 'fas fa-list', text: 'science_demande', link: '' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-cog', text: 'science_settings', link: 'parametres' },
  ],
}
