export const fr = {
  last_name: 'Nom',
  last_name_ar: 'Nom en arabe',
  first_name: 'Prénom',
  first_name_ar: 'Prénom en arabe',
  situationsfamiliale: 'Situation Familiale',
  sexe: 'Genre',
  email: 'E-mail',
  actions: 'Action',
  infoperso: 'Informations personnelles',
  matricule: 'Matricule interne',
  travail: 'Informations du métier',
  matricule_adh: 'Matricule adhérent',
  matricule_cnrps: 'Matricule CNRPS',
  cin: 'C.I.N.',
  first_nameAR: 'Prénom en Arabe',
  last_nameAR: 'Nom en Arabe',
  birth_day: 'Date de naissance',
  addremboursement: 'Ajouter un remboursement',
  listeremboursement: 'Liste de remboursements',
  phone: 'Téléphone',
  profession: 'Profession',
  prestataire: {
    justificatif: {
      status: {
        N: 'Non traité',
        V: 'Validé',
      },
    },
  },
  rib: 'RIB',
  has18: '+18',
  has62: '+62',
  categorie: 'Catégorie',
  grade: 'Grade',
  lieu: 'Établissement',
  status: 'Statut',
  importingAdh: 'Importation des adhérents',
  importingfam: 'Importation des membres de famille',
  classify: 'Classification des données',
  lvl_study: 'Niveau des études',
  attestation_reuissite: 'Attestation de réussite',
  lvl_studies: 'Niveau des études',
  date_inscription: "Date d'inscription à la mutuelle",
  listecotisation: 'Liste des cotisations',
  h18: {
    Y: '+18',
    N: '-18',
    A: 'Tous',
  },
  h62: {
    Y: '+62',
    N: '-62',
    A: 'Tous',
  },
}

export const ar = {
  last_name: 'اللقب',
  last_name_ar: 'اللقب بالعربي',
  first_name: 'الاسم',
  first_name_ar: 'الاسم بالعربي',
  situationsfamiliale: 'الحالة العائلية',
  sexe: 'الجنس',
  email: 'البريد الإلكتروني',
  actions: 'الإجراءات',
  infoperso: 'معلومات شخصية',
  matricule: 'رقم العضوية',
  travail: 'معلومات الوظيفة',
  matricule_adh: 'رقم عضوية المشترك',
  matricule_cnrps: 'رقم تسجيل CNRPS',
  cin: 'ب.ت.و',
  first_nameAR: 'الاسم بالعربي',
  last_nameAR: 'اللقب بالعربي',
  birth_day: 'تاريخ الميلاد',
  phone: 'رقم الهاتف',
  rib: 'RIB',
  has18: '+18',
  has62: '+62',
  categorie: 'الفئة',
  grade: 'الرتبة',
  lieu: 'المؤسسة',
  status: 'الحالة',
  importingAdh: 'ادخال معطيات الأعضاء',
  importingfam: 'ادخال معطيات أفراد الأسرة',
  classify: 'تصنيف البيانات',
  lvl_study: 'المستوى الدراسي',
  attestation_reuissite: 'شهادة النجاح',
  lvl_studies: 'المستوى الدراسي',
  h18: {
    Y: '+18',
    N: '-18',
    A: 'الكل',
  },
  h62: {
    Y: '+62',
    N: '-62',
    A: 'الكل',
  },
}

export const en = {
  last_name: 'Surname',
  last_name_ar: 'Surname in arabic',
  first_name: 'First name',
  first_name_ar: 'First name in arabic',
  situationsfamiliale: 'Marital status',
  sexe: 'Genre',
  email: 'E-mail',
  actions: 'Action',
  infoperso: 'Personal information',
  matricule: 'Registration number',
  travail: 'Work information',
  matricule_adh: 'Membership registration number',
  matricule_cnrps: 'CNRPS registration number',
  cin: 'C.I.N.',
  first_nameAR: 'First name in arabic',
  last_nameAR: 'Surname in arabic',
  birth_day: 'Birthday date',
  addremboursement: 'Add a refund',
  listeremboursement: 'List of refunds',
  phone: 'Phone',
  prestataire: {
    justificatif: {
      status: {
        N: 'No treated',
        V: 'Validated',
      },
    },
  },
  rib: 'RIB',
  has18: '+18',
  has62: '+62',
  categorie: 'Category',
  grade: 'Grade',
  lieu: 'Establishment',
  status: 'Status',
  importingAdh: 'Importing members',
  importingfam: 'Importing family members',
  classify: 'Data classification',
  lvl_study: 'Studies` level',
  attestation_reuissite: 'Certificate of achievement',
  lvl_studies: 'Studies` level',
  h18: {
    Y: '+18',
    N: '-18',
    A: 'All',
  },
  h62: {
    Y: '+62',
    N: '-62',
    A: 'All',
  },
}
