import axios from 'axios'
import { getUrl } from '../../../Api'
import { useEffect, useState } from 'react'
import Waiting from '../../../components/Waiting'
import { fatalError, errorMsg } from '../../../helpers/sweetAlerts'
import { Redirect } from 'react-router-dom'
import { getTrad } from '../../../lang'
import { showDate, showMoney } from '../../../helpers'

export default function ShowBS({ match }) {
  const matricule = match?.params?.matricule

  const [bs, setBS] = useState({})
  const [loading, setloading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [bsFields, setBsFields] = useState([])

  useEffect(() => {
    setloading(true)
    setNotFound(false)
    axios
      .get(getUrl('bs.crud2', [matricule]))
      .then(res => {
        setloading(false)
        setBS(res.data.success)
      })
      .catch(err => {
        setloading(false)
        setBS({})
        if (err?.response?.status === 404) {
          setNotFound(true)
        } else {
          fatalError()
        }
      })

    axios
      .get(getUrl('bs.confs'))
      .then(res => {
        setBsFields(res.data.success)
      })
      .catch(err => fatalError())
  }, [matricule])

  if (loading) return <Waiting />
  if (notFound) {
    errorMsg(getTrad('bs.notfound'))
    return <Redirect to="/bultin-soins"></Redirect>
  }

  const benif = bs.prestataie ? bs.prestataie : bs.adherant
  return (
    <div className="card">
      <div className="card-body">
        <h2>Information Bulletin</h2>
        <div className="row row-cols-1, row-cols-md-3 row-cols-lg-4">
          <div className="col">
            <b className="mr-2">{getTrad('bs.bs_number')} :</b>
            <span>{bs?.mat_bultin}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.date_soin')} :</b>
            <span>{showDate(bs?.date_soin)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.status')} :</b>
            <span>{getTrad(`bs.status_${bs?.status}`)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.complement')} :</b>
            <span>{bs?.complement}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.montant_remboursement')} :</b>
            <span>{showMoney(bs?.remboursemement)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.id_bord')} :</b>
            <span>{bs?.id_borderau}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.observations')} :</b>
            <span>{bs?.observations}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.description')} :</b>
            <span>{bs?.description}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.created_at')} :</b>
            <span>{showDate(bs?.created_at)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('bs.updated_at')} :</b>
            <span>{showDate(bs?.updated_at)}</span>
          </div>
        </div>
      </div>

      <div className="card-body">
        <h2>Information bénéficiaire</h2>
        <div className="row row-cols-1, row-cols-md-2 row-cols-lg-4">
          <div className="col">
            <b className="mr-2">{getTrad('adherant.matricule')} :</b>
            <span>{bs?.adherant?.matricule}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('adherant.birth_day')} :</b>
            <span>{showDate(benif?.birth_day)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('adherant.last_name')} :</b>
            <span>{showDate(benif?.last_name)}</span>
          </div>
          <div className="col">
            <b className="mr-2">{getTrad('adherant.first_name')} :</b>
            <span>{showDate(benif?.first_name)}</span>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {bsFields.map((e, k) => {
            return (
              <div key={k} className="col">
                <b>{e.name} : </b>
                <span>
                  {showMoney(
                    bs.values
                      .filter(ele => ele.bs_champs_id === e.id)
                      .reduce((a, b) => {
                        return a + b.bs_champs_value
                      }, 0)
                  )}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
