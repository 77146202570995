import React, { Component } from 'react'
import Swal from 'sweetalert2'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Api } from '../../../Api'
// import Waiting from '../../../components/Waiting'
import { convertDate, showDate } from '../../../helpers'
import { successMsg, errorMsg } from '../../../helpers/sweetAlerts'
import { getTrad } from '../../../lang'
import { LangContext } from '../../../context/LangContext'

const stringify = str => {
  if (!str) return ''
  return str
}

export default class GestionAdmins extends Component {
  // Component Life Cycle
  static contextType = LangContext

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      redirect: false,
      old_matricule: null,
      errors: {},

      // identifiants
      matricule: '',
      matricule_cnrps: '',
      cin: '',

      first_name: '',
      last_name: '',
      first_name_ar: '',
      last_name_ar: '',
      birth_day: '',
      email: '',
      phone: '',
      sexe: '',
      rib: '',
      date_inscription: '',

      situationsfamiliale: '',

      status: '',
      grade: '',
      lieu: '',
      categorie: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  getMatricule() {
    if (!this.props.new) {
      const { matricule } = this.props.match.params

      this.setState({ old_matricule: matricule }, this.refresh)
    }
  }

  componentDidMount() {
    this.getMatricule()
  }

  refresh() {
    if (this.state.old_matricule) {
      let url = Api.baseURL + Api.crudAdherants + '/' + this.state.old_matricule
      axios
        .get(url)
        .then(res => {
          this.setState({
            matricule: stringify(res.data.success.matricule),
            matricule_cnrps: stringify(res.data.success.matricule_cnrps),
            cin: stringify(res.data.success.cin),
            first_name: stringify(res.data.success.first_name),
            last_name: stringify(res.data.success.last_name),
            first_name_ar: stringify(res.data.success.first_name_ar),
            last_name_ar: stringify(res.data.success.last_name_ar),
            birth_day: showDate(stringify(res.data.success.birth_day)),
            email: stringify(res.data.success.email),
            phone: stringify(res.data.success.phone),
            sexe: stringify(res.data.success.sexe),
            rib: stringify(res.data.success.rib),
            situationsfamiliale: stringify(res.data.success.situationsfamiliale),
            status: stringify(res.data.success.status),
            grade: stringify(res.data.success.grade),
            lieu: stringify(res.data.success.lieu),
            categorie: stringify(res.data.success.categorie),
            date_inscription: showDate(stringify(res.data.success.date_inscription)),
          })
        })
        .catch(err => {
          this.setState({
            redirect: true,
          })
        })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/adherants" />
    }
    return (
      <div className="card">
        {this.renderHader()}
        {this.renderBody()}
      </div>
    )
  }

  // Handlers

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  // Action Bottons

  add(data) {
    let url = Api.baseURL + Api.crudAdherants
    axios
      .post(url, data)
      .then(res => {
        successMsg(getTrad('success.addAdherant'))
        this.setState({
          errors: {},
        })
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({
              errors: err.response.data.errors,
            })
          } else {
            errorMsg(getTrad('errors.cummonError'))
          }
        }
      })
  }

  edit(data) {
    data.old_matricule = this.state.old_matricule
    let url = Api.baseURL + Api.crudAdherants
    axios
      .put(url, data)
      .then(res => {
        successMsg(getTrad('success.editAdherant'))
        this.setState({
          errors: {},
        })
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({
              errors: err.response.data.errors,
            })
          } else {
            errorMsg(getTrad('errors.cummonError'))
          }
        }
      })
  }

  send() {
    let data = {}
    let fields = [
      'matricule',
      'matricule_cnrps',
      'cin',
      'first_name',
      'last_name',
      'first_name_ar',
      'last_name_ar',
      'birth_day',
      'email',
      'phone',
      'sexe',
      'rib',
      'situationsfamiliale',
      'status',
      'grade',
      'lieu',
      'categorie',
      'date_inscription',
    ]

    fields.map(e => {
      if (this.state[e] === '') {
        return false
      }
      if (e === 'birth_day') {
        data[e] = convertDate(this.state[e])
        return true
      }
      if (e === 'date_inscription') {
        data[e] = convertDate(this.state[e])
        return true
      }

      data[e] = this.state[e]
      return true
    })

    Swal.fire({
      title: getTrad('commun.areyousure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#30d637',
      cancelButtonColor: '#d33',
      confirmButtonText: getTrad('commun.yes'),
      cancelButtonText: getTrad('commun.no'),
    }).then(result => {
      if (result.value) {
        if (this.props.new) {
          this.add(data)
        } else {
          console.log(data)
          this.edit(data)
        }
      }
    })
  }

  // Rendering functions

  getErrors(e) {
    let errorMessage
    if (this.state.errors[e]) {
      errorMessage = (
        <div className="text-sm text-danger">
          {this.state.errors[e].map((ele, k) => {
            return <li key={k}>{getTrad(`${ele}`)}</li>
          })}
        </div>
      )
    }
    return errorMessage
  }

  renderHader() {
    let title
    if (this.props.new) {
      title = getTrad('pages.newAdherant')
    } else {
      title = getTrad('pages.editAdherant')
    }
    return (
      <div className="card-header">
        <div className="row">
          <div className="col-12">
            <h2 className="card-title">{title}</h2>
          </div>
        </div>
      </div>
    )
  }

  renderIdentifiants() {
    return (
      <fieldset>
        <legend>{getTrad('fileds.identifiants')}</legend>

        <div className="row">
          {this.renderInputText('matricule')}
          {this.renderInputText('matricule_cnrps')}
          {this.renderInputText('cin')}
          {this.renderInputText('date_inscription')}
        </div>
      </fieldset>
    )
  }

  renderInputText(e, width, type) {
    if (!type) {
      type = 'text'
    }
    if (!width) {
      width = 4
    }

    return (
      <div className={`col-${width}`}>
        <div className="form-group">
          <label htmlFor={e}>{getTrad(`adherant.${e}`)}</label>
          <input
            type={type}
            className="form-control w-100 form-control-sm"
            value={this.state[e]}
            onChange={event => this.handleChange(event)}
            name={e}
            id={e}
            placeholder={getTrad(`adherant.${e}`)}
          />
          {this.getErrors(e)}
        </div>
      </div>
    )
  }

  renderInputTextRIB(e, width, type) {
    if (!type) {
      type = 'text'
    }
    if (!width) {
      width = 4
    }

    return (
      <div className={`col-${width}`}>
        <div className="form-group">
          <label htmlFor={e}>{getTrad(`adherant.${e}`)}</label>
          <input
            type={type}
            maxLength={20}
            className="form-control w-100 form-control-sm"
            value={this.state[e]}
            onChange={event => this.handleChange(event)}
            name={e}
            id={e}
            placeholder={getTrad(`adherant.${e}`)}
          />
          {this.getErrors(e)}
        </div>
      </div>
    )
  }
  renderDate(e, width) {
    if (!width) {
      width = 4
    }
    return (
      <div className={`col-${width}`}>
        <div className="form-group">
          <label htmlFor={e}>{getTrad(`adherant.${e}`)}</label>
          <input
            type="text"
            className="form-control w-100 form-control-sm"
            value={this.state[e]}
            onChange={event => this.handleChange(event)}
            name={e}
            id={e}
            placeholder="DD-MM-YYYY"
            pattern="^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$"
            title="Enter une date sous la forme dd-mm-yyyy"
          />
          {this.getErrors(e)}
        </div>
      </div>
    )
  }

  renderSexe(key) {
    return (
      <div className="col-4">
        <div className="form-group">
          <label htmlFor={key}>{getTrad(`adherant.${key}`)}</label>
          <div className="pt-1">{this.renderSexeFields(key)}</div>
          {this.getErrors(key)}
        </div>
      </div>
    )
  }

  renderSexeFields(key) {
    let width = 6
    let data = [
      {
        value: 'M',
        label: getTrad('commun.sexe.M'),
      },
      {
        value: 'F',
        label: getTrad('commun.sexe.F'),
      },
    ]
    if (!width) {
      width = 4
    }

    let defaultVal = this.state[key].toString()

    return (
      <div>
        {data.map((e, k) => {
          let radioProps = {
            className: 'form-check-input',
            type: 'radio',
            onChange: e => this.handleChange(e),
            checked: defaultVal === e.value.toString(),
            name: key,
            id: `${key}-${e.value}`,
            value: e.value,
          }
          return (
            <div key={k} className="form-check form-check-inline">
              <input {...radioProps} />
              <label className="form-check-label" htmlFor={`${key}-${e.value}`}>
                {e.label}
              </label>
            </div>
          )
        })}
      </div>
    )
  }

  renderForeign(key, width) {
    if (!width) {
      width = 4
    }
    let value = this.state[key]
    let field

    if (this.context.extra[key]) {
      field = (
        <select
          name={key}
          onChange={e => this.handleChange(e)}
          value={value}
          className="form-control form-control-sm"
        >
          <option>--- {getTrad(`fileds.${key}`)} ---</option>
          {Object.keys(this.context.extra[key]).map((e, k) => {
            return (
              <option key={k} value={e}>
                {this.context.extra[key][e]}
              </option>
            )
          })}
        </select>
      )
    }

    return (
      <div className={`col-${width}`}>
        <div className="form-group">
          <label htmlFor={key}>{getTrad(`fileds.${key}`)}</label>
          {field}
          {this.getErrors(key)}
        </div>
      </div>
    )
  }

  renderInfoPerso() {
    return (
      <fieldset>
        <legend>{getTrad('fileds.infoperso')}</legend>
        <div className="row">
          {this.renderInputText('first_name', 6)}
          {this.renderInputText('last_name', 6)}
          {this.renderInputText('first_name_ar', 6)}
          {this.renderInputText('last_name_ar', 6)}
          {this.renderDate('birth_day')}
          {this.renderInputText('email', 4, 'email')}
          {this.renderInputText('phone', 4, 'number')}
          {this.renderSexe('sexe')}
          {this.renderInputTextRIB('rib', 4, 'text')}
          {this.renderForeign('situationsfamiliale')}
        </div>
      </fieldset>
    )
  }

  renderTravail() {
    return (
      <fieldset>
        <legend>Informations du métier</legend>
        <div className="form-row">
          {this.renderForeign('categorie', 6)}
          {this.renderForeign('grade', 6)}
          {this.renderForeign('status', 6)}
          {/*this.renderForeign('gouvernaurat')*/}
          {this.renderForeign('lieu', 6)}
        </div>
      </fieldset>
    )
  }

  renderBtn() {
    let btnText = getTrad('commun.add')
    if (!this.props.new) {
      btnText = getTrad('commun.edit')
    }
    return (
      <button
        className="btn btn-success w-100"
        onClick={() => {
          this.send()
        }}
      >
        {btnText}
      </button>
    )
  }

  renderBody() {
    return (
      <div className="card-body">
        {this.renderIdentifiants()}
        {this.renderInfoPerso()}
        {this.renderTravail()}
        {this.renderBtn()}
      </div>
    )
  }
}
