import React from 'react'
import { getTrad } from '../../../lang'
import NumInput from '../../../components/Form/NumInput'

function Filter(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <NumInput
            label={getTrad('fileds.matricule_adh')}
            value={props.matricule_adh}
            name="matricule_adh"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <NumInput
            label={getTrad('fileds.sous_compte')}
            value={props.sous_compte}
            name="sous_compte"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <NumInput
            label={getTrad('fileds.date_versement')}
            value={props.date_versement}
            name="date_versement"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <NumInput
            label={getTrad('fileds.matricule_cnrps')}
            value={props.matricule_cnrps}
            name="matricule_cnrps"
            handler={props.handler}
          />
        </div>
        <div className="col">
          <div className="form-check-label">{getTrad('fileds.sous_compte')}</div>
          {['A', 'Y', 'N'].map((e, k) => (
            <div key={k} className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id={`has_sous_compte-${e}`}
                name="has_sous_compte"
                onChange={props.handler}
                checked={props.has_sous_compte === e}
                value={e}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={`has_sous_compte-${e}`}>
                {getTrad(`aides.filter.${e}`)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
