import { getTrad } from '../../../lang'
import { createRef, useEffect, useState } from 'react'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { fatalError } from '../../../helpers/sweetAlerts'

function EditConf(props) {
  const [edit, setEdit] = useState(false)
  const [sending, setSending] = useState(false)

  const inputRef = createRef()

  const Value = () => {
    if (edit) return <input ref={inputRef} defaultValue={props.value}></input>
    else return props.value
  }

  const update = () => {
    if (inputRef.current.value === props.value) {
      setEdit(false)
    } else {
      setSending(true)
      axios
        .patch(getUrl('params.crud'), { key: props.keyword, value: inputRef.current.value })
        .then(() => {
          props.refresh()
          setEdit(false)
          setSending(false)
        })
        .catch(() => {
          setEdit(false)
          props.refresh()
          setSending(false)
          fatalError()
        })
    }
  }
  const Actions = () => {
    if (edit) {
      return (
        <div className="btn-group btn-group-sm">
          <button onClick={update} disabled={sending} className="btn btn-sm btn-success">
            {sending ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-check"></i>
            )}
          </button>
          <button onClick={() => setEdit(false)} className="btn btn-sm btn-danger">
            <i className="fas fa-times"></i>
          </button>
        </div>
      )
    } else {
      return (
        <button onClick={() => setEdit(true)} className="btn btn-sm btn-primary">
          <i className="fas fa-edit"></i>
        </button>
      )
    }
  }
  return (
    <tr>
      <td>{props.keyword}</td>
      <td>
        <Value />
      </td>
      <td width="1">
        <Actions />
      </td>
    </tr>
  )
}

export default function Parametres() {
  const [params, setParams] = useState([])

  const refresh = () => {
    axios
      .get(getUrl('params.crud'))
      .then(res => setParams(res.data.success))
      .catch(fatalError)
  }
  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="card">
      <div className="card-header">
        <h1 className="card-title">{getTrad('navbar.params')}</h1>
      </div>

      <div className="card-body p-0">
        <table className="table">
          <thead>
            <tr>
              <th>{getTrad('fileds.keyword')}</th>
              <th>{getTrad('fileds.value')}</th>
            </tr>
          </thead>
          <tbody>
            {params.map(e => (
              <EditConf refresh={refresh} {...e} keyword={e.key} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
