function OneCol({ cols, children }) {
  return (
    <tbody>
      <tr>
        <td colSpan={cols} className="text-center">
          {children}
        </td>
      </tr>
    </tbody>
  )
}

export default OneCol
