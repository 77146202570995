import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
const keys = ['name', 'date', 'forfait', 'montant', 'number', 'total']

function ShowLine(props) {
  return (
    <tr>
      {keys.map((e, k) => (
        <td key={k}>{props[e]}</td>
      ))}
    </tr>
  )
}

function Details({ data, name }) {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(!show)
  return (
    <>
      <button onClick={handleShow} className="btn btn-primary btn-sm">
        <i className="fas fa-eye"></i>
      </button>
      <Modal size="lg" show={show} onHide={handleShow}>
        <Modal.Header>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <table className="table">
            <thead>
              <tr>
                {keys.map((e, k) => (
                  <th key={k}>{getTrad('aidesociale.' + e)}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map((e, k) => (
                <ShowLine key={k} {...e} />
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Details
