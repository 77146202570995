import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './views/Login'
import Dashboard from './views/Dashboard'
import './assets/css/customcollapse.css'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact strict component={Login} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  )
}

export default App
