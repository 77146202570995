export const fr = {
  add: 'Élement ajouté avec succès',
  updateRole: 'Rôle changé avec succès',
  deletedAdin: 'Administrateur supprimé',
  addMembreFamily: 'Ajout de membre de famille effectué avec succès',
  addAdherant: "Ajout de l'adhérent effectué avec succès",
  editAdherant: 'Adhérent mis-à-jour',
  addBs: 'Bulletin de soins ajouté avec succès',
  updateMembreFamily: 'Modification de membre de famille effectuée avec succès',
  addDemandeAide: "Demande d'aide ajoutée avec succès",
  aideAccept: 'Aide acceptée avec succès',
  aideRefused: 'Aide refusée',
  addDemandeCredit: 'Demande de crédit ajoutée avec succès',
  refuseDemandeCredit: 'Demande de crédit refusée',
  acceptDemandeCredit: 'Demande de crédit acceptée avec succès',
  reboursAdd: 'Remboursement effectué avec succès',
  genBorderau: 'Bordereau généré avec succès',
  remboursSuccess: 'Remboursement effectué avec succès',
  prixAdd: 'Prix ajouté avec succès',
  deleteprix: 'Prix supprimé avec succès',
  updateProfil: 'Profil mis-à-jour avec succès',
  prixUpdate: 'Prix modifié avec succès',
  operation: 'Opération effectuée avec succès',
  updateBs: 'Bulletin modifié avec succès',
  deleteBS: 'Bulletin supprimé avec succès',
  delete: "l'élément a été supprimé avec succès",
  update: {
    credit: 'Crédit modifié avec succès',
    aide: 'Aide modifiée avec succès',
    remboursement: 'Remboursement modifié avec succès.',
    contact: 'Cession modifiée avec succès',
  },
  journeeSciences: {
    update: 'Journée de savoir modifiée avec succès',
  },
  gouv: {
    delete: 'Gouvernorat supprimé avec succès',
    add: 'Gouvernorat ajouté avec succès',
  },
  parent: {
    deleteContract: 'Parent à charge supprimé avec succès',
  },
  condidature: {
    A: 'Candidature acceptée avec succès',
    R: 'Candidature refusée avec succès',
    N: 'Candidature réinitialisée avec succès',
  },
  demande: 'Demande envoyée avec succès',
}

export const ar = {
  add: 'تمت عملية الاضافة بنجاح',
  updateRole: 'تم تغيير الدور بنجاح',
  deletedAdin: 'تم حذف المسؤول',
  addMembreFamily: 'تمت إضافة فرد من العائلة بنجاح',
  addAdherant: 'تمت إضافة العضو بنجاح',
  editAdherant: 'تم تحديث العضو',
  addBs: 'تمت إضافة بطاقة العلاج بنجاح',
  updateMembreFamily: 'تم تعديل معطيات فرد العائلة بنجاح',
  addDemandeAide: 'تمت إضافة طلب المساعدة بنجاح',
  aideAccept: 'تم قبول المساعدة بنجاح',
  aideRefused: 'تم رفض المساعدة',
  addDemandeCredit: 'تمت إضافة طلب الدّين بنجاح',
  refuseDemandeCredit: 'تم رفض طلب الدّين بنجاح',
  acceptDemandeCredit: 'تم قبول طلب الدّين بنجاح',
  reboursAdd: 'تم تسديد قسط من الدّين بنجاح',
  genBorderau: 'تم إنشاء القسيمة بنجاح',
  remboursSuccess: 'تم ردّ الدّين بنجاح',
  prixAdd: 'تم إضافة الجائزة بنجاح',
  deleteprix: 'تم حذف الجائزة بنجاح',
  updateProfil: 'تم تحديث ملفك الشخصي بنجاح',
  prixUpdate: 'تم تحديث الجائزة بنجاح',
  operation: 'أجريت العملية بنجاح',
  updateBs: 'تم تعديل بطاقة العلاج بنجاح',
  deleteBS: 'تم حذف بطاقة العلاج بنجاح',
  update: {
    credit: 'تم تعديل الدّين بنجاح',
    aide: 'تم تعديل المساعدة بنجاح',
    remboursement: 'تم تعديل المبلغ المسترد بنجاح',
    contact: 'تم تعديل التّنازل بنجاح',
  },
  journeeSciences: {
    update: 'تم تعديل يوم المعرفة بنجاح',
  },
  gouv: {
    delete: 'تم إلغاء الولاية بنجاح',
    add: 'تمت إضافة الولاية بنجاح',
  },
  parent: {
    deleteContract: 'تم حذف معطيات الوالد المعال بنجاح',
  },
  condidature: {
    A: 'تم قبول المطلب بنجاح',
    R: 'تم رفض المطلب بنجاح',
    N: 'تمت احالة المطلب الى وضعيّته اﻷولى بنجاح',
  },
  demande: 'تم إرسال الطلب بنجاح',
}

export const en = {
  add: 'Item successfully added',
  updateRole: 'Role successfully changed',
  deletedAdin: 'Deleted administrator',
  addMembreFamily: 'Successful addition of family member',
  addAdherant: 'Successful addition of the member',
  editAdherant: 'Member updated',
  addBs: 'treatment bulletin successfully added',
  updateMembreFamily: 'Modification of family member successfully completed',
  addDemandeAide: 'Aid request successfully added',
  aideAccept: 'Aid successfully accepted',
  aideRefused: 'Aid refused',
  addDemandeCredit: 'Credit application successfully added',
  refuseDemandeCredit: 'Credit application refused',
  acceptDemandeCredit: 'Credit request successfully accepted',
  reboursAdd: 'Refund successfully completed',
  genBorderau: 'Tax treatment successfully generated',
  remboursSuccess: 'Refund successfully completed',
  prixAdd: 'Prize successfully added',
  deleteprix: 'Prize successfully deleted',
  updateProfil: 'Profile successfully updated',
  prixUpdate: 'Prize successfully rectified',
  operation: 'Operation successfully completed',
  updateBs: 'Bulletin successfully rectified',
  deleteBS: 'Bulletin successfully deleted',
  update: {
    credit: 'Credit successfully rectified',
    aide: 'Aid successfully rectified',
    remboursement: 'Refund successfully completed',
    contact: 'Cession successfully rectified',
  },
  journeeSciences: {
    update: 'Knowledge day successfully rectified',
  },
  gouv: {
    delete: 'Governorate successfully removed',
    add: 'Governorate successfully added',
  },
  parent: {
    deleteContract: 'Dependent parent successfully removed',
  },
  condidature: {
    A: 'Application successfully accepted',
    R: 'Application refused',
    N: 'Application successfully resetted',
  },
  demande: 'Request successfully sent',
}
