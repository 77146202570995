import React, { Component } from 'react'

import { inString } from '../helpers'
import '../assets/css/AutoComplete.css'
import { getTrad } from '../lang'

export default class AutoComplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      show: false,
      error: '',
      shown: [],
    }
  }

  componentDidMount() {
    this.setState({
      shown: this.props.list,
    })
  }

  empty() {
    this.setState({
      value: '',
      error: '',
    })
  }

  refresh() {
    let tmp = []
    let error = ''
    this.props.list.map(e => {
      if (inString(this.state.value, e)) tmp.push(e)
      if (this.state.value.toString() === e.toString()) error = false
      return true
    })
    if (tmp.length === 0) {
      error = true
    }

    if (error === false) {
      this.props.handler(this.state.value)
    }

    this.setState({
      error: error,
      shown: tmp,
    })
  }

  handleChange(e) {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.refresh()
      }
    )
  }

  select(e) {
    this.setState(
      {
        value: e,
      },
      () => {
        this.refresh()
      }
    )
  }

  renderComplete() {
    if (this.state.shown.length >= 1)
      return (
        <ul className="autocompleteList">
          {this.state.shown.map((e, k) => {
            return (
              <li key={k} onClick={() => this.select(e)}>
                {e}
              </li>
            )
          })}
        </ul>
      )
  }

  render() {
    let key = this.props.field
    let classInput = 'form-control w-100 form-control-sm autocompleteInput'
    if (this.state.error === true) {
      classInput += ' is-invalid'
    } else if (this.state.error === false) {
      classInput += ' is-valid'
    }
    return (
      <div className="form-group">
        <label htmlFor={key}>{getTrad(`fileds.${key}`)}</label>
        <input
          type="text"
          onChange={e => this.handleChange(e)}
          className={classInput}
          value={this.state.value}
          name={key}
          id={key}
          placeholder={getTrad(`fileds.${key}`)}
        />
        {this.renderComplete()}
      </div>
    )
  }
}
