import React, { useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import { getTrad } from '../../../lang'
import { Modal } from 'react-bootstrap'
import Waiting from '../../../components/Waiting'
import axios from 'axios'
import * as FileSaver from 'file-saver'

function Virements() {
  const fileRef = useRef()
  const [showLoading, setShowLoading] = useState(false)

  const handleFile = event => {
    let reader = new FileReader()
    reader.readAsArrayBuffer(event.target.files[0])
    reader.onload = async () => {
      const readData = new Uint8Array(reader.result)
      var wb = XLSX.read(readData, { type: 'array' })
      const { value: sheet } = await Swal.fire({
        title: getTrad('commun.chooseSheet'),
        input: 'select',
        inputOptions: wb.SheetNames,
        inputPlaceholder: getTrad('commun.chooseSheet'),
        showCancelButton: true,
        cancelButtonText: getTrad('commun.cancel'),
        confirmButtonText: getTrad('commun.import'),
      })
      if (sheet !== undefined && sheet !== '') {
        const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[sheet]]).map(line => ({
          matricule: line['Matricule interne'],
          montant: line['Montant'],
        }))
        setShowLoading(true)
        axios
          .post('/virements', { data: data })
          .then(res => {
            const fileType =
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const now = new Date().toISOString()

            fileRef.current.value = ''
            const { success, errors } = res.data.success
            const successSheet = XLSX.utils.json_to_sheet(success)
            const errorSheet = XLSX.utils.json_to_sheet(errors)

            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, successSheet, 'virement-success')
            XLSX.utils.book_append_sheet(workbook, errorSheet, 'virement-erreur')

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
            const exportedFile = new Blob([excelBuffer], { type: fileType })

            FileSaver.saveAs(exportedFile, `ordre-virement-${now}.xlsx`)
            setShowLoading(false)
          })
          .catch(err => {
            fileRef.current.value = ''
            setShowLoading(false)
          })
      }
    }
  }

  return (
    <>
      <button onClick={() => fileRef.current.click()} className="btn btn-primary w-100">
        <i className="fas fa-upload"></i> Selectionner un fichier
      </button>
      <Modal show={showLoading} centered onHide={() => {}}>
        <Modal.Body>
          <div className="text-center">
            <Waiting />
          </div>
        </Modal.Body>
      </Modal>
      <input type="file" style={{ display: 'none' }} onChange={handleFile} ref={fileRef} />
    </>
  )
}

export default Virements
