import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isNotAdmin, isNotSuperAdmin } from '../../../helpers'

import ListeDemande from './ListeDemande'
import Demande from './Demande'
import Settings from './Settings'
import { userStore } from '../../../store/userStore'

let listeDemandes = [
  <Route key="currentEdition" exact path={`/journee-savoir`}>
    <ListeDemande current={true} path="/journee-savoir" />
  </Route>,
  <Route
    exact
    key="otherEdition"
    strict
    path={`/journee-savoir/:slug`}
    render={props => <ListeDemande current={false} path="/journee-savoir" {...props} />}
  />,
]

const magRouter = props => (
  <Switch>
    <Route exact path="/journee-savoir">
      <Demande {...props} path="/journee-savoir" />
    </Route>
    <Route>
      <Redirect to="/journee-savoir" />
    </Route>
  </Switch>
)

const adminRouter = (
  <Switch>
    {listeDemandes}
    <Route>
      <Redirect to="/journee-savoir" />
    </Route>
  </Switch>
)

const superRouter = (
  <Switch>
    <Route exact strict path={`/journee-savoir/parametres`}>
      <Settings path="/journee-savoir" />
    </Route>
    {listeDemandes}
    <Route>
      <Redirect to="/journee-savoir" />
    </Route>
  </Switch>
)

export default function ScienceDay(props) {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }
  const path = '/journee-savoir'
  if (isNotAdmin(role)) {
    return magRouter(props, path)
  } else if (isNotSuperAdmin(role)) {
    return adminRouter
  } else {
    return superRouter
  }
}
