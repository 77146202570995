import Swal from 'sweetalert2'
import { getTrad } from '../lang'

export const successMsg = msg => {
  Swal.fire({
    position: 'top-end',
    toast: true,
    icon: 'success',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    title: msg,
  })
}
export const areYouSurePromise = () =>
  new Promise(resolve =>
    Swal.fire({
      title: getTrad('commun.areyousure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#37a326',
      cancelButtonColor: '#d33',
      confirmButtonText: getTrad('commun.yes'),
      cancelButtonText: getTrad('commun.no'),
      preConfirm: () => resolve(),
      allowOutsideClick: () => !Swal.isLoading(),
    })
  )
export const successToast = Swal.mixin({
  position: 'top-end',
  toast: true,
  icon: 'success',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const errorToast = Swal.mixin({
  position: 'top-end',
  toast: true,
  icon: 'error',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const errorMsg = msg => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    toast: true,
    title: msg,
  })
}

export const fatalError = () => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    toast: true,
    title: getTrad('errors.contactAdmin'),
  })
}

export const areYouSure = Swal.mixin({
  title: getTrad('commun.areyousure'),
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#30d637',
  cancelButtonColor: '#d33',
  confirmButtonText: getTrad('commun.yes'),
  cancelButtonText: getTrad('commun.no'),
})
