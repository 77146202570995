import { createContext } from 'react'

export const LangContext = createContext({
  lang: 'fr',
  setLang: () => null,
  extra: {
    categorie: {},
    lieu: {},
    gouvernaurat: {},
    grade: {},
    status: {},
    lvlStudies: {},
    typePresta: {},
    situationsfamiliale: {},
  },
})
