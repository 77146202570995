import React from 'react'
import { useTrad } from '../../../../lang'
import UpdateAdminRole from './UpdateAdminRole'
import DeleteAdmin from './DeleteAdmin'

function TableRow({ id, username, last, name, sexe, email, role, refresh }) {
  const sexes = {
    M: useTrad(`commun.sexe.M`),
    F: useTrad(`commun.sexe.F`),
  }
  return (
    <tr>
      <td>{username}</td>
      <td>{name}</td>
      <td>{last}</td>
      <td>{sexes[sexe]}</td>
      <td>{email}</td>
      <td>
        <UpdateAdminRole admin={username} {...role} refresh={refresh} />
      </td>
      <td width="1" className="text-right">
        <DeleteAdmin admin={username} refresh={refresh} />
      </td>
    </tr>
  )
}

export default TableRow
