import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'
import Magistrat from './Magistrat'
import Saisie from './Saisie'
import Show from './Show'
import ListAll from './ListAll'
import List from './List'
import Statistiques from '../../../components/Stats'
import { userStore } from '../../../store/userStore'

function Parents(props) {
  let { path } = useRouteMatch()
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }
  if (isNotAdmin(role)) {
    return <Magistrat />
  }

  return (
    <Switch>
      <Route exact path={`${path}/saisie/:id`} component={Show} />

      <Route exact path={`${path}/saisie`}>
        <Saisie {...props} />
      </Route>

      <Route exact path={`${path}/all`}>
        <ListAll />
      </Route>

      <Route exact path={`${path}/statistiques`}>
        <Statistiques
          barKeys={[]}
          tableKeys={[
            { key: 'number_inscrit', label: 'parent.nb_inscrit' },
            { key: 'numbers', label: 'parent.nb_cessions' },
            { key: 'sommes', label: 'parent.montant_cessions', isMoney: true },
          ]}
          {...props}
          path={path}
          page="pages.statistiques.parents"
          statsUrl="parent.saisie.stats"
        />
      </Route>

      <Route exact path={`${path}`}>
        <List />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}

export default Parents
/*

*/
