import React from 'react'
import { Link } from 'react-router-dom'
import { useTrad } from '../../lang'
import ChangeLang from './ChangeLang'
import { userStore } from '../../store/userStore'

function Greeting() {
  const { user } = userStore()
  const { name, last } = user || {}
  return (
    <ul className="navbar-nav">
      <ChangeLang />
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle active"
          to="#"
          id="navbardrop"
          data-toggle="dropdown"
        >
          {useTrad('navbar.welecome')} {`${name} ${last}`}
        </Link>
        <div className="dropdown-menu">
          <Link className="dropdown-item" to="/change-password">
            <i className="fas fa-key"></i> {useTrad('navbar.change_password')}
          </Link>
          <div className="dropdown-divider"></div>
          <Link className="dropdown-item" to="/logout">
            <i className="fas fa-door-open"></i> {useTrad('navbar.logout')}
          </Link>
        </div>
      </li>
    </ul>
  )
}

export default Greeting
