import axios from 'axios'
import React from 'react'
import { useMutation } from 'react-query'
import { getTrad, useTrad } from '../../../../lang'
import { successMsg, errorMsg, areYouSurePromise } from '../../../../helpers/sweetAlerts'
import Waiting from '../../../../components/Waiting'

function UpdateAdminRole({ admin, id, refresh }) {
  const { isLoading, mutate } = useMutation(
    role => axios.post('/gestion-admins/change-role', { username: admin, role }),
    {
      onSuccess: () => {
        successMsg(getTrad('success.updateRole'))
        refresh()
      },
      onError: err => {
        if (err.response.status === 401) {
          errorMsg(getTrad('errors.mustHaveSuperUsers'))
        } else {
          errorMsg(getTrad('errors.cummonError'))
        }
      },
      onMutate: areYouSurePromise,
    }
  )

  const roles = {
    ROLE_SUPER_USER: useTrad('commun.role.ROLE_SUPER_USER'),
    ROLE_ADMINISTRATOR: useTrad('commun.role.ROLE_ADMINISTRATOR'),
    ROLE_ADMINISTRATOR_LOCAL: useTrad('commun.role.ROLE_ADMINISTRATOR_LOCAL'),
    ROLE_MAGISTRAT: useTrad('commun.role.ROLE_MAGISTRAT'),
  }

  if (isLoading) return <Waiting />
  return (
    <select
      value={id}
      onChange={event => mutate(event.target.value)}
      className="form-control form-control-sm"
    >
      <option value="1">{roles.ROLE_SUPER_USER}</option>
      <option value="2">{roles.ROLE_ADMINISTRATOR}</option>
      <option value="4">{roles.ROLE_ADMINISTRATOR_LOCAL}</option>
      <option value="3">{roles.ROLE_MAGISTRAT}</option>
    </select>
  )
}

export default UpdateAdminRole
