import React from 'react'
import { Component } from 'react'
import axios from 'axios'
import { showDate, showSexe, inString } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import { Link } from 'react-router-dom'
import InputText from '../../../../components/Form/InputText'
import ShowTable from '../../../../components/ShowTable'
import Export from '../../../../components/Export'
import Details from './Details'
import AddPaiement from './AddPaiement'

const keys = [
  'matricule',
  'matricule_cnrps',
  'last_name',
  'first_name',
  'sexe',
  'email',
  'phone',
  'birth_day',
  'actions',
]

const exportheaders = {
  matricule: getTrad('adherant.matricule'),
  matricule_cnrps: getTrad('adherant.matricule_cnrps'),
  cin: getTrad('adherant.cin'),
  first_name: getTrad('adherant.first_name'),
  last_name: getTrad('adherant.last_name'),
  first_name_ar: getTrad('adherant.first_name_ar'),
  last_name_ar: getTrad('adherant.last_name_ar'),
  sexe: getTrad('adherant.sexe'),
  birth_day: getTrad('adherant.birth_day'),
  email: getTrad('adherant.email'),
  phone: getTrad('adherant.phone'),
  rib: getTrad('adherant.rib'),
  status: getTrad('adherant.status'),
  grade: getTrad('adherant.grade'),
  lieu: getTrad('adherant.lieu'),
  situationsfamiliale: getTrad('adherant.situationsfamiliale'),
  categorie: getTrad('adherant.categorie'),
}
function RenderSearchSection(props) {
  return (
    <div className="card-body">
      <div className="row">
        {['matricule', 'last_name', 'first_name'].map((e, k) => (
          <div key={k} className="col">
            <InputText
              label={getTrad(`adherant.${e}`)}
              value={props[e]}
              name={e}
              handler={props.handler}
            />
          </div>
        ))}
      </div>
      <div className="row">
        {['matricule_cnrps', 'phone', 'birth_day'].map((e, k) => (
          <div key={k} className="col">
            <InputText
              label={getTrad(`adherant.${e}`)}
              value={props[e]}
              name={e}
              handler={props.handler}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function RenderBody({ listAdherants = [], searching = false }) {
  let header = {}

  for (var i = 0; i < keys.length; i++) {
    let k = keys[i]
    header[k] = getTrad(`adherant.${k}`)
  }

  return (
    <div className="card-body p-0">
      <ShowTable
        data={listAdherants}
        keys={keys}
        header={header}
        noData={getTrad(`commun.noSearchData`)}
        searching={searching}
      />
    </div>
  )
}

function RenderHader(props) {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-6">
          <h2 className="card-title">{getTrad('navbar.list_retraite')}</h2>
        </div>

        <div className="col-6 text-right">
          <div className="btn-group" role="group" aria-label="Basic example">
            <Export
              headers={exportheaders}
              title={getTrad('commun.export')}
              fileName={'listAdherants'}
              csvData={props.listAdherants}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

class Retraite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searching: true,
      listAdherants: [],
      matricule: '',
      matricule_cnrps: '',
      first_name: '',
      last_name: '',
      phone: '',
      birth_day: '',
      status: '',
      grade: '',
      lieu: '',
      situationsfamiliale: '',
      categorie: '',
    }
  }

  handler = e => {
    this.setState({ [e.target.name]: e.target.value }, this.filter)
  }

  refresh = () => {
    this.setState({ listAdherants: [], searching: true }, () => {
      axios
        .get('/adherants/retraites')
        .then(res => {
          this.setState({
            searching: false,
            listAdherants: res.data.success.map((e, k) => ({
              ...e,
              birth_day: showDate(e.birth_day),
              status: getTrad(`status.${e.status}`),
              status_id: e.status,
              grade: getTrad(`grade.${e.grade}`),
              grade_id: e.grade,
              lieu: getTrad(`lieu.${e.lieu}`),
              lieu_id: e.lieu,
              situationsfamiliale: getTrad(`situationsfamiliale.${e.situationsfamiliale}`),
              situationsfamiliale_id: e.situationsfamiliale,
              categorie: getTrad(`categorie.${e.categorie}`),
              categorie_id: e.categorie,
              sexe: showSexe(e.sexe),
              actions: (
                <div className="btn-group btn-group-sm" role="group">
                  <Details matricule={e.matricule} />
                  <AddPaiement matricule={e.matricule} />

                  <Link to={`${this.props.path}/${e.matricule}`} className="btn btn-sm btn-primary">
                    <i className="far fa-eye"></i>
                  </Link>
                  <Link
                    to={`${this.props.path}/${e.matricule}/edit`}
                    className="btn btn-sm btn-secondary"
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                </div>
              ),
            })),
          })
        })
        .catch(() => this.setState({ listAdherants: [], searching: false }))
    })
  }

  componentDidMount() {
    this.refresh()
  }

  filter = e => {
    if (e === undefined) return false
    if (!inString(this.state.matricule, e.matricule)) return false
    if (!inString(this.state.matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.first_name, e.first_name)) return false
    if (!inString(this.state.last_name, e.last_name)) return false
    if (!inString(this.state.phone, e.phone)) return false
    return true
  }

  render() {
    return (
      <div className="card">
        <RenderHader listAdherants={this.state.listAdherants.filter(this.filter)} />
        <RenderSearchSection {...this.state} handler={this.handler} />
        <RenderBody listAdherants={this.state.listAdherants.filter(this.filter)} />
      </div>
    )
  }
}

export default Retraite
