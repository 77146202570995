export const fr = {
  welcome: "Bienvenue à l'application GAMM",
  connectionProblem: 'Il y a un problème de connexion',
  loginProblem: "Nom d'utilisateur ou mot de passe incorrect!",
  internetConnection: 'Impossible de se connecter à internet',
}

export const ar = {
  welcome: 'مرحبًا بك في تطبيق GAMM',
  connectionProblem: 'هناك مشكلة في الاتصال',
  loginProblem: '!تثبّت من اسم المستخدم او كلمة السرّ',
  internetConnection: 'استحالة الاتصال بالانترنت',
}

export const en = {
  welcome: 'Welcome to the GAMM app',
  connectionProblem: 'There is a problem with the connection',
  loginProblem: 'Username or password incorrect!',
  internetConnection: 'Unable to connect to the internet',
}
