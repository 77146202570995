import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LangContext } from './context/LangContext'

import axios from 'axios'
import { Api } from './Api'

axios.defaults.baseURL = Api.baseURL

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

function Root() {
  const [lang, setLang] = useState('fr')
  const [categorie, setCategories] = useState({})
  const [lieu, setEtablissements] = useState({})
  const [gouvernaurat, setGouvernorats] = useState({})
  const [grade, setGrades] = useState({})
  const [lvlStudies, setLvlStudies] = useState({})
  const [typePresta, setTypePresta] = useState({})
  const [situationsfamiliale, setSituationFamiliale] = useState({})

  const [status, setStatus] = useState({})

  const arrayToObject = useCallback(
    data => {
      const tmp = {}
      data.forEach(item => {
        tmp[item.id] = item[lang] || item.fr
      })
      return tmp
    },
    [lang]
  )

  useEffect(() => {
    axios.get('/lang/categories').then(res => setCategories(arrayToObject(res.data.success)))
  }, [arrayToObject])
  useEffect(() => {
    axios
      .get('/lang/etablissements')
      .then(res => setEtablissements(arrayToObject(res.data.success)))
  }, [arrayToObject])

  useEffect(() => {
    axios.get('/lang/gouvernorats').then(res => setGouvernorats(arrayToObject(res.data.success)))
  }, [arrayToObject])

  useEffect(() => {
    axios.get('/lang/grades').then(res => setGrades(arrayToObject(res.data.success)))
  }, [arrayToObject])

  useEffect(() => {
    axios.get('/lang/status-adherants').then(res => setStatus(arrayToObject(res.data.success)))
  }, [arrayToObject])

  useEffect(() => {
    axios.get('/lang/lvl-studies').then(res => setLvlStudies(arrayToObject(res.data.success)))
  }, [arrayToObject])

  useEffect(() => {
    axios.get('/lang/type-prestataies').then(res => setTypePresta(arrayToObject(res.data.success)))
  }, [arrayToObject])
  useEffect(() => {
    axios
      .get('/lang/situations')
      .then(res => setSituationFamiliale(arrayToObject(res.data.success)))
  }, [arrayToObject])

  const extra = {
    categorie,
    lieu,
    gouvernaurat,
    grade,
    status,
    lvlStudies,
    typePresta,
    situationsfamiliale,
  }
  return (
    <LangContext.Provider
      value={{
        lang,
        setLang,
        extra,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div dir={lang === 'ar' ? 'rtl' : 'ltr'}>
          <App />
        </div>
      </QueryClientProvider>
    </LangContext.Provider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
