import { NavLink } from 'react-router-dom'
import { useTrad } from '../../lang'

function DropDownItem({ text, icon, parentLink, link }) {
  const title = useTrad('navbar.' + text)
  return text ? (
    <NavLink exact className="dropdown-item" to={`/${parentLink}/${link}`}>
      <i className={icon}></i> {title}
    </NavLink>
  ) : (
    <div className="dropdown-divider"></div>
  )
}

function Dropdown({ parentLink, submenue, id }) {
  if (submenue) {
    return (
      <div className="dropdown-menu" aria-labelledby={id}>
        {submenue.map((ele, key) => {
          return <DropDownItem key={key} parentLink={parentLink} {...ele} />
        })}
      </div>
    )
  }
  return null
}

export default Dropdown
