import React from 'react'
import { fatalError, areYouSure, successMsg } from '../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'

function Delete(props) {
  const remove = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.value) {
        axios
          .delete(getUrl('parent.saisie.show', [props.id]))
          .then(successMsg(getTrad('parent.success.delete')))
          .catch(fatalError)
      }
    })
  }

  return (
    <button onClick={remove} className="btn btn-sm btn-danger">
      <i className="fas fa-trash-alt" />
    </button>
  )
}

export default Delete
