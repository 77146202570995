import React from 'react'
import { isNotAdmin } from '../../../helpers'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import AideAdmin from './AideAdmin'
import Traite from './Traite'
import Statistiques from '../../../components/Stats'
import AideMag from './AideMag'
import { userStore } from '../../../store/userStore'

export default function AideSociales(props) {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  let { path } = useRouteMatch()
  if (isNotAdmin(role)) {
    return <AideMag user={user || { role: { role: '' } }} {...props} />
  }
  return (
    <Switch>
      <Route path={path} exact>
        <AideAdmin user={user || { role: { role: '' } }} />
      </Route>

      <Route path={`${path}/traite`} exact>
        <Traite {...props} type="T" />
      </Route>

      <Route path={`${path}/refusee`} exact>
        <Traite {...props} type="R" />
      </Route>

      <Route path={`${path}/statistiques`} exact>
        <Statistiques
          barKeys={[]}
          tableKeys={[
            { key: 'numbers', label: 'aides.nb_aides' },
            { key: 'sommes', label: 'aides.montant_aides', isMoney: true },
          ]}
          {...props}
          path={path}
          page="pages.statistiques.aidesociale"
          statsUrl="aidesociale.stats"
        />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
