import React, { Component } from 'react'
import Waiting from '../../../components/Waiting'
import axios from 'axios'
import { showDate } from '../../../helpers'
import { getUrl } from '../../../Api'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'

const convert = item => ({
  POR: item.num,
  ADH: item.header.matricule_adh,
  'NOM ET PRENOM': item.header.full_name,
  PRES: item.header.pres,
  'N° BUL': item.header.bs_number,
  'DATE SOINS': showDate(item.header.bs_date),
  V: item.details.details.V,
  PH: item.details.details.PH,
  AM: item.details.details.AM,
  D: item.details.details.D,
  BP: item.details.details.BP,
  KE_RK: item.details.details.KE_RK,
  TSK: item.details.details.TSK,
  K: item.details.details.K,
  OPTI: item.details.details.OPTI,
  DIVER: item.details.details.DIVER,
  'TOT DEP': item.details.depenses,
  'TOT REM': item.rembours,
  OBS: item.obs,
})

class DownloadBordParents extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  getData = () => {
    axios.get(getUrl('borderau.parents', [this.props.id])).then(res => {
      this.saveXLS(res.data.success.map(e => convert(e)))
      this.setState({ loading: false })
    })
  }

  saveXLS = data_input => {
    const fileName = `Bordereau Parents numero ${this.props.id}`
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
    const ws = XLSX.utils.json_to_sheet(data_input)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + '-' + now + fileExtension)
  }

  handleClick = () => {
    this.setState({ loading: true }, this.getData)
  }

  render() {
    if (this.state.loading) {
      return (
        <button className="btn btn-sm btn-success" disabled>
          <Waiting />
        </button>
      )
    }
    return (
      <button className="btn btn-sm btn-warning" onClick={this.handleClick}>
        <i className="fas fa-download"></i>
      </button>
    )
  }
}
export default DownloadBordParents
