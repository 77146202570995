import React, { useEffect, useState } from 'react'
import { getTrad } from '../../../../lang'
import InputText from '../../../../components/Form/InputText'
import { useQuery } from 'react-query'
import axios from 'axios'
import ShowTable from '../../../../components/ShowTable'
import { showMoney } from '../../../../helpers'

function ListBody(props) {
  let header = {}
  const keys = ['nb_bss', 'matricule_adh', 'full_name', 'total_depense', 'total_remboursement']

  for (var i = 0; i < keys.length; i++) {
    let k = keys[i]
    header[k] = getTrad(`bs.${k}`)
  }

  let showProps = {
    data: props.data,
    keys: keys,
    header: header,
    noData: getTrad('commun.noSearchData'),
    loading: props.loading,
  }

  return (
    <div className="card-body p-0">
      <ShowTable {...showProps} />
    </div>
  )
}

function Filter({ matricule, fullName, setMatricule, setFullName, year, setYear }) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <InputText
            label={getTrad('adherant.matricule')}
            value={matricule}
            name="num_bs"
            handler={event => setMatricule(event.target.value)}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('bs.full_name')}
            value={fullName}
            name="full_name"
            handler={event => setFullName(event.target.value)}
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('fileds.year')}
            value={year}
            name="year"
            handler={event => setYear(event.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

function Header() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{getTrad('navbar.statistiques_ad')}</h2>
        </div>
      </div>
    </div>
  )
}

function StatAdherant() {
  const [matricule, setMatricule] = useState('')
  const [fullName, setFullName] = useState('')
  const [year, setYear] = useState(() => new Date().getFullYear())

  const {
    isLoading,
    isFetching,
    data = [],
    refetch,
  } = useQuery('get-stats-ad-bs', () =>
    axios.get(`/bulletin/stats-user/${year}`).then(res =>
      Object.values(res.data.success)
        .sort((a, b) => b.total_depense - a.total_depense)
        .map(adherant => ({
          ...adherant,
          matricule_adh: adherant.matricule_adh ? adherant.matricule_adh.toString() : '',
          full_name: adherant.full_name || '',
          total_depense: showMoney(adherant.total_depense),
          total_remboursement: showMoney(adherant.total_remboursement),
        }))
    )
  )
  useEffect(() => {
    refetch()
  }, [refetch, year])

  return (
    <div className="card">
      <Header />
      <Filter
        matricule={matricule}
        fullName={fullName}
        setMatricule={setMatricule}
        setFullName={setFullName}
        year={year}
        setYear={setYear}
      />
      <ListBody
        loading={isLoading || isFetching}
        data={data.filter(
          adherant =>
            adherant.matricule_adh.toString().includes(matricule) &&
            adherant.full_name.toLowerCase().includes(fullName.toLowerCase())
        )}
      />
    </div>
  )
}

export default StatAdherant
