import React, { Component } from 'react'
import { isNotAdmin } from '../../../../helpers'
import Add from './Add'
import axios from 'axios'
import { getTrad } from '../../../../lang'
import { Api } from '../../../../Api'
import ShowTable from '../../../../components/ShowTable'
import Swal from 'sweetalert2'
import { successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import Accept from './Accept'

export default class AideSociales extends Component {
  constructor(props) {
    super(props)
    this.state = { listeAides: [], title: '', loading: true }
    this.handleAides = this.handleAides.bind(this)
  }

  componentDidMount() {
    let title = isNotAdmin(this.props.user.role.role)
      ? getTrad('pages.myAides')
      : getTrad('pages.aides')

    this.setState({ title: title })
    this.refresh()
  }

  render() {
    return (
      <>
        <div className="card mb-3">
          <div className="card-header">
            <h2 className="card-title">{this.state.title}</h2>
          </div>
          <div className="card-body">
            <Add {...this.props} handle={this.handleAides} />
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">{getTrad('aides.nonTraite')}</h2>
          </div>
          <div className="card-body p-0">{this.renderData()}</div>
        </div>
      </>
    )
  }

  renderData() {
    let header = {}

    let keys = [
      'id',
      'matricule_adh',
      'first_name',
      'last_name',
      'montant',
      'motif',
      'dateDemande',
      'status',
      'actions',
    ]

    if (isNotAdmin(this.props.user.role.role)) {
      keys = ['id', 'montant', 'motif', 'dateDemande', 'status']
    }

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`fileds.${k}`)
    }

    let showProps = {
      data: this.state.listeAides,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
      searching: this.state.loading,
    }

    return (
      <>
        <ShowTable {...showProps} />
      </>
    )
  }

  handleAides() {
    this.refresh()
  }

  async refuse(id) {
    const { value: text } = await Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#37a326',
      confirmButtonText: getTrad('commun.refuse'),
      cancelButtonText: getTrad('commun.cancel'),
      title: getTrad('aides.motif'),
      input: 'textarea',
    })

    if (text) {
      Swal.fire({
        title: getTrad('commun.areyousure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#37a326',
        cancelButtonColor: '#d33',
        confirmButtonText: getTrad('commun.yes'),
        cancelButtonText: getTrad('commun.no'),
        preConfirm: login => {
          let url = Api.baseURL + Api.aidesociale.refuse + `/${id}`
          let data = {
            obs: text,
          }
          axios
            .post(url, data)
            .then(res => {
              if (res.status === 200) {
                successMsg(getTrad('success.aideRefused'))
                this.refresh()
              }
            })
            .catch(err => {
              if (err.response) {
                if (err.response.status === 422) {
                  errorMsg(getTrad('errors.motifRequired'))
                }
              } else {
                errorMsg(getTrad('errors.cummonError'))
              }
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
    } else {
      errorMsg(getTrad('errors.motifRequired'))
    }
  }

  refresh = () => {
    let url = Api.baseURL + Api.aidesociale.crud
    axios
      .get(url)
      .then(res => {
        let tmp = []
        res.data.success.map(e => {
          tmp.push({
            id: e.id,
            matricule_adh: e.adherant.matricule,
            first_name: e.adherant.first_name,
            last_name: e.adherant.last_name,
            montant: e.montant,
            motif: e.motif,
            dateDemande: e.created_at,
            status: getTrad(`commun.statusAide.${e.status}`),
            actions: (
              <div className="btn-group" role="group">
                <Accept {...e} refresh={this.refresh} />
                <button
                  onClick={() => {
                    this.refuse(e.id)
                  }}
                  className="btn btn-danger btn-sm"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            ),
          })
          return true
        })
        this.setState({ listeAides: tmp, loading: false })
      })
      .catch(err => this.setState({ loading: false }))
  }
}
