import React, { Component, createRef } from 'react'
import Chart from 'chart.js'

const getBorders = e => `rgba(${e[0]}, ${e[1]}, ${e[2]}, 1)`
const getBackgrounds = e => `rgba(${e[0]}, ${e[1]}, ${e[2]}, 0.5)`

class Layout extends Component {
  constructor(props) {
    super(props)
    this.node = createRef()
  }

  componentDidMount() {
    const node = this.node.current

    const { labels, data, label } = this.props
    const colors = Array.from({ length: labels.length }).map(() => [
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
    ])

    this.myChart = new Chart(node, {
      type: 'bar',

      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: data,
            backgroundColor: colors.map(getBackgrounds),
            borderColor: colors.map(getBorders),
            borderWidth: 1,
          },
        ],
      },
    })
  }

  render() {
    return (
      <div>
        <canvas id={this.id} style={{ width: '100%', height: 300 }} ref={this.node} />
      </div>
    )
  }
}

export default Layout
