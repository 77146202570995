import React from 'react'
import { getTrad } from '../../../../lang'
import InputText from '../../../../components/Form/InputText'

function Filter(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <InputText
            label={getTrad('aidesociale.fileds.matricule')}
            value={props.search_matricule}
            handler={props.handler}
            name="search_matricule"
          />
        </div>

        <div className="col">
          <InputText
            label={getTrad('aidesociale.fileds.matricule_cnrps')}
            value={props.search_matricule_cnrps}
            handler={props.handler}
            name="search_matricule_cnrps"
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('aidesociale.fileds.first_name')}
            value={props.search_first}
            handler={props.handler}
            name="search_first"
          />
        </div>
        <div className="col">
          <InputText
            label={getTrad('aidesociale.fileds.last_name')}
            value={props.search_last}
            handler={props.handler}
            name="search_last"
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
