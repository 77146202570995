import React, { Component } from 'react'
import { showDate, showMoney, validDate, convertDate } from '../../../helpers'
import { getTrad } from '../../../lang'
import { areYouSure, successMsg, fatalError } from '../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../Api'
import InputDate from '../../../components/Form/InputDate'
import InputText from '../../../components/Form/InputText'
import Waiting from '../../../components/Waiting'

const MontantField = props => {
  if (props.edit) {
    return (
      <InputText
        label={getTrad('fileds.montant')}
        name="montant"
        dontShow
        value={props.value}
        handler={props.handler}
      />
    )
  }

  return showMoney(props.value)
}

const DateField = props => {
  if (props.edit) {
    return (
      <InputDate
        dontShow
        label={getTrad('fileds.date')}
        name="date"
        value={props.value}
        handler={props.handler}
      />
    )
  }

  return props.value
}

function Edit(props) {
  if (props.sending)
    return (
      <button disabled={true} className="btn btn-secondary btn-sm w-100">
        <Waiting />
      </button>
    )

  if (props.edit)
    return (
      <>
        <button disabled={props.disabled} onClick={props.send} className="btn btn-success btn-sm">
          <i className="fas fa-check" />
        </button>
        <button onClick={props.reset} disabled={props.sending} className="btn btn-danger btn-sm">
          <i className="fas fa-times" />
        </button>
      </>
    )

  return (
    <button onClick={props.handler} className="btn btn-secondary btn-sm">
      <i className="far fa-edit" />
    </button>
  )
}

function Delete(props) {
  if (props.edit) return <></>
  const deleteParent = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.value) {
        axios
          .delete(getUrl('parent.saisie.destroy', [props.id]))
          .then(res => {
            props.handler(res.data.success)
            successMsg(getTrad('parent.success.deleteParent'))
          })
          .catch(err => {
            fatalError()
          })
      }
    })
  }

  return (
    <button onClick={deleteParent} className="btn btn-danger btn-sm">
      <i className="far fa-trash-alt" />
    </button>
  )
}

class TableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      date: '',
      montant: '',
      sending: false,
    }
  }

  handleEdit = () => {
    const { sending, edit } = this.state
    if (sending) return
    this.setState({ edit: !edit })
  }

  resetData = () => {
    this.setState({
      edit: false,
      date: showDate(this.props.date),
      montant: this.props.montant,
    })
  }

  sendData = () => {
    areYouSure.fire().then(resultat => {
      if (resultat.value) {
        this.setState({ sending: true }, () => {
          const data = {
            date: convertDate(this.state.date),
            montant: this.state.montant,
          }
          axios
            .patch(getUrl('parent.saisie.update', [this.props.id]), data)
            .then(res => this.setState({ edit: false, sending: false }))
            .catch(err => {
              this.setState({ sending: false })
              fatalError()
            })
        })
      }
    })
  }

  componentDidMount() {
    this.setState({
      date: showDate(this.props.date),
      montant: this.props.montant,
    })
  }

  handleInput = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    const propsmontant = (this.props.montant || '').toString()
    const statemontant = (this.state.montant || '').toString()

    const propsdate = showDate(this.props.date.toString())
    const statedate = this.state.date.toString()

    return (
      <tr>
        <td>
          <DateField edit={this.state.edit} handler={this.handleInput} value={this.state.date} />
        </td>
        <td>
          <MontantField
            edit={this.state.edit}
            handler={this.handleInput}
            value={this.state.montant}
          />
        </td>
        <td>
          <div className="btn-group">
            <Edit
              disabled={
                (propsmontant === statemontant && propsdate === statedate) || !validDate(statedate)
              }
              sending={this.state.sending}
              reset={this.resetData}
              send={this.sendData}
              {...this.props}
              edit={this.state.edit}
              handler={this.handleEdit}
            />
            <Delete handler={this.props.deleteItem} edit={this.state.edit} {...this.props} />
          </div>
        </td>
      </tr>
    )
  }
}

export default TableRow
