import React, { Component } from 'react'
import axios from 'axios'
import ShowTable from '../../../components/ShowTable'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import { fatalError } from '../../../helpers/sweetAlerts'
import { showDate, showMoney, inString } from '../../../helpers'
import NumInput from '../../../components/Form/NumInput'
import Edit from './Edit'

const cleanData = (e, refresh) => ({
  id: e.id,
  matricule_adh: e.adherant.matricule,
  first_name: e.adherant.first_name,
  last_name: e.adherant.last_name,
  montant_demande: showMoney(e.montant),
  montant_accepte: showMoney(e.montant_accepte),
  engagementVal: e.engagement,
  engagement: getTrad(`aides.engagementVal.${e.engagement}`),
  montant_engagement: e.montant_engagement ? showMoney(e.montant_engagement) : null,
  motif: e.motif,
  dateDemande: showDate(e.created_at),
  dateAcceptation: showDate(e.updated_at),
  actions: <Edit {...e} refresh={refresh} />,
})

const cleanRefuse = (e, refresh) => ({
  id: e.id,
  matricule_adh: e.adherant.matricule,
  first_name: e.adherant.first_name,
  last_name: e.adherant.last_name,
  montant_demande: showMoney(e.montant),
  motif: e.motif,
  obs: e.obs,
  dateDemande: showDate(e.created_at),
  dateAcceptation: showDate(e.updated_at),
  actions: <Edit {...e} refresh={refresh} />,
})

function Filters(props) {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <NumInput
            name="matricule_adh"
            value={props.matricule_adh}
            handler={props.handler}
            label={getTrad('fileds.matricule_adh')}
          />
        </div>

        <div className="col">
          <div className="form-check-label">{getTrad('fileds.engagement')}</div>
          {['A', 'Y', 'N'].map((e, k) => (
            <div key={k} className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id={`engagement-${e}`}
                name="engagement"
                onChange={props.handler}
                checked={props.engagement === e}
                value={e}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={`engagement-${e}`}>
                {getTrad(`aides.filter.${e}`)}
              </label>
            </div>
          ))}
        </div>

        <div className="col">
          <div className="form-check-label">{getTrad('fileds.montant_engagement')}</div>
          {['A', 'D', 'NU'].map((e, k) => (
            <div key={k} className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id={`montant_engagement-${e}`}
                name="montant_engagement"
                onChange={props.handler}
                checked={props.montant_engagement === e}
                value={e}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={`montant_engagement-${e}`}>
                {getTrad(`aides.filter.${e}`)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

class Traite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      liste: [],
      matricule_adh: '',
      engagement: 'A',
      montant_engagement: 'A',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) this.refresh()
  }

  refresh = () => {
    const { type } = this.props

    const url = type === 'T' ? getUrl('aidesociale.traite') : getUrl('aidesociale.refusee')

    const clean = type === 'T' ? cleanData : cleanRefuse
    this.setState({ loading: true }, () =>
      axios
        .get(url)
        .then(res =>
          this.setState({
            liste: res.data.success.map(e => clean(e, this.refresh)),
            loading: false,
          })
        )
        .catch(fatalError)
    )
  }

  handleFilter = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    let { matricule_adh, engagement, montant_engagement } = this.state
    if (engagement === 'N' && e.engagementVal === 1) return false
    else if (engagement === 'Y' && e.engagementVal === 0) return false
    if (e.montant_engagement === null && montant_engagement === 'D') return false
    else if (e.montant_engagement !== null && engagement === 'NU') return false
    return inString(matricule_adh, e.matricule_adh)
  }

  render() {
    const { type } = this.props
    const keys =
      type === 'T'
        ? [
            'id',
            'matricule_adh',
            'first_name',
            'last_name',
            'montant_demande',
            'montant_accepte',
            'motif',
            'engagement',
            'montant_engagement',
            'dateDemande',
            'dateAcceptation',
            'actions',
          ]
        : [
            'id',
            'matricule_adh',
            'first_name',
            'last_name',
            'montant_demande',
            'motif',
            'obs',
            'dateDemande',
            'dateRefus',
            'actions',
          ]

    let header = {}
    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`fileds.${keys[i]}`)
    }

    const showProps = {
      data: this.state.liste.filter(this.filter),
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
      searching: this.state.loading,
    }
    return (
      <div className="card">
        <div className="card-header">
          <h3>{getTrad(type === 'T' ? 'pages.traite_aide' : 'pages.refusee_aide')}</h3>
        </div>
        <Filters
          engagement={this.state.engagement}
          montant_engagement={this.state.montant_engagement}
          matricule_adh={this.state.matricule_adh}
          handler={this.handleFilter}
        />
        <div className="card-body p-0">
          <ShowTable {...showProps} />
        </div>
      </div>
    )
  }
}

export default Traite
