import React from 'react'
import { getTrad } from '../../../../lang'
import { showDate, showMoney } from '../../../../helpers'
import EditRemboursement from './EditRemboursement'
import DeleteRemboursement from './DeleteRemboursement'

function ShowTable(props) {
  const Tbody = props => {
    let { montant_rembours } = props
    if (props.remboursements.length === 0) return <tbody></tbody>
    return (
      <tbody>
        {props.remboursements.map((e, k) => {
          montant_rembours -= e.montant_rembours
          return (
            <tr key={k}>
              <td>{showDate(e.date)}</td>
              <td>{showMoney(e.montant_rembours)}</td>
              <td>{showMoney(montant_rembours)}</td>
              <td>{e.typeString}</td>
              <td>
                <div className="btn-group">
                  <EditRemboursement {...e} refresh={props.refresh} />
                  <DeleteRemboursement {...e} refresh={props.refresh} />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }

  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>{getTrad('credit.date_rembours')}</th>
          <th>{getTrad('credit.montant_rembours')}</th>
          <th>{getTrad('credit.type_remboursement_name')}</th>
          <th>{getTrad('credit.rest')}</th>
          <th></th>
        </tr>
      </thead>
      <Tbody {...props} />
    </table>
  )
}

export default ShowTable
