import React from 'react'
import { getTrad } from '../../../../../lang'
import InputText from '../../../../../components/Form/InputText'
import InputDate from '../../../../../components/Form/InputDate'
import InputSexe from '../../../../../components/Form/InputSexe'
import InputSelect from '../../../../../components/Form/InputSelect'

export default function InfoPerso(props) {
  let situationsfamilialeData = [
    { value: '1', text: getTrad('situationsfamiliale.1') },
    { value: '2', text: getTrad('situationsfamiliale.2') },
    { value: '3', text: getTrad('situationsfamiliale.3') },
    { value: '4', text: getTrad('situationsfamiliale.4') },
  ]

  if (props.member.type !== '') {
    return (
      <fieldset id="FieldSetInfoPerso">
        <legend>{getTrad('fileds.infoperso')}</legend>
        <div className="row">
          <div className="col-6">
            <InputText
              handler={props.update}
              name="first_name"
              value={props.member.first_name}
              label={getTrad('fileds.first_name')}
            />
          </div>
          <div className="col-6">
            <InputText
              handler={props.update}
              name="last_name"
              value={props.member.last_name}
              label={getTrad('fileds.last_name')}
            />
          </div>
          <div className="col-6">
            <InputDate
              handler={props.update}
              name="birth_day"
              value={props.member.birth_day}
              label={getTrad('fileds.birth_day')}
            />
          </div>
          <div className="col-6">
            <InputSexe
              handler={props.update}
              name="sexe"
              value={props.member.sexe}
              label={getTrad('fileds.sexe')}
            />
          </div>
          <div className="col-6">
            <InputSelect
              handler={props.update}
              name="situationsfamiliale"
              value={props.member.situationsfamiliale}
              data={situationsfamilialeData}
              label={getTrad('fileds.situationsfamiliale')}
            />
          </div>
        </div>
      </fieldset>
    )
  }

  return <></>
}
