export const fr = {
  full_name: "Nom et prénom de l'adhérent",
  pres_full_name: "Nom et prénom de l'étudiant",
  prix_name: 'Prix',
  status: 'Statut',
  actions: 'Actions',
  condidature: 'Candidature de {}',
  mat_adherant: 'Matricule de l`adhérent',

  phone: 'Téléphone',
  statusCode: {
    N: 'Non traité',
    A: 'Accepté',
    R: 'Refusé',
  },
  actionsTxt: {
    N: 'Réinitialiser',
    A: 'Accepter',
    R: 'Refuser',
  },
}

export const ar = {
  full_name: 'اسم و لقب العضو',
  pres_full_name: 'اسم و لقب الطالب',
  prix_name: 'الجائزة',
  status: 'الحالة',
  actions: 'أجراءات',
  condidature: 'تطبيق {}',
  mat_adherant: 'رقم العضوية',
  phone: 'الهاتف',
  statusCode: {
    N: 'غير معالجة',
    A: 'قبول',
    R: 'رفض',
  },
  actionsTxt: {
    N: 'إعادة تعيين',
    A: 'قبول',
    R: 'رفض',
  },
}

export const en = {
  full_name: 'Member full name',
  pres_full_name: 'Student full name',
  prix_name: 'Price',
  status: 'Status',
  actions: 'Actions',
  condidature: 'Application from {}',
  mat_adherant: 'Member registration number',

  phone: 'Phone',
  statusCode: {
    N: 'Untreated',
    A: 'Accepted',
    R: 'Refused',
  },
  actionsTxt: {
    N: 'Reset',
    A: 'Accept',
    R: 'Refuse',
  },
}
