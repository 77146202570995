import React, { Component } from 'react'
import { showDate, validDate, convertDate } from '../../../../../helpers'
import {
  successToast,
  fatalError,
  areYouSure,
  errorToast,
} from '../../../../../helpers/sweetAlerts'
import moment from 'moment'
import { getUrl } from '../../../../../Api'
import { getTrad } from '../../../../../lang'
import {
  GenStats,
  ShowDate,
  Prix,
  AddBtn,
  AddFields,
  ExportEdition,
  ShowEditionName,
} from './utils'
import axios from 'axios'

class Collapse extends Component {
  constructor(props) {
    super(props)
    let eventDate = moment(props.date)
    let now = moment()
    let deadline = moment(props.deadline)

    let classCard = now < eventDate ? ' active ' : ''
    this.state = {
      ...props,
      sending: false,
      niveauInput: '',
      prixInput: '',
      errors: {},
      editing: null,
      edition: props.edition,
      inputdate: showDate(props.date),
      inputdeadline: showDate(props.deadline),
      adding: props.prix.length === 0 && now < deadline,
      hidden: false,
      classCard: now < deadline ? ' deadline ' : classCard,
      editable: now < deadline,
      dateeditable: now < eventDate,
    }
  }

  renderBtn() {
    return this.state.hidden ? (
      <button className="btn btn-sm" onClick={this.handleClick}>
        <i className="fas fa-plus" />
      </button>
    ) : (
      <button className="btn btn-sm" onClick={this.handleClick}>
        <i className="fas fa-minus" />
      </button>
    )
  }

  render() {
    let dnone = this.state.hidden ? ' d-none' : ''
    let cnVal = 'font-weight-bold align-top px-1 '
    let cnDataVal = 'align-top px-1'
    let size = ' col-12'
    return (
      <div className={`col mb-3${size}`}>
        <div className={`custom-collapse rounded${this.state.classCard}`}>
          <div className="custom-collapse-header">
            <div className="row">
              <div className="col">
                {this.renderBtn()}
                <span className=" btn-sm">{this.props.edition}</span>
              </div>

              <ExportEdition
                edition={this.props.edition}
                dataStats={this.props.prix}
                slug={this.props.slug}
                {...this}
              />
            </div>
          </div>
          <div className={`py-1 px-0 custom-collapse-body${dnone}`}>
            <table className="table">
              <tbody>
                <ShowEditionName
                  editing={this.state.editing}
                  editable={this.state.dateeditable}
                  sending={this.state.sending}
                  value={this.state.edition}
                  send={this.handleUpdateJS}
                  handleEdit={this.handleEdit}
                  handler={this.handleData}
                />
                {['deadline', 'date'].map((e, k) => (
                  <ShowDate
                    editable={this.state.dateeditable}
                    editing={this.state.editing}
                    send={this.handleUpdateJS}
                    handler={this.handleData}
                    handleEdit={this.handleEdit}
                    sending={this.state.sending}
                    key={k}
                    field={e}
                    value={this.state[`input${e}`]}
                  />
                ))}
              </tbody>
              <tbody className="border-top border-dark">
                <tr>
                  <td className={cnVal}>
                    {getTrad('science.listePrix')}
                    <AddBtn
                      handle={this.handleClickAddBtn}
                      adding={this.state.adding}
                      editable={this.state.editable}
                    />
                  </td>
                  <td className={cnVal}>:</td>
                  <td className={cnDataVal}>
                    {this.props.prix.map((e, k) => (
                      <Prix
                        key={k}
                        {...e}
                        editable={this.state.editable}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                        editing={this.state.editing}
                        niveauInput={this.state.niveauInput}
                        prixInput={this.state.prixInput}
                        handleData={this.handleData}
                        handleUpdate={this.handleUpdate}
                      />
                    ))}
                    <AddFields
                      adding={this.state.adding}
                      errors={this.state.errors}
                      editable={this.state.editable}
                      niveauInput={this.state.niveauInput}
                      prixInput={this.state.prixInput}
                      handleData={this.handleData}
                      sending={this.state.sending}
                      handleSend={this.handleSend}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <GenStats data={this.props.prix} />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Update
   */
  handleUpdateJS = event => {
    event.preventDefault()
    if (!validDate(this.state.inputdate) || !validDate(this.state.inputdeadline)) {
      errorToast.fire({ title: getTrad('errors.invalid.date') })
      return false
    }
    let data = {
      edition: this.state.edition,
      inputdate: convertDate(this.state.inputdate),
      inputdeadline: convertDate(this.state.inputdeadline),
    }
    this.setState({ sending: true }, () => {
      axios
        .patch(getUrl('journeeSciences.update', [this.state.id]), data)
        .then(() => {
          successToast.fire({ title: getTrad('success.journeeSciences.update') })
          this.props.reload()
        })
        .catch(() => {
          fatalError()
          this.props.reload()
        })
    })
  }

  handleClick = () => this.setState({ hidden: !this.state.hidden && !this.state.editable })

  handleClickAddBtn = () => {
    this.setState({
      adding: !this.state.adding && this.state.editable,
      editing: null,
      niveauInput: '',
      prixInput: '',
    })
  }

  handleData = event => this.setState({ [event.target.name]: event.target.value })

  send = () => {
    let data = {
      niveauInput: this.state.niveauInput,
      edition_id: this.state.id,
      prixInput: this.state.prixInput,
    }
    axios
      .post(getUrl('journeeSciences.niveau.add'), data)
      .then(() => {
        this.setState({ sending: false, niveauInput: '', prixInput: '', errors: {} })
        successToast.fire({ title: getTrad('success.prixAdd') })
        this.props.reload()
      })
      .catch(err => {
        if (err.response.status === 401) {
          fatalError()
          this.props.reload()
        }
        if (err.response.status === 422) this.setState({ errors: err.response.data.error })

        this.setState({ sending: false, niveauInput: '', prixInput: '', errors: {} })
      })
  }

  handleSend = () => {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.send)
    })
  }

  handleEdit = id => {
    if (this.state.editing === id) {
      this.setState({
        editing: null,
        niveauInput: '',
        prixInput: '',
        edition: this.props.edition,
        inputdate: showDate(this.props.date),
        inputdeadline: showDate(this.props.deadline),
      })
    } else {
      if (['deadline', 'date', 'edition'].includes(id)) {
        this.setState({
          adding: false,
          editing: id,
          niveauInput: '',
          prixInput: '',
          edition: this.props.edition,
          inputdate: showDate(this.props.date),
          inputdeadline: showDate(this.props.deadline),
        })
      }

      this.state.prix.map(e => {
        if (e.id === id)
          this.setState({ adding: false, editing: id, niveauInput: e.niveau, prixInput: e.prix })
        return false
      })
    }
  }

  handleUpdate = () => {
    areYouSure.fire().then(result => {
      if (result.value) {
        let data = { niveauInput: this.state.niveauInput, prixInput: this.state.prixInput }
        axios
          .patch(getUrl('journeeSciences.niveau.update', [this.state.editing]), data)
          .then(res => {
            successToast.fire({ title: getTrad('success.prixUpdate') })
            this.props.reload()
          })
          .catch(err => {
            fatalError()
            this.props.reload()
          })
      }
    })
  }

  handleDelete = (id, lvl) => {
    let edi = this.props.edition
    areYouSure.fire({ title: getTrad('science.sure.delete', [lvl, edi]) }).then(result => {
      if (result.value) this.setState({ sending: true }, () => this.sendDelete(id))
    })
  }

  sendDelete(id) {
    axios
      .delete(getUrl('journeeSciences.niveau.delete', [id]))
      .then(() => {
        this.props.reload()
        successToast.fire({ title: getTrad('success.deleteprix') })
      })
      .catch(() => {
        fatalError()
        this.props.reload()
      })
  }
}
export default Collapse
