export const parentsM = {
  icon: 'fas fa-blind',
  text: 'parent_a_charge',
  link: 'parents',
}

export const parentsA = {
  icon: 'fas fa-blind',
  text: 'parent_a_charge',
  link: 'parents',
  submenue: [
    { icon: 'fas fa-list', text: 'list_parents_active', link: '' },
    { icon: 'fas fa-cash-register', text: 'saisie_salaire', link: 'saisie' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-list', text: 'list_parents', link: 'all' },
    { icon: 'fas fa-chart-bar', text: 'statistiques', link: 'statistiques' },
  ],
}
