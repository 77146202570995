import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import { getUrl } from '../../../../Api'
import { fatalError } from '../../../../helpers/sweetAlerts'
import Waiting from '../../../../components/Waiting'

import ShowCredit from './ShowCredit'
import ShowDemande from './ShowDemande'
import Demande from './Demande'

export default class Credits extends Component {
  constructor(props) {
    super(props)
    this.state = { action: 'REDIRECT', loading: true, listCredits: [] }
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    axios
      .get(getUrl('credits.myData'))
      .then(res => {
        let action = 'DEMANDE'
        let stats = { N: 0, A: 0, R: 0, P: 0 }
        res.data.success.map(e => stats[e.status]++)
        if (stats.N) action = 'SHOW_DEMANDE'
        else if (stats.A) action = 'SHOW_CREDIT'
        this.setState({ action: action, loading: false, listCredits: res.data.success })
      })
      .catch(err => {
        fatalError()
        this.setState({ loading: false })
      })
  }

  renderBody() {}

  render() {
    if (this.state.loading) return <Waiting />
    let components = {
      REDIRECT: <Redirect to="/" />,
      DEMANDE: <Demande refresh={this.refresh} {...this.props} {...this.state} />,
      SHOW_DEMANDE: <ShowDemande {...this.props} {...this.state} />,
      SHOW_CREDIT: <ShowCredit {...this.props} {...this.state} />,
    }

    return (
      <>
        {components[this.state.action]}
        {/* <ShowCredit {...this.props} {...this.state} /> */}
      </>
    )
  }
}
