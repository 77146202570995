import React, { Component } from 'react'
import { getTrad } from '../../../../lang'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import { successMsg, fatalError } from '../../../../helpers/sweetAlerts'
function ShowForm(props) {
  if (props.loading)
    return (
      <div className="text-center">
        <Waiting />
      </div>
    )
  if (props.list.length === 0)
    return <div className="text-muted text-center">{getTrad('aidesociale.iCantApplay')}</div>

  return (
    <form onSubmit={props.send}>
      <div className="row">
        <div className="col-10">
          <select
            required
            value={props.selected}
            onChange={props.handler}
            className="form-control"
            name="selectedAide"
          >
            <option value=""></option>
            {props.list.map((e, k) => (
              <option key={k} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <button disabled={props.sending} className="btn btn-success w-100">
            {props.sending ? (
              <Waiting />
            ) : (
              <>
                <i className="fas fa-plus mr-2" />
                {getTrad('commun.add')}
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

class Add extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sending: false,
      selected: '',

      list: [],
    }
  }

  send = event => {
    event.preventDefault()
    this.setState({ sending: true }, () => {
      axios
        .post(getUrl('aide_sociale.sendDemande'), { id: this.state.selected })
        .then(res => {
          successMsg(getTrad('success.demande'))
          this.setState(
            { selected: '', sending: false, list: res.data.success },
            this.props.refresh
          )
        })
        .catch(() => {
          fatalError()
          this.setState({ selected: '', sending: false, list: [] })
        })
    })
  }

  handler = event => this.setState({ selected: event.target.value })

  refresh = () => {
    this.setState({ loading: true }, () => {
      axios
        .get(getUrl('aide_sociale.icangot'))
        .then(res => this.setState({ loading: false, list: res.data.success }))
        .catch(() => this.setState({ loading: false, list: [] }))
    })
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h3>{getTrad('pages.aideSociale.demande')}</h3>
        </div>
        <div className="card-body">
          <ShowForm {...this.state} send={this.send} handler={this.handler} />
        </div>
      </div>
    )
  }
}

export default Add
