import { en as bs } from './locals/bs'
import { en as log } from './locals/log'
import { en as auth } from './locals/auth'
import { en as type } from './locals/type'
import { en as login } from './locals/login'
import { en as pages } from './locals/pages'
import { en as aides } from './locals/aides'
import { en as saisie } from './locals/saisie'
import { en as fileds } from './locals/fileds'
import { en as navbar } from './locals/navbar'
import { en as errors } from './locals/errors'
import { en as credit } from './locals/credit'
import { en as commun } from './locals/commun'
import { en as success } from './locals/success'
import { en as science } from './locals/science'
import { en as adherant } from './locals/adherant'
import { en as bordoreau } from './locals/bordoreau'
import { en as pagination } from './locals/pagination'
import { en as validation } from './locals/validation'
import { en as aidesociale } from './locals/aidesociale'
import { en as condidature } from './locals/condidature'
import { en as lvl_studies } from './locals/lvl_studies'
import { en as situationsfamiliale } from './locals/situationsfamiliale'
import { fr as parent } from './locals/parent'

let local = {
  bs: bs,
  log: log,
  auth: auth,
  type: type,
  login: login,
  pages: pages,
  parent: parent,
  aides: aides,
  saisie: saisie,
  fileds: fileds,
  navbar: navbar,
  errors: errors,
  credit: credit,
  commun: commun,
  success: success,
  science: science,
  adherant: adherant,
  bordoreau: bordoreau,
  pagination: pagination,
  validation: validation,
  aidesociale: aidesociale,
  condidature: condidature,
  lvl_studies: lvl_studies,
  situationsfamiliale: situationsfamiliale,
}

export default local
