import React from 'react'
import { useTrad } from '../../../lang'
import { userStore } from '../../../store/userStore'

function Greating() {
  const { user } = userStore()
  const { sexe, name, last } = user || {}

  let greating = useTrad('commun.greating')
  let civi = useTrad(`commun.civility.${sexe}`)
  let first = name

  return (
    <div className="card p-5 text-center">
      <h1>{`${greating} ${civi} ${first} ${last}`}</h1>
    </div>
  )
}

export default Greating
