import React, { Component } from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { areYouSure, fatalError, errorToast, successToast } from '../../../../helpers/sweetAlerts'
import Waiting from '../../../../components/Waiting'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'

class AddCondidature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false,
      show: false,
      prix: '',
      listCondidats: [],
      matricule: '',
      condidat: '',
      proof: '',
      proofVal: '',
    }
  }

  send = () => {
    let { condidat, prix } = this.state
    if (condidat && prix) {
      axios
        .post(getUrl('journeeSciences.condidat.acceptCondidat', [condidat, prix]))
        .then(res => {
          this.setState({
            sending: false,
            prix: '',
            listCondidats: [],
            matricule: '',
            condidat: '',
          })
          successToast.fire({ title: 'Candidat ajouté avec success' })
        })
        .catch(err => {
          this.setState({
            sending: false,
            prix: '',
            listCondidats: [],
            matricule: '',
            condidat: '',
          })
          fatalError()
        })
    } else {
      errorToast.fire({ title: 'Veuillez selectionner le candidat et le prix' })
      this.setState({
        sending: false,
      })
    }
  }

  handleShow = () => this.setState({ show: !this.state.show })

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  resetImg = e => this.setState({ proof: '' })

  updatePresta = () => {
    let { matricule } = this.state
    if (matricule) {
      axios
        .get(getUrl('journeeSciences.condidat.liste', [matricule]))
        .then(res => this.setState({ listCondidats: res.data.success }))
        .catch(err => {
          this.setState({
            sending: false,
            prix: '',
            listCondidats: [],
            condidat: '',
          })
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.matricule !== prevState.matricule) {
      this.updatePresta()
    }
  }

  render() {
    let { listCondidats, sending, prix, condidat } = this.state

    const selectPrix =
      listCondidats.length === 0 ? null : (
        <div className="form-group">
          <label htmlFor="prix">{getTrad('science.prix')} :</label>
          <select
            className="form-control"
            id="prix"
            name="prix"
            value={this.state.prix}
            onChange={this.handleChange}
          >
            <option value=""></option>
            {this.props.prix.map((e, k) => (
              <option key={k} value={e.id}>
                {e.niveau}
              </option>
            ))}
          </select>
        </div>
      )

    const condidats =
      listCondidats.length === 0 ? null : (
        <div className="form-group">
          <label htmlFor="condidat">{getTrad('science.condidat')} :</label>
          <select
            className="form-control"
            id="condidat"
            name="condidat"
            value={this.state.condidat}
            onChange={this.handleChange}
          >
            <option value=""></option>
            {listCondidats.map((e, k) => (
              <option key={k} value={e.id}>
                {e.first_name} {e.last_name}
              </option>
            ))}
          </select>
        </div>
      )

    const adherant = (
      <div className="form-group">
        <label htmlFor="matricule">{getTrad('adherant.matricule')} :</label>
        <input
          type="text"
          value={this.state.matricule}
          className="form-control"
          id="matricule"
          placeholder={getTrad('adherant.matricule')}
          name="matricule"
          onChange={this.handleChange}
        />
      </div>
    )

    return (
      <>
        <button className="btn btn-sm btn-success" onClick={this.handleShow}>
          <i className="fas fa-plus"></i>
        </button>
        <Modal show={this.state.show} centered onHide={this.handleShow}>
          <Modal.Header closeButton>
            <Modal.Title>{getTrad('science.newCondidat')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {adherant}
            {condidats}
            {selectPrix}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success w-100"
              onClick={() =>
                areYouSure.fire().then(result => {
                  if (result.value) this.setState({ sending: true }, this.send)
                })
              }
              disabled={sending || !(prix && condidat)}
            >
              {this.state.sending ? (
                <Waiting />
              ) : (
                <>
                  {getTrad('commun.send')} <i className="fas fa-paper-plane"></i>
                </>
              )}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

/*



*/

export default AddCondidature
