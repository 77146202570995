import React from 'react'
import { Link } from 'react-router-dom'

import Greeting from './Greeting'
import Menue from './Menue'

function Brand(props) {
  return (
    <Link className="navbar-brand" to="/">
      {props.appName}
    </Link>
  )
}

function Header() {
  return (
    <nav className="navbar navbar-expand-md navbar-dark">
      <Brand appName="GAMM" />

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <Menue />
        <Greeting />
      </div>
    </nav>
  )
}

export default Header
