import React, { Component } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import ShowTable from '../../../../components/ShowTable'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import { inString } from '../../../../helpers'
import CardHeader from '../../../../components/CardHeader'
import Export from '../../../../components/Export'
import AddCondidature from './AddCondidature'
import Details from './Details'
import Filter from './Filter'

class ListeDemande extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edition: '',
      loading: true,
      redirect: false,
      listeLaureats: [],
      listeLaureatsFiltred: [],
      searching: false,
      prix: [],
      slug: undefined,
      action: undefined,
      listStatus: [],
      listPrix: {},

      search_prix_name: [],
      search_status: [],
      search_mat_adherant: '',
      search_full_name: '',
      search_pres_full_name: '',
    }
  }

  getParams() {
    if (this.props.current) {
      this.setState({ slug: null, action: null, loading: true }, this.refresh)
      return false
    }

    const { slug } = this.props?.match?.params || {}
    this.setState({ slug, loading: true }, this.refresh)
    return true
  }

  refresh = () => {
    let { slug } = this.state
    let url = getUrl('journeeSciences.get.current')
    if (slug) {
      url = getUrl('journeeSciences.get.all', [slug])
    }
    this.setState({ loading: true }, () => {
      axios
        .get(url)
        .then(res => {
          let listStatus = []
          let listPrix = {}
          let prix = res.data.success.journee.prix
          let tmp = []
          res.data.success.data.map(e => {
            if (!listStatus.includes(e.status)) listStatus.push(e.status)
            if (!Object.keys(listPrix).includes(e.prix_id)) listPrix[e.prix_id] = e.prix_name
            tmp.push({
              ...e,
              status_code: e.status,
              status: getTrad(`condidature.statusCode.${e.status}`),
              actions: <Details {...e} refresh={this.refresh} />,
            })
            return true
          })

          this.setState(
            {
              edition: res.data.success.journee.edition,
              loading: false,
              listeLaureatsFiltred: tmp,
              listeLaureats: tmp,
              prix: prix,
              listPrix: listPrix,
              listStatus: listStatus,
              search_prix_name: listStatus,
              search_status: Object.keys(listPrix),
            },
            this.filter
          )
        })
        .catch(err => this.setState({ loading: false, redirect: true }))
    })
  }

  filterBool = e => {
    let tmp = true
    tmp &= inString(this.state.search_mat_adherant, e.mat_adherant)
    tmp &= inString(this.state.search_full_name, e.full_name)
    tmp &= inString(this.state.search_pres_full_name, e.pres_full_name)
    tmp &= this.state.search_prix_name.includes(e.status_code)
    tmp &= this.state.search_status.includes(e.prix_id.toString())

    return tmp
  }

  filter = () => {
    this.setState({ listeLaureatsFiltred: this.state.listeLaureats.filter(this.filterBool) })
  }

  showProps() {
    let keys = [
      'mat_adherant',
      'full_name',
      'pres_full_name',
      'phone',
      'prix_name',
      'status',
      'actions',
    ]
    let header = {}
    for (var i = 0; i < keys.length; i++) {
      header[keys[i]] = getTrad(`condidature.${keys[i]}`)
    }

    let showProps = { data: this.state.listeLaureatsFiltred, keys: keys, header: header }
    showProps.noData = getTrad('commun.noSearchData')
    if (this.state.searching || this.state.loading) showProps.searching = true
    return showProps
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value }, this.filter)
  }

  handleCheck = event => {
    let field = event.target.dataset.field
    let tmp = this.state[`search_${field}`].slice()
    let value = event.target.value

    if (tmp.includes(value)) {
      tmp = tmp.filter(val => value !== val)
    } else {
      tmp.push(value)
    }

    this.setState({ [`search_${field}`]: tmp }, this.filter)
  }

  render() {
    if (this.state.redirect && !this.props.current) return <Redirect to="/journee-savoir" />
    if (this.state.redirect) return <NoEdition />
    return (
      <div className="card">
        <CardHeader
          title={getTrad('pages.listeLaureats', [this.state.edition])}
          actions={
            <div className="btn-group">
              <AddCondidature prix={this.state.prix} />
              <Export
                title={getTrad('commun.exportBtn.filtred')}
                fileName={'condidates_journée_sciences'}
                csvData={this.state.listeLaureatsFiltred.map(e => ({
                  [getTrad(`condidature.mat_adherant`)]: e.mat_adherant,
                  [getTrad(`condidature.full_name`)]: e.full_name,
                  [getTrad(`condidature.pres_full_name`)]: e.pres_full_name,
                  [getTrad(`condidature.phone`)]: e.phone,
                  [getTrad(`condidature.prix_name`)]: e.prix_name,
                  [getTrad(`condidature.status`)]: e.status,
                }))}
              />
              <Export
                title={getTrad('commun.exportBtn.all')}
                fileName={'condidates_journée_sciences_filtré'}
                csvData={this.state.listeLaureats.map(e => ({
                  [getTrad(`condidature.mat_adherant`)]: e.mat_adherant,
                  [getTrad(`condidature.full_name`)]: e.full_name,
                  [getTrad(`condidature.pres_full_name`)]: e.pres_full_name,
                  [getTrad(`condidature.phone`)]: e.phone,
                  [getTrad(`condidature.prix_name`)]: e.prix_name,
                  [getTrad(`condidature.status`)]: e.status,
                }))}
              />
              <button className="btn btn-dark" onClick={this.refresh}>
                <i className="fas fa-sync-alt"></i>
              </button>
            </div>
          }
        />
        <Filter
          values={{
            search_prix_name: this.state.search_prix_name,
            search_status: this.state.search_status,
            search_mat_adherant: this.state.search_mat_adherant,
            search_full_name: this.state.search_full_name,
            search_pres_full_name: this.state.search_pres_full_name,
          }}
          handleInput={this.handleInput}
          handleCheck={this.handleCheck}
          possibles={{
            prix_name: this.state.listStatus,
            status: this.state.listPrix,
          }}
        />
        <div className="card-body p-0">
          <ShowTable {...this.showProps()} />
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { slug } = this.props?.match?.params || {}
    const { slug: oldSlug } = prevProps?.match?.params || {}

    if (slug !== oldSlug) this.getParams()
  }

  componentDidMount() {
    this.getParams()
  }
}
export default ListeDemande

function NoEdition() {
  return (
    <div className="card">
      <div className="card-body p-5">
        <h1 className="text-center text-danger">Vous n'avez pas d'édition en cours</h1>
      </div>
    </div>
  )
}
