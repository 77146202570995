import Axios from 'axios'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { getUrl } from '../../../../Api'
import InputText from '../../../../components/Form/InputText'
import ShowTable from '../../../../components/ShowTable'
import Waiting from '../../../../components/Waiting'
import { inString, showDate, showMoney } from '../../../../helpers'
import { getTrad } from '../../../../lang'
import InfoVersement from './InfoVersement'

function Filter(props) {
  const keys = ['matricule', 'matricule_cnrps', 'full_name']

  return (
    <div className="card-body pb-0">
      <div className="row">
        {keys.map((e, k) => (
          <div key={k} className="col">
            <InputText
              label={getTrad('fileds.' + e)}
              value={props[e]}
              name={e}
              handler={props.handler}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

class VersementsShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      loading: true,
      aide: {},
      versement: [],
      redirect: false,
      matricule: '',
      matricule_cnrps: '',
      full_name: '',
    }
  }

  componentDidMount() {
    this.setState({ id: this.getId() }, this.refresh)
  }

  componentDidUpdate() {
    if (this.state.id !== this.getId())
      this.setState({ loading: true, id: this.getId() }, this.refresh)
  }

  handler = event => this.setState({ [event.target.name]: event.target.value })

  getId = () => {
    return this.props && this.props.match && this.props.match.params && this.props.match.params.id
  }

  filter = e => {
    if (!e) return false
    if (!inString(this.state.matricule, e.matricule)) return false
    if (!inString(this.state.matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(this.state.full_name, e.full_name)) return false
    return true
  }

  cleanData = e => ({
    ...e,
    total: showMoney(e.total),
  })

  refresh = () => {
    Axios.get(getUrl('aide_sociale.showVersements', [this.state.id]))
      .then(res => {
        this.setState({
          name: res.data.success.name,
          date: showDate(res.data.success.date),
          number_adherants: res.data.success.number_adherants,
          number: res.data.success.number,
          forfait: getTrad(`commun.boolean.${res.data.success.forfait ? 'oui' : 'non'}`),
          montant: showMoney(res.data.success.montant),
          total: showMoney(res.data.success.total),
          versement: res.data.success.versement.map(this.cleanData),

          loading: false,
          redirect: false,
        })
      })
      .catch(err => this.setState({ loading: false, redirect: true }))
  }

  render() {
    if (this.state.loading) return <Waiting></Waiting>
    if (this.state.redirect) return <Redirect to="/"></Redirect>

    const keys = ['matricule', 'matricule_cnrps', 'full_name', 'number', 'total']
    const header = {}

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'number') continue
      header[keys[i]] = getTrad(`fileds.${keys[i]}`)
    }
    header.number = getTrad(`aidesociale.number`)

    const showProps = {
      keys: keys,
      searching: this.state.loading,
      header: header,
      noData: getTrad('commun.noSearchData'),
      data: this.state.versement.filter(this.filter),
    }

    return (
      <>
        <InfoVersement headers={header} data={this.state.versement} {...this.state} />
        <div className="card">
          <Filter
            handler={this.handler}
            matricule={this.state.matricule}
            matricule_cnrps={this.state.matricule_cnrps}
            full_name={this.state.full_name}
          />
          <ShowTable {...showProps}></ShowTable>
        </div>
      </>
    )
  }
}

export default VersementsShow
