import axios from 'axios'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import Waiting from '../../../../../components/Waiting'
import { showMoney } from '../../../../../helpers'
import { getTrad } from '../../../../../lang'

const defaultProps = { matAd: { value: '', error: [] }, prestatire: { value: '' } }

function Plafond({
  bsInfo: { matAd: { value: matAd, error }, prestatire: { value: prestataire } } = defaultProps,
}) {
  const {
    mutate,
    data: {
      plafond = '',
      current = '',
      canGet = { status: false, message: '' },
      remboursements = '',
    } = {},
    isLoading,
    isError,
  } = useMutation(() => {
    if (prestataire)
      return axios.get(`bulletins/${matAd}/${prestataire}/plafond`).then(res => res.data.success)
    if (matAd) return axios.get(`bulletins/${matAd}/plafond`).then(res => res.data.success)
  })

  useEffect(() => {
    mutate()
  }, [matAd, mutate, prestataire])
  if ((matAd === '' && prestataire === '') || error?.[0] === 'notfound') return null

  const varient = isError
    ? 'danger'
    : isLoading
    ? 'info'
    : canGet.status && plafond > remboursements
    ? 'success'
    : 'danger'
  const innerCard = isError ? (
    getTrad('errors.cummonError')
  ) : isLoading ? (
    <Waiting />
  ) : plafond > remboursements ? (
    getTrad('bs.depacepas', [showMoney(remboursements), showMoney(plafond)])
  ) : (
    getTrad('bs.depace', [showMoney(remboursements), showMoney(plafond)])
  )

  return (
    <div className={`alert text-center rounded-0 alert-${varient}`} role="alert">
      <div>{innerCard}</div>
      <div>{getTrad('bs.totalDepences', [showMoney(current)])}</div>
      {(canGet.message || '').split('|').map((mess, index) => (
        <div key={index}>{mess}</div>
      ))}
    </div>
  )
}
export default Plafond
