export const fr = {
  numBs: 'N° Bulletin',
  prestatire: 'Prestataire',
  date_soin: 'Date de soin',
  mat_adherant: 'Matricule',
  id: 'ID',
  matricule_adh: 'Matricule adhérent',
  full_name: 'Nom et prénom',
  prestataire: 'Prestataire',
  bs_number: 'N° bulletin de soins',
  number: 'N°',
  bs_date: 'Date de bulletin de soins',
  beneficiere: 'Bénéficiaire',
  description: 'Description',
  V: 'V',
  modifdelete: "Motif d'annulation de bulletin",
  PH: 'PH',
  AM: 'AM',
  depace: "L'adhérent a dépassé son plafond de remboursement {} / {}",
  depacepas: "L'adhérent n'a pas encore dépassé son plafond de remboursement {} / {}",
  D: 'D',
  BP: 'BP',
  KE_RK: 'KE_RK',
  TSK: 'TSK',
  K: 'K',
  OPTI: 'OPTI',
  DIVER: 'Divers',
  depenses: 'Dépenses',
  nb_bords: 'Nombre de bordereaux',
  observations: 'Observation',
  estimation: 'Estimation de remboursement',
  action: 'Actions',
  total_depense: 'Total de dépenses',
  total_remboursement: 'Total remboursé ',
  montant_remboursement: 'Montant remboursé',
  last_update: 'Dernière mise-à-jour',
  status: 'Statut',
  status_NT: 'Non traité',
  status_ET: 'En cours de traitement',
  status_C: 'Annulé',
  status_V: 'En attente de validation',
  status_T: 'Traité',
  notfound: 'Bulletin inexistant',
  complement: 'Complément',
  rembours: 'Remboursement de bulletin de soins N° ',
  nb_bss: 'Nombre de bulletins de soins',
  montant_bss: 'Montant de bulletins de soins',
  id_bord: 'N° de bordereau',
  date_bord: 'Date de bordereau',
  created_at: 'Date de création',
  updated_at: 'Date de mise-à-jour',
  empty: 'Ce bulletin est vide',
  totalDepences: "Le montant dépensé par l'adhérent est {}",
}

export const ar = {
  numBs: 'رقم الدّفتر',
  prestatire: 'المنتفع',
  date_soin: 'تاريخ العلاج',
  mat_adherant: 'رقم التسجيل',
  id: 'المعرّف',
  matricule_adh: 'رقم العضوية',
  full_name: 'الاسم و اللّقب',
  prestataire: 'المنتفع',
  bs_number: 'رقم بطاقة العلاج',
  number: 'رقمْ',
  bs_date: 'تاريخ بطاقة العلاج',
  description: 'الوصف',
  V: 'V',
  modifdelete: 'سبب إلغاء البطاقة',
  PH: 'PH',
  AM: 'AM',
  D: 'D',
  BP: 'BP',
  KE_RK: 'KE_RK',
  TSK: 'TSK',
  K: 'K',
  OPTI: 'OPTI',
  DIVER: 'متعدد',
  depenses: 'نفقات',
  observations: 'ملاحظات',
  estimation: 'تقدير معلوم سداد الدّين',
  action: 'اجراءات',
  total_depense: 'مجموع النّفقات',
  total_remboursement: 'مجموع المبالغ المستردة',
  last_update: 'التحديث اﻷخير',
  status: 'الحالة',
  status_NT: 'غير معالجة',
  status_ET: 'بصدد المعالجة',
  status_C: 'ملغاة',
  status_T: 'عولجت',
  complement: 'ملحق',
  rembours: 'استرداد معاليم بطاقة العلاج رقمْ ',
}

export const en = {
  numBs: 'N° of treatment bulletin',
  prestatire: 'Beneficiary',
  date_soin: 'Treatment date',
  mat_adherant: 'Registration number',
  id: 'ID',
  matricule_adh: 'Membership Registration number',
  full_name: 'Full name',
  prestataire: 'Recipient',
  bs_number: 'N° of treatment bulletin',
  number: 'N°',
  bs_date: 'Date of treatment bulletin',
  beneficiere: 'Beneficiairy',
  description: 'Description',
  V: 'V',
  modifdelete: 'Reason for treatment bulletin cancellation',
  PH: 'PH',
  AM: 'AM',
  depace: 'The member has exceeded his refund limit {}DT / {} DT',
  depacepas: "The member hasn't yet exceeded his refund limit {}DT / {} DT",
  D: 'D',
  BP: 'BP',
  KE_RK: 'KE_RK',
  TSK: 'TSK',
  K: 'K',
  OPTI: 'OPTI',
  DIVER: 'Various',
  depenses: 'Expenses',
  nb_bords: 'Number of tax treatment',
  observations: 'Observation',
  estimation: 'Refund estimation',
  action: 'Actions',
  total_depense: 'Total expenses',
  total_remboursement: 'Total refund ',
  montant_remboursement: 'Reimbursed amount',
  last_update: 'Last update',
  status: 'Status',
  status_NT: 'Untreated',
  status_ET: 'In the process of treatment',
  status_C: 'Cancelled',
  status_V: 'Waiting for validation',
  status_T: 'Treated',
  notfound: 'Inexistent treatment bulletin',
  complement: 'Complement',
  rembours: 'Refund of treatment bulletin N° ',
  nb_bss: 'Number of treatment bulletin',
  montant_bss: 'Amount of treatment bulletin',
  id_bord: 'N° of tax treatment',
  date_bord: 'Date of tax treatment',
  created_at: 'Date of creation',
  updated_at: 'Date of update',
}
