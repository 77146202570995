import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isNotAdmin } from '../../../helpers'

import Add from './Add'
import Demandes from './Demandes'
import Credits from './Credits'
import MesCredits from './MesCredits'
import Saisie from './Saisie'
import Show from './Show'
import Statistiques from '../../../components/Stats'
import NonPayee from './NonPayee'
import { userStore } from '../../../store/userStore'

export default function Credit(props) {
  const { user } = userStore()
  const {
    role: { role },
  } = user || { role: { role: '' } }

  if (isNotAdmin(role)) {
    return (
      <Switch>
        <Route exact strict path={`/credit`}>
          <MesCredits {...props} path="/credit" />
        </Route>

        <Route>
          <Redirect to="/credit" />
        </Route>
      </Switch>
    )
  }
  return (
    <Switch>
      <Route exact strict path={`/credit/add`}>
        <Add />
      </Route>
      <Route exact strict path={`/credit/all`}>
        <Credits all />
      </Route>

      <Route exact strict path={`/credit/non-payee`}>
        <NonPayee />
      </Route>

      <Route exact path={`/credit/statistiques`}>
        <Statistiques
          barKeys={[]}
          tableKeys={[
            { key: 'numbers', label: 'credit.nb_credits' },
            { key: 'sommes', label: 'credit.montant_credits', isMoney: true },
          ]}
          path="/credit"
          page="pages.statistiques.credits"
          statsUrl="credits.stats"
        />
      </Route>
      <Route exact strict path={`/credit/demande`}>
        <Demandes {...props} path="/credit" />
      </Route>

      <Route strict path={`/credit/saisie`}>
        <Saisie {...props} />
      </Route>

      <Route strict exact path={`/credit/:id`} component={Show}></Route>

      <Route exact strict path={`/credit`}>
        <Credits {...props} path="/credit" />
      </Route>

      <Route>
        <Redirect to="/credit" />
      </Route>
    </Switch>
  )
}
