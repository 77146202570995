import React, { Component } from 'react'
import QRCode from '../../../components/QrCode'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import { showDate } from '../../../helpers'
import Family from './Family'
import { LangContext } from '../../../context/LangContext'

function RenderHader() {
  return (
    <div className="card-header">
      <div className="row">
        <div className="col-6">
          <h2 className="card-title">{getTrad('pages.monProfil')}</h2>
        </div>

        <div className="col-6 text-right">
          <div className="btn-group" role="group" aria-label="Basic example">
            <div className="btn-group" role="group" aria-label="Basic example">
              <Link to="/mon-profil/edit" className="btn btn-secondary">
                <i className="far fa-edit"></i> {getTrad('commun.edit')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class GestionAdmins extends Component {
  // Component Life Cycle

  static contextType = LangContext

  constructor(props) {
    super(props)
    this.state = { redirect: false, matricule: null, adherant: {} }
  }

  componentDidMount() {
    axios
      .get(getUrl('adherants.profil'))
      .then(res => this.setState({ adherant: res.data.success }))
      .catch(err => this.setState({ redirect: true }))
  }

  render() {
    if (this.state.redirect) return <Redirect to="/" />
    return (
      <div className="card">
        <RenderHader />
        {this.renderBody()}
      </div>
    )
  }

  getQrCodeText() {
    return `${getTrad('adherant.matricule_adh')} : ${this.state.adherant.matricule}`
  }

  print() {
    // // TODO: FIX PRINT
    // var divToPrint = document.getElementById('printableDiv')
    // var newWin = window.open('', 'Print-Window')
    // newWin.document.open()
    // newWin.document.write(
    //     '<html><head><link rel="stylesheet" href="' +
    //         "{{ asset('css/print.min.css') }}" +
    //         '" ></head><body onload="window.print()">' +
    //         divToPrint.innerHTML +
    //         '</body></html>'
    // )
    // newWin.document.close()
    // setTimeout(function() {
    //     newWin.close()
    // }, 10)
  }

  renderField(key, width) {
    let w = width ? width : 4
    if (this.state.adherant[key]) {
      return (
        <div className={`col-${w}`}>
          <span className="field-title">{getTrad(`adherant.${key}`)} :</span>
          {this.state.adherant[key]}
        </div>
      )
    }
  }

  renderDate(key, width) {
    let w = width ? width : 4
    if (this.state.adherant[key]) {
      return (
        <div className={`col-${w}`}>
          <span className="field-title">{getTrad(`adherant.${key}`)} :</span>
          {showDate(this.state.adherant[key])}
        </div>
      )
    }
  }

  renderSexe(key, width) {
    let w = width ? width : 4
    let sexe = this.state.adherant[key]

    if (['M', 'F'].includes(sexe)) {
      return (
        <div className={`col-${w}`}>
          <span className="field-title">{getTrad(`adherant.${key}`)} :</span>
          {getTrad(`commun.sexe.${sexe}`)}
        </div>
      )
    }
  }

  renderForeign(key, value, width) {
    const { extra } = this.context
    let w = width ? width : 4
    if (!value) return <></>
    if (!extra[key]) return <></>

    return (
      <div className={`col-${w}`}>
        <span className="field-title">{getTrad(`adherant.${key}`)} :</span>
        {extra[key][value]}
      </div>
    )
  }

  renderBody() {
    return (
      <div className="card-body" id="printableDiv">
        <div className="row">
          <div className="col-10">
            <h4 className="card-title">{getTrad('fileds.identifiants')}</h4>
            <div className="row">
              {this.renderField('matricule')}
              {this.renderField('matricule_cnrps')}
              {this.renderField('cin')}
            </div>
          </div>
          <div className="col-2 text-right">
            <QRCode value={this.getQrCodeText()} />
          </div>

          <hr />
        </div>
        <h4 className="card-title">{getTrad('adherant.infoperso')}</h4>
        <div className="row">
          {this.renderField('first_name', 6)}
          {this.renderField('first_name_ar', 6)}
          {this.renderField('last_name', 6)}
          {this.renderField('last_name_ar', 6)}
          {this.renderField('email')}
          {this.renderField('phone')}
          {this.renderSexe('sexe')}
          {this.renderDate('birth_day')}

          {this.renderForeign('situationsfamiliale', this.state.adherant.situationsfamiliale)}
          {this.renderField('rib')}
        </div>
        <Family family={this.state.adherant.family} />

        <h4 className="card-title">{getTrad('adherant.travail')}</h4>
        <div className="row">
          {this.renderForeign('categorie', this.state.adherant.categorie, 6)}
          {this.renderForeign('grade', this.state.adherant.grade, 6)}
          {this.renderForeign('lieu', this.state.adherant.lieu, 6)}
          {this.renderForeign('status', this.state.adherant.status, 6)}
        </div>
      </div>
    )
  }
}
