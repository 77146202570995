export const fr = {
  login_page: 'Page de connexion',
  crudAdmin_page: 'Liste des administrateurs',
  addAdmin: 'Ajouter un administrateur',
  addFamily: 'Ajouter',
  updateFamily: 'Modifier',
  showFamily: 'Afficher',
  addBs: 'Ajouter un bulletin de soins',
  editBs: 'Modifier un bulletin de soins',
  listDemandesBS: 'Liste des demandes de bulletin de soins',
  aides: 'Aides',
  myAides: 'Mes aides',
  listnonpayee: 'Liste des remboursements non payés',
  newAdherant: 'Ajouter un adhérent',
  editAdherant: 'Modifier un adhérent',
  listDemandesCredits: 'Liste des demandes de crédits',
  listeDemandesAide: 'Liste des demandes des aides',
  ListAdherants: 'Liste des adhérents',
  showAdherant: 'Fiche de l`adhérent',
  newCredit: 'Demande de crédit',
  listCreditsEncour: 'Liste des crédits en cours',
  listCreditsTerminee: 'Liste des crédits terminés',

  nontraite_aide: 'Liste des aides non traitées',
  traite_aide: 'Liste des aides traitées',
  refusee_aide: 'Liste des aides refusées',
  list_orphelin: 'Liste des enfants orphelins',

  listBS: 'Liste des bulletin de soins',
  listeLaureats: "Liste des candidatures pour l'édition {}",
  listEditionScience: 'Liste des éditions des journées de savoir',
  addEditionScience: 'Ajouter une édition',
  searchBS: 'Recherche de bulletin de soins',
  listBord: 'Liste des bordereaux',
  monProfil: 'Mon profil',
  DetailsBord: 'Détails de bordereau N°',
  editProfil: 'Modifier mes informations personnelles',
  demanderAide: "Demande d'aide",
  myBs: 'Mes bulletins de soin',
  monCreditEnCour: 'Mon crédit en cours',
  demandeCredit: 'Demander un crédit',
  historyCredit: 'Mon historique de crédits',
  log: "Historique de l'Application GAMM",
  saisie: 'Liste de saisies',
  showsaisie: 'saisie N°{} du {}',
  detailsCredit: 'Crédit N°{}',
  listGouvs: 'Liste des gouvernorats',
  listEtabs: 'Liste des établissements',

  etablissements: 'Gestion des établissements',

  aideSociale: {
    myVersements: "Mes versements d'aides",
    demande: "Demande d'aide sociale",
    my: 'Mes aides sociales',
    config: 'Paramètres des aides sociales',
    list: 'Liste des aides sociales',
    addAdherant: 'Ajouter un adhérent',
    details: 'Détails du versement "{}" du {}',
  },
  maDemandeCredit: 'Ma demande de crédit',
  statistiques: {
    bs: 'Statistiques des bulletins de soins',
    credits: 'Statistiques de crédits',
    aidesociale: 'Statistiques des aides',
    parents: 'Statistiques de parents',
    aides_sociales: 'Statistiques des aides sociales',
  },

  parent: {
    demande: 'Demande de parent à charge',
    contract: 'Liste des contrats de parents à charge',
    cession: 'Liste des cessions de parents à charge',
  },

  copyright:
    '© 2020 Copyright<br /> Application GAMM <br /> <a target="_blank" href="http://www.mutuellemagistrat.com.tn/">Mutuelle des magistrats</a>',
}

export const ar = {
  login_page: 'صفحة تسجيل الدخول',
  crudAdmin_page: 'قائمة المشرفين',
  addAdmin: 'إضافة مشرف',
  addFamily: 'اضافة',
  updateFamily: 'تعديل',
  showFamily: 'عرض',
  addBs: 'أضف بطاقة العلاج',
  editBs: 'تعديل بطاقة العلاج',
  aides: 'المساعدات',
  myAides: 'اعاناتي',
  newAdherant: 'إضافة عضو',
  editAdherant: 'تعديل معطيات عضو',
  listDemandesCredits: 'قائمة طلبات الدّيون',
  listeDemandesAide: 'قائمة طلبات الاعانة',
  ListAdherants: 'قائمة الأعضاء',
  showAdherant: 'معطيات العضو',
  newCredit: 'طلب قرض',
  listCreditsEncour: 'قائمة القروض الحالية',
  listCreditsTerminee: 'قائمة القروض المسدّدة',

  listBS: 'قائمة بطاقات العلاج',
  listeLaureats: 'قائمة المرشحين المتعلِّقة بالاصدار {}',
  listEditionScience: 'قائمة اصدارات أيام المعرفة',
  addEditionScience: 'أضف إصدارًا',
  searchBS: 'البحث عن بطاقة علاج',
  listBord: 'قائمة القسيمات',
  monProfil: 'ملفي',
  DetailsBord: 'تفاصيل القسيمة عدد',
  editProfil: 'تعديل معلوماتي الشخصية',
  demanderAide: 'طلب للحصول على الاعانة',
  myBs: 'بطاقات العلاج الخاصة بي',
  monCreditEnCour: 'قرضي الحالي',
  demandeCredit: 'التقدم بطلب للحصول على قرض',
  historyCredit: 'سجل القروض الخاص بي',
  log: 'تاريخ تطبيق GAMM',
  saisie: 'قائمة إدخال المعلومات',
  showsaisie: 'إدخال المعلومات رقم {} من {}',
  detailsCredit: 'معطيات القرض رقم {}',
  listGouvs: 'قائمة الولايات',
  listEtabs: 'قائمة المنشآت',

  etablissements: 'إدارة المنشآت',
  aideSociale: {
    config: 'تعديلات المساعدات الاجتماعية',
    list: 'قائمة المساعدات الاجتماعية',
    addAdherant: 'اضافة عضو',
  },
  maDemandeCredit: 'طلب القرض الخاص بي',
  copyright:
    '© 2020 Copyright<br /> تعاونية القضاة<br /> <a target="_blank" href="http://www.mutuellemagistrat.com.tn/">تعاونية القضاة</a>',
}

export const en = {
  login_page: 'Connection page',
  crudAdmin_page: 'Administrators`list',
  addAdmin: 'Add an administrator',
  addFamily: 'Add',
  updateFamily: 'Rectify',
  showFamily: 'Show',
  addBs: 'Add a treatment bulletin',
  editBs: 'Rectify a treatment bulletin',
  listDemandesBS: 'List of treatment bulletin requests',
  aides: 'Support',
  myAides: 'My support',
  listnonpayee: 'List of unpaid refunds',
  newAdherant: 'Add a member',
  editAdherant: 'Rectify a member',
  listDemandesCredits: 'List of credit requests',
  listeDemandesAide: 'List of aid requests',
  ListAdherants: 'List of members',
  showAdherant: 'Member card',
  newCredit: 'Credit requests',
  listCreditsEncour: 'Current credits list',
  listCreditsTerminee: 'Closed credits list',

  nontraite_aide: 'List of untreated aid',
  traite_aide: 'List of treated aid',
  refusee_aide: 'List of refused aid',
  list_orphelin: 'List of orphans',

  listBS: 'List of care bulletins',
  listeLaureats: 'List of candidates for the edition {}',
  listEditionScience: 'List of editions of the Knowledge Days',
  addEditionScience: 'Add an edition',
  searchBS: 'Search a care bulletin',
  listBord: 'List of tax treatments',
  monProfil: 'My profile',
  DetailsBord: 'Details of tax treatment N°',
  editProfil: 'Rectify personal information',
  demanderAide: 'Support request',
  myBs: 'My care bulletins',
  monCreditEnCour: 'My current credits',
  demandeCredit: 'Credit request',
  historyCredit: 'My credits history',
  log: 'GAMM application history',
  saisie: 'Data entries list',
  showsaisie: 'Dta entry N°{} du {}',
  detailsCredit: 'Credit N°{}',
  listGouvs: 'List of governorates',
  listEtabs: 'List of establishments',

  etablissements: 'Establishments management',

  aideSociale: {
    myVersements: 'My aid payments',
    demande: 'Social help request',
    my: 'My social support',
    config: 'Social support settings',
    list: 'List of social help',
    addAdherant: 'Add member',
    details: 'Payment details "{}" of {}',
  },
  maDemandeCredit: 'My credit request',
  statistiques: {
    bs: 'Care bulletins statistics',
    credits: 'Credits statistics',
    aidesociale: 'Support statistics',
    parents: 'Dependent parents statistics',
    aides_sociales: 'Social support statistics',
  },

  parent: {
    demande: 'Request for dependent parent',
    contract: 'List of dependent parents contracts',
    cession: 'List of dependent parents cessions',
  },

  copyright:
    '© 2020 Copyright<br /> Application GAMM <br /> <a target="_blank" href="http://www.mutuellemagistrat.com.tn/">Mutuelle des magistrats</a>',
}
