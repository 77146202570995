import React, { Component } from 'react'
import DateInput from '../../../../components/Form/DateInput'
import Input from '../../../../components/Form/Input'
import axios from 'axios'
import { getUrl } from '../../../../Api'
import { successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import { convertDate } from '../../../../helpers'
import Waiting from '../../../../components/Waiting'
import { getTrad } from '../../../../lang'
import CardHeader from '../../../../components/CardHeader'

function RenderBtn(props) {
  let btnClass = 'btn btn-success w-100 btn-sm'
  let inner = props.sending ? <Waiting /> : getTrad('commun.add')
  return (
    <button className={btnClass} onClick={props.send}>
      {inner}
    </button>
  )
}

class Settings extends Component {
  // Component Life Cycle

  constructor(props) {
    super(props)
    this.state = {
      sending: false,
      edition: '',
      deadline: '',
      date: '',
      loading: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.send = this.send.bind(this)
  }

  render() {
    return (
      <div className="card">
        <CardHeader title={getTrad('pages.addEditionScience')} />
        {this.renderBody()}
      </div>
    )
  }

  send(event) {
    this.setState({ sending: true }, () => {
      let data = {
        edition: this.state.edition,
        deadline: convertDate(this.state.deadline),
        date: convertDate(this.state.date),
      }
      axios
        .post(getUrl('journeeSciences.listAll'), data)
        .then(res => {
          this.props.handler()
          successMsg(getTrad('success.add'))
        })
        .catch(err => {
          if (err.response.status === 401) {
            errorMsg(getTrad('errors.js401'))
          } else if (err.response.status === 422) {
            errorMsg(getTrad('errors.jsData'))
          } else {
            errorMsg('Error')
          }
          this.setState({ sending: false })
        })
    })
  }

  renderBtn() {
    let btnClass = 'btn btn-success w-100 btn-sm'
    let inner = this.state.sending ? <Waiting /> : getTrad('commun.add')
    return (
      <button className={btnClass} onClick={this.send}>
        {inner}
      </button>
    )
  }

  handleChange(name, value) {
    this.setState({ [name]: value })
  }

  renderBody() {
    return (
      <div className="card-body">
        <Input
          ref={this.editionInput}
          label={getTrad('science.edition')}
          name="edition"
          handler={this.handleChange}
        />
        <DateInput
          ref={this.deadlineInput}
          label={getTrad('science.deadline')}
          name="deadline"
          handler={this.handleChange}
        />
        <DateInput
          ref={this.dateInput}
          label={getTrad('science.date')}
          name="date"
          handler={this.handleChange}
        />
        <RenderBtn sending={this.state.sending} send={this.send} />
      </div>
    )
  }
}
export default Settings
