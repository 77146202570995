import React from 'react'
import { getTrad } from '../../../../lang'
import { showDate, showMoney } from '../../../../helpers'
import ShowTable from './ShowTable'

function Details(props) {
  return (
    <div className="card-body">
      <h3>{props.title}</h3>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
        {props.data.map((e, k) => (
          <ShowDetail key={k} {...e} />
        ))}
      </div>
    </div>
  )
}

function ShowDetail(props) {
  let { title, value } = props
  return (
    <div className="col mb-3">
      <b>{title} : </b>
      {value}
    </div>
  )
}

function ShowCredit(props) {
  return (
    <div className="card">
      <div className="card-header">
        <h3>{getTrad('pages.detailsCredit', [props.id])}</h3>
      </div>

      <Details
        title={getTrad('credit.detailsAd')}
        data={[
          { title: getTrad('adherant.matricule'), value: props.adherant.matricule },
          { title: getTrad('adherant.matricule_cnrps'), value: props.adherant.matricule_cnrps },
          { title: getTrad('adherant.first_name'), value: props.adherant.first_name },
          { title: getTrad('adherant.last_name'), value: props.adherant.last_name },
        ]}
      />
      <Details
        title={getTrad('credit.detailsCr')}
        data={[
          { title: getTrad('pages.detailsCredit', ['']), value: props.id },
          { title: getTrad('saisie.sous_compte'), value: props.sous_compte },
          { title: getTrad('credit.date_demande'), value: showDate(props.date_demande) },
          { title: getTrad('fileds.date_versement'), value: showDate(props.date_versement) },
          { title: getTrad('credit.nb_echeance'), value: props.nb_echeance },
          { title: getTrad('credit.montant_demande'), value: showMoney(props.montant_demande) },
          { title: getTrad('credit.montant_rembours'), value: showMoney(props.montant_rembours) },
          { title: getTrad('fileds.montant_echance'), value: showMoney(props.montant_echance) },
          { title: getTrad('credit.motif'), value: props.motif },
        ]}
      />

      <div className="card-body p-0">
        <ShowTable {...props} refresh={props.refresh} />
      </div>
    </div>
  )
}

export default ShowCredit
