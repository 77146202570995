import React, { Component } from 'react'
import axios from 'axios'
import { formatFloat } from '../../../helpers'
import { Link } from 'react-router-dom'
import { getUrl } from '../../../Api'
import { getTrad } from '../../../lang'
import ShowTable from '../../../components/ShowTable'
import Filter from './Filter'
import { inString, showMoney, showDate } from '../../../helpers/'
import Rembours from './Rembours'
import Export from '../../../components/Export'
import Edit from './Edit'
const keys = [
  'id',
  'matricule_adh',
  'sous_compte',
  'matricule_cnrps',
  'first_name',
  'last_name',
  'date_versement',
  'montant',
  'montantRembourse',
  'montant_echance',
  'rest',
  'echeance',
  'actions',
]

function Header(props) {
  const headers = {
    matricule_adh: getTrad('fileds.matricule_adh'),
    matricule_cnrps: getTrad('fileds.matricule_cnrps'),
    first_name: getTrad('fileds.first_name'),
    last_name: getTrad('fileds.last_name'),
    montant: getTrad('fileds.montant'),
    montantRembourse: getTrad('fileds.montantRembourse'),
    rest: getTrad('fileds.rest'),
    montant_echance: getTrad('fileds.montant_echance'),
    echeance: getTrad('fileds.echeance'),
  }

  let title = props.all ? getTrad('pages.listCreditsTerminee') : getTrad('pages.listCreditsEncour')
  return (
    <div className="card-header">
      <div className="row">
        <div className="col">
          <h2 className="card-title">{title}</h2>
        </div>
        <div className="col text-right">
          <div className="btn-group">
            <Export
              fileName="Liste des crédits filtrée"
              title={getTrad('commun.exportBtn.filtred')}
              headers={headers}
              color="dark"
              csvData={props.csvDataFiltred}
            />
            <Export
              fileName="Liste des crédits"
              title={getTrad('commun.export')}
              headers={headers}
              csvData={props.csvData}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function ShowBody(props) {
  let header = {}

  for (var i = 0; i < keys.length; i++) {
    header[keys[i]] = getTrad(`fileds.${keys[i]}`)
  }

  let showProps = {
    data: props.data,
    keys: keys,
    header: header,
    noData: getTrad('commun.noSearchData'),
    searching: props.loading,
  }

  return (
    <div className="card-body p-0">
      <ShowTable {...showProps} />
    </div>
  )
}

export default class Credits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      listCredits: [],
      matricule_adh: '',
      matricule_cnrps: '',
      sous_compte: '',
      date_versement: '',
      has_sous_compte: 'A',
    }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    let { all } = this.props

    if (all !== prevProps.all) this.refresh()
  }

  cleanData = e => {
    let rembour = e.remboursements.map(e => e.montant_rembours).reduce((a, b) => a + b, 0)
    let rest = e.montant_rembours - rembour
    let item = {
      id: e.id,
      matricule_adh: e.adherant.matricule,
      matricule_cnrps: e.adherant.matricule_cnrps,
      sous_compte: e.sous_compte,
      first_name: e.adherant.first_name,
      date_versement: showDate(e.date_versement),
      montant_echance: showMoney(e.montant_echance),
      frais_dossier: e.frais_dossier,
      montant_echance_val: e.montant_echance,
      date_demande: showDate(e.date_demande),
      montant_demande: e.montant_demande,
      last_name: e.adherant.last_name,
      montant: showMoney(formatFloat(e.montant_rembours)),
      montant_val: formatFloat(e.montant_rembours),
      montantRembourse: showMoney(formatFloat(rembour)),
      rest: formatFloat(rest) > 0 ? showMoney(formatFloat(rest)) : getTrad('credit.soldee'),
      echeance: `${e.remboursements.length}/${e.nb_echeance}`,
      nb_echeance: e.nb_echeance,
    }

    let remb = rest <= 0 ? <></> : <Rembours {...e} handler={this.refresh} />

    return {
      ...item,
      actions: (
        <div className="btn-group ">
          {remb}
          <Edit seed={Math.floor(Math.random() * 10000)} refresh={this.refresh} {...item} />
          <Link to={`/credit/${e.id}`} className="btn btn-success btn-sm">
            <i className="fas fa-eye" />
          </Link>
        </div>
      ),
    }
  }

  refresh = () => {
    let url = this.props.all ? getUrl('credits.all') : getUrl('credits.courent')
    axios
      .get(url)
      .then(res =>
        this.setState({ listCredits: res.data.success.map(this.cleanData), loading: false })
      )
  }

  handleInput = event => this.setState({ [event.target.name]: event.target.value })

  filter = e => {
    const { has_sous_compte, date_versement, matricule_adh, matricule_cnrps, sous_compte } =
      this.state
    if (!e) return false
    if (has_sous_compte === 'Y' && e.sous_compte === null) return false
    if (has_sous_compte === 'N' && e.sous_compte !== null) return false
    if (!inString(matricule_adh, e.matricule_adh)) return false
    if (!inString(date_versement, e.date_versement)) return false
    if (!inString(matricule_cnrps, e.matricule_cnrps)) return false
    if (!inString(sous_compte, e.sous_compte)) return false

    return true
  }

  render() {
    return (
      <div className="card">
        <Header
          all={this.props.all}
          fileName="Liste des crédits"
          title={getTrad('commun.export')}
          csvData={this.state.listCredits}
          csvDataFiltred={this.state.listCredits.filter(this.filter)}
        />
        <Filter
          handler={this.handleInput}
          matricule_adh={this.state.matricule_adh}
          matricule_cnrps={this.state.matricule_cnrps}
          date_versement={this.state.date_versement}
          sous_compte={this.state.sous_compte}
          has_sous_compte={this.state.has_sous_compte}
        />
        <ShowBody data={this.state.listCredits.filter(this.filter)} loading={this.state.loading} />
      </div>
    )
  }
}
