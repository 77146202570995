import React from 'react'
import { getTrad } from '../../../../../lang'
import InputSelect from '../../../../../components/Form/InputSelect'

function Tuteur(props) {
  let data = [
    { value: 'P', text: getTrad('commun.tuteur.P') },
    { value: 'M', text: getTrad('commun.tuteur.M') },
  ]

  if (props.member.type !== '5') return <></>
  return (
    <div className="col">
      <InputSelect
        handler={props.update}
        name="tuteur"
        value={props.member.tuteur}
        data={data}
        label={getTrad('fileds.tuteur')}
      />
    </div>
  )
}

function IsSupported(props) {
  let is_supportedData = [
    { value: '1', text: getTrad('commun.yes') },
    { value: '0', text: getTrad('commun.no') },
  ]
  return (
    <div className="col">
      <InputSelect
        handler={props.update}
        name="is_supported"
        value={props.member.is_supported}
        data={is_supportedData}
        label={getTrad('fileds.is_supported')}
      />
    </div>
  )
}

function IsHandicape(props) {
  let is_supportedData = [
    { value: '1', text: getTrad('commun.yes') },
    { value: '0', text: getTrad('commun.no') },
  ]
  return (
    <div className="col">
      <InputSelect
        handler={props.update}
        name="is_handicape"
        value={props.member.is_handicape}
        data={is_supportedData}
        label={getTrad('fileds.is_handicape')}
      />
    </div>
  )
}

export default function Charge(props) {
  if (props.member.type !== '') {
    return (
      <fieldset id="FieldSetInfoPerso">
        <legend>{getTrad('fileds.encharge')}</legend>
        <div className="row">
          <IsSupported {...props} />
          <Tuteur {...props} />
          <IsHandicape {...props} />
        </div>
      </fieldset>
    )
  }

  return <></>
}
