import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AdminList from './GestionAdmins'
import Add from './Add'
import SuperUserMiddleWare from '../../../middleware/SuperUserMiddleWare'

export default function Admin(props) {
  return (
    <Switch>
      <Route exact path={'/gestion-admins'}>
        <SuperUserMiddleWare>
          <AdminList />
        </SuperUserMiddleWare>
      </Route>
      <Route path={`/gestion-admins/add`}>
        <SuperUserMiddleWare>
          <Add {...props} />
        </SuperUserMiddleWare>
      </Route>
      <Route>
        <Redirect to="/gestion-admins" />
      </Route>
    </Switch>
  )
}
