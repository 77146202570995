import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { setLang as updateLang } from '../../helpers'
import { useTrad } from '../../lang'
import axios from 'axios'
import { LangContext } from '../../context/LangContext'

function ChangeLang() {
  const { lang, setLang } = useContext(LangContext)

  const update = newLang => {
    axios.get('/update-lang/' + newLang).then(() => {
      setLang(newLang)
      updateLang(newLang)
    })
  }

  return (
    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle active"
        to="#"
        id="navbardrop"
        data-toggle="dropdown"
      >
        {useTrad(`commun.local.${lang}`)}
      </Link>
      <div className="dropdown-menu">
        <div className="dropdown-item" onClick={() => update('fr')}>
          {useTrad(`commun.local.fr`)}
        </div>
        <div className="dropdown-item" onClick={() => update('ar')}>
          {useTrad(`commun.local.ar`)}
        </div>
      </div>
    </li>
  )
}

export default ChangeLang
