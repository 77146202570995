import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../../lang'
import { errorToast } from '../../../../../helpers/sweetAlerts'
import axios from 'axios'
import { getUrl } from '../../../../../Api'
import Body from './Body'
import AddJustif from './AddJustif'

const thisYear = new Date().getFullYear()

class Justificatif extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showAdd: false,
      annee: thisYear,
      proof: '',
    }
  }

  AddHandler = event => this.setState({ [event.target.name]: event.target.value })
  show = () => this.setState({ show: true })
  hide = () => this.setState({ show: false, showAdd: false, annee: '', proof: '' })
  handleshowAdd = () => this.setState({ annee: '', proof: '', showAdd: !this.state.showAdd })

  send = () => {
    const data = {
      annee: this.state.annee,
      proof: this.state.proof,
    }
    axios
      .post(getUrl('prestataire.justify', [this.props.id]), data)
      .then(res => {
        this.props.refresh()
        this.setState({ show: true, showAdd: false, annee: thisYear, proof: '' })
      })
      .catch(err => {
        if (err && err.response)
          errorToast.fire({ text: getTrad(`errors.${err.response.data.annee}`) })
      })
  }

  handleFile = e => {
    let reader = new FileReader()
    if (e.target.files.length < 1) {
      return false
    }
    if (e.target.files[0].size > 3 * 1024 * 1024) {
      errorToast.fire({ text: getTrad('errors.bigFile') })
      return false
    }

    reader.readAsDataURL(e.target.files[0])
    reader.onload = evnt => this.setState({ proof: evnt.target.result })
  }

  render() {
    return (
      <>
        <button onClick={this.show} className="btn btn-primary">
          <i className="fas fa-file"></i>
        </button>
        <Modal show={this.state.show} onHide={this.hide}>
          <Modal.Header closeButton>
            <Modal.Title>
              {getTrad('fileds.justificatif')} {this.props.first_name} {this.props.last_name}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-0">
            <table className="table">
              <thead>
                <tr>
                  <th>{getTrad('fileds.year')}</th>
                  <th>{getTrad('fileds.justificatif')}</th>
                  <th>{getTrad('fileds.status')}</th>
                  <th>
                    <button
                      onClick={this.handleshowAdd}
                      className={`w-100 btn btn-sm btn-${
                        this.state.showAdd ? 'danger' : 'success'
                      }`}
                    >
                      <i className={`fas fa-${this.state.showAdd ? 'times' : 'plus'}`}></i>
                    </button>
                  </th>
                </tr>

                <AddJustif
                  proof={this.state.proof}
                  annee={this.state.annee}
                  show={this.state.showAdd}
                  handleFile={this.handleFile}
                  handler={this.AddHandler}
                  send={this.send}
                />
              </thead>
              <Body
                dontShow={this.props.dontShow}
                justificatifs={this.props.justificatifs}
                refresh={this.props.refresh}
              />
            </table>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default Justificatif
