import React, { Component } from 'react'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import axios from 'axios'
import { validDate, convertDate } from '../../../helpers'
import { getUrl } from '../../../Api'
import { Modal } from 'react-bootstrap'
import Waiting from '../../../components/Waiting'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import { getTrad } from '../../../lang'

const getAdherantObj = ele => {
  let tmp = {}
  if (ele.matricule) tmp.matricule = ele.matricule
  if (ele.matricule_cnrps) tmp.matricule_cnrps = ele.matricule_cnrps
  if (ele.cin) tmp.cin = ele.cin
  if (ele.first_name) tmp.first_name = ele.first_name
  if (ele.last_name) tmp.last_name = ele.last_name
  if (ele.first_name_ar) tmp.first_name_ar = ele.first_name_ar
  if (ele.last_name_ar) tmp.last_name_ar = ele.last_name_ar
  if (ele.email) tmp.email = ele.email
  if (ele.phone) tmp.phone = ele.phone
  if (['M', 'F'].includes(ele.sexe)) tmp.sexe = ele.sexe
  if (ele.rib) tmp.rib = ele.rib
  if (ele.situationsfamiliale) tmp.situationsfamiliale = ele.situationsfamiliale
  if (ele.categorie) tmp.categorie = ele.categorie
  if (ele.grade) tmp.grade = ele.grade
  if (ele.status) tmp.status = ele.status
  if (ele.lieu) tmp.lieu = ele.lieu
  if (validDate(ele.birth_day)) tmp.birth_day = convertDate(ele.birth_day)
  return tmp
}

const getPrestaObj = ele => {
  let tmp = {}

  if (ele.matricule) tmp.id_adherant = ele.matricule

  if (ele.code === 99) {
    tmp.type = 2
  } else {
    tmp.type = 5
  }

  if (ele.first_name) tmp.first_name = ele.first_name
  if (ele.last_name) tmp.last_name = ele.last_name
  if (validDate(ele.birth_day)) tmp.birth_day = convertDate(ele.birth_day)
  if (['M', 'F'].includes(ele.sexe)) tmp.sexe = ele.sexe
  if (ele.situationsfamiliale) tmp.situationsfamiliale = ele.situationsfamiliale
  if (ele.lvl_studies) tmp.lvl_studies = ele.lvl_studies
  if (ele.works) tmp.works = ele.works
  if (ele.profession) tmp.profession = ele.profession
  if (ele.is_supported) tmp.is_supported = ele.is_supported
  if (['M', 'P'].includes(ele.tuteur)) tmp.tuteur = ele.tuteur

  return tmp
}

export default class Import extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      finish: false,
      message: '',
      reading: 'N',
      sendAD: 'N',
      sentAd: 0,
      importFam: [],
      errorList: [],
      sentFam: 0,
      sendFAM: 'N',
      importAdh: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.reading !== this.state.reading && this.state.reading === 'F') {
      if (this.state.importAdh.length === 0) {
        this.setState({ sendAD: 'F' })
      } else {
        this.sendAdherents()
      }
    }

    if (
      this.state.importAdh.length === this.state.sentAd &&
      this.state.importAdh.length !== 0 &&
      this.state.sendAD === 'N'
    ) {
      this.setState({ sendAD: 'F' })
    }

    if (
      this.state.importFam.length === this.state.sentFam &&
      this.state.importFam.length !== 0 &&
      this.state.sendFAM === 'N'
    ) {
      this.setState({ sendFAM: 'F' })
    }

    if (prevState.sendAD !== this.state.sendAD && this.state.sendAD === 'F') {
      this.sendFamily()
    }

    if (prevState.sendFAM !== this.state.sendFAM && this.state.sendFAM === 'F') {
      this.finish()
    }
  }

  finish() {
    let fileName = 'Error Import'
    let csvData = this.state.errorList

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + '-' + now + fileExtension)

    this.setState({ finish: true })
  }

  async sendFamily() {
    this.setState({ message: getTrad('adherant.importingfam') })
    let url = getUrl('crudPrestataire')

    for (var i = 0; i < this.state.importFam.length; i++) {
      let item = this.state.importFam[i]
      let data = getPrestaObj(item)
      await axios
        .post(url, data)
        .then(res => {
          this.setState({ sentFam: this.state.sentFam + 1 })
        })
        .catch(err => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                let tmp = this.state.errorList.slice()
                tmp.push({ ...item, ...err.response.data })
                this.setState({
                  errorList: tmp,
                  sentFam: this.state.sentFam + 1,
                })
              }
            }
          }
        })
    }
  }

  async sendAdherents() {
    this.setState({ message: getTrad('adherant.importingAdh') })
    let url = getUrl('crudAdherants')

    for (var i = 0; i < this.state.importAdh.length; i++) {
      let item = this.state.importAdh[i]
      let data = getAdherantObj(item)
      await axios
        .post(url, data)
        .then(res => {
          this.setState({ sentAd: this.state.sentAd + 1 })
        })
        .catch(err => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                let tmp = this.state.errorList.slice()
                tmp.push({ ...item, ...err.response.data })
                this.setState({
                  errorList: tmp,
                  sentAd: this.state.sentAd + 1,
                })
              }
            }
          }
        })
    }
  }

  readData(e) {
    let reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = async e => {
      var data = new Uint8Array(reader.result)
      var wb = XLSX.read(data, { type: 'array' })
      const { value: sheet } = await Swal.fire({
        title: getTrad('commun.chooseSheet'),
        input: 'select',
        inputOptions: wb.SheetNames,
        inputPlaceholder: getTrad('commun.chooseSheet'),
        showCancelButton: true,
        cancelButtonText: getTrad('commun.cancel'),
        confirmButtonText: getTrad('commun.import'),
      })

      if (sheet !== undefined && sheet !== '') {
        this.setState({
          show: true,
        })
        let adherants = []
        let family = []
        let jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[sheet]])
        this.setState({ message: getTrad('adherant.classify') })
        jsonData.map(e => {
          if (e.code === 0) {
            adherants.push(e)
            return true
          } else {
            family.push(e)
            return false
          }
        })

        this.setState({
          importAdh: adherants,
          importFam: family,
          reading: 'F',
        })
      }
    }
  }

  renderModel() {
    let total
    let pending
    let reading = this.state.reading
    let sendAD = this.state.sendAD
    let sendFAM = this.state.sendFAM

    let renderProgress

    if (reading === 'F' && sendAD === 'N') {
      total = this.state.importAdh.length
      pending = this.state.sentAd
    }
    if (sendFAM === 'N' && sendAD === 'F') {
      total = this.state.importFam.length
      pending = this.state.sentFam
    }
    if (!this.state.finish) {
      renderProgress = `${pending} / ${total}`
    } else {
      renderProgress = (
        <button
          className="btn btn-success w-100"
          onClick={() => {
            this.setState({ show: false })
          }}
        >
          {getTrad('commun.close')}
        </button>
      )
    }

    return (
      <Modal show={this.state.show} centered onHide={() => {}}>
        <Modal.Body>
          <div className="text-center">
            <h4>{this.state.message}</h4>
            <h2>
              <Waiting />
            </h2>
            {renderProgress}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    return (
      <>
        <button
          id="excelFileBtn"
          onClick={() => this.fileInput.click()}
          className="btn btn-primary"
        >
          <i className="fas fa-file-import"></i> {getTrad('commun.import')}
        </button>
        <input
          type="file"
          onChange={e => this.readData(e)}
          style={{ display: 'none' }}
          ref={fileInput => (this.fileInput = fileInput)}
        />
        {this.renderModel()}
      </>
    )
  }
}
