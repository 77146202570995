import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTrad } from '../../../../lang'
import { getUrl } from '../../../../Api'
import { areYouSure, successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import NumInput from '../../../../components/Form/NumInput'
import axios from 'axios'

function Accept(props) {
  const [show, setShow] = useState(false)
  const [montant_accepte, setMontant_accepte] = useState(props.montant.toString())
  const [engagement, setEngagement] = useState(props.engagement)
  const [montant_engagement, setMontant_engagement] = useState(
    props.montant_engagement ? props.montant_engagement.toString() : ''
  )

  const handleMontantAccepte = event => setMontant_accepte(event.target.value)
  const handleMontantEngagement = event => setMontant_engagement(event.target.value)
  const handleEngagement = event => setEngagement(!engagement)
  const handleShow = () => setShow(!show)
  const send = () => {
    areYouSure.fire().then(response => {
      if (response.value) {
        const data = {
          montant: montant_accepte,
          engagement: engagement,
          montant_engagement: montant_engagement,
        }
        let url = getUrl('aidesociale.accept') + `/${props.id}`

        axios
          .post(url, data)
          .then(res => {
            if (res.status === 200) {
              handleShow()
              successMsg(getTrad('success.aideAccept'))
              props.refresh()
            }
          })
          .catch(err => errorMsg(getTrad('errors.cummonError')))
      }
    })
  }
  return (
    <>
      <button className="btn btn-success btn-sm" onClick={handleShow}>
        <i className="fas fa-hands-helping"></i>
      </button>

      <Modal show={show} onHide={() => false}>
        <Modal.Header>
          <Modal.Title>{getTrad('aides.accept.title', [props.id])}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NumInput
            label={getTrad('aides.acceptedAmount')}
            name="montant_accepte"
            handler={handleMontantAccepte}
            value={montant_accepte}
          />

          <div className="custom-control custom-switch pb-3">
            <input
              type="checkbox"
              checked={engagement}
              onChange={handleEngagement}
              className="custom-control-input"
              id="engagement_switch"
            />
            <label className="custom-control-label" htmlFor="engagement_switch">
              {getTrad('aides.engagement')}
            </label>
          </div>

          <NumInput
            label={getTrad('aides.montantEngagement')}
            name="montant_engagement"
            handler={handleMontantEngagement}
            value={montant_engagement}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button onClick={send} className="btn btn-success w-100">
                {getTrad('commun.accept')}
              </button>
            </div>
            <div className="col">
              <button onClick={handleShow} className="btn btn-danger w-100">
                {getTrad('commun.cancel')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Accept
