import React from 'react'

function CardHeader({ title, actions }) {
  return (
    <div className="card-header">
      <div className="row">
        <div className={`col${actions ? '-6' : '-12'}`}>
          <h2 className="card-title">{title}</h2>
        </div>
        {actions && <div className="col-6 text-right">{actions}</div>}
      </div>
    </div>
  )
}

export default CardHeader
