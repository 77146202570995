import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { getUrl } from '../../../Api'
import { showDate } from '../../../helpers'
import ShowTable from '../../../components/ShowTable'
import { getTrad } from '../../../lang'
import DownloadBord from './DownloadBord'
import DownloadBordParents from './DownloadBordParents'

export default class Bordereau extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listBS: [],
      loading: true,
      listBsFiltred: [],
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    axios.get(getUrl('borderau.crud')).then(res => {
      let data = res.data.success.map(e => ({
        ...e,
        date_bord: showDate(e.date_bord),

        action: (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Link to={`/bultin-soins/bordereaux/${e.num}`} className="btn btn-sm btn-primary">
              <i className="far fa-eye"></i>
            </Link>
            <DownloadBord id={e.num} />
            <DownloadBordParents id={e.num} />
          </div>
        ),
      }))
      let dataReverse = data.reverse();

      this.setState({ listBS: data, listBsFiltred: dataReverse })
    })
  }

  download(e) {
    alert(e)
  }

  renderHeader() {
    return (
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h2 className="card-title">{getTrad('pages.listBord')}</h2>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    let header = {}
    let keys = ['num', 'date_bord', 'nb_bs_nt', 'nb_bs_t', 'nb_bs', 'action']

    for (var i = 0; i < keys.length; i++) {
      let k = keys[i]
      header[k] = getTrad(`bordoreau.${k}`)
    }

    let showProps = {
      data: this.state.listBsFiltred,
      keys: keys,
      header: header,
      noData: getTrad('commun.noSearchData'),
    }
    if (this.state.searching) {
      showProps.searching = true
    }

    return (
      <div className="card-body p-0">
        <ShowTable {...showProps} />
      </div>
    )
  }

  render() {
    return (
      <div className="card">
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    )
  }
}
