export const fr = {
  number: 'Numéro',
  date: 'Date de saisie',
  nb_items: 'Nombre de saisies',
  details: 'Détails',
  mat_adh: 'Marticule d`adhérent',
  full_name: 'Nom et prénom',
  mat_cnrps: 'Matricule CNRPS',
  sous_compte: 'déjà remboursé ',
  montant: 'Montant',
  retry: 'Actualiser la page et réessayer',
  import: {
    reading: 'Lecture des données',
    verify: 'Vérification des matricules CNRPS',
    sending: 'Envoie des données',
    finish: 'Importation terminée avec succès',
    error:
      "Erreur lors de l'importation des saisies : Il y a quelques matricules CNRPS inexistantes",
    DownloadCNRPS: 'Téléchargement des matricules CNRPS',
  },
}

export const ar = {
  number: 'العدد',
  date: 'تاريخ ادخال المعطيات',
  nb_items: 'عدد المضبوطات',
  details: 'التفاصيل',
  mat_adh: 'رقم العضوية',
  full_name: 'الإسم واللقب',
  mat_cnrps: 'رقم CNRPS',
  sous_compte: 'تحت الحساب',
  montant: 'المبلغ',
  retry: 'قم بتحديث الصفحة وحاول مرة أخرى',
  import: {
    reading: 'قراءة البيانات',
    verify: 'التحقق من أرقام CNRPS',
    sending: 'إرسال البيانات',
    finish: 'الانتهاء من استيراد البيانات بنجاح',
    error: 'خطأ عند إدخال المعطيات: هناك بعض أرقام CNRPS غير موجودة',
    DownloadCNRPS: 'تنزيل أرقام CNRPS',
  },
}

export const en = {
  number: 'Number',
  date: 'Data entry date',
  nb_items: 'Number of data entries',
  details: 'Details',
  mat_adh: 'Member registration number',
  full_name: 'Full name',
  mat_cnrps: 'CNRPS registration number',
  sous_compte: 'already reimbursed',
  montant: 'Amount',
  retry: 'Refresh the page and try again',
  import: {
    reading: 'Data reading',
    verify: 'Verification of CNRPS registration keys',
    sending: 'Data sending',
    finish: 'Successfully completed data importing',
    error: 'Error when importing data entries: There are some inexistent CNRPS registration keys',
    DownloadCNRPS: 'Download CNRPS registration numbers',
  },
}
