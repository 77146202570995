import React, { forwardRef } from 'react'
import { getTrad } from '../../../../lang'
import NumInput from '../../../../components/Form/NumInput'
import DateInput from '../../../../components/Form/Date'
import TxtInput from '../../../../components/Form/TxtInput'
import Waiting from '../../../../components/Waiting'
function calcAdd(str) {
  let tmp = str
    .split('+')
    .map(e => parseFloat(e))
    .reduce((a, b) => a + b, 0)
  return tmp
}
const BSInfo = forwardRef((props, ref) => {
  return (
    <fieldset>
      <legend>{getTrad('bs.prestatire')}</legend>
      <div className="row">
        <div className="col">
            <NumInput
              label={getTrad('bs.mat_adherant')}
              name="mat_adherant"
              value={props.mat_adherant.value}
              handler={props.handler}
              error={props.mat_adherant.error}
            />
          </div>

          <div className="col">
            <div className="form-group w-100">
              <label htmlFor="id_prestatire">{getTrad('bs.prestatire')}</label>
              <Prestataire {...props.id_prestatire} handler={props.handler} />
            </div>
          </div>
        <div className="col">
          <NumInput
            label={getTrad('bs.numBs')}
            name="mat_bultin"
            value={props.mat_bultin.value}
            handler={props.handler}
            error={props.mat_bultin.error}
          />
        </div>
        <div className="col">
          <DateInput
            label={getTrad('bs.date_soin')}
            name="date_soin"
            value={props.date_soin.value}
            handler={props.handler}
            error={props.date_soin.error}
          />
        </div>

      </div>
      <div className="row">
        <div className="col">
          <div className="form-group w-100">
            <TxtInput
              label={getTrad('bs.description')}
              name="description"
              value={props.description.value}
              error={props.description.error}
              handler={props.handler}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group w-100">
            <label>{getTrad('bs.complement')}</label>
            <input
              ref={ref}
              onChange={event => {
                props.handleCalculator(calcAdd(event.target.value).toString())
              }}
              type="text"
              className="form-control w-100 form-control-sm mb-2"
            />

            <NumInput
              dontShow
              label={getTrad('bs.complement')}
              name="complement"
              value={props.complement.value}
              handler={props.handler}
              error={props.complement.error}
            />
          </div>
        </div>
      </div>
    </fieldset>
  )
})

function Prestataire(props) {
  if (props.loading) return <Waiting />

  let name = 'id_prestatire'
  let value = props.value
  let inputClass = ['form-control', 'w-100', 'form-control-sm']
  let error = props.error

  if (props.error) inputClass.push('is-invalid')

  if (props.prestatires.length <= 0) return <></>

  return (
    <>
      <select
        value={value}
        onChange={props.handler}
        id={name}
        className={inputClass.join(' ')}
        name={name}
      >
        {props.prestatires.map((e, k) => {
          return (
            <option key={k} value={e.id}>
              {e.name}
            </option>
          )
        })}
      </select>
      {error}
    </>
  )
}

export default BSInfo
