import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import { getTrad } from '../../../../lang'
import Waiting from '../../../../components/Waiting'
import Rembours from '../components/Rembours'
import { showMoney, showDate, inString } from '../../../../helpers'
import { useQuery } from 'react-query'
import RenderBody from './RenderBody'
import RenderHeader from './RenderHeader'
import RenderSearch from './RenderSearch'

function DetailsBord({
  match: {
    params: { id },
  },
}) {
  const [searchMatAd, setSearchMatAd] = useState('')
  const [searchMatBs, setSearchMatBs] = useState('')
  const [searchDateBs, setSearchDateBs] = useState('')
  const [checkET, setCheckET] = useState(true)
  const [checkT, setCheckT] = useState(true)

  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: bordoreau = [],
  } = useQuery('get-bordoreau-id', () =>
    axios.get(`/borderau/${id}`).then(res => res.data.success.data.map(cleanData))
  )

  useEffect(() => {
    refetch()
  }, [id, refetch])

  const cleanData = e => ({
    ...e,
    statusCode: e.status,
    bs_date: showDate(e.bs_date),
    total_depense: showMoney(e.total_depense),
    estimation: showMoney(e.estimation),
    total_remboursement: showMoney(e.total_remboursement),
    action: (
      <div className="btn-group btn-group-sm">
        <Link className="btn btn-primary" to={`/bultin-soins/${e.bs_number}/show`}>
          <i className="fas fa-eye"></i>
        </Link>
        <Rembours
          matricule={e.bs_number}
          reload={refetch}
          observation={e.observations}
          remboursemement={e.total_remboursement}
        />
      </div>
    ),
    status: getTrad(`bs.status_${e.status}`),
  })

  const handler = ({ target: { name, value } }) => {
    if (name === 'searchDateBs') setSearchDateBs(value)
    if (name === 'searchMatAd') setSearchMatAd(value)
    if (name === 'searchMatBs') setSearchMatBs(value)
    if (name === 'checkET') setCheckET(!checkET)
    if (name === 'checkT') setCheckT(!checkT)
  }

  if (isLoading || isFetching) return <Waiting />
  if (isError) return <Redirect to="/bultin-soins/bordereaux/" />
  return (
    <div className="card">
      <RenderHeader bordId={id} />
      <RenderSearch
        handler={handler}
        searchMatBs={searchMatBs}
        searchMatAd={searchMatAd}
        searchDateBs={searchDateBs}
        checkET={checkET}
        checkT={checkT}
      />
      <RenderBody
        data={bordoreau.filter(e => {
          if (!inString(searchMatAd, e.matricule_adh)) return false
          if (!inString(searchMatBs, e.bs_number)) return false
          if (!inString(searchDateBs, e.bs_date)) return false
          if (checkET && checkT) return true
          if (!checkET && !checkT) return true
          if (checkET && e.statusCode === 'ET') return true
          if (checkT && e.statusCode === 'T') return true
          return false
        })}
      />
    </div>
  )
}

export default DetailsBord
