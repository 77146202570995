export const fr = {
  id: 'ID',
  password: 'Mot de passe',
  username: "Nom d'utilisateur",
  login_btn: 'Se connecter',
  matricule_adh: 'Matricule d`adhérent',
  forgot_password: 'Mot de passe oublié ?',
  old_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_new_password: 'Confirmer le nouveau mot de passe',
  matricule_cnrps: 'Matricule CNRPS',
  montant: 'Montant',
  first_name: 'Prénom',
  date_versement: 'Date de versement',
  last_name: 'Nom',
  sexe: 'Genre',
  relationCode: 'Code',
  montant_demande: 'Montant demandé',
  birth_day: 'Date de naissance',
  encharge: 'En charge',
  is_supported: 'En charge',
  deadline: 'Date limite:',
  tuteur: 'Bénéficiaire',
  montant_accepte: 'Montant accepté',
  echance: "Nombre d'échéance",
  relation: 'Lien',
  identifiants: 'Les identifiants',
  email: 'Adresse E-mail',
  dateAcceptation: "Date de l'acceptation",
  roles: 'Rôle',
  works: 'Travail',
  motif: 'Motif',
  is_handicape: 'Handicapé',
  engagement: 'Engagement',
  keyword: 'Mot-clé',
  value: 'Valeur',
  montant_engagement: 'Montant versé',
  type: 'Choisissez un type',
  matricule: 'Matricule d`adhérent',
  actions: 'Actions',
  situationsfamiliale: 'Situation Familiale',
  //situationsfamiliale: 'Situation Familiale',
  sous_compte: 'Sous-compte',
  lvl_studies: "Niveau d'études",
  profession: 'Profession',
  infoperso: 'Informations personnelles',
  travail: 'Informations du métier',
  date_demande: 'Date de demande',
  dateDemande: 'Date de demande',
  gouvernaurat: 'Gouvernorat',
  motif_refus: 'Motif de refus',
  dateRefus: 'Date de refus',
  status: 'Statut',
  grade: 'Grade',
  lieu: 'Établissements',
  categorie: 'Catégorie',
  date_debut: 'Date de demande',
  reponse: 'Réponse',
  montant_echance: 'Montant d`une échéance',
  fullname: 'Nom et prénom',
  selectElement: '--- Sélectionner un élément ---',
  obs: 'Observations',
  montantRembourse: 'Montant remboursé',
  rest: 'Reste à rembourser',
  echeance: 'Échéance',
  created_at: 'Date de création',
  updated_at: 'Date de mise-à-jour',
  date: 'Date',
  nb: 'Nombre de cessions',
  justificatif: 'Justificatif',
  nbTotal: 'Somme des cessions',
  year: 'Année',
  yearNb: 'Nombre de cessions cette année',
  yearTotal: 'Somme des cessions cette année',
  nb_cessions: 'Nombre de cessions',
  total: 'Total',
  nb_echeance: "Nombre d'échéance",
  full_name: 'Nom et Prénom',
  nb_versement: 'Nombre de versements',
  details: 'Détails',
  frais: 'Frais de dossier',
}

export const ar = {
  id: 'ID',
  password: 'كلمة السر',
  username: 'إسم المستخدم',
  login_btn: 'تسجيل الدخول',
  matricule_adh: 'رقم العضوية',
  forgot_password: 'نسيت رقمك السري ؟',
  old_password: 'كلمة السر الحالية',
  new_password: 'كلمة سر جديدة',
  confirm_new_password: 'قم بتأكيد كلمة المرور الجديدة',
  matricule_cnrps: 'رقم تسجيل CNRPS',
  montant: 'المبلغ',
  first_name: 'الاسم',
  last_name: 'اللقب',
  sexe: 'الجنس',
  relationCode: 'الرمز',
  montant_demande: 'المبلغ المطلوب',
  birth_day: 'تاريخ الميلاد',
  encharge: 'مسؤول عن',
  is_supported: 'مسؤول عن',
  deadline: 'التاريخ النّهائي :',
  tuteur: 'المستفيد',
  echance: 'عدد أقساط الدّفع',
  relation: 'الصّلة',
  identifiants: 'المعرفات',
  email: 'البريد الإلكتروني',
  roles: 'المنصب',
  works: 'العمل',
  motif: 'الدافع',
  type: 'اختر نوعًا',
  actions: 'الإجراءات',
  situationsfamiliale: 'الحالة العائلية',
  sous_compte: 'تحت الحساب',
  lvl_studies: 'المستوى الدراسي',
  profession: 'المهنة',
  infoperso: 'معلومات شخصية',
  travail: 'معلومات الوظيفة',
  date_demande: 'تاريخ الطلب',
  dateDemande: 'تاريخ الطلب',
  gouvernaurat: 'الولاية',
  status: 'الحالة',
  grade: 'الرتبة',
  lieu: 'المؤسسات',
  categorie: 'الفئة',
  date_debut: 'تاريخ الطلب',
  reponse: 'الرد',
  montant_echance: 'مبلغ القسط',
  fullname: 'الاسم و اللقب',
  selectElement: '--- حدد عنصرا ---',
  montantRembourse: 'المبلغ المسترد',
  rest: 'المبلغ المتبقي',
  echeance: 'الموعد النهائي للتّسديد',
  date_versement: 'يوم الدفع',
  montant_accepte: 'المبلغ المقبول',
  dateAcceptation: 'تاريخ القبول',
  engagement: 'مساهمة',
  keyword: 'الكلمة المفتاح',
  value: 'القيمة',
  montant_engagement: 'المبلغ المدفوع',
  matricule: 'رقم العضوية',
  motif_refus: 'دافع الرّفض',
  dateRefus: 'تاريخ الرّفض',
  obs: 'الملاحظات',
  created_at: 'تاريخ الإنشاء',
  updated_at: 'تاريخ التحديث',
  date: 'التاريخ',
  nb: 'عدد التّنازلات',
  justificatif: 'المبرّر',
  nbTotal: 'مجموع التّنازلات',
  year: 'السنة',
  yearNb: 'عدد التّنازلات هذا العام',
  yearTotal: 'مجموع التّنازلات هذا العام',
  nb_cessions: 'مجموع التّنازلات',
  total: 'المجموع',
  nb_echeance: 'عدد اقساط الدفع',
  full_name: 'الاسم و اللقب',
  nb_versement: 'عدد الدفوعات',
  details: 'التفاصيل',
  frais: 'رسوم تقديم الملف',
}

export const en = {
  id: 'ID',
  password: 'Password',
  username: 'User name',
  login_btn: 'Log in',
  matricule_adh: 'Member registration key',
  forgot_password: 'Forgotten password ?',
  old_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm the new password',
  matricule_cnrps: 'CNRPS registration key',
  montant: 'Amount',
  first_name: 'First name',
  date_versement: 'Payment date',
  last_name: 'Last name',
  sexe: 'Genre',
  relationCode: 'Code',
  montant_demande: 'Requested amount',
  birth_day: 'Birthday date',
  encharge: 'In charge',
  is_supported: 'In charge',
  deadline: 'Deadline:',
  tuteur: 'Beneficiary',
  montant_accepte: 'Accepted amount',
  echance: 'Number of due date',
  relation: 'Link',
  identifiants: 'Identifiers',
  email: 'E-mail address',
  dateAcceptation: 'Date of acceptance',
  roles: 'Role',
  works: 'Occupation',
  motif: 'Motive',
  engagement: 'Engagement',
  keyword: 'Keyword',
  value: 'Value',
  montant_engagement: 'Paid amount',
  type: 'Choose the type',
  matricule: 'Member registration key',
  actions: 'Actions',
  situationsfamiliale: 'Marital status',
  sous_compte: 'already reimbursed',
  lvl_studies: "Niveau d'études",
  profession: 'Profession',
  infoperso: 'Personal information',
  travail: 'Occupation information',
  date_demande: 'Date of request',
  dateDemande: 'Date of request',
  gouvernaurat: 'Governorate',
  motif_refus: 'Reason for refusal',
  dateRefus: 'Refusal date',
  status: 'Status',
  grade: 'Grade',
  lieu: 'Establishments',
  categorie: 'Category',
  date_debut: 'Request date',
  reponse: 'Response',
  montant_echance: 'Amount of a payment due date',
  fullname: 'Full name',
  selectElement: '--- Select an element ---',
  obs: 'Observations',
  montantRembourse: 'Reimbursed amount',
  rest: 'Rest to refund',
  echeance: 'Payment due date',
  created_at: 'Creation date',
  updated_at: 'Update date',
  nb: 'Number of cessions',
  justificatif: 'Proof',
  nbTotal: 'Sum of cessions',
  year: 'Year',
  yearNb: 'Number of cessions this year',
  yearTotal: 'Sum of cessions this year',
  nb_cessions: 'Number of cessions',
  total: 'Total',
  nb_echeance: 'Number of payment due date',
  full_name: 'Full name',
  nb_versement: 'Number of payments',
  details: 'Details',
  frais: 'Application fees',
}
