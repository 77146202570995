import React from 'react'

function RenderInput({ label, name, value, handle }) {
  return (
    <div className="col">
      <div className="form-group">
        <label htmlFor="old_password">{label} :</label>
        <input
          type="text"
          className="form-control w-100 form-control-sm"
          name={name}
          onChange={e => handle(e)}
          id={name}
          placeholder={label}
          value={value}
        />
      </div>
    </div>
  )
}

export default RenderInput
