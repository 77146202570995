import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Selector from './Selector'
import { validMounth } from '../../../helpers'
import './style.css'

function ShowErrors({ data }) {
  return (
    <div>
      {data.map((e, k) => (
        <li key={k}>{e}</li>
      ))}
    </div>
  )
}

class InputMounth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.addBackDrop)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.addBackDrop)
  }

  addBackDrop = e => {
    if (this.state.show && !ReactDOM.findDOMNode(this).contains(e.target)) {
      this.handleHide()
    }
  }

  handleShow = () => this.setState({ show: true })
  handleHide = () => this.setState({ show: false })

  render() {
    const show = this.state.show
    const label = this.props.label
    const name = this.props.name
    const handler = this.props.handler ? this.props.handler : () => null
    const value = this.props.value ? this.props.value : ''
    const error = []
    const classList = ['form-control', 'form-control-sm', 'w-100']
    if (!validMounth(value)) {
      error.push('MM-YYYY')
    }
    if (error.length) classList.push('is-invalid')

    const Label = () => {
      if (this.props.dontShow) return <></>
      return <label htmlFor={name}>{label}</label>
    }

    if (Array.isArray(this.props.error)) {
      if (this.props.error.length) {
        classList.push('is-invalid')
      }
    }

    return (
      <div className="form-group">
        <Label />
        <input
          name={name}
          onClick={this.handleShow}
          value={value}
          onChange={handler}
          className={classList.join(' ')}
          type="text"
        />
        <ShowErrors data={error} />
        <Selector name={name} value={value} handler={handler} show={show} />
      </div>
    )
  }
}

InputMounth.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}

export default InputMounth
