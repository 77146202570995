import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'

import axios from 'axios'
import { areYouSure, successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import { getUrl } from '../../../../Api'
import { getTrad } from '../../../../lang'
import { convertDate } from '../../../../helpers'
import Waiting from '../../../../components/Waiting'

import Type from './CrudFields/Type'
import InfoPerso from './CrudFields/InfoPerso'
import Travail from './CrudFields/Travail'
import Charge from './CrudFields/Charge'

export default class Add extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      sending: false,
      errors: {},
      member: {
        type: '',
        first_name: '',
        last_name: props.defaultLast,
        birth_day: '',
        sexe: '',
        situationsfamiliale: '',
        works: '',
        is_supported: '',
        profession: '',
        lvl_studies: '',
        tuteur: '',
        is_handicape: '',
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultLast !== this.props.defaultLast) {
      this.setState({
        member: {
          ...this.state.member,
          last_name: this.props.defaultLast,
        },
      })
    }
  }

  submit = () => {
    areYouSure.fire().then(result => {
      if (result.value) this.setState({ sending: true }, this.add)
    })
  }

  add = () => {
    let data = {
      id_adherant: this.props.matricule,
      type: this.state.member.type,
      first_name: this.state.member.first_name,
      last_name: this.state.member.last_name,
      birth_day: convertDate(this.state.member.birth_day),
      sexe: this.state.member.sexe,
      situationsfamiliale: this.state.member.situationsfamiliale,
      works: this.state.member.works,
      is_supported: this.state.member.is_supported,
      profession: this.state.member.profession,
      lvl_studies: this.state.member.lvl_studies,
      tuteur: this.state.member.tuteur,
      is_handicape: this.state.member.is_handicape,
    }

    axios
      .post(getUrl('prestataire.add'), data)
      .then(res => {
        this.setState(
          {
            sending: false,
            member: {
              type: '',
              first_name: '',
              last_name: this.props.defaultLast,
              birth_day: '',
              sexe: '',
              situationsfamiliale: '',
              works: '',
              is_supported: '',
              profession: '',
              lvl_studies: '',
              tuteur: '',
              is_handicape: '',
            },
          },
          () => {
            let family = this.props.family
            successMsg(getTrad('success.addMembreFamily'))
            family.push(res.data.success)
            this.props.familyHandle(family)
            this.handleShow()
          }
        )
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            this.setState({ errors: err.response.data.errors })
          } else if (err.response.status === 500) {
            errorMsg(getTrad('errors.cummonError'))
          } else if (err.response.status === 403) {
            errorMsg(err.response.data.message)
          }
        }
        this.setState({ sending: false })
      })
  }

  handleUpdate = e => {
    let member = JSON.parse(JSON.stringify(this.state.member))
    member[e.target.name] = e.target.value
    this.setState({ member: member })
  }

  handleShow = () => this.setState({ show: !this.state.show })

  render() {
    if (this.state.redirect) return <Redirect to="/" />

    let btnPops = {
      className: 'btn btn-success w-100',
      disabled: this.state.sending,
      onClick: this.submit,
    }

    return (
      <>
        <button className="btn btn-success btn-sm w-100" onClick={this.handleShow}>
          <i className="fas fa-plus"></i> {getTrad('commun.add')}
        </button>
        <Modal size="lg" show={this.state.show} onHide={this.handleShow}>
          <Modal.Header closeButton>
            <Modal.Title>{getTrad('pages.addFamily')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Type {...this.props} value={this.state.member.type} update={this.handleUpdate} />
            <InfoPerso {...this.props} {...this.state} update={this.handleUpdate} />
            <Travail {...this.props} {...this.state} update={this.handleUpdate} />
            <Charge {...this.props} {...this.state} update={this.handleUpdate} />
          </Modal.Body>

          <Modal.Footer>
            <button {...btnPops}>{this.state.sending ? <Waiting /> : getTrad('commun.add')}</button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
