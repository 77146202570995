export const bsM = {
  icon: 'fas fa-copy',
  text: 'mag_bs',
  link: 'bultin-soins',
  submenue: [
    { icon: 'fas fa-plus', text: 'add_bultin_soins', link: 'add' },
    { icon: 'fas fa-list', text: 'mag_bs', link: '' },
  ],
}

export const bsA = {
  icon: 'fas fa-copy',
  text: 'crud_bultin_soins',
  link: 'bultin-soins',
  submenue: [
    { icon: 'fas fa-plus', text: 'add_bultin_soins', link: 'add' },
    { icon: 'fas fa-question', text: 'demande_bultin_soins', link: 'demande' },
    { icon: 'fas fa-list', text: 'list_bultin_soins', link: '' },
    { icon: 'fas fa-search', text: 'search_bultin_soins', link: 'recherche' },
    { icon: null, text: null, link: '#' },
    { icon: 'fas fa-folder-plus', text: 'list_bordereaux', link: 'bordereaux' },
    { icon: 'fas fa-chart-bar', text: 'statistiques', link: 'statistiques' },
    { icon: 'fas fa-chart-bar', text: 'statistiques_ad', link: 'statistiques-adherant' },
    { icon: 'fas fa-cash-register', text: 'virement', link: 'virements' },
  ],
}
