import React from 'react'
import PropTypes from 'prop-types'
import { getTrad } from '../../lang'

export default function InputText(props) {
  let label = props.label
  let name = props.name
  let value = props.value
  let inputClass = ['form-control', 'w-100', 'form-control-sm']
  let error = []

  if (Array.isArray(props.error)) {
    if (props.error.length) {
      inputClass.push('is-invalid')
    }
    props.error.map(e => {
      return error.push(e)
    })
  }
  let labelJSX = <label htmlFor={name}>{label}</label>
  if (props.dontShow) {
    labelJSX = <></>
  }
  return (
    <div className="form-group p-0">
      {labelJSX}
      <input
        type="text"
        name={name}
        id={name}
        placeholder={label}
        onChange={props.handler}
        className={inputClass.join(' ')}
        value={value}
      />
      <div className="invalid-feedback">
        {error.map((e, k) => (e === '' ? null : <li key={k}>{getTrad(e)}</li>))}
      </div>
    </div>
  )
}

InputText.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  error: PropTypes.array,
}
