import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { Api } from '../../../../Api'
import Waiting from '../../../../components/Waiting'
import AutoComplete from '../../../../components/AutoComplete'
import Swal from 'sweetalert2'
// import { inString } from '../../../../helpers'
import { successMsg, errorMsg } from '../../../../helpers/sweetAlerts'
import { getTrad } from '../../../../lang'

export default class GestionAdmins extends Component {
  // Component Life Cycle

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      searching: false,
      matricule_adh: '',
      montant: '',
      errors: { matricule_adh: '', montant: '' },
      motif: '',
      adherant: {},
      listAdherants: [],
    }
    this.handleAdherant = this.handleAdherant.bind(this)
  }

  componentDidMount() {
    let url = Api.baseURL + Api.crudAdherants
    axios.get(url).then(res => {
      let tmp = []
      res.data.success.map(e => {
        tmp.push(e.matricule)
        return true
      })
      this.setState({ loading: false, listAdherants: tmp })
    })
  }

  render() {
    if (this.state.loading) return <Waiting />
    return (
      <>
        <h4>{getTrad('aides.addDemandeTitle')}</h4>
        <div className="row">
          <div className="col">
            <AutoComplete
              ref="AutoComplete"
              handler={this.handleAdherant}
              field="matricule_adh"
              list={this.state.listAdherants}
            />
          </div>
          <div className="col">{this.renderInput('montant')}</div>
          <div className="col">{this.renderInput('motif')}</div>
          <div className="col">{this.renderBtn()}</div>
        </div>
        {this.renderInfo()}
      </>
    )
  }

  // some functions

  refresh = () => {
    let url = Api.baseURL + Api.crudAdherants + '/' + this.state.matricule_adh
    axios
      .get(url)
      .then(res => this.setState({ adherant: res.data.success, searching: false }))
      .catch(err => {
        let url = Api.baseURL + Api.crudAdherants
        axios.get(url).then(res => {
          let tmp = []
          res.data.success.map(e => {
            tmp.push(e.matricule)
            return true
          })
          this.setState({ loading: false, searching: false, listAdherants: tmp })
        })
      })
  }

  // Handlers

  handleAdherant = e => this.setState({ matricule_adh: e, searching: true }, this.refresh)

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  // Action Bottons

  send() {
    let url = Api.baseURL + Api.aidesociale.crud
    let data = {
      matricule: this.state.adherant.matricule,
      montant: this.state.montant,
      motif: this.state.motif,
    }
    Swal.fire({
      title: getTrad('commun.areyousure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#37a326',
      cancelButtonColor: '#d33',
      confirmButtonText: getTrad('commun.yes'),
      cancelButtonText: getTrad('commun.no'),

      preConfirm: login => {
        axios
          .post(url, data)
          .then(res => {
            if (res.status === 200) {
              successMsg(getTrad('success.addDemandeAide'))
              this.props.handle()
              this.setState({
                matricule_adh: '',
                montant: '',
                motif: '',
                adherant: {},
              })
              this.refs.AutoComplete.empty()
            }
          })
          .catch(err => errorMsg(getTrad('errors.cummonError')))
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }

  // Rendering functions

  renderInput(key) {
    let classInput = 'form-control w-100 form-control-sm'
    if (this.state.errors[key] === true) {
      classInput += ' is-invalid'
    } else if (this.state.errors[key] === false) {
      classInput += ' is-valid'
    }
    return (
      <div className="form-group">
        <label htmlFor={key}>{getTrad(`fileds${key}`)}</label>
        <input
          type="text"
          onChange={e => this.handleChange(e)}
          className={classInput}
          value={this.state[key]}
          name={key}
          id={key}
          placeholder={getTrad(`fileds${key}`)}
        />
      </div>
    )
  }

  renderBtn() {
    let valid = this.state.montant !== '' && this.state.adherant.id !== undefined
    return (
      <div className="form-group">
        <label>.</label>
        <button
          type="submit"
          onClick={() => this.send()}
          id="AddaidesocialeBtn"
          disabled={!valid}
          className="btn-sm w-100 btn btn-success mb-3"
        >
          {getTrad('commun.add')}
        </button>
      </div>
    )
  }

  renderInfo() {
    if (this.state.searching) {
      return <Waiting />
    }
    if (this.state.adherant.id)
      return (
        <div id="addAideSocialeAdherantData" className="mb-3">
          <span className="info-adherant-label">{getTrad('fileds.first_name')}</span>
          <span className="info-adherant-text" id="first_name">
            {this.state.adherant.first_name}
          </span>
          <br />
          <span className="info-adherant-label">{getTrad('fileds.last_name')}</span>
          <span className="info-adherant-text" id="last_name">
            {this.state.adherant.last_name}
          </span>
          <br />
        </div>
      )
  }
}
