import axios from 'axios'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Waiting from '../../../../components/Waiting'
import { getTrad } from '../../../../lang'
import { showDate } from '../../../../helpers'
import { useQuery } from 'react-query'

function ShowPrestataires({ prestataires, selected, update, loading }) {
  if (!prestataires) return <></>
  if (loading) return <Waiting />
  return (
    <table className="table mt-3">
      <thead>
        <tr>
          <th>{getTrad('fileds.fullname')}</th>
          <th>{getTrad('fileds.birth_day')}</th>
          <th>{getTrad('fileds.situationsfamiliale')}</th>
        </tr>
      </thead>
      <tbody>
        {prestataires
          .filter(e => e.id)
          .map((e, k) => (
            <tr key={k}>
              <td>
                <label htmlFor={e.id}>{e.name}</label>
              </td>
              <td>
                <label htmlFor={e.id}>{showDate(e.birth_day)}</label>
              </td>
              <td>
                <label htmlFor={e.id}>
                  {getTrad(`situationsfamiliale.${e.situationsfamiliale}`)}
                </label>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

function ShowAdherant(props) {
  if (!props.matricule) return <></>
  const showInfo = ['matricule', 'last_name', 'first_name', 'birth_day']
  return (
    <div className="row">
      {showInfo.map((e, k) => (
        <div key={k} className="col-md-6 col-12">
          <b>{getTrad(`adherant.${e}`)} :</b> {props[e]}
        </div>
      ))}
    </div>
  )
}

function Details({ matricule, last_name, first_name, birth_day, ...props }) {
  const [show, setShow] = useState(false)

  const {
    isLoading,
    isFetching,
    data = [],
  } = useQuery(`get-ad-${matricule}-family`, () =>
    axios
      .get(`/adherants/family/${matricule}`)
      .then(res =>
        res.data.success.filter(member => member.type === 5 && member.is_supported === 1)
      )
  )

  return (
    <>
      <button onClick={() => setShow(true)} className="btn btn-primary w-100">
        <i className="fas fa-eye"></i>
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {getTrad('aidesociale.editmodel', [first_name, last_name, matricule])}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShowAdherant
            matricule={matricule}
            last_name={last_name}
            first_name={first_name}
            birth_day={birth_day}
          />

          <ShowPrestataires prestataires={data} loading={isLoading || isFetching} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Details
