function BSField({ id, calcRef, handlerCalc, champs, value = '' }) {
  return (
    <div className="card">
      <div className="card-header">
        {champs.label} : {value}{' '}
        {value < 0 && <i className="fas fa-exclamation-circle text-danger"></i>}
      </div>
      <div className="card-body">
        <input
          ref={calcRef}
          onChange={event => handlerCalc(event, id)}
          placeholder={champs.label}
          type="text"
          className="form-control w-100 form-control-sm"
        />
      </div>
    </div>
  )
}
export default BSField
