import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import Waiting from '../../../../components/Waiting'
import { showDate, showMoney } from '../../../../helpers'
import { areYouSurePromise, successMsg } from '../../../../helpers/sweetAlerts'
import { getTrad } from '../../../../lang'

function DeletePaiement({ id, refetch }) {
  const { isLoading, mutate } = useMutation(() => axios.delete('/veuve/' + id), {
    onMutate: areYouSurePromise,
    onSuccess: () => {
      successMsg(getTrad('success.delete'))
      refetch()
    },
  })
  return (
    <button disabled={isLoading} onClick={mutate} className="btn btn-sm btn-danger">
      {isLoading ? <Waiting /> : <i className="fas fa-trash"></i>}
    </button>
  )
}

const typeVersements = {
  E: getTrad('credit.type_remboursement.E'),
  C: getTrad('credit.type_remboursement.C'),
  B: getTrad('credit.type_remboursement.B'),
  P: getTrad('credit.type_remboursement.P'),
  V: getTrad('credit.type_remboursement.V'),
}

function Show({ id, first_name, last_name }) {
  const [show, setShow] = useState(false)
  const toogle = () => setShow(!show)

  const {
    isLoading,
    refetch,
    data = [],
  } = useQuery('get-details-veuve-' + id, () =>
    axios.get('/veuve/' + id).then(res => res.data.success)
  )

  return (
    <>
      <button onClick={toogle} className="btn btn-primary">
        <i className="fas fa-eye"></i>
      </button>
      <Modal show={show} onHide={toogle}>
        <Modal.Header closeButton>
          <Modal.Title>
            {first_name} {last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Waiting />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>{getTrad('fileds.date')}</th>
                  <th>{getTrad('fileds.montant')}</th>
                  <th>Type</th>
                  <th>{getTrad('fileds.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(({ id, date, montant, type }) => (
                  <tr key={id}>
                    <td>{showDate(date)}</td>
                    <td>{showMoney(montant)}</td>
                    <td>{typeVersements[type]}</td>
                    <td>
                      <DeletePaiement id={id} refetch={refetch} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Show
