import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import List from './List'
import Show from './Show'

function Saisie() {
  const path = '/credit'
  return (
    <Switch>
      <Route exact strict path={`/credit/saisie/:id`} component={Show}></Route>

      <Route exact strict path={`/credit/saisie`}>
        <List />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  )
}
export default Saisie
